import React, { useState, useEffect } from "react";
import { Header } from "../layoutComponent/Header";
import { useNavigate, Link, useLocation } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { azureProjectIntegration, checkJWTToken } from "../authApi/AuthCRUD";
import "./Integrations.css";

export default function IntegrationsNew() {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserToken = Authentication.Token;

  const { search } = useLocation();
  const titleValue = new URLSearchParams(search).get("value");

  const [orginization, setOrginization] = useState("");
  const [project, setProject] = useState("");
  const [api, setAPI] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formerrors, setFormErrors] = useState({
    ProjectName: "",
    PersonalAccessToken: "",
    Organization: "",
  });

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    }
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.ProjectName) {
      errors.ProjectName = "Project name is required*";
    }
    if (!values.Organization) {
      errors.Organization = "Organization is required*";
    }
    if (!values.PersonalAccessToken) {
      errors.PersonalAccessToken = "Access Token is required*";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        ProjectName: "",
        PersonalAccessToken: "",
        Organization: "",
      });
      return true;
    }
  };

  const postData = () => {
    let data = {
      ProjectName: project,
      PersonalAccessToken: api,
      Organization: orginization,
      IntegrationName: titleValue,
    };
    
    if (validate(data)) {
      setLoading(true)
      azureProjectIntegration(data)
        .then((res) => {
          setLoading(false)
          navigate("/integrations");
        })
        .catch((err) => {
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } 
          else if(err.response.status == 409){
            console.log(err.response.data.Message);
            setError(err.response.data.Message)
            setLoading(false)
          }
            else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
        });
    }
  };

  return (
    <>
      <section className="m-0 d-flex">
        <div className="container-fluid uploadAppHeader">
          <Header title={"Add New Integration"} />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>

          <div className="addUsers mt-3 mb-3" style={{ padding: "30px" }}>
            <div className="card-body p-2">
              <div className="integration-page">
                <h2>Settings</h2>
              <div className="text-center">{error && <p style={{ color: "red", marginTop: '10px', padding: '10px', border: '1px solid' }}>{error}</p>}</div>
                
                <form className="mt-4">
                  <div>
                  
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Organization Name <span className="text-danger">*</span>
                        <input
                          id="input-control"
                          name="application"
                          type="text"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                          onChange={(e) => {
                            setOrginization(e.target.value);
                            setFormErrors({ ...formerrors, Organization: "" });
                          }}
                        />
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formerrors.Organization}
                          </span>
                        </div>
                      </label>
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Project Name <span className="text-danger">*</span>
                        <input
                          id="input-control"
                          name="application"
                          type="text"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                          onChange={(e) => {
                            setProject(e.target.value);
                            setFormErrors({ ...formerrors, ProjectName: "" });
                          }}
                        />
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formerrors.ProjectName}
                          </span>
                        </div>
                      </label>

                      <div className="mt-4">
                        <h4 className="fs-5">Synchronization</h4>
                        <p className="mt-4">To enable time synchronization:</p>
                      </div>

                      <div class="steps-box">
                        <div class="card step">
                          <div class="step-count"></div>
                          <div class="step-content">
                            <span className="step-heading">
                              Create a personal access token
                            </span>{" "}
                            <br />
                            <span className="font-poppins-regular">
                              From the User Settings → Access Tokens page
                            </span>
                          </div>
                        </div>
                        <div class="card step">
                          <div class="step-count"></div>
                          <div class="step-content">
                            <span className="step-heading font-poppins-medium">
                              Copy the generated access token
                            </span>
                            <br />
                            <span className="font-poppins-regular">
                              The access token appears in Your New Personal
                              Access Token field.
                            </span>
                          </div>
                        </div>
                        <div class="card">
                          <div class="step">
                            <div class="step-count"></div>
                            <div class="step-content">
                              <span className="step-heading font-poppins-medium">
                                Paste the API access key in the field below and
                                click Submit.
                              </span>
                            </div>
                          </div>
                          <div class="mt-3">
                            <label class="label-field w-100 mb-2 font-poppins-medium">
                              API access key{" "}
                              <span className="text-danger">*</span>
                              <input
                                class="form-control input-field"
                                placeholder="Enter access key"
                                onChange={(e) => {
                                  setAPI(e.target.value);
                                  setFormErrors({
                                    ...formerrors,
                                    PersonalAccessToken: "",
                                  });
                                }}
                              />
                              <div className="text-alert font-poppins-medium">
                                <span role="alert" className="formErr">
                                  {formerrors.PersonalAccessToken}
                                </span>
                              </div>
                            </label>
                            <h3
                              className="font-poppins-medium"
                              style={{ color: "red" }}
                            >
                              {message}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="d-flex flex-row row-manage mt-5 ">
                  <div className="d-flex">
                  <button
                  type="button"
                  className="btn text-white  buttonDefault font-poppins-medium"
                  disabled={loading}
                  onClick={postData}
                >
                  {!loading && <span>Save</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Save
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                  </div>
                  <div className="d-flex">
                  <Link
                          to="/integrations"
                          className="text-decoration-none"
                        >
                          <button
                            type="button"
                            id="cancel-add-leave"
                            class="btn text-white  buttonDefault cancelbtnhover font-poppins-medium"
                            data-bs-dismiss="modal"
                            style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                          >
                            Cancel
                          </button>
                        </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
