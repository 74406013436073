import React, { useEffect, useState } from "react";
import "../css/style.css";
import "font-awesome/css/font-awesome.min.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import edit from "../Images/edit.svg";
import deleteIcon from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import sorticon from "../Images/sorticon.svg";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { Header } from "../layoutComponent/Header";
import Select from "react-select";
import {
  getUserbyTeam,
  removeUserfromteam,
  getUserToAssignTeam,
  addUserinTeam,
} from "../authApi/AuthCRUD";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

const options = [];
export default function ViewTeamMembers() {
  const navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const { search } = useLocation();
  const teamId = new URLSearchParams(search).get("id");
  let [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [teamname, setTeamname] = useState("");
  const [currentpage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(1);
  let [btnloading, setBtnLoading] = useState(false);
  const [empList, setEmpList] = useState([]);
  let [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    getUsers();
    usersList();
  }, []);

  const getUsers = () => {
    let data = {
      tenantname: Tenant,
      id: teamId,
    };
    getUserbyTeam(data)
      .then((res) => {
        setData(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };
  const handleDelete = (id) => {
    let data = {
      tenantname: Tenant,
      id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this user from team? \n Removed user added into the Default team",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then((result) => {
      if (result.isDenied) {
        removeUserfromteam(data)
          .then((res) => {
            Swal.fire("Sucessfully deleted", "", "success");
            getUsers();
          })
          .catch((err) => {});
      }
    });
  };
  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getUsers();
  };

  const usersList = () => {
    getUserToAssignTeam(teamId)
      .then((res) => {
        res.data.Data.map((res) => {
          options.push({ value: res.value, label: res.label });
        });
        setEmpList(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setSelectedOption(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    selectedOption.forEach((result, i) => {
      let data = {
        tenantname: Tenant,
        tid: teamId,
        uid: result,
      };
      setUrlResponseMsg(true)
      addUserinTeam(data)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
          }, 2000);
          setBtnLoading(false);
        })
        .catch((err) => {
          setBtnLoading(false);
        });
    });
    getUsers();
  };

  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Team Dashboard" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          <div className="mt-4 mb-4">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
                <div className="sb-example-3 w-100">
                  <div className="search__container">
                    <input
                      className="search-input-field font-poppins-regular"
                      type="text"
                      placeholder="Search Users..."
                      autoComplete="on"
                      // onKeyUp={(e) => {
                      //     setSearchKey(e.target.value);
                      //     inputChangeHandler(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                  <div className="btn-div-width">
                    <div
                      type="button"
                      className="btn btn-lg w-100 font-poppins-medium align-center"
                      style={{
                        borderRadius: "5px",
                        borderColor: "#6300B0",
                        background: "#6300B0",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        color: "#FFF",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "10px 20px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#AddNewMember"
                    >
                      <span>+ Add Member</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {!data || data.length === 0 ? (
            <>
              <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                No Result Available
              </h5>
            </>
          ) : (
            <>
              <div className="table-responsive">
                <table
                  className="table"
                  style={{
                    borderBottomWidth: "0px",
                    borderBottomColor: "none",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="tableHead">User Id</th>
                      <th className="tableHead text-center">Name</th>
                      <th className="tableHead text-center">Email</th>
                      {teamId === "1" ? (
                        " "
                      ) : (
                        <>
                          <th className="tableHead">Remove</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((res, i) => {
                      return (
                        <React.Fragment key={i}>
                          <tr style={{ borderRadius: "30px" }}>
                            <td className="front-radius tableCell">
                              <div className="mainDiv d-flex">
                                <p>{res.User_Id}</p>
                              </div>
                            </td>
                            <td className="tableCell">
                              <div className="mainDiv text-center">
                                <p>{res.User_Name}</p>
                              </div>
                            </td>
                            <td className="tableCell">
                              <div className="mainDiv text-center">
                                <p>{res.Email}</p>
                              </div>
                            </td>
                            <td className="tableCell back-radius">
                              {teamId === "1" ? (
                                " "
                              ) : (
                                <>
                                  <div
                                    className="mainDiv text-center cursor-pointer"
                                    onClick={() => {
                                      handleDelete(res.User_Id);
                                    }}
                                  >
                                    <img src={deleteIcon} alt="delete-icon" />
                                  </div>
                                </>
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {(noOfPage == 1 && data.length <= 10) || noOfPage == 0 ? (
                ""
              ) : (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={noOfPage}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              )}
            </>
          )}
        </div>
        <div
          className="modal fade"
          id="AddNewMember"
          data-bs-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddNewMemberLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div id="modal-center" className="modal-content">
              {/* row for heading and cross icon */}
              <div className="row" style={{ padding: "1rem 1rem" }}>
                <div className="col-12">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <h4 className="font-poppins-bold profile-details-heading">
                        Add New Member
                      </h4>
                    </div>
                    <div
                      role="button"
                      id="graph-import-close"
                      data-bs-dismiss="modal"
                    >
                      {/* <img style={{ height: "25px", width: "29px" }} src={crossIcon} alt="cross" /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* row for heading and cross icon end  */}
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="form-group col-12">
                      <label className="w-100 label-field mb-4 font-poppins-medium">
                        Select users which will be assigned to this team
                        <Select
                          className="team-select mt-2"
                          placeholder="Select New Members"
                          isMulti
                          isClearable
                          defaultValue={selectedOption}
                          onChange={handleChange}
                          options={options}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="d-flex flex-row row-manage">
                        <div className="d-flex">
                          <button
                            className="btn  buttonDefault text-white font-poppins-medium"
                            data-bs-dismiss="modal"
                            onClick={handleSubmit}
                          >
                            {!btnloading ? (
                              <span
                                className="indicator-progress font-poppins-regular"
                                style={{ display: "block" }}
                              >
                                Submit
                              </span>
                            ) : (
                              <span
                                className="indicator-progress font-poppins-regular"
                                style={{ display: "block" }}
                              >
                                Submit
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div className="d-flex">
                          <button
                            type="button"
                            id="cancel-add-leave"
                            className="btn text-white cancelbtnhover  buttonDefault font-poppins-medium"
                            style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
}
