import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { shallowEqual, useSelector } from "react-redux";
import {
  getAllUserForReport,
  getEmpLogs,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import ReactPaginate from "react-paginate";
import ClockLoader from "react-spinners/ClockLoader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EmployeeLogs() {
  const navigate = useNavigate();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const loggedInUserId = Authentication.User_Id;
  let [loading, setLoading] = useState(false);
  const loggedInUserName = Authentication.Full_Name;
  const [empList, setEmpList] = useState([]);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  let [currentpage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(1);
  const [user_Id, setUser_Id] = useState(0);
  const [endDate, setEndDate] = useState(today);
  let [emplog, setEmplog] = useState([]);
  let [pagePermission, setPagePermission] = useState([]);
  let [dataPerPage, setDataPerPage] = useState(10);

  useEffect(() => {
    getUserPermission();
    employeeLogs(1, UserRole == "2" ? loggedInUserId : user_Id);
    emplist();
  }, []);

  //UserDropdown
  const emplist = (permissionName = "user_employee_log") => {
    getAllUserForReport(Tenant, permissionName)
      .then((res) => {
        if (!Array.isArray(res.data.Data)) {
          let temp = { ...res.data.Data };
          res.data.Data = [temp];
          setEmpList(res.data.Data);
        } else {
          setEmpList(res.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Employee_logs") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                emplist(list.Sub_Permission_Name);
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  const employeeLogs = (
    currentpage,
    userId,
    startDate = date,
    end_date = endDate,
    pageSize = dataPerPage
  ) => {
    let data = {
      userid: userId,
      tenantname: Tenant,
      Start_Date: startDate,
      End_Date: end_date,
      page: currentpage,
      pagesize: pageSize,
    };
    setLoading(true);
    getEmpLogs(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setEmplog(res.data.Data);
          dataExport(res.data.Data);
          setNoOfPage(res.data.TotalPages);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      employeeLogs(currentpage, user_Id, date, endDate, dataPerPage);
    }
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    employeeLogs(pageNumber, UserRole == "2" ? loggedInUserId : user_Id);
  };

  let userObj = {};
  const [csvData, setCsvData] = useState([]);
  const dataExport = (userData) => {
    let userArr = [];
    userData &&
      userData.forEach((res, i) => {
        userObj = {
          User_Name:res.User_Name,
          Project_Name: res.Project_Name,
          Task_Name: res.Task_Name,
          Start_Date: res.Start_Date,
          Start_Time: res.Start_Time,
          End_Time: res.End_Time,
          Active_Time: res.Active_Time,
        };
        userArr.push(userObj);
      });
    setCsvData(userArr);
  };

      //date selected
const handleStartDateChange = (selectedDate) => {
  const formattedDate = selectedDate
    ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}`
    : null;
    setDate(formattedDate);
                  employeeLogs(
                    currentpage,
                    UserRole == "2" ? loggedInUserId : user_Id,
                    formattedDate,
                    endDate
                  )
};

const handleEndDateChange = (selectedDate) => {
  const formattedDate = selectedDate
    ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}`
    : null;
    setEndDate(formattedDate);
                  employeeLogs(
                    currentpage,
                    UserRole == "2" ? loggedInUserId : user_Id,
                    date,
                    formattedDate
                  );
};


  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Logs" />

        <div className="mt-3 mb-3">
          <div className="row d-flex  align-items-center">
            <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-between align-items-center mt-1">
              <p className="lableRepo">Employees </p>
              <select
                className="form-select team-select borders"
                // value={selectedUser || ""}
                onChange={(e) => {
                  setUser_Id(e.target.value);
                  employeeLogs(currentpage, e.target.value);
                }}
              >
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "admin_employee_log") {
                    return (
                      <>
                        <option defaultValue="0" value="0">
                          All Employees
                        </option>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                  if (list.Sub_Permission_Name === "user_employee_log") {
                    return (
                      <>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </select>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center mt-1">
              <p className="lableRepo">Start&nbsp;Date</p>
              <DatePicker
        selected={date ? new Date(date) : null}
        onChange={handleStartDateChange}
        dateFormat="dd.MM.yyyy"
        className="form-control borders team-select "
        maxDate={new Date()} 
        todayButton="TODAY"
      />

              {/* <input
                type="date"
                defaultValue={date}
                className="borders form-control team-select selectDateSummary"
                max={today}
                onChange={(e) => {
                  setDate(e.target.value);
                  employeeLogs(
                    currentpage,
                    UserRole == "2" ? loggedInUserId : user_Id,
                    e.target.value,
                    endDate
                  );
                }}
              /> */}
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12  d-flex justify-content-center align-items-center mt-1">
              <p className="lableRepo">End&nbsp;Date</p>
              <DatePicker
        selected={endDate ? new Date(date) : null}
        onChange={handleEndDateChange}
        dateFormat="dd.MM.yyyy"
        className="form-control borders team-select "
        maxDate={new Date()} 
        todayButton="TODAY"
      />
              {/* <input
                type="date"
                defaultValue={endDate}
                className="form-control borders team-select selectDateSummary"
                min={date}
                max={today}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  employeeLogs(
                    currentpage,
                    UserRole == "2" ? loggedInUserId : user_Id,
                    date,
                    e.target.value
                  );
                }}
              /> */}
            </div>

            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-12 mt-1 d-flex justify-content-center align-items-center">
                  <div className="btn-div-width w-100">
                    <button
                      type="button"
                      className={
                        emplog == null
                          ? "btn btn-lg exportBtn not-allowed team-select"
                          : "btn btn-lg exportBtn team-select"
                      }
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        borderColor: "#8a899c",
                        background: "#8a899c",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        color: "#FFF",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "10px 20px",
                      }}
                      disabled={emplog == null}
                    >
                      <CSVLink
                        data={csvData}
                        className="csvEx"
                        filename={"user-data.csv"}
                      >
                        Export to CSV
                      </CSVLink>
                    </button>
                  </div>
                </div>
          </div>
        </div>

        <hr />
        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3}/>
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {emplog == null || emplog.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table reports">
                    <thead>
                      <tr>
                        <th className="tableHead"> Employee </th>
                        <th className="tableHead "> Date </th>
                        <th className="tableHead ps-3"> Project</th>
                        <th className="tableHead ps-3"> Task</th>
                        <th className="tableHead "> Start</th>
                        <th className="tableHead "> End </th>
                        <th className="tableHead " style={{width: '100px'}}>Active Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {emplog.map((res, i) => {
                        return (
                          <React.Fragment key={i}>
                            <tr>
                              <td className="tableCell compName front-radius">
                                {res.User_Name}
                              </td>
                              <td className=" tableCell">
                                {moment(res.Start_Date).format("DD.MM.YYYY")}
                              </td>
                              <td className="tableCell">
                                {res.Project_Name}
                              </td>
                              <td className="tableCell">{res.Task_Name}</td>
                              <td className=" tableCell ">
                                {res.Start_Time}
                              </td>
                              <td className=" tableCell">
                                {res.End_Time}
                              </td>
                              <td className=" tableCell back-radius">
                                {res.Active_Time}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
        {(noOfPage === 1 && (!emplog || emplog.length <= 10)) ||
            noOfPage === 0 ? (
              ""
            ) : (
              <div className="paginations d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <p className="lableRepo">Rows per page : </p>
                  <input
                    id="input-control"
                    name="policy"
                    type="text"
                    className="form-control w-20 text-center team-select"
                    autoComplete="off"
                    style={{
                      borderRadius: "6px",
                      height: "30px",
                    }}
                    defaultValue={dataPerPage}
                    onChange={(e) => setDataPerPage(e.target.value)}
                    onKeyDown={testPage}
                  />
                </div>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={noOfPage}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            )}
      </div>
    </section>
  );
}
