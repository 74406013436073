import React, { useEffect, useState } from "react";
import "../css/style.css";
import { Link, useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import enableIcon from "../Images/livedashboard/enable-icon.svg";
import disableIcon from "../Images/livedashboard/disable-icon.svg";
import profile from "../Images/profile.svg";
import ClockLoader from "react-spinners/ClockLoader";
import view from "../Images/livedashboard/view.svg";

import {
  disableRole,
  enableRole,
  getNewRoles,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { shallowEqual, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Card from "react-bootstrap/Card";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

export default function Role() {
  const navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  let [flag, setflag] = useState(false);
  let [currentpage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(1);
  const [searchKey, setSearchKey] = useState(" ");
  let [dataPerPage, setDataPerPage] = useState(20);
  let [pagePermission, setPagePermission] = useState([]);

  useEffect(() => {
    getUserPermission();
    get_Roles();
  }, []);

  const get_Roles = (
    colName = "",
    page = currentpage,
    search = searchKey,
    sorttype = flag,
    pagesize = dataPerPage
  ) => {
    let data = {
      PageNumber: page,
      sortType: sorttype,
      sortColumn: colName,
      searchname: search,
      pageSize: pagesize,
    };
    setLoading(true);
    getNewRoles(data)
      .then((res) => {
        setLoading(false);
        setRoles(res.data.Data);
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    get_Roles("", pageNumber, searchKey, flag);
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      get_Roles("", currentpage, searchKey, flag, dataPerPage);
    }
  };

  const inputChangeHandler = (e) => {
    if (e == "") {
      get_Roles();
    } else {
      get_Roles("", 1, e, flag);
    }
  };

  //disable_Role
  const disable_Role = (id) => {
    disableRole(id)
      .then((res) => {
        get_Roles();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  //enable_Role
  const enable_Role = (id) => {
    enableRole(id)
      .then((res) => {
        get_Roles();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Role") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <>
      <section className="m-0 d-flex pb-2">
        <div className="container-fluid">
          <Header title="Roles" />
          <div className="contentSection mt-4 mb-4">
            <div className="row d-flex align-items-center">
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12  d-flex">
                <div className="sb-example-3 w-100">
                  <div className="search__container">
                    <input
                      className="search-input-field font-poppins-regular"
                      type="text"
                      placeholder="Search Roles..."
                      autoComplete="on"
                      onKeyUpCapture={(e) => {
                        setSearchKey(e.target.value);
                        inputChangeHandler(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "create_role") {
                    return (
                      <>
                        <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center  mt-md-0 mt-lg-0">
                          <div className="btn-div-width">
                            <Link
                              type="button"
                              className="btn btn-lg w-100 font-poppins-medium align-center"
                              style={{
                                borderRadius: "5px",
                                borderColor: "#6300B0",
                                background: "#6300B0",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                color: "#FFF",
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "10px 20px",
                              }}
                              to={{
                                pathname: "/create-role",
                              }}
                            >
                              <span>Create New Roles</span>
                            </Link>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <hr />
          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              {roles.length === 0 ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No Result Available
                  </h5>
                </>
              ) : (
                <>
                  <div className="row mt-5">
                    {roles.map((res, i) => (
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-2">
                        {res.Is_Enable ? (
                          <>
                            <Card
                              className="p-3"
                              style={{ borderRadius: "10px" }}
                            >
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="compName">
                                  <Link
                                    to={`/view-roles?roleid=${res.Role_Id}`}
                                  >
                                    {res.Role_Name}
                                  </Link>
                                </p>
                                <div>
                                  <div className="">
                                    {pagePermission.map((list) => {
                                      if (
                                        list.Sub_Permission_Name === "read_role"
                                      ) {
                                        return (
                                          <div className="Maindiv d-flex justify-content-center align-items-center">
                                          <div className="edit me-2" title="Update Role">
                                          {pagePermission.map((list) => {
                                                  if (
                                                    list.Sub_Permission_Name ===
                                                    "update_role"
                                                  ) {
                                                    return (
                                                      <>
                                                          <Link
                                                            to={`/edit-role?id=${res.Role_Id}`}
                                                          >
                                                              <img width={20}
                                                                src={edit}
                                                                alt="icon"
                                                              />
                                                          </Link>
                                                      </>
                                                    );
                                                  }
                                                })}
                                          </div>
                                          <div className="view" title="View Role">
                                          <Link
                                                      to={`/view-roles?roleid=${res.Role_Id}`}
                                                      className="drpdwnitem"
                                                      style={{
                                                        color:
                                                          "#59A561 !important",
                                                      }}
                                                    >
                                                    <img
                                                    width={22} style={{marginTop: '3px'}}
                                                      src={view}
                                                      alt="icon"
                                                    />
                                                    </Link>
                                          </div>
                                        </div>
                                          
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                {/* <span className="total-users font-mont-regular">
                                      Total {res.Usercount} users
                                    </span> */}
                              </div>
                            </Card>
                          </>
                        ) : (
                          <>
                            <Card
                              className="p-3 disableRoles"
                              style={{ borderRadius: "10px" }}
                            >
                              <div className="d-flex justify-content-between">
                                <p className="compName">
                                  <Link
                                    to={`/view-roles?roleid=${res.Role_Id}`}
                                  >
                                    {res.Role_Name}
                                  </Link>
                                </p>
                                <div>
                                  <div className="">
                                    {pagePermission.map((list) => {
                                      if (
                                        list.Sub_Permission_Name === "read_role"
                                      ) {
                                        return (
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              as={CustomToggle}
                                            />
                                            <Dropdown.Menu size="sm">
                                              {/* {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "update_role"
                                            ) {
                                              return (
                                                <>
                                                  <Dropdown.Item
                                                    class="drpdwnitem"
                                                    style={{
                                                      color: "#616773",
                                                      opacity: "0.9",
                                                      fontSize: "12px",
                                                      fontWeight: "400",
                                                      fontFamily: "Poppins",
                                                      fontStyle: "normal",
                                                    }}
                                                  >
                                                    <Link
                                                    className="d-flex align-items-center"
                                                      to={`/edit-role?id=${res.Role_Id}`}
                                                    >
                                                        <img
                                                          src={edit}
                                                          alt="icon"
                                                        />
                                                        &nbsp; Edit
                                                    </Link>
                                                  </Dropdown.Item>
                                                </>
                                              );
                                            }
                                          })} */}
                                              <Dropdown.Item
                                                class="drpdwnitem"
                                                style={{
                                                  color: "#59A561",
                                                  opacity: "0.9",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                  fontFamily: "Poppins",
                                                  fontStyle: "normal",
                                                }}
                                              >
                                                <Link
                                                  className="d-flex align-items-center"
                                                  to={`/view-roles?roleid=${res.Role_Id}`}
                                                >
                                                  <img
                                                    src={profile}
                                                    alt="icon"
                                                  />
                                                  &nbsp; Viewfryjr
                                                </Link>
                                              </Dropdown.Item>

                                              {pagePermission.map((list) => {
                                                if (
                                                  list.Sub_Permission_Name ===
                                                  "delete_role"
                                                ) {
                                                  return (
                                                    <>
                                                      <Dropdown.Item
                                                        className="drpdwnitem"
                                                        style={{
                                                          color: "#FF4E4E",
                                                          opacity: "0.9",
                                                          fontSize: "12px",
                                                          fontWeight: "400",
                                                          fontFamily: "Poppins",
                                                          fontStyle: "normal",
                                                        }}
                                                      >
                                                        {!res.Is_Enable ? (
                                                          <div
                                                            className="d-flex"
                                                            id="1"
                                                            style={{
                                                              color: "blue",
                                                            }}
                                                            onClick={() =>
                                                              enable_Role(
                                                                res.Role_Id
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src={enableIcon}
                                                              alt="icon"
                                                            />
                                                            <div>
                                                              {" "}
                                                              &nbsp; Enable
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className="d-flex"
                                                            id="2"
                                                            onClick={() =>
                                                              disable_Role(
                                                                res.Role_Id
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src={disableIcon}
                                                              alt="icon"
                                                            />
                                                            <div>
                                                              {" "}
                                                              &nbsp; Disable
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Dropdown.Item>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                {/* <span className="total-users font-mont-regular">
                                      Total {res.Usercount} users
                                    </span> */}
                              </div>
                            </Card>
                          </>
                        )}
                        {/* <Card className="p-3" style={{ borderRadius: "10px" }}>
                          <div className="d-flex justify-content-between">
                            <p className="compName">
                              <Link to={`/view-roles?roleid=${res.Role_Id}`}>
                                {res.Role_Name}
                              </Link>
                            </p>
                            <div>
                              <div className="">
                                {pagePermission.map((list) => {
                                  if (
                                    list.Sub_Permission_Name === "read_role"
                                  ) {
                                    return (
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm">
                                          {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "update_role"
                                            ) {
                                              return (
                                                <>
                                                  <Dropdown.Item
                                                    class="drpdwnitem"
                                                    style={{
                                                      color: "#616773",
                                                      opacity: "0.9",
                                                      fontSize: "12px",
                                                      fontWeight: "400",
                                                      fontFamily: "Poppins",
                                                      fontStyle: "normal",
                                                    }}
                                                  >
                                                    <Link
                                                    className="d-flex align-items-center"
                                                      to={`/edit-role?id=${res.Role_Id}`}
                                                    >
                                                        <img
                                                          src={edit}
                                                          alt="icon"
                                                        />
                                                        &nbsp; Edit
                                                    </Link>
                                                  </Dropdown.Item>
                                                </>
                                              );
                                            }
                                          })}
                                          <Dropdown.Item
                                            class="drpdwnitem"
                                            style={{
                                              color: "#59A561",
                                              opacity: "0.9",
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              fontFamily: "Poppins",
                                              fontStyle: "normal",
                                            }}
                                          >
                                              <Link className="d-flex align-items-center"
                                                to={`/view-roles?roleid=${res.Role_Id}`}
                                              >
                                              <img src={profile} alt="icon" />
                                              &nbsp;
                                                View
                                              </Link>
                                          </Dropdown.Item>

                                          {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "delete_role"
                                            ) {
                                              return (
                                                <>
                                                  <Dropdown.Item
                                                    className="drpdwnitem"
                                                    style={{
                                                      color: "#FF4E4E",
                                                      opacity: "0.9",
                                                      fontSize: "12px",
                                                      fontWeight: "400",
                                                      fontFamily: "Poppins",
                                                      fontStyle: "normal",
                                                    }}
                                                  >
                                                    {!res.Is_Enable ? (
                                                      <div
                                                        className="d-flex"
                                                        id="1"
                                                        style={{
                                                          color: "blue",
                                                        }}
                                                        onClick={() =>
                                                          enable_Role(
                                                            res.Role_Id
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={enableIcon}
                                                          alt="icon"
                                                        />
                                                        <div>
                                                          {" "}
                                                          &nbsp; Enable
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="d-flex"
                                                        id="2"
                                                        onClick={() =>
                                                          disable_Role(
                                                            res.Role_Id
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={disableIcon}
                                                          alt="icon"
                                                        />
                                                        <div>
                                                          {" "}
                                                          &nbsp; Disable
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Dropdown.Item>
                                                </>
                                              );
                                            }
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                        </Card> */}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
          {(noOfPage === 1 && (!roles || roles.length <= 20)) ||
          noOfPage === 0 ? (
            ""
          ) : (
            <div className="paginations d-flex justify-content-between align-items-center mt-2">
              <div className="d-flex justify-content-center align-items-center">
                <p className="lableRepo">Rows per page : </p>
                <input
                  id="input-control"
                  name="policy"
                  type="text"
                  className="form-control w-20 text-center team-select"
                  autoComplete="off"
                  style={{
                    borderRadius: "6px",
                    height: "30px",
                  }}
                  defaultValue={dataPerPage}
                  onChange={(e) => setDataPerPage(e.target.value)}
                  onKeyDown={testPage}
                />
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={noOfPage}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
}

{
  /* <div className="text-danger d-flex justify-content-center">
  <h5
    className=" font-poppins-medium"
    style={{
      marginTop: "10rem",
      border: "1px solid",
      padding: "10px",
    }}
  >
    You don't have Permission to view Role but you have Permission to create
    role.
  </h5>
</div> */
}
