import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useNavigate, Link } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import moment from "moment";
import "./Integrations.css";
import Swal from "sweetalert2";
import {
  getIntegrations,
  deleteIntegration,
  checkJWTToken,
  checkUserPermissions,
  enableIntegration,
  disableIntegration,
} from "../authApi/AuthCRUD";
import ClockLoader from "react-spinners/ClockLoader";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="actionBtn"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </button>
));

export default function IntegrationsList() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const UserRole = Authentication.User_Role;
  const Tenant = Authentication.Tenant;
  const [data, setData] = useState([]);
  const UserToken = Authentication.Token;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getUserPermission();
      getIntegrationData();
    }
  }, []);

  const getIntegrationData = () => {
    setLoading(true);
    getIntegrations()
      .then((response) => {
        setLoading(false);
        setData(response.data.Data);
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const handleNewIntegration = (event) => {
    let value = event.target.value;
    navigate(`/integrations-new?value=${value}`);
  };

  const removeIntegration = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Integration?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then((result) => {
      if (result.isDenied) {
        deleteIntegration(id)
          .then((res) => {
            Swal.fire("Sucessfully deleted", "", "success");
            getIntegrationData();
          })
          .catch((err) => {});
      }
    });
  };

  //DisableUser
  const disableUser = (id) => {
    setLoading(true);
    disableIntegration(id)
      .then((res) => {
        setLoading(false);
        getIntegrationData();
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };
  //EnableUser
  const enableUser = (id) => {
    setLoading(true);
    enableIntegration(id)
      .then((res) => {
        setLoading(false);
        getIntegrationData();
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  let [pagePermission, setPagePermission] = useState([]);
  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Inegrations") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <>
      <section className="m-0 d-flex">
        <div className="container-fluid">
          <Header title="Integrations" />
          <div className="contentSection mt-4 mb-2">
            <div className="row">
              <div className="col-lg-8 mt-2 col-md-7 col-sm-12 col-xs-12 d-flex">
                <div className="sb-example-3 w-100">
                  <div className="search__container">
                    <input
                      className="search-input-field font-poppins-regular"
                      type="text"
                      placeholder="Search Integrations..."
                      autoComplete="on"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="integration-drop mt-2 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <div className="d-flex justify-content-lg-end justify-content-md-end align-items-center">
                  <label className="lableRepo" style={{ marginRight: "10px" }}>
                    Select Integration
                  </label>
                  <select className="form-select team-select integration-select">
                  <option value="Select Integration">
                  Select Integration
                    </option>
                    <option value="AzureDevOps" onClick={handleNewIntegration}>
                      Azure DevOps
                    </option>
                    <option value="jira" onClick={handleNewIntegration}>
                      Jira Software
                    </option>
                    <option value="clickup" onClick={handleNewIntegration}>
                      Click Up
                    </option>
                  </select>
                </div>
              </div> */}
              {pagePermission.map((list) => {
                if (list.Sub_Permission_Name === "create_Inegration") {
                  return (
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-2 align-items-center">
                      <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center align-items-center mt-2 mt-md-0 mt-lg-0">
                        <button
                          class="integrationBtn active"
                          value="AzureDevOps"
                          onClick={handleNewIntegration}
                        >
                          Azure DevOps Integration
                        </button>
                        {/* <div class="dropdown">
                          <button
                            class="btn dropdown-toggle drop-dwn-new-integration shadow-none font-poppins-medium"
                            type="button"
                            id="dropdownMenuButton2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            New Integration &nbsp;
                          </button>
                          <ul
                            class="dropdown-menu dropdown-menu-start"
                            aria-labelledby="dropdownMenuButton2"
                          >
                            <li className="integration-li px-1">
                              <button
                                class="dropdown-item active"
                                value="AzureDevOps"
                                onClick={handleNewIntegration}
                              >
                                Azure DevOps
                              </button>
                            </li>
                            <li className="integration-li px-1">
                              <button
                                class="dropdown-item"
                                value="jira"
                                onClick={handleNewIntegration}
                              >
                                Jira Software
                              </button>
                            </li>
                            <li className="integration-li px-1">
                              <button
                                class="dropdown-item"
                                value="clickup"
                                onClick={handleNewIntegration}
                              >
                                Click Up
                              </button>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              {!data || data.length === 0 ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No Result Available
                  </h5>
                </>
              ) : (
                <>
                  <div className="table-responsive">
                    <table
                      className="table leaves"
                      style={{
                        borderBottomWidth: "0px",
                        borderBottomColor: "none",
                      }}
                    >
                      <thead>
                        <tr>
                          <th className="tableHead leaveTable ps-0">
                            Integration Type
                          </th>
                          <th
                            className="tableHead leaveTable"
                            style={{ width: "250px" }}
                          >
                            Organization
                          </th>
                          <th className="tableHead leaveTable text-center">
                            Creation Date
                          </th>
                          <th className="tableHead leaveTable text-center">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((res, i) => (
                          <tr
                            className=""
                            style={{ borderRadius: "30px" }}
                            key={i}
                          >
                            <td
                              className={
                                res.Is_Enable
                                  ? "tableCell front-radius compName"
                                  : "cellInactive front-radius compName"
                              }
                            >
                              <div className="mainDiv">
                                {res.Integration_Name}
                              </div>
                            </td>
                            <td
                              className={
                                res.Is_Enable ? "tableCell" : "cellInactive"
                              }
                            >
                              <div className="mainDiv">{res.Organization}</div>
                            </td>
                            <td
                              className={
                                res.Is_Enable
                                  ? "tableCell text-center"
                                  : "cellInactive text-center"
                              }
                            >
                              <div className="mainDiv">
                                {moment(res.Created_Date).format("DD.MM.YYYY")}
                              </div>
                            </td>

                            <td
                              className={
                                res.Is_Enable
                                  ? "tableCell back-radius"
                                  : "cellInactive back-radius"
                              }
                            >
                              {pagePermission.map((list) => {
                                if (
                                  list.Sub_Permission_Name === "read_Inegration"
                                ) {
                                  return (
                                    <div className="mainDiv text-center">
                                      <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="">
                                          {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "update_Inegration"
                                            ) {
                                              return (
                                                <>
                                                  {!res.Is_Enable ? (
                                                    ""
                                                  ) : (
                                                    <Dropdown.Item className="drpdwnitem">
                                                      <Link
                                                        to={`/edit-integration?id=${res.Id}`}
                                                      >
                                                        <div className="d-flex">
                                                          <img
                                                            src={edit}
                                                            alt="icon"
                                                          />
                                                          <div>&nbsp; Edit</div>
                                                        </div>
                                                      </Link>
                                                    </Dropdown.Item>
                                                  )}
                                                </>
                                              );
                                            }
                                          })}

                                          {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "delete_Inegration"
                                            ) {
                                              return (
                                                <>
                                                  <Dropdown.Item className="drpdwnitem">
                                                    {!res.Is_Enable ? (
                                                      <div
                                                        className="d-flex"
                                                        id="1"
                                                        style={{
                                                          color: "blue",
                                                        }}
                                                        onClick={() =>
                                                          enableUser(res.Id)
                                                        }
                                                      >
                                                        <img
                                                          src={delet}
                                                          alt="icon"
                                                        />
                                                        <div>
                                                          {" "}
                                                          &nbsp; Enable
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="d-flex"
                                                        style={{ color: "red" }}
                                                        id="2"
                                                        onClick={() =>
                                                          disableUser(res.Id)
                                                        }
                                                      >
                                                        <img
                                                          src={delet}
                                                          alt="icon"
                                                        />
                                                        <div>
                                                          {" "}
                                                          &nbsp; Disable
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Dropdown.Item>
                                                </>
                                              );
                                            }
                                          })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  );
                                }
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}
