import React, { useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useNavigate } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { addLeaveType } from "../authApi/AuthCRUD";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddLeave.css";
import { Link } from "react-router-dom";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { getListLeaveType, searchProjectName } from "../authApi/AuthCRUD";
import { useEffect } from "react";


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </button>
));
const leaveSchema = Yup.object().shape({
  Leave_Name: Yup.string().required("Leave name is required"),
  Subject_Of_Approval: Yup.string().required("Subject is required"),
});
export default function AddLeave() {
  const navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [leaveList, setleaveList] = useState([]);
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);
  const Tenant = Authentication.Tenant;
  const createdById = Authentication.User_Id;

  useEffect(() => {
    getLeave();
  }, []);

  const getLeave = () => {
    getListLeaveType(Tenant)
      .then((res) => {
        setleaveList(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues: {
      Leave_Name: "",
      Allow_Half_Leave: false,
      Allow_Quarter_Leave: false,
      Record_Without_Approval: false,
      Subject_Of_Approval: "",
      Created_By: createdById,
      TenantName: Tenant,
    },
    validationSchema: leaveSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let data = {
        Leave_Name: values.Leave_Name,
        Allow_Half_Leave: values.Allow_Half_Leave,
        Allow_Quarter_Leave: values.Allow_Quarter_Leave,
        Record_Without_Approval: values.Record_Without_Approval,
        Subject_Of_Approval: values.Subject_Of_Approval,
        Created_By: createdById,
        TenantName: Tenant,
      };
      // setLoading(true);
      // setUrlResponseMsg(true);
      // addLeaveType(data)
      //     .then((res) => {
      //         console.log(res);
      //         setLoading(false);
      //         document.getElementById('cancel-add-leave').click();
      //         toast.success(res.data.Message, {
      //             position: "top-right",
      //             autoClose: 3000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: false,
      //             progress: undefined,
      //         });
      //     })
      //     .catch((error) => {
      //         // console.log(error.res.data)
      //         setLoading(false);
      //     });
    },
  });

  //Searching
  let [data, setData] = useState([]);
  const [timer, setTimer] = useState(null);
  const searchHandler = (e) => {
    let typingVal = e.target.value;
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      if (typingVal !== "") {
        searchProjectName(Tenant, typingVal).then((response) => {
          if (response.data.Data.length === 0) {
            setData([]);
            setLoading(false);
          } else {
            setData(response.data.Data);
            setLoading(false);
          }
        });
      } else {
        setLoading(true);
        getLeave();
      }
    }, 500);
    setTimer(newTimer);
  };
  return (
    <>
      <div className="container-fluid">
        <Header title="Leave" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="col-12 bg-white p-3">
          <div className="col-lg-3">
            <h3 className="font-poppins-medium fs-5">Leave Type</h3>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
            <div className="sb-example-3 w-100">
              <div className="search__container">
                <input
                  className="search-input-field font-poppins-regular"
                  type="text"
                  placeholder="Search Projects..."
                  autoComplete="on"
                  onChange={searchHandler}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            {/* <div className="d-flex justify-content-end"> */}
            <button
              id="leave-button"
              data-bs-toggle="modal"
              data-bs-target="#AddNewLeave"
              className="btn add-leave-modal-btn"
            >
              Add New Leave Type
            </button>
          </div>
          {/* </div> */}
        </div>

        <div
          className="modal fade"
          id="AddNewLeave"
          data-bs-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddNewLeave"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div
              id="modal-center"
              className="modal-content"
              style={{ padding: "22px 32px" }}
            >
              {/* row for heading and cross icon */}
              <div className="row">
                <div className="col-12">
                  <div className="mx-3 d-flex justify-content-between">
                    <div className="d-flex">
                      <h4 className="font-poppins-bold profile-details-heading">
                        Leave Type
                      </h4>
                    </div>
                    <div
                      role="button"
                      id="graph-import-close"
                      data-bs-dismiss="modal"
                    >
                      {/* <img style={{ height: "25px", width: "29px" }} src={crossIcon} alt="cross" /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* row for heading and cross icon end  */}
              <div className="modal-body padding-profile">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row margin-row">
                    <div className="form-group col-sm-12 col-md-6  col-lg-6 col-xl-6 mb-4">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Leave Type
                        <input
                          id="input-control"
                          type="text"
                          value={formik.values.Leave_Name}
                          {...formik.getFieldProps("Leave_Name")}
                          onChange={formik.handleChange}
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                          placeholder="Leave Type"
                        />
                      </label>
                    </div>
                    {formik.values.Leave_Name == "" &&
                      formik.touched.Leave_Name &&
                      formik.errors.Leave_Name && (
                        <div>
                          <div className="text-alert font-poppins-medium">
                            <span role="alert" className="formErr">
                              {formik.errors.Leave_Name}
                            </span>
                          </div>
                        </div>
                      )}
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Subject of Approval
                        <input
                          id="input-control"
                          type="text"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                          value={formik.values.Subject_Of_Approval}
                          onChange={formik.handleChange}
                          {...formik.getFieldProps("Subject_Of_Approval")}
                          placeholder="Subject"
                        />
                      </label>
                    </div>
                    {formik.values.Subject_Of_Approval == "" &&
                      formik.touched.Subject_Of_Approval &&
                      formik.errors.Subject_Of_Approval && (
                        <div>
                          <div className="text-alert font-poppins-medium">
                            <span role="alert" className="formErr">
                              {formik.errors.Subject_Of_Approval}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                  <div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                      <div className="col-12">
                        <div className="form-check">
                          <input
                            className="form-check-input input-checkbox"
                            type="checkbox"
                            // value="false"
                            id="flexCheckDefault"
                            checked={formik.values.Allow_Half_Leave}
                            {...formik.getFieldProps("Allow_Half_Leave")}
                          />
                          <span
                            className="font-poppins-regular"
                            id="remember_me_chkbox"
                          >
                            Allow half leave
                          </span>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input input-checkbox"
                            type="checkbox"
                            // value="false"
                            id="flexCheckDefault"
                            checked={formik.values.Allow_Quarter_Leave}
                            {...formik.getFieldProps("Allow_Quarter_Leave")}
                          />
                          <span
                            className="font-poppins-regular"
                            id="remember_me_chkbox"
                          >
                            Allow quarter leave
                          </span>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input input-checkbox"
                            type="checkbox"
                            // value="false"
                            id="flexCheckDefault"
                            checked={formik.values.Record_Without_Approval}
                            {...formik.getFieldProps("Record_Without_Approval")}
                          />
                          <span
                            className="font-poppins-regular"
                            id="remember_me_chkbox"
                          >
                            Record without approval
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 p-0">
                    <div className="d-flex justify-content-end f-manage">
                      <div className="d-flex flex-row row-manage">
                        <div className="d-flex">
                          <button
                            type="submit"
                            className="btn save-btn text-white font-poppins-medium"
                          >
                            {!loading && <span>Submit</span>}
                            {loading && (
                              <span
                                className="indicator-progress font-poppins-regular"
                                style={{ display: "block" }}
                              >
                                Submit
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div className="d-flex">
                          <button
                            type="button"
                            id="cancel-add-leave"
                            className="btn text-white
                                                    cancel-leave-btn
                                                    font-poppins-medium"
                            data-bs-dismiss="modal"
                            onClick={formik.resetForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 bg-white p-3 mb-3">
          <div className="table table-responsive">
            <table
              className="table  table-responsive"
              style={{ borderBottomWidth: "0px", borderBottomColor: "none" }}
            >
              <thead className="">
                <tr>
                  <th className="tableHead">Subject &nbsp;</th>
                  <th className="tableHead">Quarter Leave &nbsp;</th>
                  <th className="tableHead">Half Leave &nbsp;</th>
                  <th className="tableHead">Subject_Of_Approval &nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {leaveList.map((list, i) => (
                  <tr className="" key={i}>
                    <td className="front-radius">
                      <div>
                        <span>{list.Leave_Name}</span>
                      </div>
                    </td>
                    <td className="front-radius">
                      <div>
                        <span>
                          {!list.Quarter_Leave == true ? (
                            <div className="tick" style={{ color: "#111" }}>
                              ✔️
                            </div>
                          ) : (
                            <div className="tick" style={{ color: "#111" }}>
                              X
                            </div>
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="front-radius">
                      <div>
                        <span>
                          {!list.Half_Leave == true ? (
                            <div className="tick" style={{ color: "#111" }}>
                              ✔️
                            </div>
                          ) : (
                            <div className="tick" style={{ color: "#111" }}>
                              X
                            </div>
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="front-radius">
                      <div>
                        <span>{list.Subject_Of_Approval}</span>
                      </div>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} />
                        <Dropdown.Menu size="sm" title="">
                          <Dropdown.Item
                            className="drpdwnitem"
                            style={
                              !list.Status ? { pointerEvents: "none" } : {}
                            }
                          >
                            <div>
                              <a
                                id="leave-button"
                                data-bs-toggle="modal"
                                data-bs-target="#UpdateLeave"
                              >
                                Edit
                              </a>
                              {/* <button id="leave-button" data-bs-toggle="modal" data-bs-target="#UpdateLeave" className="btn ">Add New Leave Type</button> */}
                            </div>
                            <div
                              className="modal fade"
                              id="UpdateLeave"
                              data-bs-backdrop="static"
                              tabIndex="-1"
                              role="dialog"
                              aria-labelledby="UpdateLeave"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-xl">
                                <div
                                  id="modal-center"
                                  className="modal-content"
                                  style={{ padding: "22px 32px" }}
                                >
                                  <form onSubmit={formik.handleSubmit}>
                                    <div className="row margin-row">
                                      <div className="form-group col-sm-12 col-md-6  col-lg-6 col-xl-6 mb-4">
                                        <label className="w-100 label-field mb-2 font-poppins-medium">
                                          Leave Name
                                          <input
                                            {...formik.getFieldProps(
                                              "Leave_Name"
                                            )}
                                            id="input-control"
                                            type="text"
                                            className="mt-2 form-control form-input-field"
                                            autoComplete="off"
                                            placeholder="Leave Name"
                                          />
                                        </label>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item className="drpdwnitem">
                            <div className="d-flex">
                              <Link>
                                <div className="d-flex">
                                  <img src={delet} alt="icon" />
                                  <div>&nbsp; Delete</div>
                                </div>
                              </Link>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
}
