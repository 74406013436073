import React, { useEffect, useState } from "react";
import "./ProfilePage.css";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import ProfileIamge from "../Images/profileimage.png";
import editProfile from "../Images/edit.svg";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import {
  getUserProfile,
  updateUserProfile,
  uploadProfileLogo,
} from "../authApi/AuthCRUD";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { profilePic, updateProfile } from "../reduxAuthentication/actions";
import { useDispatch } from "react-redux";

export default function ProfilePage() {
  let Detail = useSelector((store) => store.auth.data, shallowEqual);
  const storedImg = useSelector(
    (store) => store.auth.userProfile,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [userStoreData, setUserStoreData] = useState([]);
  let fullName = [];
  let initials = "";
  fullName = Detail.Full_Name.split(" ");
  if (fullName.length === 2) {
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  } else {
    initials = fullName.shift().charAt(0);
  }
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);
  const [imageSizeErr, setImageSizeErr] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [storedProfileImage, setStoredProfileImage] = useState("");
  useEffect(() => {
    let isMounted = true;
    setUserStoreData(Detail);
    setStoredProfileImage(storedImg);
    getUserProfile(Detail.Tenant, Detail.Email)
      .then((response) => {
        if (isMounted) {
          setUserDetail(response.data.Data);
          // window.location.reload(false);
        }
        return () => {
          isMounted = false;
        };
      })
      .catch((err) => {
        setUserDetail([]);
        // window.location.reload(false);
      });
  }, [storedImg]);

  const formik = useFormik({
    initialValues: {
      Email: Detail.Email,
      UserName: Detail.User_Name,
      FullName: Detail.Full_Name,
      TenantName: Detail.Tenant,
    },
    // validationSchema: passwordFormValidationSchema,
    onSubmit: (values, { setStatus, resetForm, setSubmitting, setErrors }) => {
      let data = {
        Email: values.Email,
        UserName: values.UserName,
        Full_Name: values.FullName,
        TenantName: values.TenantName,
      };
      const imgData = new FormData();
      imgData.append("email", values.Email);
      imgData.append("TenantName", values.TenantName);
      imgData.append("UserProfile", selectedProfile);
      setLoading(true);
      setDisable(true);
      setUrlResponseMsg(true);
      setTimeout(() => {
        updateUserProfile(data)
          .then((res) => {
            // dispatch(updateProfile(res.data.Data));
            document.getElementById("cancel-profile-edit").click();
            toast.success(res.data.Message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            dispatch(profilePic(res.data.Data.ProfileImage));
            // setLoading(false)
            // window.location.reload(false);
            setDisable(false);
          })
          .catch((err) => {
            setLoading(false);
            setDisable(false);
          });
        // uploadProfileLogo(imgData).then((res) => {
        //     setProfileLoading(false);
        //     dispatch(profilePic(res.data.Data.ProfileImage));
        // }).catch((err) => {
        //     setProfileLoading(false);
        // })
      }, 1000);
    },
  });

  // const removeLogo = () => {
  //     setFile({ preview_image: "" });
  //     document.getElementById("bdrImg").classList.remove("bdr");
  // };
  const UploadImage = (e) => {
    const imgSelected = e.target.files[0];
    setSelectedProfile(imgSelected);
    if (!imgSelected.name.match(/\.(jpg|png|jpeg|gif)$/)) {
      setImageSizeErr("File must be one of jpg,png");
    } else if (2 * 1024 * 1024 < imgSelected.size) {
      setImageSizeErr("File must be of size  2MB only ");
    } else {
      setImageSizeErr("");
      setProfileLoading(true);
      const imgData = new FormData();
      imgData.append("email", userStoreData.Email);
      imgData.append("TenantName", userStoreData.Tenant);
      imgData.append("UserProfile", imgSelected);
      uploadProfileLogo(imgData)
        .then((res) => {
          setProfileLoading(false);
          dispatch(profilePic(res.data.Data.ProfileImage));
        })
        .catch((err) => {
          setProfileLoading(false);
        });
    }
  };
  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Profile" />
          <div className="row mt-3 mb-3">
            <div className="col-12">
              <div className="text-start">
                <div
                  className="d-flex align-items-center"
                  role="button"
                  onClick={() => navigate(-1)}
                >
                  <i>
                    <img src={backArrow} alt="back-Arrow" />
                  </i>
                  <span className="font-poppins-regular backBtn">
                    &nbsp;&nbsp;&nbsp;Back
                  </span>
                </div>
              </div>

              <div
                id="card-part-user"
                className="card display-profile-card mt-4 mb-4"
              >
                {/* begin::profile header code for md and lg screen */}
                <div className="card-header bg-white d-none d-md-block d-lg-block p-4">
                  <div className="card-title m-0 d-flex justify-content-between">
                    <h3 className="fw-bolder font-poppins-bold m-0 profile-details-heading">
                      Profile Details
                    </h3>

                    {/* <div
                      className="d-flex"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#EditProfile"
                    >
                      <img
                        src={editProfile}
                        alt="edit profile"
                        className="edit-profile-icon"
                      />
                    </div> */}
                  </div>
                </div>
                {/* end::profile header code for md and lg screen */}

                {/* begin::profile header code for sm screen */}
                <div className="card-header cursor-pointer bg-white d-md-none d-lg-none">
                  <div className="card-title m-0">
                    <div className="row d-flex text-center">
                      <h5 className="fw-bolder font-poppins-bold m-0">
                        Profile Details
                      </h5>
                    </div>
                    {/* <div
                      className="row d-flex text-center mt-2"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#EditProfile"
                    >
                      <img
                        src={editProfile}
                        alt="edit profile"
                        className="edit-profile-icon"
                      />
                    </div> */}
                  </div>
                </div>
                {/* end::profile header code for sm screen */}

                {/* begin::profile card body*/}
                <div className="card-body">
                  <div className="row">
                    <div className="col-xs-0 col-sm-12 col-md-4 col-lg-4 col-xl-4 user-profile">
                      <div className="card-block text-center text-white">
                        <div className="m-b-25">
                          <div className="d-flex justify-content-center">
                            <div className="ProfileContent">
                              {storedProfileImage &&
                              storedProfileImage != "null" ? (
                                <>
                                  <img
                                    src={storedProfileImage}
                                    alt="ProfileIamge"
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <div
                                    className="rounded-circle mb-3 d-flex justify-content-center"
                                    style={{
                                      width: " 150px",
                                      height: "150px",
                                      background:
                                        "linear-gradient(264.71deg, #8F009B 18.58%, #420085 128.77%)",
                                    }}
                                  >
                                    <div className="d-flex span-text text-uppercase">
                                      {initials}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="text-center mb-2 pt-3">
                            <input
                              id="input-img"
                              type="file"
                              style={{ display: "none" }}
                              onChange={UploadImage}
                            />
                            <span
                              onClick={() => {
                                document.getElementById("input-img").click();
                              }}
                              className="label-profile font-poppins-regular text-secondary"
                              role="button"
                            >
                              {storedProfileImage &&
                              storedProfileImage != "null"
                                ? "Change Photo"
                                : "Add Photo"}
                            </span>
                            <div>
                              <span
                                className="font-poppins-regular"
                                style={{
                                  color: "red",
                                  fontSize: "0.89rem",
                                  display: "block",
                                }}
                              >
                                {imageSizeErr}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-0 d-none d-md-block d-lg-block col-md-8 col-lg-8 col-xl-8 mt-4">
                      <div className="card-block">
                        <div className="d-flex justify-content-between b-b-default">
                          <h3
                            className="m-b-20 p-b-5"
                            style={{
                              fontSize: "25px",
                              fontWeight: "500",
                            }}
                          >
                            Information
                          </h3>
                          {/* <img src={editProfile} alt="edit profile" height={"32px"} role="button" data-bs-toggle="modal" data-bs-target="#EditProfile" /> */}
                          {/* <span className="mont-font-semi-bold f-w-600" role="button" data-bs-toggle="modal" data-bs-target="#EditProfile">Edit your profile&nbsp;<img src={editProfile} alt="edit profile" height={"32px"} role="button" data-bs-toggle="modal" data-bs-target="#EditProfile" /></span> */}
                        </div>

                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              User Name
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userDetail.UserName}
                              </span>
                            ) : (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userStoreData.User_Name}
                              </span>
                            )}
                          </div>
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              Full Name
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userDetail.FullName}
                              </span>
                            ) : (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userStoreData.Full_Name}
                              </span>
                            )}
                          </div>
                        </div>
                        <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                        <div className="row">
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              Email
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userDetail.Email}
                              </span>
                            ) : (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userStoreData.Email}
                              </span>
                            )}
                          </div>
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              Company Name
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                                {userDetail.TenantName}
                              </span>
                            ) : (
                              <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                                {userStoreData.Tenant}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 d-md-none d-lg-none">
                      <div className="card-block">
                        <div className="text-center b-b-default">
                          <h3 className="m-b-20 p-b-5 mont-font-semi-bold">
                            Information
                          </h3>
                        </div>
                        <div className="row mt-3 text-center">
                          <div className="col-sm-12">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              User Name
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userDetail.UserName}
                              </span>
                            ) : (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userStoreData.User_Name}
                              </span>
                            )}
                          </div>
                          <div className="col-sm-12 mt-3">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              Full Name
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userDetail.FullName}
                              </span>
                            ) : (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userStoreData.Full_Name}
                              </span>
                            )}
                          </div>
                        </div>
                        <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600"></h6>
                        <div className="row text-center mt-3">
                          <div className="col-sm-12">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              Email
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userDetail.Email}
                              </span>
                            ) : (
                              <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                                {userStoreData.Email}
                              </span>
                            )}
                          </div>
                          <div className="col-sm-12 mt-3">
                            <p className="m-b-10 f-w-600 font-poppins-regular">
                              Company Name
                            </p>
                            {userDetail === null ? (
                              <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                                {userDetail.TenantName}
                              </span>
                            ) : (
                              <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                                {userStoreData.Tenant}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::profile card body*/}
              </div>

              <div>
                <ChangePassword />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="EditProfile"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="EditProfile"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div
            id="modal-center"
            className="modal-content"
            style={{ padding: "22px 32px" }}
          >
            {/* row for heading and cross icon */}
            <div className="row">
              <div className="col-12">
                <div className="mx-3 d-flex justify-content-between">
                  <div className="d-flex">
                    <h4 className="font-poppins-bold profile-details-heading">
                      Update Profile
                    </h4>
                  </div>
                  <div
                    role="button"
                    id="graph-import-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <img style={{ height: "25px", width: "29px" }} src={crossIcon} alt="cross" /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* row for heading and cross icon end  */}
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body padding-profile">
                <div className="row  margin-row ">
                  <div className="form-group col-12 col-sm-6 col-md-6  col-lg-6 col-xl-6 mb-4">
                    <label className="w-100 label-field mb-2 font-poppins-medium">
                      User Name
                      <input
                        {...formik.getFieldProps("UserName")}
                        id="input-control"
                        name="UserName"
                        type="text"
                        className="mt-2 form-control form-input-field"
                        autoComplete="off"
                        readOnly
                      />
                    </label>
                    {/* <div className="mt-2 ps-3">
                                        <span className="font-poppins-regular" style={{ color: "red", fontSize: "13px" }} > {formerrors.first_name}  </span>
                                    </div> */}
                  </div>
                  <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
                    <label className="w-100 label-field mb-2 font-poppins-medium">
                      Email
                      <input
                        {...formik.getFieldProps("Email")}
                        id="input-control-email"
                        name="Email"
                        type="text"
                        className="mt-2 form-control form-input-field"
                        autoComplete="off"
                        readOnly
                      />
                    </label>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="form-group col-12 col-sm-6 col-md-6  col-lg-6 col-xl-6 mb-4">
                    <label className="w-100 label-field mb-2 font-poppins-medium">
                      Full Name
                      <input
                        // defaultValue={formik.values.FullName}
                        // onChange={handleChange}
                        {...formik.getFieldProps("FullName")}
                        id="input-control"
                        name="FullName"
                        type="text"
                        className="mt-2 form-control form-input-field"
                        autoComplete="off"
                        placeholder="Full Name"
                      />
                    </label>
                    {/* <div className="mt-2 ps-3">
                                        <span className="font-poppins-regular" style={{ color: "red", fontSize: "13px" }} > {formerrors.last_name}  </span>
                                    </div> */}
                  </div>
                  <div className="form-group col-12 col-sm-6 col-md-6  col-lg-6 col-xl-6 mb-4">
                    <label className="w-100 label-field mb-2 font-poppins-medium">
                      Company Name
                      <input
                        // defaultValue={formik.values.TenantName}
                        {...formik.getFieldProps("TenantName")}
                        id="input-control"
                        name="company_name"
                        type="text"
                        className="mt-2 form-control form-input-field"
                        autoComplete="off"
                        placeholder="Company Name"
                      />
                    </label>
                    {/* <div className="mt-2 ps-3">
                                        <span className="font-poppins-regular" style={{ color: "red", fontSize: "13px" }} > {formerrors.company_name}  </span>
                                    </div> */}
                  </div>
                </div>
              </div>

              {/* <div className="modal-footer footer-comment"> */}
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  <div className="py-2 px-2 hide-content">
                    <div className="d-flex justify-content-center justify-content-md-center justify-content-lg-end f-manage">
                      <div className="d-flex flex-row row-manage">
                        <div className="d-flex">
                          <button
                            type="submit"
                            className="btn save-changes-button save-btn text-white"
                            disabled={disable}
                          >
                            {!loading && (
                              <span className="indicator-label font-poppins-regular text-white">
                                Save Changes
                              </span>
                            )}
                            {loading && (
                              <span
                                className="indicator-progress font-poppins-regular text-white"
                                style={{ display: "block" }}
                              >
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle text-white ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div className="d-flex">
                          <button
                            type="button"
                            id="cancel-profile-edit"
                            className="btn cancel-leave-btn text-white font-poppins-regular"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>

      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
}
