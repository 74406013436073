import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import "./ApplyLeave.css";
import moment from "moment";
import "../css/style.css";
import styled from "styled-components";
import "react-calendar/dist/Calendar.css";
import FormGroup from "@mui/material/FormGroup";
import Calendar from "react-calendar";
import Select from "react-select";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { TableHead } from "@mui/material";
import {
  userDropdown,
  listofLeaves,
  postLeaveRequest,
  teamCalendar,
  showLeave,
  checkJWTToken,
} from "../authApi/AuthCRUD";
import "./ApplyLeave.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let hue = 0;
let colors = [];
for (let j = 0; j < 10; j++) {
  let color = "hsl(" + hue + ",80%,50%)";
  colors.push(color);
  hue += 500;
}

export default function NewApplyLeave() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [empList, setEmpList] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState();
  const [leaves, setLeaves] = useState([]);
  const [todate, setToDate] = useState(null);
  const [fromdate, setFromDate] = useState(null);
  const [note, setNoteData] = useState("");
  const [to, setTo] = useState(0);
  const [from, setFrom] = useState(1);
  const [data, setData] = useState([]);
  const [teamLeave, setTeamLeave] = useState([]);
  const [loading, setLoading] = useState(false);

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserToken = Authentication.Token;

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      emplist();
      leaveList();
      leaveCalendar();
    }
  }, []);

  const leaveList = () => {
    listofLeaves()
      .then((res) => {
        setLeaves(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const emplist = () => {
    userDropdown(Tenant)
      .then((res) => {
        setEmpList(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setSelectedPeople(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const saveNote = (e) => {
    setFormErrors({ ...formerrors, Leave_Note: "" });
    setNoteData(e.target.value);
  };

  const [formerrors, setFormErrors] = useState({
    From_Date: "",
    To_Date: "",
    LeaveId: "",
    Leave_Note: "",
  });

  const validate = (values) => {
    const errors = {};

    console.log(values.LeaveType_Id);

    if (!values.From_Date) {
      errors.From_Date = "From Date is required*";
    }
    if (!values.To_Date) {
      errors.To_Date = "To Date is required*";
    }
    if (!values.LeaveType_Id) {
      errors.LeaveId = "Leave Type is required*";
    }
    if (!values.Leave_Note) {
      errors.Leave_Note = "Leave Note is required*";
    }
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        From_Date: "",
        To_Date: "",
        LeaveId: "",
        Leave_Note: "",
      });
      return true;
    }
  };

  const postData = () => {
    let data = {
      LeaveType_Id: selectedLeave,
      From_Date: fromdate,
      From_Date_Half: parseInt(from),
      To_Date: todate,
      To_Date_Half: parseInt(to),
      Leave_Note: note,
      Notify_User: selectedPeople,
    };
    if (validate(data)) {
      setLoading(true);
      postLeaveRequest(data)
        .then((res) => {
          navigate("/show-leave");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          // console.log(err, "error");
        });
    }
  };

  const leaveCalendar = () => {
    let date = moment(new Date()).format("YYYY-MM-DD");
    teamCalendar(date)
      .then((res) => {
        setData(res.data.Data);
      })
      .catch((err) => {
        // console.log(err, "error");
      });
  };

  let [SingleDay, setSingleDay] = useState(false);
  let [MultipleDay, setMultipleDay] = useState(false);

  const leaveDuration = () => {
    let x = document.getElementById("fromDate");
    let y = document.getElementById("ToDate");

    console.log(x.value == y.value);
    if (x.value && y.value) {
      if (x.value == y.value) {
        console.log("same date");
        setMultipleDay(false);
        setSingleDay(true);
      } else {
        console.log("alternate date");
        setMultipleDay(true);
        setSingleDay(false);
      }
    }
  };

  //date selected
  const handleStartDateChange = (selectedDate) => {
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${selectedDate
          .getDate()
          .toString()
          .padStart(2, "0")}`
      : null;
    setDate(formattedDate);
    setFromDate(formattedDate);
    setFormErrors({ ...formerrors, From_Date: "" });
    leaveDuration();
  };

  //date selected
  const handleEndtDateChange = (selectedDate) => {
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${selectedDate
          .getDate()
          .toString()
          .padStart(2, "0")}`
      : null;
    setEndDate(formattedDate);
    setToDate(formattedDate);
    setFormErrors({ ...formerrors, To_Date: "" });
    leaveDuration();
  };

  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Apply Leave" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          <section className="addUsers" style={{ padding: "30px 20px" }}>
            <div className="row">
              <div className="col-sm-12 col-md-7 col-lg-7 p-sm-box">
                <div className="form-box-border" style={{padding: '15px 25px'}}>
                  <div className="row">
                    <div className="col-sm-12 col-lg-6">
                      <div className="form-group">
                        <Label className="font-poppins-medium">
                          From Date<span className="text-danger">*</span>
                        </Label>
                        <DatePicker
                        selected={date ? new Date(date) : null}
                          onChange={handleStartDateChange}
                          dateFormat="dd.MM.yyyy"
                          className="form-control borders team-select"
                          maxDate={endDate}
                          minDate={today}
                          todayButton="TODAY"
                        />

                        {/* <input
                          type="date"
                          min={today}
                          id="fromDate"
                          max={endDate}
                          className="form-control form-input-field"
                          // defaultValue={date}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setFromDate(e.target.value);
                            setDate(e.target.value);
                            // setLeaveFrom(e.target.value);
                            setFormErrors({ ...formerrors, From_Date: "" });
                            leaveDuration();
                          }}
                        /> */}
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formerrors.From_Date}
                          </span>
                        </div>
                        {MultipleDay ? (
                          <div className="mt-2">
                            <select
                              class="form-select form-input-field"
                              onChange={(e) => {
                                setFrom(e.target.value);
                              }}
                            >
                              <option value={1} selected>
                                First Half
                              </option>
                              <option value={0}>Second Half</option>
                            </select>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6">
                      <div className="form-group">
                        <Label className="font-poppins-medium">
                          To Date<span className="text-danger">*</span>
                        </Label>
                        <DatePicker
                        selected={endDate ? new Date(endDate) : null}
                          onChange={handleEndtDateChange}
                          dateFormat="dd.MM.yyyy"
                          className="form-control borders team-select"
                          minDate={date} // Set maximum date to today's date
                          // Other props, such as max date, can be added here
                        />
                        {/* <input
                          type="date"
                          min={date}
                          id="ToDate"
                          className="form-control form-input-field"
                          // defaultValue={endDate}
                          onChange={(e) => {
                            setToDate(e.target.value);
                            setEndDate(e.target.value);
                            setFormErrors({ ...formerrors, To_Date: "" });
                            leaveDuration();
                          }}
                        /> */}
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formerrors.To_Date}
                          </span>
                        </div>
                      </div>
                      {MultipleDay ? (
                        <div className="mt-2">
                          <select
                            class="form-select form-input-field"
                            onChange={(e) => {
                              setTo(e.target.value);
                            }}
                          >
                            <option value={1}>First Half</option>
                            <option value={0} selected>
                              Second Half
                            </option>
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {SingleDay ? (
                    <div className="row">
                      <div className="col-12">
                        <div className="mt-2">
                          <select class="form-select form-input-field">
                            <option>Full day</option>
                            <option>First Half</option>
                            <option>Second Half</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Label className="mt-3 mb-1 font-poppins-medium">
                          Select available leave type
                          <span className="text-danger">*</span>
                        </Label>
                        <Select
                          className="dropdown team-select"
                          placeholder="Select Leave Type"
                          options={leaves}
                          onChange={(e) => {
                            setSelectedLeave(e.value);
                            setFormErrors({ ...formerrors, LeaveId: "" });
                          }}
                          id="leaveId"
                          isSingle
                        />
                      </div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formerrors.LeaveId}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Label className="mt-3 mb-1 font-poppins-medium">
                          Note (Message to your Reporting Manager*)
                          <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          placeholder="Please enter reason for applying leave"
                          onChange={saveNote}
                          rows={5}
                          className="form-control form-text-field"
                        />
                      </div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formerrors.Leave_Note}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Label className="mt-3 mb-1 font-poppins-medium">
                        Notify Peoples (You can notify multiple peoples*)
                      </Label>
                      <Select
                        className="dropdown team-select"
                        placeholder="These employees will be notified"
                        value={empList.filter((obj) =>
                          selectedPeople.includes(obj.value)
                        )}
                        options={empList}
                        onChange={handleChange}
                        isMulti
                        isClearable
                      />
                    </div>
                  </div>
                  <div class="row m-0 p-0 mt-4">
                    <div class="d-flex justify-content-end f-manage p-0">
                      <div class="d-flex flex-row row-manage">
                        <div class="d-flex">
                          <button
                            class="btn  buttonDefault text-white font-poppins-medium"
                            onClick={postData}
                          >
                            {!loading && <span>Submit</span>}
                            {loading && (
                              <span
                                className="indicator-progress font-poppins-regular"
                                style={{ display: "block" }}
                              >
                                Submit
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div class="d-flex">
                          <button
                            type="button"
                            onClick={() => navigate(-1)}
                            className="btn text-white  buttonDefault font-poppins-medium"
                            style={{
                              textDecoration: "none;",
                              color: "white",
                              background: "#8A899C",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5 cal-box-border">
                <div className="cal p-2">
                  <Calendar
                    value={new Date()}
                    minDate={new Date()}
                    showNeighboringMonth={false}
                    onClickDay={(value) =>
                      showLeave(moment(value).format("YYYY-MM-DD"))
                        .then((res) => {
                          setTeamLeave(res.data.Data);
                        })
                        .catch((err) => {
                          console.log(err, "error");
                        })
                    }
                    tileContent={({ date, view }) =>
                      (view === "month" && date.getDay() === 6) ||
                      (view === "month" && date.getDay() === 0) ? (
                        <p className="weekend">WEEKEND</p>
                      ) : (
                        data.map((v) => {
                          return v.From_Date ===
                            moment(date).format("YYYY-MM-DD") ? (
                            <p className="leave">LEAVE</p>
                          ) : null;
                        })
                      )
                    }
                  />
                  <hr />
                  {teamLeave.length === 0 ? (
                    <h3 className="text-center mt-2">
                      Click on leave day to know more...
                    </h3>
                  ) : (
                    <div className="table-responsive mt-2">
                      <table className="table">
                        <TableHead>
                          <tr>
                            <th
                              className="tableHead"
                              style={{ paddingLeft: "2rem" }}
                            >
                              Name
                            </th>
                            <th
                              className="tableHead "
                              style={{ paddingLeft: "2rem" }}
                            >
                              Date
                            </th>
                            <th className="tableHead">Day</th>
                          </tr>
                        </TableHead>

                        <tbody className="tablespace">
                          {teamLeave.map((value) => {
                            return (
                              <tr>
                                <td className="tableCell front-radius tablebackground ">
                                  <div className="d-flex">
                                    <div
                                      className="cal2"
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        textAlign: "center",
                                        color: "#fff",
                                        backgroundColor:
                                          colors[
                                            Math.floor(
                                              Math.random() * colors.length
                                            )
                                          ],
                                      }}
                                    >
                                      {value.Full_Name.charAt(0).toUpperCase()}
                                    </div>
                                    <div className="ml-2">
                                      {value.Full_Name.split(/\s(.+)/)[0]}
                                    </div>
                                  </div>
                                </td>
                                <td className="tableCell tablebackground">
                                  <div className="badge teamMonth text-wrap">
                                    {moment(value.From_Date).format("DD")}
                                    {"-"}
                                    {moment(value.To_Date).format("DD")}{" "}
                                    {moment(value.From_Date).format("MMM")}
                                  </div>
                                </td>
                                <td className="tableCell back-radius tablebackground">
                                  {value.Total_Leave_Days}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
