import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useLocation, useNavigate } from "react-router-dom";
import "./ViewProjectDetails.css";
import { shallowEqual, useSelector } from "react-redux";
import { getTasksById } from "../authApi/AuthCRUD";
import moment from "moment";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { initializeConnect } from "react-redux/es/components/connect";

export default function TaskDetails() {
  let navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const { search } = useLocation();
  const taskId = new URLSearchParams(search).get("id");
  const [taskDetail, setTaskDetail] = useState([]);
  const [assigneeName, setassigneeName] = useState('');

  let assignee = []
  let initials = "";
  console.log(assigneeName);
  assignee = assigneeName.split(" ");
  if (assignee.length === 2) {
    initials = assignee.shift().charAt(0) + assignee.pop().charAt(0);
  } else {
    initials = assignee.shift().charAt(0);
  }


  useEffect(() => {
    getTasksById(taskId)
      .then((response) => {
        setTaskDetail(response.data.Data);
        setassigneeName(response.data.Data.Assignee_Name);
      })
      .catch((err) => {
        setTaskDetail([]);
      });
  }, [taskId]);

  return (
    <>
      <div className="container-fluid">
        <Header title="Task Details" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <div className="projectDetailsCard">
          <div className="projectName">{taskDetail.Task_Name}</div>
          <hr />

          <div className="projectheader mt-3 mb-3">
            <div className="row d-flex align-items-center">
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12  d-flex">
                <div className="customerDetails d-flex  align-items-center">
                  <div
                    className="rounded-circle d-flex justify-content-center"
                    style={{
                      width: " 50px",
                      height: "50px",
                      color: "#ffffff",
                      background:
                        "linear-gradient(264.71deg, #8F009B 18.58%, #420085 128.77%)",
                    }}
                  >
                    <div
                      className="d-flex span-text text-uppercase"
                      style={{ fontSize: "16px" }}
                    >
                      {initials}
                    </div>
                  </div>
                  <div className="customerHeading ms-3">
                    <div className="customerName">Assignee Name</div>
                    <div className="customerDetailName">
                      {taskDetail.Assignee_Name}
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>

          <div className="projectContent mt-3 mb-3">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">Start Date</div>
                  <div className="customerDetailName">
                    {moment(taskDetail.Start_Date).format("MM.DD.YYYY")}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">End Date</div>
                  <div className="customerDetailName">
                    {moment(taskDetail.End_Date).format("MM.DD.YYYY")}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">Spent Time</div>
                  <div className="customerDetailName">
                    {taskDetail.Spend_Time}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">Estimated Time</div>
                  <div className="customerDetailName">
                    {taskDetail.Estimated_Time}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="assign-team mt-4">
            <div className="d-flex align-items-center">
              <div className="customerName">Description: </div>
              <div className="customerDetailName ms-2">
                <p
                  className="text-muted"
                  dangerouslySetInnerHTML={{ __html: taskDetail.Description }}
                ></p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card mt-4 p-2 p-md-4 p-lg-4">
                    <div>
                        <div className="">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card-box task-detail">
                                            <h4 className="m-b-20 font-poppins-medium">Task name : {taskDetail.Task_Name}</h4>
                                            <p className="text-muted" dangerouslySetInnerHTML={{ __html: taskDetail.Description }}></p>
                                            <div className="assign-team mt-4">
                                                <div className="mt-2 d-flex flex-wrap align-items-center">
                                                    <h5 className="font-poppins-medium">Assign to :</h5>&nbsp;
                                                    {taskDetail.Assignee_Name === "" ?
                                                        (<React.Fragment>
                                                            <span className="text-secondary d-flex justify-content-center font-poppins-regular" style={{ fontSize: "14px" }}>
                                                                This project is not assign to anyone.
                                                            </span>
                                                        </React.Fragment>) :
                                                        (<React.Fragment>
                                                            <div>
                                                                <span>{taskDetail.Assignee_Name}</span>
                                                            </div>
                                                        </React.Fragment>)
                                                    }
                                                </div>
                                            </div>
                                            <ul className="list-inline task-dates m-b-0 mt-4">
                                                <li>
                                                    <h5 className="m-b-5 font-poppins-medium">Start Date</h5>
                                                    <p className="font-poppins-regular">{moment(taskDetail.Start_Date).format("DD MMMM YYYY")}</p>
                                                </li>
                                                <li>
                                                    <h5 className="m-b-5 font-poppins-medium">Estimated Time</h5>
                                                    <p className="font-poppins-regular">{taskDetail.Estimated_Time}</p>
                                                </li>
                                                <li className="mt-4">
                                                    <h5 className="m-b-5 font-poppins-medium">End Date</h5>
                                                    <p className="font-poppins-regular">{moment(taskDetail.End_Date).format("DD MMMM YYYY")}</p>
                                                </li>
                                                <li className="mt-4">
                                                    <h5 className="m-b-5 font-poppins-medium">Spent Time</h5>
                                                    <p className="font-poppins-regular">{taskDetail.Spend_Time}</p>
                                                </li>
                                            </ul>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
}
