import React, { useState } from "react";
import "./SignUpNew.css";
import odyseyyLogo from "../Images/formImages/odyssey-logo.svg";
import odyseyySmLogo from "../Images/formImages/odyssey-sm-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { login } from "../authApi/AuthCRUD";
import { useDispatch } from "react-redux";
import { authUserLogin, profilePic } from "../reduxAuthentication/actions";
import eyeSlashIcon from "../Images/formImages/eye-slash-icon.svg";
import eyeIcon from "../Images/formImages/eye-icon.svg";

const loginPasswordSchema = Yup.object().shape({
  tenantname: Yup.string().required("Company name is required").trim(),
  email: Yup.string()
    .email("Enter valid email address")
    .max(50, "Maximum 50 symbols")
    .required("Email is required")
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .max(20, "Maximum 20 characters is required")
    .trim(),
});

export default function LoginNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      tenantname: "",
    },
    validationSchema: loginPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let data = {
        tenantname: values.tenantname.replaceAll(" ", ""),
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
      };
      login(data)
        .then((response) => {
          setLoading(false);
          const getResponse = response.data;
          dispatch(authUserLogin(response.data.Data.Token, response.data.Data));
          dispatch(profilePic(response.data.Data.ProfileImage));
          navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            if (
              error.response.data.Message ==
              "Your authentication information is incorrect. Please try again"
            ) {
              let errorMsg = "Invalid Credentials";
              setStatus(errorMsg);
            } else {
              setStatus(error.response.data.Message);
              console.log(error.response);
            }
          } else if (error.response.status == 404) {
            let errorMsg = "Invalid Credentials";
            setStatus(errorMsg);
          } else if (error.code === "ENOTFOUND") {
            console.error(
              "Server not found. Please check your internet connection."
            );
            setStatus(
              "Server not found. Please check your internet connection."
            );
          } else {
            setStatus(error.message);
          }
        });
    },
  });
  return (
    <>
      <div className="sign-up-wrapper d-none d-md-block d-lg-block d-xl-block">
        <div className="content">
          <div className="container">
            <div className="mb-7 d-flex justify-content-end text-white">
              <div className="d-flex align-items-center gap-22">
                <span className="font-poppins-regular account-head">
                  Don’t have an account?
                </span>
                <Link to="/sign/up" className="text-decoration-none">
                  <span className="text-white account-btn" role="button">
                    <span className="font-poppins-medium">Get started</span>
                  </span>
                </Link>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-md-5 bg-left-section position-relative">
                <div className="position-absolute logo-center">
                  <img src={odyseyyLogo} alt="logo" />
                </div>
              </div>
              <div className="col-md-7 bg-right-form">
                <div className="row">
                  <div className="" style={{ padding: "2.5rem 5rem" }}>
                    <div className="text-center">
                      <h1 className="main-heading font-poppins-bold">
                        Sign In
                      </h1>
                      <span className="sub-heading font-poppins-regular">
                        Please enter your email and password
                      </span>
                    </div>
                    <div>
                      <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <div>
                          {formik.status ? (
                            <>
                              <div
                                style={{
                                  border: "2px solid red",
                                  padding: "5px 10px",
                                  margin: "10px 0",
                                }}
                              >
                                <span className="d-flex justify-content-center text-danger font-poppins-medium">
                                  {formik.status}
                                </span>
                              </div>
                            </>
                          ) : null}
                        </div>

                        <div className="row">
                          <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                            <label className="w-100 label-field font-poppins-medium">
                              Company Name*
                              <input
                                id="input-name-control"
                                type="text"
                                className="mt-1 form-control input-field font-poppins-regular text-capitalize"
                                autoComplete="off"
                                placeholder="Company Name"
                                {...formik.getFieldProps("tenantname")}
                                onChange={formik.handleChange}
                              />
                            </label>
                            {formik.touched.tenantname &&
                              formik.errors.tenantname && (
                                <div>
                                  <div className="text-alert font-poppins-medium">
                                    <span role="alert" className="formErr">
                                      {formik.errors.tenantname}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2">
                            <label className="w-100 label-field font-poppins-medium">
                              Email*
                              <input
                                id="input-email-control"
                                type="email"
                                className="mt-1 form-control input-field font-poppins-regular"
                                autoComplete="off"
                                placeholder="Enter Email"
                                {...formik.getFieldProps("email")}
                                onChange={formik.handleChange}
                              />
                            </label>
                            {formik.touched.email && formik.errors.email && (
                              <div>
                                <div className="text-alert font-poppins-medium">
                                  <span role="alert" className="formErr">
                                    {formik.errors.email}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-2">
                            <label className="w-100 label-field font-poppins-medium">
                              Password*
                              <input
                                id="input-password-control"
                                type={passwordShown ? "text" : "password"}
                                className="mt-1 form-control input-field-psd font-poppins-regular"
                                autoComplete="off"
                                placeholder="Password"
                                {...formik.getFieldProps("password")}
                                onChange={formik.handleChange}
                              />
                            </label>
                            {passwordShown ? (
                              <i
                                onClick={togglePasswordVisiblity}
                                className="pe-auto"
                              >
                                <img
                                  className="eye-icon"
                                  src={eyeIcon}
                                  alt="eyeicon"
                                />
                              </i>
                            ) : (
                              <i
                                onClick={togglePasswordVisiblity}
                                className="pe-auto"
                              >
                                <img
                                  className="eye-icon"
                                  src={eyeSlashIcon}
                                  alt="eyeicon"
                                />
                              </i>
                            )}
                            {formik.touched.password &&
                              formik.errors.password && (
                                <div>
                                  <div className="text-alert font-poppins-medium">
                                    <span role="alert" className="formErr">
                                      {formik.errors.password}
                                    </span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="fv-row"  style={{ marginTop: '-15px'}}>
                          <div className="d-flex justify-content-end col-12">
                            {/* <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value="false" id="flexCheckDefault" />
                                                            <span className="grey-links font-poppins-regular" id="remember_me_chkbox">
                                                                Keep me logged in on this computer
                                                            </span>
                                                        </div> */}

                            <div className="d-flex">
                              <div>
                                <Link
                                  to="/forgot/password"
                                  className="text-decoration-none grey-links font-poppins-regular"
                                >
                                  Forgot Password ?
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 d-flex justify-content-center">
                            <button
                              type="submit"
                              className="btn mt-5 submit-btn font-poppins-regular"
                            >
                              {!loading && <span>Sign In</span>}
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Sign In
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-md-none d-lg-none d-xl-done">
        <div className="row m-0 p-0">
          <div className="col-12 sm-screen-bg">
            <div className="m-4 mt-5 d-flex justify-content-center align-items-center">
              <span className="font-poppins-regular account-sm-head">
                Don’t have an account?
              </span>
              &nbsp;&nbsp;
              <Link to="/sign/up" className="text-decoration-none">
                <span className="text-white account-sm-btn">
                  <span className="font-poppins-medium">Get started</span>
                </span>
              </Link>
            </div>

            <div className="m-5 d-flex justify-content-center">
              <img src={odyseyySmLogo} alt="logo" />
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="col-12 bg-white">
              <div className="mt-5 mb-5 mx-1">
                <div className="text-center">
                  <h1 className="main-sm-heading font-poppins-bold">Sign In</h1>
                  <span className="sub-heading font-poppins-regular">
                    Please enter your email and password
                  </span>
                </div>

                <div>
                  <form className="mt-5" onSubmit={formik.handleSubmit}>
                    <div>
                      {formik.status ? (
                        <>
                          <div
                            style={{
                              border: "2px solid red",
                              padding: "5px 10px",
                              margin: "10px 0",
                            }}
                          >
                            <span className="d-flex justify-content-center text-danger font-poppins-medium">
                              {formik.status}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="row m-0">
                      <div className="form-group col-sm-12">
                        <label className="w-100 mb-3 label-field font-poppins-medium">
                          Company Name*
                          <input
                            id="input-name-control"
                            type="text"
                            className={clsx(
                              "mt-1 form-control input-sm-field font-poppins-regular text-capitalize",
                              {
                                "is-invalid":
                                  formik.touched.tenantname &&
                                  formik.errors.tenantname,
                              },
                              {
                                "is-valid":
                                  formik.touched.tenantname &&
                                  !formik.errors.tenantname,
                              }
                            )}
                            autoComplete="off"
                            placeholder="Company Name"
                            {...formik.getFieldProps("tenantname")}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.tenantname &&
                            formik.errors.tenantname && (
                              <div>
                                <div className="text-alert font-poppins-medium">
                                  <span role="alert" className="formErr">
                                    {formik.errors.tenantname}
                                  </span>
                                </div>
                              </div>
                            )}
                        </label>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="form-group col-sm-12">
                        <label className="w-100 mb-3 label-field font-poppins-medium">
                          Email*
                          <input
                            id="input-email-control"
                            type="text"
                            className="mt-1 form-control input-sm-field font-poppins-regular"
                            autoComplete="off"
                            placeholder="Enter Email"
                            {...formik.getFieldProps("email")}
                            onChange={formik.handleChange}
                          />
                          {formik.touched.email && formik.errors.email && (
                            <div>
                              <div className="text-alert font-poppins-medium">
                                <span role="alert" className="formErr">
                                  {formik.errors.email}
                                </span>
                              </div>
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="form-group col-sm-12">
                        <label className="w-100 label-field font-poppins-medium">
                          Password*
                          <input
                            id="input-password-control"
                            type={passwordShown ? "text" : "password"}
                            className="mt-1 form-control input-sm-field font-poppins-regular"
                            autoComplete="off"
                            placeholder="Password"
                            {...formik.getFieldProps("password")}
                            onChange={formik.handleChange}
                          />
                          {passwordShown ? (
                            <i
                              onClick={togglePasswordVisiblity}
                              className="pe-auto"
                            >
                              <img
                                className="eye-sm-icon"
                                src={eyeIcon}
                                alt="eyeicon"
                              />
                            </i>
                          ) : (
                            <i
                              onClick={togglePasswordVisiblity}
                              className="pe-auto"
                            >
                              <img
                                className="eye-sm-icon"
                                src={eyeSlashIcon}
                                alt="eyeicon"
                              />
                            </i>
                          )}
                          {formik.touched.password &&
                            formik.errors.password && (
                              <div>
                                <div className="text-alert font-poppins-medium">
                                  <span role="alert" className="formErr">
                                    {formik.errors.password}
                                  </span>
                                </div>
                              </div>
                            )}
                        </label>
                      </div>
                    </div>
                    <div className="row m-0 align-items-baseline">
                      <div className="col-4 d-flex forgotPass">
                        <Link
                          to="/forgot/password"
                          className="text-decoration-none"
                        >
                          <span className="grey-sm-links">
                            Forgot password?
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 mt-2 d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn mt-5 submit-sm-btn font-poppins-regular"
                          disabled={loading}
                        >
                          {!loading && <span>Sign In</span>}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Sign In
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
