import React from "react";
import { Link } from "react-router-dom";
import odyseyyLogo from "../Images/formImages/odyssey-logo.svg";
import odyseyySmLogo from "../Images/formImages/odyssey-sm-logo.svg";

const styles = {
  boxHeight: {
    height: "400px",
  },
  pageNotFoundBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function PageNotFound() {
  return (
    <>
      <div className="sign-up-wrapper d-none d-md-block d-lg-block d-xl-block">
        <div className="content mt-5">
          <div className="container">
            <div
              className="row no-gutters error-box-height"
              style={styles.boxHeight}
            >
              <div className="col-md-12 bg-right-form" style={{padding: '100px 0'}}>
                <div className="text-center">
                  <h1 className="main-heading font-poppins-bold">
                    403 - Forbidden
                  </h1>
                  <span className="sub-heading font-poppins-regular">
                    Oops, Something went wrong. Please try again later.
                  </span>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 d-flex justify-content-center">
                      <Link to="/">
                        <button
                          type="submit"
                          className="btn mt-5 submit-btn font-poppins-regular"
                        >
                          Back to Home Page
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-md-none d-lg-none d-xl-done">
        <div className="row m-0 p-0">
          <div className="col-12 sm-screen-bg">
            <div className="m-5 d-flex justify-content-center">
              <img src={odyseyySmLogo} alt="logo" />
            </div>
          </div>

          <div
            className="row p-0 m-0 position-relative"
            style={styles.boxHeight}
          >
            <div
              className="col-12 bg-white position-absolute"
              style={styles.pageNotFoundBox}
            >
              <div className="mt-5 mb-5 mx-1">
                <div className="text-center">
                  <h1 className="main-sm-heading font-poppins-bold">404</h1>
                  <span className="sub-heading font-poppins-regular">
                    Page Not Found
                  </span>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-12 mt-2 d-flex justify-content-center">
                      <Link to="/">
                        <button
                          type="submit"
                          className="btn mt-5 submit-sm-btn font-poppins-regular"
                        >
                          Back to Home Page
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
