import React, { useEffect, useState } from "react";
import "../css/style.css";
import "./reports.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getUserTaskReport } from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import ClockLoader from "react-spinners/ClockLoader";
import { useNavigate } from "react-router-dom";

export default function UserTaskReport(props) {
  const navigate = useNavigate();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  let [loading, setLoading] = useState(true);

  let [taskReport, setTaskReport] = useState();
  useEffect(() => {
    let data = {
      tenantname: Tenant,
      userid: props.usrId,
      Start_Date: props.startDate,
      End_Date: props.end_date,
    };
    setLoading(true);
    getUserTaskReport(data)
      .then((res) => {
        setTaskReport(res.data.Data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  }, [props]);

  return (
    <>
      {loading ? (
        <>
          <div className="d-flex justify-content-center text-center loaderDiv" style={{margin:"8% 38%"}}>
            <ClockLoader color="#6400B0" speedMultiplier={3}/>
            <span className="loading">Loading....</span>
          </div>
        </>
      ) : (
        <>
          <div className="table-responsive">
            <table className="table reports">
              <thead>
                <tr>
                  <th className="tableHead" style={{minWidth: '300px'}}> Task </th>
                  <th className="tableHead" style={{minWidth: '100px'}}> Activity(%) </th>
                  <th className="tableHead" style={{minWidth: '100px'}}> Idle(%)</th>
                  <th className="tableHead " style={{minWidth: '120px'}}> Active Time </th>
                  <th className="tableHead " style={{minWidth: '120px'}}> Manual Time </th>
                  <th className="tableHead " style={{minWidth: '120px'}}> Idle Time </th>
                  <th className="tableHead  " style={{minWidth: '150px'}}>
                    {" "}
                    Total Time{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {taskReport &&
                  taskReport.map((res, i) => {
                    return (
                      <React.Fragment key={i}>
                        <tr>
                          <td className="tableCell front-radius tableCellCenter compName">
                          <div className="mainDiv">
                          {res.Task_Name}
                          </div>
                          <p style={{color: '#616773', fontSize: '13px', fontWeight: '400', marginLeft: '10px'}}>●{" "}{res.Project_Name}</p>
                          </td>
                          <td className=" tableCell">
                            <div className="progress-circle">
                              <CircularProgressbar
                                value={res.Activity_Level}
                                text={`${res.Activity_Level}%`}
                                styles={buildStyles({
                                  textColor: "black",
                                  pathColor: "green",
                                })}
                              />
                            </div>
                          </td>
                          <td className=" tableCell ">
                            <div className="progress-circle">
                              <CircularProgressbar
                                value={res.Idle_Percentage}
                                counterClockwise={true}
                                text={`${res.Idle_Percentage}%`}
                                styles={buildStyles({
                                  textColor: "black",
                                  pathColor: "red",
                                })}
                              />
                            </div>
                          </td>
                          <td
                            className=" tableCellCenter"
                            style={{ color: "#59A561" }}
                          >
                            {res.Active_Time}
                          </td>
                          <td
                            className=" tableCellCenter"
                            style={{ color: "#CCAF95" }}
                          >
                            {res.Manual}
                          </td>
                          <td
                            className=" tableCellCenter"
                            style={{ color: "#FF7B69" }}
                          >
                            {res.Idle_Time}
                          </td>
                          <td
                            className=" tableCellCenter back-radius"
                            style={{ color: "#BA95CC" }}
                          >
                            {res.Total_Time}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
