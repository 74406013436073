import React, { useEffect, useState } from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getAllUserForTask,
  getTasksById,
  updateTasks,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { shallowEqual, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditTask = () => {
  let navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const { search } = useLocation();

  const TaskId = new URLSearchParams(search).get("id");
  const [priority, setPriority] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [estimatedTime, setEstimatedTime] = useState("");
  const [empList, setEmpList] = useState([]);
  const [value, setValue] = useState("");
  const [taskSource_Id, setTaskSource_Id] = useState("");
  const [projectId, setProjectId] = useState("");
  const Editorvalue = (value) => {
    setValue(value);
  };
  
  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;

  const emplist = (id) => {
    getAllUserForTask(Tenant, id)
      .then((res) => {
        setEmpList(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //for userDD
  const [formValues, setFormValues] = useState({
    Task_Id: TaskId,
    Task_Name: "",
    Start_Date: "",
    End_Date: "",
    Assignee: "",
    Assignee_Name: "",
    Description: value,
    Estimated_Time: "",
    Assignee_Name: "",
    Task_Status: "",
    tenantname: Tenant,
  });
  const [loading, setLoading] = useState(false);
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  useEffect(() => {
    getTasksById(TaskId)
      .then((response) => {
        setFormValues({
          Task_Id: TaskId,
          Task_Name: response.data.Data.Task_Name,
          Start_Date: response.data.Data.StartDate,
          End_Date: response.data.Data.EndDate,
          Assignee: response.data.Data.Assignee,
          Description: response.data.Data.Description,
          Assignee_Name: response.data.Data.Assignee_Name,
          Estimated_Time: response.data.Data.Estimated_Time,
          Task_Status: response.data.Data.Status,
          tenantname: Tenant,
        });
        emplist(response.data.Data.Project_Id);
        setProjectId(response.data.Data.Project_Id);
        setTaskSource_Id(response.data.Data.Task_Source_Id);
        setValue(response.data.Data.Description);
        setPriority(response.data.Data.Priority);
        setTaskStatus(response.data.Data.Task_Status);
        setEstimatedTime(response.data.Data.Estimated_Time);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  }, [TaskId]);

  const [formerrors, setFormErrors] = useState({
    Task_Name:"",
    Start_Date:"",
    Estimated_Time: ""
 });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    let regexTime = /\d:\d/;

    if (!values.Task_Name.trim())
     {
     errors.Task_Name = "Task name is required*";
     } 
    if (!values.Start_Date)
     {
     errors.Start_Date = "Start date is required*";
     } 
     if(!regexTime.test(values.Estimated_Time)){
      errors.Estimated_Time = "Invalid Time";
     }
  
    if (Object.keys(errors).length)
    {
     setFormErrors(errors);
     return false;
    } 
    else 
    {
     setFormErrors({
      Task_Name:"",
      Start_Date:"",
      Estimated_Time:""
     });
     return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      Task_Id: TaskId,
      Task_Name: formValues.Task_Name,
      Start_Date: formValues.Start_Date,
      End_Date: formValues.End_Date,
      Priority: priority,
      Assignee: formValues.Assignee,
      Description: value,
      Estimated_Time: estimatedTime,
      Assignee_Name: formValues.Assignee_Name,
      Task_Status: taskStatus,
      tenantname: Tenant,
      Project_Id: projectId,
      Task_Source_Id: taskSource_Id,
    };
    if(validate(formData)){
       setLoading(true);
    setUrlResponseMsg(true);
    updateTasks(formData)
      .then((res) => {
        setUrlResponseMsg(true);
        toast.success(res.data.Message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    }
   
  };
  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Update Task" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>

          <section className="addUsers p-2 p-md-4 p-lg-5">
            <div>
              <form onSubmit={handleSubmit}>
                <div className="heading">Update Task</div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Task Name <span className="text-danger">*</span></Label>
                      <input
                        style={styles.select}
                        type="text"
                        name="Task_Name"
                        value={formValues.Task_Name}
                        onChange={handleChange}
                        className="form-control form-input-field"
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formerrors.Task_Name}
                        </span>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Assignee</Label>
                      <select
                        className="form-select form-input-field"
                        name="Assignee"
                        value={formValues.Assignee}
                        onChange={handleChange}
                      >
                      
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option value={data.User_Id} id={data.Full_Name}>
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </select>
                      {/* <input
                        value={formValues.Assignee}
                        onChange={handleChange}
                        style={styles.select}
                        type="text"
                        className="form-control form-input-field"
                        placeholder="Assignee"
                      /> */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Start Date <span className="text-danger">*</span></Label>
                      <input
                        type="date"
                        name="Start_Date"
                        value={formValues.Start_Date}
                        onChange={handleChange}
                        className="form-control form-input-field"
                        placeholder="Zip"
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formerrors.Start_Date}
                        </span>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>End Date</Label>
                      <input
                        type="date"
                        name="End_Date"
                        value={formValues.End_Date}
                        onChange={handleChange}
                        className="form-control form-input-field"
                        placeholder="Zip"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Priority</Label>
                      <select
                        class="form-select form-input-field"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                      // {...formik.getFieldProps('Priority')}
                      >
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Status</Label>
                      <select
                        class="form-select form-input-field"
                        value={taskStatus}
                        onChange={(e) => setTaskStatus(e.target.value)}
                      >
                        <option value="To-Do">To-Do</option>
                        <option value="In-Progress">In Progress</option>
                        <option value="Done">Completed</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Estimated Time(in hh:mm)</Label>
                      <input
                        style={styles.select}
                        type="text"
                        defaultValue={formValues.Estimated_Time}
                        onChange={(e) => setEstimatedTime(e.target.value)}
                        className="form-control form-input-field"
                        placeholder="Estimated_Time"
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formerrors.Estimated_Time}
                        </span>
                      </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Description</Label>
                      {/* <input style={styles.select}type="text" onChange={(e) => setDescription(e.target.value)} className="form-control" /> */}
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={Editorvalue}
                      />
                    </div>
                  </div>
                </div>
                <div class="row m-0 p-0 mt-3"><div class="d-flex justify-content-end f-manage p-0">
                  <div class="d-flex flex-row row-manage">
                    <div class="d-flex">
                      <button class="btn  buttonDefault text-white font-poppins-medium">
                        {!loading && <span>Submit</span>}
                        {loading && (
                          <span
                            className="indicator-progress font-poppins-regular"
                            style={{ display: "block" }}
                          >Submit
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div class="d-flex">
                      <button type="button" id="cancel-add-leave"
                        class="btn text-white  buttonDefault cancelbtnhover font-poppins-medium"
                        style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </React.Fragment>
  );
};

export default EditTask;
