import React, { useEffect, useState } from "react";
import "../css/style.css";
import "./reports.css";
import ClockLoader from "react-spinners/ClockLoader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { CSVLink } from "react-csv";
import "react-circular-progressbar/dist/styles.css";
import {
  getSummaryReport,
  getAllUserForReport,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import { useNavigate, Link } from "react-router-dom";

export default function SummaryReport() {
  const navigate = useNavigate();

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const UserRole = Authentication.User_Role;
  const Tenant = Authentication.Tenant;
  const loggedInUserId = Authentication.User_Id;
  const loggedInUserName = Authentication.Full_Name;
  let [loading, setLoading] = useState(true);
  let [report, setReport] = useState();
  const [user_Id, setUser_Id] = useState(0);
  const [empList, setEmpList] = useState([]);
  let [pagePermission, setPagePermission] = useState([]);

  useEffect(() => {
    getUserPermission();
    emplist();
    getReport(UserRole == "2" ? loggedInUserId : user_Id);
  }, []);

  let userObj = {};

  //UserDropdown
  const emplist = (permissionName) => {
    getAllUserForReport(Tenant, permissionName)
      .then((res) => {
        if (!Array.isArray(res.data.Data)) {
          let temp = { ...res.data.Data };
          res.data.Data = [temp];
          setEmpList(res.data.Data);
        } else {
          setEmpList(res.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Summary_report") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                emplist(list.Sub_Permission_Name);
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };
  const getReport = (userId, startDate = date, end_date = endDate) => {
    let data = {
      tenantname: Tenant,
      userid: userId,
      Start_Date: startDate,
      End_Date: end_date,
    };
    getSummaryReport(data)
      .then((res) => {
        setLoading(false);
        setReport(res.data.Data);
        dataExport(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const [csvData, setCsvData] = useState([]);
  const dataExport = (userData) => {
    let userArr = [];
    userData &&
      userData.forEach((res, i) => {
        userObj = {
          Project_Name: res.Project_Name,
          Activity_Level: res.Activity_Level+'%',
          Idle_Percentage: res.Idle_Percentage+'%',
          Active_Time: res.Active_Time,
          Manual: res.Manual,
          Idle_Time: res.Idle_Time,
          Total_Time: res.Total_Time,
        };
        userArr.push(userObj);
      });
    setCsvData(userArr);
  };


  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Summary Report" />
        <div className="mt-3 mb-3">
          <div className="row d-flex  align-items-center">
            <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12 d-flex justify-content-between align-items-center mt-1">
              <p className="lableRepo">Group</p>
              <select
                className="form-select team-select borders"
                // value={selectedUser || ""}
                onChange={(e) => {
                  setUser_Id(e.target.value);
                  getReport(e.target.value);
                }}
              >
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "admin_summary_report") {
                    return (
                      <>
                        <option defaultValue="0" value="0">
                          All Employees
                        </option>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </select>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 d-flex  align-items-center mt-1">
              <p className="lableRepo">Start&nbsp;Date</p>
              <input
                type="date"
                defaultValue={date}
                max={today}
                className="borders form-control team-select selectDateSummary"
                onChange={(e) => {
                  setDate(e.target.value);
                  getReport(
                    UserRole == "2" ? loggedInUserId : user_Id,
                    e.target.value,
                    endDate
                  );
                }}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12  d-flex  align-items-center mt-1">
              <p className="lableRepo">End&nbsp;Date</p>
              <input
                type="date"
                defaultValue={endDate}
                min={date}
                max={today}
                className="form-control borders team-select selectDateSummary"
                onChange={(e) => {
                  setEndDate(e.target.value);
                  getReport(
                    UserRole == "2" ? loggedInUserId : user_Id,
                    date,
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-12 mt-1 d-flex justify-content-center align-items-center">
                  <div className="btn-div-width w-100">
                    <button
                      type="button"
                      className={
                        report == null
                          ? "btn btn-lg exportBtn not-allowed team-select"
                          : "btn btn-lg exportBtn team-select"
                      }
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        borderColor: "#8a899c",
                        background: "#8a899c",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        color: "#FFF",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "10px 20px",
                      }}
                      disabled={report == null}
                    >
                      <CSVLink
                        data={csvData}
                        className="csvEx"
                        filename={"user-data.csv"}
                      >
                        Export to CSV
                      </CSVLink>
                    </button>
                  </div>
                </div>
            {/* <div className="col-md-2 col-sm-12 d-flex justify-content-center align-items-center">
              <p className="lableRepo">Timezone</p>
              <select className="form-select borders">
                <option value="1">+5:30GMT</option>
                <option value="2">+10:00GMT</option>
                <option value="3">+7:30GMT</option>
                <option  defaultValue="4">+3:00GMT</option>
              </select>
            </div> */}
            {/* <div className="col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
              <p className="lableRepo">Report&nbsp;Period</p>
              <select className="form-select borders">
                <option value="1">Custom</option>
              </select>
            </div> */}
            {/* <div className="col-md-3 col-sm-12 d-flex justify-content-center align-items-center">
              <p className="lableRepo">Work day</p>
              <input
                type="date"
                name="Start_Date"
                className="form-control borders"
              />
            </div> */}
          </div>
        </div>

        <hr />
        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {!report && report === null ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table reports">
                    <thead>
                      <tr>
                        <th className="tableHead"> Project </th>
                        <th className="tableHead"> Activity(%) </th>
                        <th className="tableHead"> Idle(%)</th>
                        <th className="tableHead text-center"> Active Time </th>
                        <th className="tableHead text-center"> Manual Time</th>
                        <th className="tableHead text-center"> Idle Time </th>
                        <th className="tableHead text-center">Total Time </th>
                      </tr>
                    </thead>
                    <tbody>
                      {report &&
                        report.map((res, i) => {
                          return (
                            <React.Fragment key={i}>
                              <tr>
                                <td className="tableCell front-radius compName tableCellCenter">
                                  <Link
                                    to={`/project-details?id=${res.Project_Id}`}
                                  >
                                    {res.Project_Name}
                                  </Link>
                                </td>
                                <td className="text-center tableCell">
                                  <div className="progress-circle">
                                    <CircularProgressbar
                                      value={res.Activity_Level}
                                      text={`${res.Activity_Level}%`}
                                      styles={buildStyles({
                                        textColor: "black",
                                        pathColor: "green",
                                      })}
                                    />
                                  </div>
                                </td>
                                <td className="text-center tableCell ">
                                  <div className="progress-circle">
                                    <CircularProgressbar
                                      value={res.Idle_Percentage}
                                      counterClockwise={true}
                                      text={`${res.Idle_Percentage}%`}
                                      styles={buildStyles({
                                        textColor: "black",
                                        pathColor: "red",
                                      })}
                                    />
                                  </div>
                                </td>
                                <td
                                  className="text-center tableCellCenter"
                                  style={{ color: "#59A561" }}
                                >
                                  {res.Active_Time}
                                </td>
                                <td
                                  className="text-center tableCellCenter"
                                  style={{ color: "#CCAF95" }}
                                >
                                  {res.Manual}
                                </td>
                                <td
                                  className="text-center tableCellCenter"
                                  style={{ color: "#FF7B69" }}
                                >
                                  {res.Idle_Time}
                                </td>
                                <td
                                  className="text-center tableCellCenter back-radius"
                                  style={{ color: "#BA95CC" }}
                                >
                                  {res.Total_Time}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
}
