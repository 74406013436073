import React, { useEffect, useState } from "react";
import "../css/style.css";
import "font-awesome/css/font-awesome.min.css";
import { Link, useNavigate } from "react-router-dom";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import ReactPaginate from "react-paginate";
import ClockLoader from "react-spinners/ClockLoader";
import { CSVLink } from "react-csv";
import sorticon from "../Images/sorticon.svg";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import { Chart } from "react-google-charts";
import {
  GetUserView,
  getAllUserForReport,
  checkUserPermissions,
  getBackgroundApplications,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import tick from "../Images/profile.svg";
import crossIcon from "../Images/livedashboard/cross-icon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

export default function ApplicationTrackingReport() {
  const navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const User_Id = Authentication.User_Id;

  let [user_Id, setUser_Id] = useState(User_Id);
  const [User_Name, setUser_Name] = useState("");
  const [Email, setEmail] = useState("");
  const [Team_Name, setTeam_Name] = useState("");
  const [TaskCount, setTaskCount] = useState(0);
  const [Role_Name, setRole_Name] = useState("");
  let [loading, setLoading] = useState(true);

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  let [date, setDate] = useState(today);

  useEffect(() => {
    getUserPermission();
    emplist();
    backgroundApplicationTracking(user_Id, date);
  }, []);

  const [empList, setEmpList] = useState([]);
  const emplist = (permissionName = "admin_screenshot") => {
    getAllUserForReport(Tenant, permissionName)
      .then((res) => {
        if (!Array.isArray(res.data.Data)) {
          let temp = { ...res.data.Data };
          res.data.Data = [temp];
          setEmpList(res.data.Data);
        } else {
          setEmpList(res.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let [pagePermission, setPagePermission] = useState([]);

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Team") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  let [Data, setData] = useState([]);

  const [chartData, setChartData] = useState([
    ["Application", "Running Time"],
    ["No Apps", 0],
  ]);

  const options = {
    // width: "90%",
    // height: "100%",
    // legend: {
    //   position: "right", // <--- top position
    // },
    // chartArea: {
    //   height: "100%",
    //   width: "90%",
    //   top: "30%", // <-- top space (you can use fix values as well. (e.x. 50))
    // },

    chartArea: { left: 30, bottom: 30, top: "20%", width: "95%", height: "100%" },
              legend: { position: 'right',textStyle:{ color: 'black',fontSize: 14},alignment:'center' },

    title: "Background Applications:",
    is3D: true,
  };

  const backgroundApplicationTracking = (usrId = user_Id, startDate = date) => {
    setLoading(true);
    let data = {
      userid: usrId,
      date: startDate,
    };
    getBackgroundApplications(data)
      .then((res) => {
        setData(res.data.Data);

        const formattedData = [
          ["Application", "Running Time"],
          ...res.data.Data.map((item) => [
            item.Application_Name,
            Number(item.ActivityPer),
          ]),
        ];

        console.log(formattedData);
        // Update the state with the new data
        setChartData(formattedData);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const handleStartDateChange = (selectedDate) => {
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
        .getDate()
        .toString()
        .padStart(2, "0")}`
      : null;
      setDate(formattedDate);
      backgroundApplicationTracking(user_Id, formattedDate);
  };

  

  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Application Tracking" />

        <section className="empDetail">
          <div className="row">
            <div className="col-lg-5 col-md-4 col-sm-6 col-xs-12 mt-3 d-flex justify-content-between align-items-center">
              <p
                className="lableRepo font-poppins-regular"
                style={{ marginRight: "15px" }}
                value="0"
              >
                Employees
              </p>
              <select
                width="250px"
                className="form-select team-select borders"
                value={user_Id}
                onChange={(e) => {
                  setUser_Id(e.target.value);
                  backgroundApplicationTracking(e.target.value, date);
                }}
              >
                {empList.map((data, i) => {
                  return (
                    <React.Fragment key={i}>
                      <option username={data.Full_Name} value={data.User_Id}>
                        {data.Full_Name}
                      </option>
                    </React.Fragment>
                  );
                })}
              </select>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-3 d-flex justify-content-between align-items-center">
              <p
                className="lableRepo font-poppins-regular"
                style={{ marginRight: "8px" }}
              >
                Date
              </p>

              <DatePicker
        selected={date ? new Date(date) : null}
        onChange={handleStartDateChange}
        dateFormat="dd.MM.yyyy"
        className="form-control borders team-select selectDate"
        maxDate={new Date()} 
        todayButton="TODAY"
      />


              {/* <input
                width="250px"
                type="date"
                defaultValue={date}
                max={today}
                className="borders form-control team-select"
                onChange={(e) => {
                  setDate(e.target.value);
                  backgroundApplicationTracking(user_Id, e.target.value);
                }}
              /> */}
            </div>
          </div>
        </section>

        <section className="usersAppDetails mt-3">
          <Chart
            chartType="PieChart"
            data={chartData}
            options={options}
            width={"100%"}
            height={"400px"}
          />
        </section>
      </div>
    </section>
  );
}
