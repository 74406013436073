import React,  {useEffect} from "react";
import { Routes, Route } from "react-router-dom";

import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { checkJWTToken } from "../authApi/AuthCRUD";



import { useNavigate } from "react-router-dom";

import Admin from "../adminDashboard/Admin";
import DailyAttendance from "../reportDashboard/DailyAttendance";
import Download from "../pages/Download";
import Upload from "../pages/Upload";
import Dashboard from "../livedashboard/Dashboard";
import AddUser from "../adminDashboard/AddUser";
import TasksAndProjects from "../taskAndProjectDashboard/TasksAndProjects";
import Settings from "../pages/Settings";
import EditUser from "../adminDashboard/EditUser";
import CreateProject from "../taskAndProjectDashboard/CreateProject";
import UpdateProject from "../taskAndProjectDashboard/UpdateProject";
import Tasks from "../taskAndProjectDashboard/Tasks";
import Role from "../rolesAndPermissions/Role";
import EditRoles from "../rolesAndPermissions/EditRoles";
import CreateRole from "../rolesAndPermissions/CreateRole";
import CreateTask from "../taskAndProjectDashboard/CreateTask";
import EditTask from "../taskAndProjectDashboard/EditTask";
import UserProfileView from "../adminDashboard/UserProfileView";
// import Client from "../pages/Client";
// import CreateClient from "../pages/CreateClient";
import EmailTemplateList from "../pages/Email-template";
import EditEmailTemplate from "../pages/EditEmailTemplate";
import SummaryReport from "../reportDashboard/SummaryReport";
import UserReport from "../reportDashboard/UserReport";
import UserTimeline from "../reportDashboard/UserTimeline";
import UserScreenshot from "../screenshotDashboard/UserScreenshots";
import ProfilePage from "../profileComponent/ProfilePage";
import EmployeeHourlyRate from "../hourlyRate/EmployeeHourlyRate";
import AppProductivityPolicy from "../policyComponent/AppProductivityPolicy";
import EmployeeLogs from "../reportDashboard/EmployeeLogs";
import ViewProjectDetails from "../taskAndProjectDashboard/ViewProjectDetails";
import ListLeave from "../leaveType/ListLeave";
import ViewRole from "../rolesAndPermissions/ViewRole";
import TaskDetails from "../taskAndProjectDashboard/TaskDetails";
import TeamDashboard from "../teamDashboard/TeamDashboard";
import ProjectReport from "../reportDashboard/ProjectReport";
import ViewTeamMembers from "../teamDashboard/ViewTeamMembers";
import IntegrationsList from "../integrationComponent/IntegrationsList";
import IntegrationsNew from "../integrationComponent/IntegrationsNew";
import IntegrationEdit from "../integrationComponent/IntegrationEdit";
import UploadApp from "../pages/UploadApp";
import UpdateUpload from "../pages/UpdateUpload";
import ViewUpload from "../pages/ViewUpload";
import ShowLeaves from "../applyLeave/showLeaves";
import ApplyLeave from "../applyLeave/applyleave";
import UpdateLeave from "../applyLeave/updateLeave";
import ApproveLeaveRequests from "../leaveType/ApproveLeaveRequests";
import ImportEmployee from "../adminDashboard/ImportEmployee";
import PageNotFound from "../errorPage/PageNotFound";
import PricingPage from "../pricingComponent/PricingPage";
import NewApplyLeave from "../applyLeave/NewApplyLeave";
import AdminProjectReport from "../reportDashboard/AdminProjectReport";
import Supscription from "../pricingComponent/Supscription";
import PageUnAuthorize from "../errorPage/PageUnAuthorize";
import { SuperAdminDashboard } from "../SuperAdminView/SuperAdminDashboard";
import { SuperAdminSupscription } from "../SuperAdminView/SuperAdminSupscription";
import SuperAdminEditSupscription from "../SuperAdminView/SuperAdminEditSupscription";
import PlanExpire from "../errorPage/PlanExpire";
import CheckStatus from "../pricingComponent/CheckStatus";
import EditLeaveType from "../leaveType/EditLeaveType";
import ApplicationTrackingReport from "../ApplicationTracking/ApplicationTrackingReport";

const NavPage = (props) => {

  let navigate = useNavigate();
  let dispatch = useDispatch();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserToken = Authentication.Token;

  useEffect(() => {
    // Auto logout user when token is expire
    if(!checkJWTToken(UserToken)){
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    }
  }, []);

  return (
    <>
      <div>
        <section>
          <Routes>
            {props.userData.map((show) => {
              return (
                <React.Fragment Key={show.Id}>
                  {show.Name === "DashBoard" ? (
                    <>
                      <Route path="/" element={<Dashboard />} />
                      <Route
                        path="/dashboard"
                        element={<Dashboard sub={show.SubView} />}
                      />
                      <Route path="*" element={<PageNotFound />} />
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Screenshots" ? (
                    <>
                      {" "}
                      <Route path="/screenshots" element={<UserScreenshot />} />
                      <Route path="*" element={<UserScreenshot />} />
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "ApplyLeave" ? (
                    <>
                      <Route path="/show-leave" element={<ShowLeaves />} />
                      <Route path="/apply-leave" element={<NewApplyLeave />} />
                      <Route path="/update-leave" element={<UpdateLeave />} />
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Reports" ? (
                    <>
                      {show.SubView.map((view) => {
                        return (
                          <React.Fragment key={view.Id}>
                            {view.SubViewName === "Daily Attendance" ? (
                              <>
                                <Route path="*" element={<DailyAttendance />} />
                                <Route
                                  path="/daily-attendance"
                                  element={<DailyAttendance />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "Summary Reports" ? (
                              <>
                                <Route path="*" element={<SummaryReport />} />
                                <Route
                                  path="/summary-report"
                                  element={<SummaryReport />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "User Reports" ? (
                              <>
                                <Route path="*" element={<UserReport />} />
                                <Route
                                  path="/user-report"
                                  element={<UserReport />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "User Timeline" ? (
                              <>
                                <Route path="*" element={<UserTimeline />} />
                                <Route
                                  path="/user-timeline"
                                  element={<UserTimeline />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "Employees Logs" ? (
                              <>
                                <Route path="*" element={<EmployeeLogs />} />
                                <Route
                                  path="/employee-logs"
                                  element={<EmployeeLogs />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "Project Reports" ? (
                              <>
                                <Route
                                  path="*"
                                  element={<AdminProjectReport />}
                                />
                                <Route
                                  path="/admin-project-report"
                                  element={<AdminProjectReport />}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Task & Projects" ? (
                    <>
                      <Route path="*" element={<TasksAndProjects />} />
                      <Route
                        path="/projects-list"
                        element={<TasksAndProjects />}
                      />
                      <Route
                        path="/create-project"
                        element={<CreateProject />}
                      />
                      <Route
                        path="/update-project"
                        element={<UpdateProject />}
                      />
                      <Route
                        path="/project-details"
                        element={<ViewProjectDetails />}
                      />
                      <Route path="/project-tasks" element={<Tasks />} />
                      <Route path="/task-details" element={<TaskDetails />} />
                      <Route path="/create-task" element={<CreateTask />} />
                      <Route path="/edit-task" element={<EditTask />} />
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Admin" ? (
                    <>
                      {show.SubView.map((view) => {
                        return (
                          <React.Fragment key={view.Id}>
                            {view.SubViewName === "Users" ? (
                              <>
                                <Route path="*" element={<Admin />} />
                                <Route path="/admin" element={<Admin />} />
                                <Route path="/add-user" element={<AddUser />} />
                                <Route
                                  path="/edit-user"
                                  element={<EditUser />}
                                />
                                <Route
                                  path="/UserProfileView"
                                  element={<UserProfileView />}
                                />
                                <Route
                                  path="/import-bulk-user"
                                  element={<ImportEmployee />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "Users" ? (
                              <>
                                <Route path="*" element={<Admin />} />
                                <Route path="/user-used-applications" element={<ApplicationTrackingReport />} />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "Role" ? (
                              <>
                                <Route path="*" element={<Role />} />
                                <Route path="/role" element={<Role />} />
                                <Route
                                  path="/create-role"
                                  element={<CreateRole />}
                                />
                                <Route
                                  path="/edit-role"
                                  element={<EditRoles />}
                                />
                                <Route
                                  path="/view-roles"
                                  element={<ViewRole />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {view.SubViewName === "User Hourly Rate" ? (
                              <>
                                <Route
                                  path="*"
                                  element={<EmployeeHourlyRate />}
                                />
                                <Route
                                  path="/hourly-rate-list"
                                  element={<EmployeeHourlyRate />}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {/* {view.SubViewName === "Productivity Policy" ? (
                              <>
                                <Route
                                  path="*"
                                  element={<AppProductivityPolicy />}
                                />
                                <Route
                                  path="/productivity-policy"
                                  element={<AppProductivityPolicy />}
                                />
                              </>
                            ) : (
                              ""
                            )} */}

                            {view.SubViewName === "Team" ? (
                              <>
                                <Route path="*" element={<TeamDashboard />} />
                                <Route
                                  path="/team"
                                  element={<TeamDashboard />}
                                />
                                <Route
                                  path="/view-team"
                                  element={<ViewTeamMembers />}
                                />
                              </>
                            ) : (
                              ""
                            )}
                            
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Leave" ? (
                    <>
                      <Route path="*" element={<ListLeave />} />
                      <Route path="/get-leave-types" element={<ListLeave />} />
                      <Route path="/UpdateLeaveTypes" element={<EditLeaveType />} />
                      <Route
                        path="/view-all-leaves"
                        element={<ApproveLeaveRequests />}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Integration" ? (
                    <>
                      <Route path="*" element={<IntegrationsList />} />
                      <Route
                        path="/integrations"
                        element={<IntegrationsList />}
                      />
                      <Route
                        path="/integrations-new"
                        element={<IntegrationsNew />}
                      />
                      <Route
                        path="/edit-integration"
                        element={<IntegrationEdit />}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Company Setting" ? (
                    <>
                      <Route path="*" element={<Settings />} />
                      <Route path="/company-settings" element={<Settings />} />
                    </>
                  ) : (
                    ""
                  )}

                  {show.Name === "Download" ? (
                    <>
                      <Route path="*" element={<Download />} />
                      <Route path="/download" element={<Download />} />
                    </>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })}
            {/* Profile module routing */}
            <Route path="/profile-page" element={<ProfilePage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/subscription" element={<Supscription />} />
            <Route path="/pagenotfound" element={<PageNotFound />} />
            <Route path="/unauthorize" element={<PageUnAuthorize />} />
            <Route path="/plan-expire" element={<PlanExpire />} />
            <Route path="/subscription-check" element={<CheckStatus />} />
            {/* <Route path="/superadmindashboard" element={<SuperAdminDashboard />} />
            <Route path="/superadminsubscription" element={<SuperAdminSupscription />} />
            <Route path="/superadminsubscription/editsubscription" element={<SuperAdminEditSupscription />} /> */}


          </Routes>
        </section>
      </div>
    </>
  );
};

export default NavPage;
