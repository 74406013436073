import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import SignUpNew from "../authComponent/SignUpNew";
import LoginNew from "../authComponent/LoginNew";
import ForgotPasswordNew from "../authComponent/ForgotPasswordNew";
import ResetPassword from "../authComponent/ResetPassword";
import VerifyYourEmail from "../authComponent/VerifyYourEmail";
import ResendEmail from "../authComponent/ResendEmail";
import VerifiedSuccessfully from "../authComponent/VerifiedSuccessfully";
import PasswordResetSuccessfully from "../authComponent/PasswordResetSuccessfully.js";
import EmailAlreadyVerified from "../authComponent/EmailAlreadyVerified";
import Layout from "./Layout";

import $ from "jquery";
import PageNotFound from "../errorPage/PageNotFound";

export default function PublicRoutes() {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const [permissionData, setPermissionData] = React.useState([]);
  let getToken = null;

  useEffect(() => {
    if (Authentication) {
      getToken = Authentication.Token.data;
      setPermissionData(Authentication.UserPermission);
    }
    if (!Authentication && getToken === (undefined || null)) {
      $("body").css("background-color", "#02030A");
    } else {
      $("body").css("background-color", "#ffffff");
    }
  });

  return (
    <React.Fragment>
      {!Authentication && getToken === (undefined || null) ? (
        <>
          <Routes>
            <Route path="/" element={<LoginNew />} />
            <Route path="/sign/up" element={<SignUpNew />} />
            <Route path="/forgot/password" element={<ForgotPasswordNew />} />
            <Route path="/reset/password" element={<ResetPassword />} />
            <Route path="/verify/your/email" element={<VerifyYourEmail />} />
            <Route path="/resend/email" element={<ResendEmail />} />
            <Route
              path="/email/verified/successfully"
              element={<VerifiedSuccessfully />}
            />
            <Route
              path="/password/changed/successfullly"
              element={<PasswordResetSuccessfully />}
            />
            <Route
              path="/email/already/verified"
              element={<EmailAlreadyVerified />}
            />
            {/* <Route path="/page-not-found" element={<PageNotFound />} /> */}
            <Route path="*" element={<LoginNew/>}/>
          </Routes>
        </>
      ) : (
        <>
          <Layout userData={permissionData} />
        </>
      )}
    </React.Fragment>
  );
}
