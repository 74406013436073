import React, { useEffect, useState } from "react";
import "../css/style.css";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { permissionData, addRolePermission } from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function CreateRole() {
  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;
  const [permission, setPermission] = useState([]);
  const [Role_Name, setRolename] = useState("");
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    get_Permissions();
  }, []);

  const get_Permissions = () => {
    permissionData()
      .then((res) => {
        setPermission(res.data.Data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handleChange = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  const [formerrors, setFormErrors] = useState({
    Role_Name: "",
  });

  const validate = (values) => {
    const errors = {};
    if (!values.RoleName) {
      errors.Role_Name = "Role name is required*";
    }
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Role_Name: "",
      });
      return true;
    }
  };

  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  const postData = (e) => {
    e.preventDefault();
    let data = {
      RoleName: Role_Name,
      SubPermissions: checked,
    };
    if (validate(data)) {
      setUrlResponseMsg(true);
      addRolePermission(data)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/role");
          }, 2000);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
        });
    }
  };

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Create New Role" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          <section
            className="bg-white p-0 p-md-2 p-lg-3 p-xl-5"
            style={{ borderRadius: "10px" }}
          >
            <div className="container-fluid">
              <form onSubmit={postData}>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>
                        Role Name <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        name="Role_Name"
                        onChange={(e) => {
                          setRolename(e.target.value);
                          setFormErrors({ ...formerrors, Role_Name: "" });
                        }}
                        className="form-control bdr-radius team-select"
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Role_Name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12"></div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt-4">
                      <Label className="mb-2">Permissions</Label>
                    </div>
                    
                      {permission.map((item) => {
                        return (
                          <React.Fragment key={item.Permission_Id}>
                          <div className="row align-items-center">
                          <div className="col-lg-4 col-md-4 col-sm-12">
                              <Label className="font-poppins-medium">
                                {item.Permission_Name}
                              </Label>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                              <div className="row">
                                {item.SubPermissions.map((sub) => {
                                  return (
                                    <>
                                      <div className="col-lg-6 col-md-6 col-sm-12">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={sub.Sub_Permission_Id}
                                              onChange={handleChange}
                                            />
                                          }
                                          label={sub.Display_Name}
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                            
                            <hr className="me-4" />
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <div class="d-flex justify-content-end f-manage p-0">
                    <div class="d-flex flex-row row-manage">
                      <div class="d-flex">
                        <button class="btn buttonDefault text-white font-poppins-medium">
                          {!loading && <span>Submit</span>}
                          {loading && (
                            <span
                              className="indicator-progress font-poppins-regular"
                              style={{ display: "block" }}
                            >
                              Submit
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div class="d-flex">
                        <Link
                          to="/role"
                          className="text-decoration-none"
                        >
                          <button
                            type="button"
                            id="cancel-add-leave"
                            class="btn text-white buttonDefault cancelbtnhover font-poppins-medium" style={{
                      background: "#8A899C",
                    }}
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </React.Fragment>
  );
}
