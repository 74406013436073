import React, { useState } from "react";
import "./SignUpNew.css";
import odyseyyLogo from "../Images/formImages/odyssey-logo.svg";
import odyseyySmLogo from "../Images/formImages/odyssey-sm-logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import verifyEmailIcon from "../Images/formImages/verify-email-success.svg";
import { resend } from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";

export default function VerifyYourEmail() {
    let userData = useSelector(
        (store) => store.auth.activationCode,
        shallowEqual
    );
    let navigate = useNavigate();
    const { search } = useLocation();
    const emailVerificationId = new URLSearchParams(search).get("id");
    const [loading, setLoading] = useState(false);

    const resendEmail = () => {
        let data = {
            Email: userData.Company_Email,
            Tenant: userData.Tenant_Name,
            ActivationCode: userData.ActivetionCode,
        };
        setLoading(true);
        resend(data)
            .then((res) => {
                setLoading(false);
                if(res.data.Message === "Email is already verified") {
                    navigate('/email/already/verified');
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    return (
        <>
            <div className="sign-up-wrapper d-none d-md-block d-lg-block d-xl-block">
                <div className="content">
                    <div className="container">
                        <div className="mb-7 d-flex justify-content-end text-white">
                            <div className="d-flex align-items-center gap-22">
                                <span className="font-poppins-regular account-head">Back to</span>
                                <Link to="/" className="text-decoration-none">
                                    <span className="text-white account-btn" role="button">
                                        <span className="font-poppins-medium">Sign</span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-5 bg-left-section position-relative">
                                <div className="position-absolute logo-center">
                                    <img src={odyseyyLogo} alt="logo" />
                                </div>
                            </div>
                            <div className="col-md-7 bg-right-form h-500 position-relative">
                                <div className="row">
                                    <div className="pt-pb-3">
                                        <div className="verify-password-box">
                                            <div className="text-center">
                                                <div className="d-flex justify-content-center">
                                                    <img src={verifyEmailIcon} alt="password-success" />
                                                </div>
                                                <div className="text-center mt-4">
                                                    <h1 className="main-heading font-poppins-bold">Verify Your Email</h1>
                                                    <span className="sub-heading font-poppins-regular">To confirm your email address, please click on the link in the email we sent you.</span>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 mt-2 d-flex justify-content-center">
                                                        <button
                                                            type="submit"
                                                            className="btn mt-5 submit-btn font-poppins-regular"
                                                            disabled={loading}
                                                            onClick={resendEmail}
                                                        >
                                                            {!loading && (
                                                                <span>Resend Email</span>
                                                            )}
                                                            {loading && (
                                                                <span
                                                                    className="indicator-progress"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    Resend Email
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block d-md-none d-lg-none d-xl-done">
                <div className="row m-0 p-0">
                    <div className="col-12 sm-screen-bg">
                        <div className="m-4 mt-5 d-flex justify-content-center align-items-center">
                            <span className="font-poppins-regular account-sm-head">Back to</span>&nbsp;&nbsp;
                            <Link to="/" className="text-decoration-none">
                                <span className="text-white account-sm-btn">
                                    <span className="font-poppins-medium">Sign in</span>
                                </span>
                            </Link>
                        </div>

                        <div className="m-5 d-flex justify-content-center">
                            <img src={odyseyySmLogo} alt="logo" />
                        </div>
                    </div>

                    <div className="row p-0 m-0">
                        <div className="col-12 bg-white">
                            <div className="mt-5 mb-5 mx-1">
                                <div>
                                    <div>
                                        <div className="text-center mt-5">
                                            <div className="d-flex justify-content-center">
                                                <img src={verifyEmailIcon} alt="password-success" />
                                            </div>
                                            <div className="text-center mt-4">
                                                <h1 className="main-sm-heading font-poppins-bold">Verify Your Email</h1>
                                                <span className="sub-heading font-poppins-regular">To confirm your email address, please click on the link in the email we sent you.</span>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-center">
                                                    <button
                                                        type="submit"
                                                        className="btn mt-8 submit-sm-btn font-poppins-regular"
                                                        disabled={loading}
                                                    >
                                                        {!loading && (
                                                            <span onClick={resendEmail}>Resend Email</span>
                                                        )}
                                                        {loading && (
                                                            <span
                                                                className="indicator-progress"
                                                                style={{ display: "block" }}
                                                            >
                                                                Resend Email
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}