import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { importEmployeeExcelSheet } from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import execlFile from "../Images/ImportUsers.xlsx";
import ClockLoader from "react-spinners/ClockLoader";
import Swal from "sweetalert2";

export default function ImportEmployee() {
  const navigate = useNavigate();
  const FileInputRef = React.useRef(null);

  const downloadTxtFile = () => {
    var link = document.createElement("a");
    link.download = "ImportUsers.xlsx";
    link.href = execlFile;
    link.click();
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    setLoading(true);
    const enteredFileInputRef = FileInputRef.current.files[0];
    let fileName1 = e.target;
    let fileName = fileName1.value;
    let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    if (ext == "xlsx") {
      const formData = new FormData();
      formData.append("emlpoyee_excel_file", enteredFileInputRef);
      importEmployeeExcelSheet(formData).then((res) => {
        setLoading(false);
        Swal.fire({
          title: "File Imported Successfully",
          text: "Note : " + res.data.Message,
          icon: "success",
        });
      });
    } else {
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "Unsupported file format. Please upload .xlsx file",
        icon: "error",
      });
    }
  };
  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Import Employee's" />

          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              <section className="addUsers p-2 p-md-4 p-lg-5">
                <div className="padding-profile">
                  <div className="mt-3">
                    <span className="font-poppins-medium">
                      This feature support bulk of registration of employee's.
                      You can import employee's through excel sheet.
                    </span>
                    <h4 className="font-poppins-regular fs-6 mt-3">
                      Remember that excel sheet must have the heading :-
                      UserName, Email, Shift_Id.
                    </h4>
                  </div>

                  <div className="d-flex mt-3 align-items-center">
                    <label className="me-2">Sample file : </label>
                    <button
                      className="buttonDefault"
                      id="downloadBtn"
                      onClick={downloadTxtFile}
                      value="download"
                    >
                      Download Sample File
                    </button>
                  </div>

                  <form className="mt-4">
                    <button
                      type="button"
                      className="btn btn-sm btn-flex btn-secondary font-poppins-medium"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-flip="top-end"
                      id="file2"
                    >
                      Import User{" "}
                      <input type="file" ref={FileInputRef} name="file" onChange={handleSubmit}/>
                    </button>
                  </form>

                  {/* <button
                    onClick={}
                    value="download"
                    className="buttonDefault mt-4"
                  >
                    {!loading && <span>Save</span>}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Save
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button> */}
                </div>
              </section>
            </>
          )}
        </div>
      </section>
    </>
  );
}
