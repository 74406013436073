import React, { useEffect, useState } from "react";
import "../css/style.css";
import "font-awesome/css/font-awesome.min.css";
import { Link, useNavigate } from "react-router-dom";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import ReactPaginate from "react-paginate";
import ClockLoader from "react-spinners/ClockLoader";
import { CSVLink } from "react-csv";
import sorticon from "../Images/sorticon.svg";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import view from "../Images/livedashboard/view.svg";

import {
  createTeam,
  getTeams,
  updateTeam,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import tick from "../Images/profile.svg";
import crossIcon from "../Images/livedashboard/cross-icon.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

export default function TeamDashboard() {
  const navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  let [loading, setLoading] = useState(true);
  let [btnloading, setBtnLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [currentpage, setCurrentPage] = useState(1);
  const [teamname, setTeamname] = useState("");
  const [editTeamname, setEditTeamname] = useState("");
  const [noOfPage, setNoOfPage] = useState(1);
  let [data, setData] = useState([]);
  let [flag, setflag] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [editTeamId, setEditTeamId] = useState(0);
  let [msg, setmsg] = useState(false);

  useEffect(() => {
    getUserPermission();
    getTeamList();
  }, []);

  let [dataPerPage, setDataPerPage] = useState(10);

  const getTeamList = (
    page = currentpage || 1,
    keys = "",
    check = true,
    search = searchKey,
    pageSize = dataPerPage
  ) => {
    setLoading(true);
    let data = {
      tenantname: Tenant,
      colName: keys,
      sortOrdr: check,
      searchVal: search,
      pageNumber: page,
      pagesize: pageSize,
    };
    getTeams(data)
      .then((res) => {
        setLoading(false);
        setData(res.data.Data);
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        setLoading(false);
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
      });
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      getTeamList(currentpage, "", flag, "", dataPerPage);
    }
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getTeamList(pageNumber, "", flag, "");
  };

  const onSortChange = (key, check) => {
    if (check) setflag(false);
    else setflag(true);
    getTeamList(currentpage, key, flag, searchKey);
  };

  const inputChangeHandler = (e) => {
    if (e == "") {
      getTeamList();
    } else {
      getTeamList(1, "", flag, e);
    }
  };

  const handleEdit = (getTeamId) => {
    setEditTeamId(getTeamId);
    setIsInputActive(true);
  };

  const handleChangeName = (id) => {
    let data = {
      Team_Id: id,
      Team_Name: editTeamname,
    };
    updateTeam(data)
      .then((res) => {
        setmsg(true);
        toast.success(res.data.Message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setIsInputActive(false);
        getTeamList();
      })
      .catch((err) => {});
  };

  const handleCancelChangeName = () => {
    setIsInputActive(false);
  };

  const [formerrors, setFormErrors] = useState({
    Team_Name: "",
  });

  const validate = (values) => {
    const errors = {};
    if (!values.Team_Name) {
      errors.Team_Name = "Team name is required*";
    }
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Team_Name: "",
      });
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      Team_Name: teamname,
    };
    if (validate(data)) {
      setBtnLoading(true);
      createTeam(data)
        .then((res) => {
          setmsg(true);
          document.getElementById("cancel-add-name").click();
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setBtnLoading(false);
          getTeamList();
          setTeamname("");
        })
        .catch((err) => {
          setBtnLoading(false);
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
        });
    }
  };

  let [pagePermission, setPagePermission] = useState([]);
  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Team") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Teams" />
        <div className="mt-4 mb-4">
          <div className="row">
            <div className="col-sm-12 col-md-8 col-lg-8 d-flex">
              <div className="sb-example-3 w-100">
                <div className="search__container">
                  <input
                    className="search-input-field font-poppins-regular"
                    type="text"
                    placeholder="Search Team..."
                    autoComplete="on"
                    onKeyUp={(e) => {
                      setSearchKey(e.target.value);
                      inputChangeHandler(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              {pagePermission.map((list) => {
                if (list.Sub_Permission_Name === "create_team") {
                  return (
                    <>
                      <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                        <div className="btn-div-width">
                          <div
                            type="button"
                            className="btn btn-lg w-100 font-poppins-medium align-center"
                            style={{
                              borderRadius: "5px",
                              borderColor: "#6300B0",
                              background: "#6300B0",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              color: "#FFF",
                              fontWeight: "500",
                              fontSize: "12px",
                              padding: "10px 20px",
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#AddNewTeam"
                          >
                            <span>Create New Team</span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <hr />

        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3}/>
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {data == null || data.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div className="table-responsive">
                  <table
                    className="table"
                    style={{
                      borderBottomWidth: "0px",
                      borderBottomColor: "none",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="tableHead">
                          Team Name &nbsp;
                          <button
                            onClick={() => {
                              onSortChange("Team_Name", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>
                        </th>
                        <th className="tableHead ">
                          Total members &nbsp;
                          <button
                            onClick={() => {
                              onSortChange("Users", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>{" "}
                        </th>
                        <th className="tableHead ">
                          Creation Date &nbsp;
                          <button
                            onClick={() => {
                              onSortChange("Created_Date", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>
                        </th>
                        <th className="tableHead ">
                          
                          <button
                            // onClick={() => { onSortChange('Last_Seen', flag) }}
                            type="button"
                          ></button>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((res, i) => (
                        <tr style={{ borderRadius: "30px" }} key={i}>
                          <td className="front-radius tableCell compName">
                            <div className="mainDiv d-flex">
                              {isInputActive && editTeamId == res.Team_Id ? (
                                <>
                                  <form>
                                    <div className="d-flex align-items-center">
                                      <input
                                        id="input-control"
                                        name="policy"
                                        type="text"
                                        className="form-control w-50 team-select"
                                        autoComplete="off"
                                        style={{
                                          borderRadius: "6px",
                                          height: "33px",
                                        }}
                                        defaultValue={res.Team_Name}
                                        onChange={(e) =>
                                          setEditTeamname(e.target.value)
                                        }
                                      />
                                      &nbsp;
                                      <img
                                        src={tick}
                                        alt="tick-icon"
                                        className="tick-icon-height"
                                        onClick={() =>
                                          handleChangeName(res.Team_Id)
                                        }
                                      />
                                      &nbsp;
                                      <div
                                        className="cross-policy-name"
                                        role="button"
                                      >
                                        <img
                                          src={crossIcon}
                                          alt="tick-icon"
                                          style={{ height: "18px" }}
                                          onClick={handleCancelChangeName}
                                        />
                                      </div>
                                    </div>
                                  </form>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <p>{res.Team_Name}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                          <td className=" tableCell">
                            <div className="mainDiv">
                              <div>{res.Users}</div>
                            </div>
                          </td>
                          <td className=" tableCell">
                            <div className="mainDiv">
                              <div>
                                {moment(res.Created_Date).format("DD.MM.YYYY")}
                              </div>
                            </div>
                          </td>
                          <td className="tableCell back-radius">
                            {pagePermission.map((list) => {
                              if (list.Sub_Permission_Name === "read_team") {
                                return (
                                  <div className="mainDiv ">

                                  <div className="Maindiv d-flex justify-content-center align-items-center">
                                          <div className="edit me-2" title="Update Team">
                                          {pagePermission.map((list) => {
                                                  if (
                                                    list.Sub_Permission_Name ===
                                                    "update_team"
                                                  ) {
                                                    return (
                                                      <>
                                                              <img width={20} onClick={() => {
                                                        handleEdit(res.Team_Id);
                                                      }} style={{cursor: "pointer",}}
                                                                src={edit}
                                                                alt="icon"
                                                              />
                                                      </>
                                                    );
                                                  }
                                                })}
                                          </div>
                                          <div className="view" title="View Team Members">
                                          <Link
                                                       to={`/view-team?id=${res.Team_Id}`}
                                                      className="drpdwnitem"
                                                      style={{
                                                        color:
                                                          "#59A561 !important",
                                                      }}
                                                    >
                                                    <img
                                                    width={22} style={{marginTop: '3px'}}
                                                      src={view}
                                                      alt="icon"
                                                    />
                                                    </Link>
                                          </div>
                                        </div>


                                    {/* <Dropdown>
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu size="sm" title="">
                                        {pagePermission.map((list) => {
                                          if (
                                            list.Sub_Permission_Name ===
                                            "update_team"
                                          ) {
                                            return (
                                              <>
                                                <Dropdown.Item className="drpdwnitem">
                                                  <Link>
                                                    <div
                                                      className="d-flex"
                                                      onClick={() => {
                                                        handleEdit(res.Team_Id);
                                                      }}
                                                    >
                                                      <img
                                                        src={edit}
                                                        alt="icon"
                                                      />
                                                      <div>&nbsp; Edit</div>
                                                    </div>
                                                  </Link>
                                                </Dropdown.Item>
                                              </>
                                            );
                                          }
                                        })}
                                        
                                        <Dropdown.Item className="drpdwnitem">
                                            <Link className="d-flex align-items-center"
                                              to={`/view-team?id=${res.Team_Id}`}
                                            >
                                                <img src={profile} alt="icon" />
                                                <div>&nbsp; View</div>
                                            </Link>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown> */}
                                  </div>
                                );
                              }
                            })}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
        {(noOfPage == 1 && (!data || data.length <= 10)) || noOfPage == 0 ? (
          ""
        ) : (
          <div className="paginations d-flex justify-content-between align-items-center mt-2">
            <div className="d-flex justify-content-center align-items-center">
              <p className="lableRepo">Rows per page : </p>
              <input
                id="input-control"
                name="policy"
                type="text"
                className="form-control w-20 text-center team-select"
                autoComplete="off"
                style={{
                  borderRadius: "6px",
                  height: "30px",
                }}
                defaultValue={dataPerPage}
                onChange={(e) => setDataPerPage(e.target.value)}
                onKeyDown={testPage}
              />
            </div>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={noOfPage}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
      <div
        className="modal fade"
        id="AddNewTeam"
        data-bs-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddNewTeamLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div id="modal-center" className="modal-content">
            {/* row for heading and cross icon */}
            <div className="row" style={{ padding: "1rem 1rem" }}>
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <h4 className="font-poppins-bold profile-details-heading">
                      Create New Team
                    </h4>
                  </div>
                  <div
                    role="button"
                    id="graph-import-close"
                    data-bs-dismiss="modal"
                  >
                    {/* <img style={{ height: "25px", width: "29px" }} src={crossIcon} alt="cross" /> */}
                  </div>
                </div>
              </div>
            </div>
            {/* row for heading and cross icon end  */}
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="form-group col-12 mb-4">
                    <label className="w-100 label-field mb-2 font-poppins-medium">
                      Team Name <span className="text-danger">*</span>
                      <input
                        id="input-control"
                        type="text"
                        className="mt-2 form-control form-input-field"
                        autoComplete="off"
                        placeholder="Enter team name"
                        value={teamname}
                        onChange={(e) => {
                          setTeamname(e.target.value);
                          setFormErrors({ ...formerrors, Team_Name: "" });
                        }}
                      />
                    </label>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Team_Name}
                      </span>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="row">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-row row-manage">
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn  buttonDefault text-white font-poppins-medium"
                          onClick={handleSubmit}
                        >
                          {!btnloading ? (
                            <span
                              className="indicator-progress font-poppins-regular"
                              style={{ display: "block" }}
                            >
                              Submit
                            </span>
                          ) : (
                            <span
                              className="indicator-progress font-poppins-regular"
                              style={{ display: "block" }}
                            >
                              Submit
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div className="d-flex">
                        <button
                          type="button"
                          id="cancel-add-name"
                          className="btn text-white cancelbtnhover  buttonDefault font-poppins-medium"
                          style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setTeamname("");
                            setFormErrors({
                              Team_Name: "",
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {msg && <ToastContainer />}
    </section>
  );
}
