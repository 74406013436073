import rootReducer from "./reduxAuthentication/reducers/index";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { legacy_createStore as createStore } from 'redux';
const persisConfig = {
    key: 'auth',
    storage
}

const persistedStore = persistReducer(persisConfig, rootReducer)
const store = createStore(persistedStore);
const Persistor = persistStore(store);

export default store;

export { Persistor };