import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";
import "../css/style.css";
import ProfileIamge from "../Images/profileimage.png";
import CheckIamge from "../Images/checkImage.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import { TableHead } from "@mui/material";
// import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import { useLocation } from "react-router-dom";
import settings from "../Images/settingsico.svg";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import ReactPaginate from "react-paginate";
import { selectedPlan, subscriptonPlans } from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import { shallowEqual, useSelector } from "react-redux";
import sorticon from "../Images/sorticon.svg";
import moment from "moment";
import view from "../Images/livedashboard/view.svg";
import ClockLoader from "react-spinners/ClockLoader";

export default function PricingPage() {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let [subscriptionData, setSubscriptionData] = useState([]);
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("id");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    subscriptonPlans()
      .then((res) => {
        setLoading(false);
        console.log(res);
        if(res.data.Status == "Success"){
          setSubscriptionData(res.data.Data);
        }
        else if(res.data.Status == "Expire"){
          navigate('/plan-expire')
        }
        else if(res.status == 401){
          navigate('/unauthorize')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getYourPlan = (planData) => {
    let selectData = {
      Id: planData.Id,
      Plan_Name: planData.Plan_Name,
      Price: planData.Price,
      GST: planData.GST,
      Currency: "usd",
      Plan_Duration: planData.Plan_Duration,
      Discount: planData.Discount,
    };
    setLoading(true);
    selectedPlan(selectData)
      .then((res) => {
        let redirectURL = res.data.Data.URL;
        window.location.href = redirectURL;
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Subscription Plans" />

          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>

          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              <section className="pricing">
                <div className="row">
                  {subscriptionData.map((data, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <div className="priceCard mt-2">
                            <div className="priceHeader">
                              <div className="priceHead">{data.Plan_Name}</div>
                              <div className="priceAmount">
                                <span className="amount">${data.Price} </span>
                                <span className="amountLabel">
                                  {" "}
                                  for {data.Users} Users
                                </span>
                              </div>
                              {/* <div className="amountGST">GST: {data.GST}%</div> */}
                              <div className="amountGST">Discount: {data.Discount}%</div>
                            </div>

                            <div className="priceDesc" id="custom-scroll">
                              {data.PlanDetails.map((list, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <div className="priceDescList d-flex align-items-center">
                                      <span>
                                        <img
                                          src={CheckIamge}
                                          alt="checkImage"
                                          width={10}
                                          height={10}
                                        />
                                      </span>
                                      <span className="listItems">{list}</span>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>

                            <div className="d-flex justify-content-center align-items-center">

                             {data.ActivePlanId ?
                             
                              <button
                                className="buyBtnUp"
                                type="submit"
                                disabled
                                onClick={() => {
                                  getYourPlan(data);
                                }}
                              >
                                Buy Plan
                              </button>
                              :
                              <button
                                className="buyBtn"
                                type="submit"
                                onClick={() => {
                                  getYourPlan(data);
                                }}
                              >
                                Buy Plan
                              </button>
                             }
                              
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </section>
            </>
          )}
        </div>
      </section>
    </React.Fragment>
  );
}
