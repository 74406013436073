import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";
import "../css/style.css";
import ProfileIamge from "../Images/profileimage.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import { TableHead } from "@mui/material";
// import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import { useLocation } from "react-router-dom";
import settings from "../Images/settingsico.svg";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import ReactPaginate from "react-paginate";
import {
  checkUserPermissions,
  disableTask,
  enableTask,
  getTaskByProjectStatus,
  getTasksByProject,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import { shallowEqual, useSelector } from "react-redux";
import sorticon from "../Images/sorticon.svg";
import moment from "moment";
import enableIcon from "../Images/livedashboard/enable-icon.svg";
import disableIcon from "../Images/livedashboard/disable-icon.svg";
import view from "../Images/livedashboard/view.svg";

const Tasks = () => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));
  const CustomDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="ProfileContent">
        <img
          src={ProfileIamge}
          alt="ProfileIamge"
          style={{ width: "35px", height: "35px" }}
        />
      </span>
    </a>
  ));

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  let [data, setData] = useState([]);
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("id");
  localStorage.setItem("ProjectId", projectId);
  const [sort, setSort] = useState("ASC");
  const [issort, setIssort] = useState(false);
  // Pagination
  const [noOfPage, setNoOfPage] = useState(1);
  let [currentpage, setCurrentPage] = useState(1);
  let [pagePermission, setPagePermission] = useState([]);

  let [dataPerPage, setDataPerPage] = useState(10);

  useEffect(() => {
    getData();
    getUserPermission();
  }, []);

  const getData = (
    colName = "",
    page = currentpage,
    search = "",
    sorttype = "",
    sort = issort,
    pageSize = dataPerPage
  ) => {
    let data = {
      projectId: projectId,
      PageNumber: page,
      isSort: sort,
      sortType: sorttype,
      sortColumn: colName,
      searchname: search,
      status: "",
      PageSize: pageSize,
    };
    getTasksByProject(data)
      .then((res) => {
        setData(res.data.Data);
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      getData("", currentpage, "", "", dataPerPage);
    }
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getData("", pageNumber);
  };

  const onSort = (colName, sortType) => {
    if (sortType == "ASC") setSort("DESC");
    else setSort("ASC");
    getData(colName, 1, "", sortType, true);
  };

  const inputChangeHandler = (e) => {
    if (e.target.value == "") {
      getData();
    } else {
      getData("", 1, e.target.value);
    }
  };

  const handlePending = () => {
    let data = {
      tenantname: Tenant,
      roleid: UserRole,
      projectid: projectId,
      taskstatus: "Pending",
    };
    getTaskByProjectStatus(data)
      .then((res) => {
        setData(res.data.FirstList);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const handleCompleted = () => {
    let data = {
      tenantname: Tenant,
      roleid: UserRole,
      projectid: projectId,
      taskstatus: "Completed",
    };
    getTaskByProjectStatus(data)
      .then((res) => {
        setData(res.data.FirstList);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const handleCancel = () => {
    let data = {
      tenantname: Tenant,
      roleid: UserRole,
      projectid: projectId,
      taskstatus: "Cancel",
    };
    getTaskByProjectStatus(data)
      .then((res) => {
        setData(res.data.FirstList);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Tasks") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  const handleToggle = (id, taskStatus) => {
    // setIsToggled(!isToggled);
  
    if (taskStatus) {
      disableTask(id)
        .then((res) => {
          getData("", currentpage);
        })
        .catch((err) => {
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
        });
    } else {
      enableTask(id)
        .then((res) => {
          getData("", currentpage);
        })
        .catch((err) => {
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
        });
    }
  };
  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Project Tasks" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>

          <div className="mt-4 mb-4">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 d-flex mb-2">
                <div className="sb-example-3 w-100">
                  <div className="search__container">
                    <input
                      className="search__input_task search-input-field font-poppins-regular"
                      type="text"
                      placeholder="Search Tasks..."
                      autoComplete="on"
                      onKeyUp={inputChangeHandler}
                      //onKeyPress={keypressed}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "create_task") {
                    return (
                      <>
                        <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center  mt-md-0 mt-lg-0">
                          <div className="btn-div-width">
                            <Link
                              type="button"
                              className="btn btn-lg w-100 font-poppins-medium align-center"
                              style={{
                                borderRadius: "6px",
                                borderColor: "#6300B0",
                                background: "#6300B0",
                                fontFamily: "Poppins",

                                fontStyle: "normal",
                                color: "#FFF",
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "10px 20px",
                              }}
                              to={`/create-task?id=${projectId}`}
                            >
                              <span>Create New Task</span>
                            </Link>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <hr />
          {data == null || data.length === 0 ? (
            <>
              <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                No Result Available
              </h5>
            </>
          ) : (
            <>
              <div className="table-responsive">
                <table
                  className="table projects"
                  style={{
                    borderBottomWidth: "0px",
                    borderBottomColor: "none",
                  }}
                >
                  <TableHead>
                    <tr>
                      <th className="tableHead" style={{ minWidth: "180px" }}>
                        Task Name &nbsp;
                        <button
                          onClick={() => {
                            onSort("Task_Name", sort);
                          }}
                          type="button"
                        >
                          <img src={sorticon} alt="icon" />
                        </button>
                      </th>
                      <th className="tableHead" style={{ minWidth: "130px" }}>
                        Assignee&nbsp;
                        <button
                          onClick={() => {
                            onSort("Assignee_Name", sort);
                          }}
                          type="button"
                        >
                          <img src={sorticon} alt="icon" />
                        </button>
                      </th>
                      <th className="tableHead " style={{ minWidth: "130px" }}>
                        Status&nbsp;
                        <button
                          onClick={() => {
                            onSort("Task_Status", sort);
                          }}
                          type="button"
                        >
                          <img src={sorticon} alt="icon" />
                        </button>
                      </th>
                      {/* <th className="tableHead " style={{ minWidth: "100px" }}>
                        Start Date&nbsp;
                        <button
                          onClick={() => {
                            onSort("Start_Date", sort);
                          }}
                          type="button"
                        >
                          <img src={sorticon} alt="icon" />
                        </button>
                      </th> */}
                      
                      <th className="tableHead " style={{ minWidth: "120px" }}>
                        Spend Time&nbsp;
                        <button
                          onClick={() => {
                            onSort("Spend_Time", sort);
                          }}
                          type="button"
                        >
                          <img src={sorticon} alt="icon" />
                        </button>
                      </th>
                      <th className="tableHead " style={{ minWidth: "150px" }}>
                        Estimated Time&nbsp;
                        <button
                          onClick={() => {
                            onSort("Estimated_Time", sort);
                          }}
                          type="button"
                        >
                          <img src={sorticon} alt="icon" />
                        </button>
                      </th>
                      <th className="tableHead " style={{ minWidth: "100px" }}>
                        Last Date&nbsp;
                        <button
                          onClick={() => {
                            onSort("End_Date", sort);
                          }}
                          type="button"
                        >
                          <img src={sorticon} alt="icon" />
                        </button>
                      </th>
                      <th
                        className="tableHead "
                        style={{ minWidth: "100px" }}
                      ></th>
                    </tr>
                  </TableHead>
                  <tbody>
                    {data.map((res, i) => (
                      <>
                        <tr>
                          <td
                            className={
                              res.Status
                                ? "tableCell front-radius d-flex"
                                : "cellInactive front-radius d-flex"
                            }
                          >
                            <div className="mainDiv d-flex align-items-center">
                              <label class="switch">
                                <input
                                  type="checkbox"
                                  checked={res.Status}
                                  onChange={() =>
                                    handleToggle(res.Task_Id, res.Status)
                                  }
                                />
                                <span class="slider round"></span>
                              </label>
                            </div>
                            <div className="compName ms-2">
                                        {pagePermission.map((list) => {
                                          if (
                                            list.Sub_Permission_Name ===
                                              "read_task" 
                                          ) {
                                            return (
                                              <>
                                              <Link
                              to={`/task-details?id=${res.Task_Id}`}
                              className="text-decoration-none"
                              // style={{color: 'blueviolet' }}
                            >
                              <span className="font-poppins-regular"></span>{" "}
                              {res.Task_Name}
                            </Link>
                                              </>
                                            );
                                          } else {
                                            <>{res.Task_Name}</>;
                                          }
                                        })}
                                      </div>
                            
                          </td>
                          <td
                            className={
                              res.Status ? "tableCell" : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>{res.Assignee_Name}</div>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status ? "tableCell" : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>{res.Task_Status}</div>
                            </div>
                          </td>
                          {/* <td
                            className={
                              res.Status ? "tableCell" : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>
                                {moment(res.Start_Date).format("D.MM.YYYY")}
                              </div>
                            </div>
                          </td> */}
                          
                          <td
                            className={
                              res.Status ? "tableCell" : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>{res.Spend_Time}</div>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status ? "tableCell" : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>{res.Estimated_Time}</div>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status ? "tableCell" : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>
                                {moment(res.End_Date).format("D.MM.YYYY")}
                              </div>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status
                                ? "tableCell back-radius"
                                : "cellInactive back-radius"
                            }
                          >
                            <div className="Maindiv d-flex justify-content-center align-items-center">
                              <div className="edit me-2" title="Update Task">
                                {pagePermission.map((list) => {
                                  if (
                                    list.Sub_Permission_Name === "update_task"
                                  ) {
                                    return (
                                      <>
                                        <Link
                                          to={`/edit-task?id=${res.Task_Id}`}
                                          className="drpdwnitem"
                                        >
                                          <img
                                            width={22}
                                            src={edit}
                                            alt="icon"
                                          />
                                        </Link>
                                      </>
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </td>

                          {/* <div className="mainDiv text-center">
                              <Dropdown>
                                {pagePermission.map((list) => {
                                  if (
                                    list.Sub_Permission_Name === "update_task"
                                  ) {
                                    return (
                                      <>
                                        <Dropdown.Toggle as={CustomToggle} />
                                        <Dropdown.Menu size="sm" title="">
                                          {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "update_task"
                                            ) {
                                              return (
                                                <>
                                                  <Dropdown.Item
                                                    className="drpdwnitem"
                                                    style={{
                                                      color: "#616773",
                                                      opacity: "0.9",
                                                      fontSize: "12px",
                                                      fontWeight: "400",
                                                      fontFamily: "Poppins",
                                                      fontStyle: "normal",
                                                    }}
                                                  >
                                                    <Link
                                                          to={`/edit-task?id=${res.Task_Id}`}
                                                          className="drpdwnitem"
                                                        >
                                                      <div className="d-flex ">
                                                        <img
                                                          src={edit}
                                                          alt="icon"
                                                        />
                                                        &nbsp;
                                                        <Link
                                                          to={`/edit-task?id=${res.Task_Id}`}
                                                          className="drpdwnitem"
                                                        >
                                                          Edit Task
                                                        </Link>{" "}
                                                      </div>
                                                    </Link>
                                                  </Dropdown.Item>
                                                </>
                                              );
                                            }
                                          })}
                                        </Dropdown.Menu>
                                      </>
                                    );
                                  }
                                })}
                              </Dropdown>
                            </div> */}
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {(noOfPage == 1 && (!data || data.length <= 10)) || noOfPage == 0 ? (
            ""
          ) : (
            <div className="paginations d-flex justify-content-between align-items-center mt-2">
              <div className="d-flex justify-content-center align-items-center">
                <p className="lableRepo">Rows per page : </p>
                <input
                  id="input-control"
                  name="policy"
                  type="text"
                  className="form-control w-20 text-center team-select"
                  autoComplete="off"
                  style={{
                    borderRadius: "6px",
                    height: "30px",
                  }}
                  defaultValue={dataPerPage}
                  onChange={(e) => setDataPerPage(e.target.value)}
                  onKeyDown={testPage}
                />
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={noOfPage}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};
export default Tasks;
