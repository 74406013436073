import React, { useEffect, useState } from "react";
import "../css/style.css";
import ProfileIamge from "../Images/profileimage.png";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "font-awesome/css/font-awesome.min.css";
import { TableHead } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import enableIcon from "../Images/livedashboard/enable-icon.svg";
import disableIcon from "../Images/livedashboard/disable-icon.svg";
import view from "../Images/livedashboard/view.svg";
import sorticon from "../Images/sorticon.svg";
import {
  getAdminProjects,
  getAdminProjectsByTeam,
  listTeams,
  disable_Project,
  enable_Project,
  searchProjectName,
  checkUserPermissions,
  checkJWTToken,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import { outline } from "tailwindcss/defaultTheme";
import ClockLoader from "react-spinners/ClockLoader";

import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";

let a = window.location.href;
let result = a.slice(22);
localStorage.setItem("WindowLocation", result);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
export default function TasksAndProjects() {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Tenant = Authentication.Tenant;
  const UserToken = Authentication.Token;
  // const UserToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjZmE2OWJjNy1hNzUwLTQ5OWMtOWRjMy01OWZjMWMzM2MwOGIiLCJ2YWxpZCI6IjEiLCJ1c2VyaWQiOiIxMDAyIiwidGVuYW50bmFtZSI6Im5ld3NhbXRlY2giLCJVc2VyUm9sZUlkIjoiMiIsImV4cCI6MTY3MzkzMzM2MCwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzNjkvIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzNjkvIn0.llxCpsfNd26rymrfLdP4Ws8VunGsBxiHotCet1fQ6ds";
  const [order, setOrder] = useState("ASC");
  let [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noOfPage, setNoOfPage] = useState(1);
  let [currentpage, setCurrentPage] = useState(1);
  let [flag, setflag] = useState(false);
  const [responseMsg, setResponseMsg] = useState(false);
  let [pagePermission, setPagePermission] = useState([]);
  let [dataPerPage, setDataPerPage] = useState(10);

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getUserPermission();
      getData();
    }
  }, []);

  const getData = (
    pageNo = currentpage || 1,
    keys = "",
    check = true,
    search = "",
    pageSize = dataPerPage
  ) => {
    let data = {
      tenant: Tenant,
      key: keys,
      ascdsc: check,
      searchname: search,
      pageNumber: pageNo,
      PageSize: pageSize,
    };
    setLoading(true);
    getAdminProjects(data)
      .then((response) => {
        setLoading(false);
        if (response.data.Message == "UnAuthorized ") {
          // navigate("/forbidden");
        } else {
          setData(response.data.Data);
          setNoOfPage(response.data.TotalPages);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      getData(currentpage, "", flag, "", dataPerPage);
    }
  };

  const onSort = (key, check) => {
    if (check) setflag(false);
    else setflag(true);
    getData(currentpage, key, flag);
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getData(pageNumber, "", flag);
  };

  const searchHandler = (e) => {
    if (e.target.value == "") {
      getData();
    } else {
      getData(1, "", flag, e.target.value);
    }
  };
  //DisableProject
  const disableProject = (id) => {
    setResponseMsg(true);
    disable_Project(Tenant, id)
      .then((res) => {
        toast.success(res.data.Message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  //EnableProject
  const enableProject = (id) => {
    setResponseMsg(true);
    enable_Project(Tenant, id)
      .then((res) => {
        toast.success(res.data.Message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Projects") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  const handleToggle = (id, taskStatus) => {
    // setIsToggled(!isToggled);
    let data = {
      taskId: id,
    };
    if (taskStatus) {
      disable_Project(Tenant, id)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          getData();
        })
        .catch((err) => {
          console.log(err, "error");
        });
    } else {
      enable_Project(Tenant, id)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          getData();
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };
  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Manage Projects" />

        <div className="mt-4 mb-4">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12 d-flex">
              <div className="sb-example-3 w-100">
                <div className="search__container">
                  <input
                    className="search-input-field font-poppins-regular"
                    type="text"
                    placeholder="Search Projects..."
                    autoComplete="on"
                    onKeyUp={searchHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
              {pagePermission.map((list) => {
                if (list.Sub_Permission_Name === "create_project") {
                  return (
                    <>
                      <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                        <div className="btn-div-width">
                          <Link
                            type="button"
                            className="btn btn-lg w-100 font-poppins-medium align-center"
                            style={{
                              borderRadius: "5px",
                              borderColor: "#6300B0",
                              background: "#6300B0",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              color: "#FFF",
                              fontWeight: "500",
                              fontSize: "12px",
                              padding: "10px 20px",
                            }}
                            to={{
                              pathname: "/create-project",
                            }}
                          >
                            <span>Create New Project</span>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <hr />

        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {!data || data.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                {pagePermission.map((list) => {
                  if (
                    list.Sub_Permission_Name === "read_own_project" ||
                    list.Sub_Permission_Name === "read_all_project"
                  ) {
                    return (
                      <>
                        <div className="table-responsive">
                          <table
                            className="table projects"
                            style={{
                              borderBottomWidth: "0px",
                              borderBottomColor: "none",
                            }}
                          >
                            <TableHead>
                              <tr>
                                <th className="tableHead ps-2">
                                  Project Name &nbsp;
                                  <button
                                    onClick={() => {
                                      onSort("Project_Name", flag);
                                    }}
                                    type="button"
                                  >
                                    <img src={sorticon} alt="icon" />
                                  </button>
                                </th>
                                <th className="tableHead">
                                  Start Date &nbsp;
                                  <button
                                    onClick={() => {
                                      onSort("Start_Date", flag);
                                    }}
                                    type="button"
                                  >
                                    <img src={sorticon} alt="icon" />
                                  </button>
                                </th>
                                <th className="tableHead">
                                  End Date &nbsp;
                                  <button
                                    onClick={() => {
                                      onSort("End_Date", flag);
                                    }}
                                    type="button"
                                  >
                                    <img src={sorticon} alt="icon" />
                                  </button>
                                </th>
                                <th className="tableHead">
                                  Last Worked &nbsp;
                                  <button
                                    onClick={() => {
                                      onSort("Last_WorkDate", flag);
                                    }}
                                    type="button"
                                  >
                                    <img src={sorticon} alt="icon" />
                                  </button>
                                </th>
                                <th className="tableHead">
                                  Total Time &nbsp;
                                  <button
                                    onClick={() => {
                                      onSort("Total_Time", flag);
                                    }}
                                    type="button"
                                  >
                                    <img src={sorticon} alt="icon" />
                                  </button>
                                </th>
                              </tr>
                            </TableHead>
                            <tbody>
                              {data.map((res, i) => {
                                return (
                                  <tr>
                                    <td
                                      className={
                                        res.Status
                                          ? "tableCell front-radius d-flex"
                                          : "cellInactive front-radius d-flex"
                                      }
                                    >
                                      <div className="Maindiv d-flex">
                                        <label class="switch">
                                          <input
                                            type="checkbox"
                                            checked={res.Status}
                                            onChange={() =>
                                              handleToggle(
                                                res.Project_Id,
                                                res.Status
                                              )
                                            }
                                          />
                                          <span class="slider round"></span>
                                        </label>

                                        <div
                                          className="profileImg"
                                          style={
                                            res.Color_Code !== null
                                              ? {
                                                  backgroundColor:
                                                    res.Color_Code,
                                                }
                                              : {
                                                  backgroundColor: "#6414b5",
                                                }
                                          }
                                        >
                                          <p>{res.Project_Name.charAt(0)}</p>
                                        </div>
                                      </div>
                                      <div className="compName">
                                        {pagePermission.map((list) => {
                                          if (
                                            list.Sub_Permission_Name ===
                                              "read_own_project" ||
                                            list.Sub_Permission_Name ===
                                              "read_all_project"
                                          ) {
                                            return (
                                              <>
                                                <Link
                                                  to={`/project-details?id=${res.Project_Id}`}
                                                >
                                                  {res.Project_Name}
                                                </Link>
                                              </>
                                            );
                                          } else {
                                            <>{res.Project_Name}</>;
                                          }
                                        })}
                                      </div>
                                    </td>
                                    <td
                                      className={
                                        res.Status
                                          ? "tableCell"
                                          : "cellInactive"
                                      }
                                      // style={res.Status ? {textAlign: 'center'}: {textAlign: 'center', color: '#bcbec6'}}
                                    >
                                      <div>
                                        {moment(res.Start_Date).format(
                                          "D.MM.YYYY"
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      className={
                                        res.Status
                                          ? "tableCell"
                                          : "cellInactive"
                                      }
                                      // style={res.Status ? {textAlign: 'center'}: {textAlign: 'center', color: '#bcbec6'}}
                                    >
                                      <div>
                                        {moment(res.End_Date).format(
                                          "D.MM.YYYY"
                                        )}
                                      </div>
                                    </td>
                                    <td
                                      className={
                                        res.Status
                                          ? "tableCell"
                                          : "cellInactive"
                                      }
                                    >
                                      {moment(res.Last_WorkDate).format(
                                        "D.MM.YYYY"
                                      )}
                                    </td>
                                    <td
                                      className={
                                        res.Status
                                          ? "tableCell"
                                          : "cellInactive"
                                      }
                                    >
                                      {res.Total_Time}
                                    </td>

                                    <td
                                      className={
                                        res.Status
                                          ? "tableCell back-radius"
                                          : "cellInactive back-radius"
                                      }
                                    >
                                      <div className="Maindiv d-flex justify-content-center align-items-center">
                                        <div className="edit me-2" title='Update Project'>
                                          {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "update_project"
                                            ) {
                                              return (
                                                <>
                                                  <Link
                                                    to={`/update-project?id=${res.Project_Id}`}
                                                  >
                                                    <img
                                                      width={20}
                                                      src={edit}
                                                      alt="icon"
                                                    />
                                                  </Link>
                                                </>
                                              );
                                            }
                                          })}
                                        </div>
                                        <div className="view" title='View Tasks'>
                                          <Link
                                            to={`/project-tasks?id=${res.Project_Id}`}
                                            className="drpdwnitem"
                                            style={{
                                              color: "#59A561 !important",
                                            }}
                                          >
                                            <img
                                              width={22}
                                              src={view}
                                              alt="icon"
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </td>

                                    {/* <td
                                      className={
                                        res.Status
                                          ? "tableCell back-radius"
                                          : "cellInactive back-radius"
                                      }
                                    >
                                      {pagePermission.map((list) => {
                                        if (
                                          list.Sub_Permission_Name ===
                                            "read_own_project" ||
                                          list.Sub_Permission_Name ===
                                            "read_all_project"
                                        ) {
                                          return (
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                as={CustomToggle}
                                              />
                                              <Dropdown.Menu size="sm">
                                                {pagePermission.map((list) => {
                                                  if (
                                                    list.Sub_Permission_Name ===
                                                    "update_project"
                                                  ) {
                                                    return (
                                                      <>
                                                        <Dropdown.Item
                                                          className="drpdwnitem"
                                                          style={{
                                                            color: "#616773",
                                                            opacity: "0.9",
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            fontFamily:
                                                              "Poppins",
                                                            fontStyle: "normal",
                                                          }}
                                                        >
                                                          <Link
                                                            to={`/update-project?id=${res.Project_Id}`}
                                                            className="d-flex align-items-center"
                                                          >
                                                              <img
                                                                src={edit}
                                                                alt="icon"
                                                              />
                                                              &nbsp;
                                                                Edit
                                                          </Link>
                                                        </Dropdown.Item>
                                                      </>
                                                    );
                                                  }
                                                })}
                                                <Dropdown.Item
                                                  className="drpdwnitem"
                                                  style={{
                                                    opacity: "0.9",
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                    fontFamily: "Poppins",
                                                    fontStyle: "normal",
                                                  }}
                                                >
                                                    <Link
                                                      to={`/project-tasks?id=${res.Project_Id}`}
                                                      className="drpdwnitem d-flex align-items-center"
                                                      style={{
                                                        color:
                                                          "#59A561 !important",
                                                      }}
                                                    >
                                                    <img
                                                      src={view}
                                                      alt="icon"
                                                    />
                                                    &nbsp;
                                                      View Tasks
                                                    </Link>
                                                </Dropdown.Item>

                                                {pagePermission.map((list) => {
                                                  if (
                                                    list.Sub_Permission_Name ===
                                                    "disable_project"
                                                  ) {
                                                    return (
                                                      <>
                                                        <Dropdown.Item
                                                          className="drpdwnitem"
                                                          style={{
                                                            // color: "#616773",
                                                            opacity: "0.9",
                                                            fontSize: "12px",
                                                            fontWeight: "400",
                                                            fontFamily:
                                                              "Poppins",
                                                            fontStyle: "normal",
                                                          }}
                                                        >
                                                          {!res.Status ? (
                                                            <div
                                                              className="d-flex"
                                                              id="1"
                                                              style={{
                                                                color:
                                                                  "blue !important",
                                                              }}
                                                              onClick={() =>
                                                                enableProject(
                                                                  res.Project_Id
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={enableIcon}
                                                                alt="icon"
                                                              />
                                                              <div className="drpdwnitem">
                                                                {" "}
                                                                &nbsp; Enable
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div
                                                              className="d-flex"
                                                              style={{
                                                                color:
                                                                  "red !important",
                                                              }}
                                                              id="2"
                                                              onClick={() =>
                                                                disableProject(
                                                                  res.Project_Id
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  disableIcon
                                                                }
                                                                alt="icon"
                                                              />
                                                              <div className="drpdwnitem">
                                                                &nbsp; Disable
                                                              </div>
                                                            </div>
                                                          )}
                                                        </Dropdown.Item>
                                                      </>
                                                    );
                                                  }
                                                })}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          );
                                        }
                                      })}
                                    </td> */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    );
                  }
                })}
                {(noOfPage == 1 && data.length <= 10) || noOfPage == 0 ? (
                  ""
                ) : (
                  <div className="paginations d-flex justify-content-between align-items-center mt-2">
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="lableRepo">Rows per page : </p>
                      <input
                        id="input-control"
                        name="policy"
                        type="text"
                        className="form-control w-20 text-center team-select"
                        autoComplete="off"
                        style={{
                          borderRadius: "6px",
                          height: "30px",
                        }}
                        defaultValue={dataPerPage}
                        onChange={(e) => setDataPerPage(e.target.value)}
                        onKeyDown={testPage}
                      />
                    </div>
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={noOfPage}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      {responseMsg && <ToastContainer />}
    </section>
  );
}
