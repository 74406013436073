import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "../css/style.css";
import "./reports.css";
import { shallowEqual, useSelector } from "react-redux";
import { getDailyReport } from "../authApi/AuthCRUD";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useNavigate } from "react-router-dom";


export default function UserDailyActivitySummary(props) {
  const navigate = useNavigate();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  let [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
const [chart,setChart]=useState(null)
  useEffect(() => {
    let data = {
      userid: props.usrId,
      Start_Date: props.startDate,
      End_Date: props.end_date,
    };
    setLoading(true);
    getDailyReport(data)
      .then((res) => {
        setLoading(false);
        setData(res.data.Data[0].usersDates);
        getchartDetails(res.data.Data[0].usersDates);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  }, [props]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  let state={}
  const getchartDetails=(data)=>{
    let timer=[]
    let manual=[]
    let timeout=[]
   const labels = []; 
    data.map((res)=>{
      labels.push(moment(res.Start_Date).format("DD.MM.YYYY"))
      timer.push(res.Active_Time);
      manual.push(res.Manual_Time);
      timeout.push(res.Idle_Time);
    })
    
    state = {
      labels: labels,
      datasets: [
        {
          label: "Active Time",
          data: timer,
          backgroundColor:"rgba(75, 192, 192, 0.2)",
          borderColor:"rgb(75, 192, 192)",
          borderWidth: 1,
        },
        {
          label: "Manual Time",
          data: manual,
          backgroundColor: "rgba(201, 203, 207, 0.3)",
          borderColor: "rgb(201, 203, 207)",
          borderWidth: 1,
        },
        {
          label: "Idle Time",
          data: timeout,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 1,
        },
      ],
    };
    setChart(state);
  }

  return (
      <>
        <div className="container">
         {
          chart && <Bar
            data={chart}
            options={{
              scales: {
                y: {
                  ticks: {
                    callback: function (value) {
              return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
              },
                  }
                }
              },
              title: {
                display: true,
                text: "Average Rainfall per month",
                fontSize: 20,
              },
              legend: {
                display: true,
                position: "right",
              },
            }}
          />
         }
         
        </div>
      </>
    );

 
}
