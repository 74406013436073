import React, { useEffect, useState } from "react";
import "../css/style.css";
import "./reports.css";
import "react-circular-progressbar/dist/styles.css";
import { getAdminProjectReport, getProjectDrop } from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import ClockLoader from "react-spinners/ClockLoader";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AdminProjectReport() {
  let today = new Date();
  const navigate = useNavigate();

  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const [projectId, setProjectId] = useState(1);
  const [projectsList, setProjectsList] = useState([]);
  const [projectDetail, setProjectDetail] = useState([]);
  const [UsersCostDetails, setUsersCostDetails] = useState([]);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    projectList();
    projectreport();
  }, []);
  const projectList = () => {
    getProjectDrop(Tenant)
      .then((res) => {
        setProjectsList(res.data.Data);
      })
      .catch((err) => {});
  };
  const projectreport = (pId = projectId, sdate = date, edate = endDate) => {
    let data = {
      projectId: pId,
      startDate: sdate,
      endDate: edate,
      pageNumber: 1,
    };
    setLoading(true);
    getAdminProjectReport(data)
      .then((res) => {
        setLoading(false);
        setProjectDetail(res.data.Data);
        setUsersCostDetails(res.data.Data[0].UsersCostDetails);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const handleEndtDateChange = (selectedDate) => {
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
        .getDate()
        .toString()
        .padStart(2, "0")}`
      : null;
      setEndDate(formattedDate);
                  projectreport(projectId, date, formattedDate);
  };
  

const handleStartDateChange = (selectedDate) => {
  const formattedDate = selectedDate
    ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}`
    : null;
    setDate(formattedDate);
                  projectreport(projectId, formattedDate, endDate);
};



  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Project Report" />

        <div className="mt-3 mb-3">
          <div className="row d-flex  align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-between align-items-center mt-1">
              <p className="lableRepo">Projects</p>
              <select
                className="form-select team-select borders"
                defaultValue={projectId}
                onChange={(e) => {
                  setProjectId(e.target.value);
                  projectreport(e.target.value, date, endDate);
                }}
              >
                {projectsList.map((data, i) => {
                  return (
                    <option key={i} value={data.Project_Id}>
                      {data.Project_Name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center mt-1">
              <p className="lableRepo">Start&nbsp;Date</p>
              <DatePicker
        selected={date ? new Date(date) : null}
        onChange={handleStartDateChange}
        dateFormat="dd.MM.yyyy"
        className="form-control borders team-select selectDate"
        maxDate={new Date()} 
        todayButton="TODAY"
      />
              {/* <input
                type="date"
                name="Start_Date"
                className="form-control borders team-select selectDate"
                defaultValue={date}
                max={today}
                onChange={(e) => {
                  setDate(e.target.value);
                  projectreport(projectId, e.target.value, endDate);
                }}
              /> */}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12  d-flex justify-content-center align-items-center mt-1">
              <p className="lableRepo">End&nbsp;Date</p>
              <DatePicker
        selected={endDate ? new Date(endDate) : null}
        onChange={handleEndtDateChange}
        dateFormat="dd.MM.yyyy"
        className="form-control borders team-select selectDate"
        maxDate={new Date()} // Set maximum date to today's date
        // Other props, such as max date, can be added here
      />

              {/* <input
                type="date"
                name="end_Date"
                className="form-control borders team-select selectDate"
                defaultValue={endDate}
                min={date}
                max={today}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  projectreport(projectId, date, e.target.value);
                }}
              /> */}
            </div>
          </div>
        </div>
        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3}/>
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            <div
              className="card"
              style={{ border: "none", borderRadius: ".5rem" }}
            >
              <div className="row d-flex justify-content-between align-items-center p-4">
                {projectDetail == null || projectDetail.length === 0 ? (
                  <>
                    <h5 className="text-secondary d-flex justify-content-center my-3 font-poppins-medium">
                      Data not found
                    </h5>
                  </>
                ) : (
                  <>
                    <div className="col-md-2">
                      <div className="userRepoSumm">
                      <p className="lableRepo">Total Time</p>
                        <h1 className="fontSize">
                          {projectDetail[0].Total_Time}
                        </h1>                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="userRepoSumm">
                      <p className="lableRepo">Active Time</p>
                        <h1 className="fontSize">
                          {projectDetail[0].Active_Time}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="userRepoSumm">
                      <p className="lableRepo">Manual Time </p>
                        <h1 className="fontSize">{projectDetail[0].Manual}</h1>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="userRepoSumm">
                      <p className="lableRepo">Idle Time</p>
                        <h1 className="fontSize">
                          {projectDetail[0].Idle_Time}
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="userRepoSumm">
                      <p className="lableRepo">Cost</p>
          
                        <h1 className="fontSize">{projectDetail[0].Budget}</h1>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            
              {UsersCostDetails == null || UsersCostDetails.length === 0 ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center my-3 mt-5 font-poppins-medium">
                  No Result Available
                  </h5>
                </>
              ) : (
                <div className="table-responsive mt-4">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="tableHead">Employees</th>
                      <th className="tableHead">Hourly Rate</th>
                      <th className="tableHead "> Active Time </th>
                      <th className="tableHead "> Manual Time </th>
                      <th className="tableHead "> Idle Time </th>
                      <th className="tableHead "> Total Time </th>
                      <th className="tableHead "> Cost </th>
                    </tr>
                  </thead>
                  <tbody>
                  {projectDetail[0].UsersCostDetails.map((res, i) => {
                      return (
                        <React.Fragment key={i}>
                          <tr
                              className="AdminProjectReports"
                              style={{ borderRadius: "30px" }}
                              key={i}
                            >
                              <td className="tableCell front-radius compName d-flex">
                                <div className="mainDiv  d-flex" >
                                   <div> {res.Full_Name}</div>
                                </div>
                              </td>
                              <td className="tableCell">
                                <div className="mainDiv  d-flex" >
                                   <div> {res.Hourly_Rate}</div>
                                </div>
                              </td>
                              <td className='tableCell '>
                                <div className="mainDiv" style={{ color: "#2fbb3ed9", fontSize: '15px', fontWeight: 500 }} >
                                  <div>{res.Active_Time}</div>
                                </div>
                              </td>
                              <td className='tableCell '>
                                <div className="mainDiv" style={{ color: "#cfcf2e", fontSize: '15px', fontWeight: 500 }}>
                                  <div>{res.Manual_Time}</div>
                                </div>
                              </td>
                              <td className='tableCell '>
                                <div className="mainDiv" style={{ color: "red", fontSize: '15px', fontWeight: 500 }}>
                                  <div>{res.Idle_Time}</div>
                                </div>
                              </td>
                              <td className='tableCell '>
                                <div className="mainDiv" style={{ color: "blue", fontSize: '15px' }}>
                                  <div>{res.Total_Time}</div>
                                </div>
                              </td>
                              <td className='tableCell  back-radius'>
                              <div className="mainDiv" style={{ color: "#111", fontSize: '15px', fontWeight: '700' }}>
                                  <div>{res.Cost}</div>
                                </div>
                              </td>
                            </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
                </div>
              )}
            
          </>
        )}
      </div>
    </section>
  );
}
