import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Header } from "../layoutComponent/Header";
import "./supscription.css";
import * as AiIcons from "react-icons/ai";
import {
  getSubscriptionDetails,
  getUpdatedSubscription,
  getPaymentInvoice,
} from "../authApi/AuthCRUD";
import ClockLoader from "react-spinners/ClockLoader";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";

export default function Supscription() {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;

  const [planName, setPlanName] = useState("");
  const [Total_Users, setTotal_Users] = useState(0);
  const [Registered_Users, setRegistered_Users] = useState(0);
  const [Subscription_End_Date, setSubscription_End_Date] = useState("");
  const [Currency, setCurrency] = useState("");
  const [Total_Amount, setTotal_Amount] = useState();
  const [loading, setLoading] = useState(false);
  const [Plan_Status, setPlan_Status] = useState("");
  const [userId, setUserId] = useState();
  const [data, setData] = useState([]);
  let navigate = useNavigate();
  const { search } = useLocation();
  let statusReq = new URLSearchParams(search).get("status");

  const uploadPlan = () => {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (m, key, value) {
        vars[key] = value;
      }
    );

    let data = {
      status: vars.status,
      skey: vars.skye,
      siv: vars.sivi,
    };
    setLoading(true);
    getUpdatedSubscription(data)
      .then((res) => {
        navigate(`/subscription-check?status=${res.data.Status}`);
      })
      .catch((err) => {
        navigate("/subscription-check?status=Cancel");
      });
  };

  let FilterCurrency = Currency.toUpperCase();
  useEffect(() => {
    PaymentInvoice();
    SubsDetails();
    if (statusReq) {
      uploadPlan();
    }
  }, []);

  const SubsDetails = () => {
    setLoading(true);
    getSubscriptionDetails().then((res) => {
      setLoading(false);
      setData(res.data.Data);
      setPlanName(res.data.Data.Plan_Name);
      setPlan_Status(res.data.Data.Plan_Status);
      setTotal_Users(res.data.Data.Total_Users);
      setRegistered_Users(res.data.Data.Registered_Users);
      setSubscription_End_Date(res.data.Data.Subscription_End_Date);
      setTotal_Amount(res.data.Data.Total_Amount);
      setCurrency(res.data.Data.Currency);
    });
  };

  const [paymentData, setPaymentData] = useState([]);
  const [userName, setUserName] = useState("");

  const PaymentInvoice = () => {
    setLoading(true);
    getPaymentInvoice().then((res) => {
      setLoading(false);
      setPaymentData(res.data.Data);
    });
  };

  //subscricption End Date
  let filterEnd_Date = moment(Subscription_End_Date).format("DD.MM.YYYY");

  //Users count
  let filterUserData = Total_Users - Registered_Users;

  //progress bar percentage calculate
  let filterUserDataPer = Math.abs((Registered_Users / Total_Users) * 100);
  if (filterUserDataPer > 100) {
    filterUserDataPer = 100;
  }

  let bgcolor = "#59A561";
  let progress = filterUserDataPer;
  let height = "8px";

  const Parentdiv = {
    height: height,
    width: "100%",
    backgroundColor: "#DDDDDD",
    borderRadius: 40,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
  };

  return (
    <React.Fragment>
      {loading ? (
        <>
          <div className="d-flex justify-content-center text-center loaderDiv">
            <ClockLoader color="#6400B0" speedMultiplier={3} />
            <span className="loading">Loading....</span>
          </div>
        </>
      ) : (
        <>
          <section className="d-flex" style={{ padding: "10px 0" }}>
            <div className="container-fluid">
              <Header title="Subscription Plan" />

              <div className="subscription">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="row d-flex align-items-center">
                      <div className="col-12">
                        <div className="businessPlan d-flex align-items-center">
                          <h6>{planName} Plan</h6>
                          <span
                            className={
                              Plan_Status === "Active"
                                ? "ActivePlan"
                                : "ExpirePlan"
                            }
                          >
                            ({Plan_Status})
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="userData">
                          <div className="userCount">
                            <span>
                              {Registered_Users} of {Total_Users} users
                            </span>
                            {Math.sign(filterUserData) == -1 ? (
                              <>
                                <span style={{ color: "red" }}>
                                  ({Math.abs(filterUserData)} extra users)
                                </span>
                              </>
                            ) : (
                              <>
                                <span style={{ color: "green" }}>
                                  ({filterUserData} remaining users)
                                </span>
                              </>
                            )}
                          </div>

                          <div style={Parentdiv}>
                            <div style={Childdiv}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div className="d-flex justify-content-center align-items-center userPayData">
                          <div className="paymentDiv">
                            <div className="userCount">Subscription Ends</div>
                            <div className="paymentDate">{filterEnd_Date}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-center flex-column align-items-center">
                        <div className="amount text-center">
                          {FilterCurrency} {Total_Amount}
                        </div>
                        <Link className="upgradePlan" to={"/pricing"}>
                          Upgrade Plan
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="subscription">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-lg-9 col-md-7 col-sm-12">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="businessPlan d-flex align-items-center">
                          <h6>{planName} Plan</h6>
                          <span
                            className={
                              Plan_Status === "Active"
                                ? "ActivePlan"
                                : "ExpirePlan"
                            }
                          >
                            ({Plan_Status})
                          </span>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="userData">
                              <div className="userCount">
                                <span>
                                  {Registered_Users} of {Total_Users} users
                                </span>
                                {Math.sign(filterUserData) == -1 ? (
                                  <>
                                    <span style={{ color: "red" }}>
                                      ({Math.abs(filterUserData)} extra users)
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span style={{ color: "green" }}>
                                      ({filterUserData} remaining users)
                                    </span>
                                  </>
                                )}
                              </div>

                              <div style={Parentdiv}>
                                <div style={Childdiv}></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="d-flex justify-content-center align-items-center userPayData">
                              <div className="paymentDiv">
                                <div className="userCount">
                                  Subscription Ends
                                </div>
                                <div className="paymentDate">
                                  {filterEnd_Date}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="plan text-center">
                      <Link className="upgradePlan" to={"/pricing"}>
                        Upgrade Plan
                      </Link>
                    </div>
                    <div className="amount text-center">
                      {FilterCurrency} {Total_Amount}
                    </div>
                  </div>
                </div>
              </div> */}

              {loading ? (
                <>
                  <div className="d-flex justify-content-center text-center loaderDiv">
                    <ClockLoader color="#6400B0" speedMultiplier={3} />
                    <span className="loading">Loading....</span>
                  </div>
                </>
              ) : (
                <>
                  {!paymentData || paymentData.length === 0 ? (
                    <>
                      <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                        No Result Available
                      </h5>
                    </>
                  ) : (
                    <>
                      {paymentData &&
                        paymentData.map((res, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div className="invoice mb-4">
                                <div className="tableCard">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="d-flex align-items-center mt-2">
                                        <div className="nameId">
                                          Invoice_#{res.Id}
                                        </div>
                                        <div
                                          className={
                                            res.Plan_Status === "Active"
                                              ? "ActivePlan"
                                              : "ExpirePlan"
                                          }
                                        >
                                          ({res.Plan_Status})
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 mt-1 mb-2">
                                      <div className="compName">
                                        By :&nbsp;{res.Tenant_Name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="downloadInvoiceLink">
                                    {res.Invoice_Link === null ? (
                                      ""
                                    ) : (
                                      <a
                                        type="button"
                                        className="btn btn-sm addBtn team-select text-center me-1 d-flex align-items-center"
                                        href={res.Invoice_Link}
                                        target="_blank"
                                      >
                                       <AiIcons.AiOutlineDownload className="icon me-1" /> Invoice 
                                      </a>
                                    )}
                                  </div>
                                </div>

                                <div className="mainListData">
                                  <div className="row m-0">
                                    <div className="invoiceRow col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs 12">
                                      <div className="listItems">
                                        <div className="keyList">
                                          Plan Name : &nbsp;
                                        </div>
                                        <div className="value">
                                          {res.Plan_Name}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="invoiceRow col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs 12">
                                      <div className="listItems">
                                        <div className="keyList">
                                          Payment Date : &nbsp;
                                        </div>
                                        <div className="value">
                                          {res.Payment_Date === null
                                            ? "-"
                                            : moment(res.Payment_Date).format(
                                                "DD.MM.YYYY"
                                              )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="invoiceRow col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs 12">
                                      <div className="listItems">
                                        <div className="keyList">
                                          Payment Status : &nbsp;
                                        </div>
                                        <div className="value">
                                          {res.Payment_Status}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="invoiceRow col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs 12">
                                      <div className="listItems">
                                        <div className="keyList">
                                          Start Date : &nbsp;
                                        </div>
                                        <div className="value">
                                          {moment(
                                            res.Subscription_Start_Date
                                          ).format("DD.MM.YYYY")}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="invoiceRow col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs 12">
                                      <div className="listItems">
                                        <div className="keyList">
                                          End Date : &nbsp;
                                        </div>
                                        <div className="value">
                                          {moment(
                                            res.Subscription_End_Date
                                          ).format("DD.MM.YYYY")}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="invoiceRow col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs 12">
                                      <div className="listItems">
                                        <div className="keyList">
                                          Amount : &nbsp;
                                        </div>
                                        <div className="value">
                                          {res.Currency.toUpperCase()} &nbsp;
                                          {res.Total_Amount}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </div>
          </section>
        </>
      )}
    </React.Fragment>
  );
}
