import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import ClockLoader from "react-spinners/ClockLoader";
import "../css/style.css";
import "font-awesome/css/font-awesome.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import deleteIcon from "../Images/delet.svg";
import backArrow from "../Images/livedashboard/back-arrow.svg";

import moment from "moment";

import { TableHead } from "@mui/material";
import { Header } from "../layoutComponent/Header";

import {
  userLeaves,
  deleteLeaveRequest,
  checkJWTToken,
  checkUserPermissions,
} from "../authApi/AuthCRUD";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

const ShowLeaves = () => {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Id = Authentication.User_Id;
  const UserToken = Authentication.Token;

  let [data, setData] = useState([]);
  let [currentpage, setCurrentPage] = useState(1);

  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [noOfPage, setNoOfPage] = useState(1);

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getLeaveList();
      getUserPermission();
    }
  }, []);
  let [loading, setLoading] = useState(true);
  let [date, setDate] = useState("");
  let todayDate = moment(new Date()).format("YYYY-MM-DD");

  const getLeaveList = (pageNo = currentpage || 1) => {
    let data = {
      userId: Id,
      pageNumber: pageNo,
    };
    setLoading(true);
    userLeaves(data)
      .then((response) => {
        setLoading(false);
        setData(response.data.Data);
        setDate(response.data.Data[0].From_Date);
        setNoOfPage(response.data.TotalPages);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        // if (err.response.data.Status == "Expire") {
        //   navigate("/plan-expire");
        // } else if (err.response.data.Status == "Unauthorize") {
        //   navigate("/unauthorize");
        // } else {
        //   navigate("/forbidden");
        // }
      });
  };

  const removeLeave = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Leave Request?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then((result) => {
      if (result.isDenied) {
        deleteLeaveRequest(id)
          .then((res) => {
            Swal.fire("Sucessfully deleted", "", "success");
            getLeaveList();
          })
          .catch((err) => {
            console.log(err, "error");
          });
      }
    });
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getLeaveList(pageNumber);
  };

  let [pagePermission, setPagePermission] = useState([]);
  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Apply_leaves") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Leave Status" />
        <div className="mt-4 mb-4">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
              <div className="sb-example-3 w-100">
                <div className="search__container">
                  <input
                    className="search-input-field font-poppins-regular d-none"
                    type="text"
                    placeholder="Search Your Leave..."
                    autoComplete="on"
                  />
                </div>
              </div>
            </div>
            {pagePermission.map((list) => {
              if (list.Sub_Permission_Name === "create_leave") {
                return (
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                      <div className="btn-div-width">
                        <Link
                          type="button"
                          className="btn btn-lg w-100 font-poppins-medium align-center"
                          style={{
                            borderRadius: "5px",
                            borderColor: "#6300B0",
                            background: "#6300B0",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            color: "#FFF",
                            fontWeight: "500",
                            fontSize: "12px",
                            padding: "10px 20px",
                          }}
                          to={{
                            pathname: "/apply-leave",
                          }}
                        >
                          <span>Click To Apply Leave</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <hr />

        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {!data || data.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div className="table-responsive">
                  <table
                    className="table"
                    style={{
                      borderBottomWidth: "0px",
                      borderBottomColor: "none",
                    }}
                  >
                    <TableHead>
                      <tr>
                        <th className="tableHead">Leave Type</th>
                        <th className="tableHead ">Requested On </th>
                        <th className="tableHead ">Leave Date</th>
                        <th className="tableHead "> Status</th>
                        <th className="tableHead "></th>
                      </tr>
                    </TableHead>
                    <tbody>
                      {data.map((res, i) => {
                        return (
                          <tr>
                            <td
                              className=" tableCell front-radius"
                              style={{ marginLeft: "10px" }}
                            >
                              <div className="mainDiv d-flex compName">
                                <div>{res.Leave_Type}</div>
                              </div>
                            </td>
                            <td className="tableCell ">
                              <div>
                                {moment(res.Request_Date).format("D.MM.YYYY")}
                              </div>
                            </td>
                            <td className=" tableCell  ">
                              <div>
                                {moment(res.From_Date).format("D.MM.YYYY")} -
                                {moment(res.To_Date).format("D.MM.YYYY")}
                              </div>
                            </td>
                            <td className=" tableCell  ">
                              <div>
                                {res.Status == 1 ? (
                                  <>
                                    <div
                                      className="mainDiv "
                                      style={{ color: "green" }}
                                    >
                                      Approved
                                    </div>
                                  </>
                                ) : res.Status == 0 ? (
                                  <>
                                    <div
                                      className="mainDiv "
                                      style={{ color: "blue" }}
                                    >
                                      Pending
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="mainDiv "
                                      style={{ color: "red" }}
                                    >
                                      Cancelled
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                            <td className="tableCell   back-radius">
                              {todayDate <= res.From_Date &&
                              res.Status === 0 ? (
                                <>
                                  <div className="Maindiv d-flex justify-content-center align-items-center">
                                    <div className="edit me-2" title="Update Leave">
                                      {pagePermission.map((list) => {
                                        if (
                                          list.Sub_Permission_Name ===
                                          "update_leave"
                                        ) {
                                          return (
                                            <>
                                              <Link
                                                to={`/update-leave?id=${res.LeaveRequest_Id}`}
                                              >
                                                <img
                                                  width={20}
                                                  src={edit}
                                                  alt="icon"
                                                />
                                              </Link>
                                            </>
                                          );
                                        }
                                      })}
                                    </div>
                                    <div className="view" title="Delete Leave Request">
                                      {pagePermission.map((list) => {
                                        if (
                                          list.Sub_Permission_Name ===
                                          "delete_leaves"
                                        ) {
                                          return (
                                            <>
                                              <img
                                                onClick={() => {
                                                  removeLeave(
                                                    res.LeaveRequest_Id
                                                  );
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                  width: "16px",
                                                }}
                                                src={deleteIcon}
                                                alt="icon"
                                              />
                                            </>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>

                                  {/* <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu size="sm">
                                      {pagePermission.map((list) => {
                                        if (
                                          list.Sub_Permission_Name ===
                                          "update_leave"
                                        ) {
                                          return (
                                            <>
                                              <Dropdown.Item
                                                className="drpdwnitem"
                                                style={{
                                                  color: "#616773",
                                                  opacity: "0.9",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                  fontFamily: "Poppins",
                                                  fontStyle: "normal",
                                                }}
                                              >
                                                <Link
                                                  to={`/update-leave?id=${res.LeaveRequest_Id}`}
                                                  className="d-flex align-items-center"
                                                >
                                                  <img src={edit} alt="icon" />
                                                  &nbsp; Edit
                                                </Link>
                                              </Dropdown.Item>
                                            </>
                                          );
                                        }
                                      })}
                                      {pagePermission.map((list) => {
                                        if (
                                          list.Sub_Permission_Name ===
                                          "delete_leaves"
                                        ) {
                                          return (
                                            <>
                                              <Dropdown.Item className="drpdwnitem">
                                                <div className="d-flex">
                                                  <div className="d-flex">
                                                    <img
                                                      src={deleteIcon}
                                                      alt="icon"
                                                    />
                                                    <div
                                                      onClick={() => {
                                                        removeLeave(
                                                          res.LeaveRequest_Id
                                                        );
                                                      }}
                                                    >
                                                      &nbsp; Delete
                                                    </div>
                                                  </div>
                                                </div>
                                              </Dropdown.Item>
                                            </>
                                          );
                                        }
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown> */}
                                </>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {(noOfPage == 1 && data.length <= 10) || noOfPage == 0 ? (
                  ""
                ) : (
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={noOfPage}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-end"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default ShowLeaves;
