import React, { useEffect, useState } from "react";
import "../css/style.css";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import * as IconName from "react-icons/bs";
import "font-awesome/css/font-awesome.min.css";
import { TableHead } from "@mui/material";
import { downloadApplication } from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import MacInfo from "../Images/MacInfo.png";

const Download = () => {
  const styles = {
    new: {
      display: "inline",
      paddingLeft: "7px",
      color: "rgb(100, 20, 181)",
      fontWeight: "bold",
    },
  };
  const [data, setData] = useState([]);
  const [latestVersion, setLatestVersion] = useState("");
  const [winFile, setWinFile] = useState("");
  const [macOsFile, setMacOsFile] = useState("");
  const [linuxFile, setLinuxFile] = useState("");

  useEffect(() => {
    downloadApplication().then((response) => {
      setData(response.data.Data);
      setLatestVersion(response.data.Data[0].Version_No);
      setWinFile(response.data.Data[0].Win_file);
      setMacOsFile(response.data.Data[0].MacOS_file);
      setLinuxFile(response.data.Data[0].Linux_file);
    });
  }, []);

  let text = "/NoFile";
  let wind = winFile.includes(text) ? false : winFile;
  let mac = macOsFile.includes(text) ? false : macOsFile;
  let li = linuxFile.includes(text) ? false : linuxFile;


  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Desktop Application" />

        <div className="downloadPage">
          <div className="head">Download Odyssey Desktop Application</div>
          <div className="content">
            Latest Version:{" "}
            <span className="vName">Odyssey {latestVersion}</span>
          </div>
          <p>
          Our application is compatible with different operating systems, download your version.
          </p>
        </div>

        <div className="table-responsive">
          <table
            className="table"
            style={{ borderBottomWidth: "0px", borderBottomColor: "none" }}
          >
            <TableHead>
              <tr>
                <th className="tableHead" style={{ paddingLeft: "1.25rem" }}>
                  Application Version
                </th>
                <th className="tableHead">Version Date</th>
                <th className="tableHead text-center">Download</th>
                <th></th>
              </tr>
            </TableHead>
            <tbody>
              {data &&
                data.map((res, i) => {
                  return (
                    <React.Fragment key={i}>
                    <tr>
                      <td className={"tableCell front-radius "}>
                        <div className="Maindiv d-flex compName">
                          <div className="mt-1">
                            Odyssey {res.Version_No}
                            <div style={styles.new}>
                              {res.Id === data[0].Id ? "New" : null}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className={"tableCell"}>
                        <div className="mt-1">
                          {moment(res.Uploaded_Date).format("D.MM.YYYY")}
                        </div>
                      </td>
                      <td className={"tableCell text-center"}>
                        <div className="mainDiv d-flex justify-content-center align-items-center">
                        {
                          res.Win_file.includes(text) ? '' : (
                            <a
                              type="button"
                              className="btn btn-sm downloadBtnIcon team-select text-center me-1"
                              href={res.Win_file}
                              target="_blank"
                              style={{ padding: '5px 20px', fontSize: '20px'}}
                            >
                            <AiIcons.AiFillWindows className="icon" />
                            </a>
                          )
                        }
                        {
                          res.MacOS_file.includes(text) ? '' : (
                            <a
                              type="button"
                              className="btn btn-sm downloadBtnIcon team-select text-center me-1"
                              href={res.MacOS_file}
                              target="_blank"
                              style={{ padding: '5px 20px', fontSize: '20px'}}
                            >
                              <AiIcons.AiFillApple className="icon" />
                            </a>
                          )
                        }{
                          res.Linux_file.includes(text) ? '' : (
                            <a
                              type="button"
                              className="btn btn-sm downloadBtnIcon team-select text-center"
                              href={res.Linux_file}
                              target="_blank"
                              style={{ padding: '5px 20px', fontSize: '20px'}}
                            >
                            <FaIcons.FaLinux className="icon" />
                              linux
                            </a>
                          )
                        }
                        </div>
                      </td>
                      <td className={"tableCell text-center back-radius"}>
                      <div className="mainDiv d-flex justify-content-center align-items-center">
                      <Link
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                          style={{
                            marginTop: '6px',
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          {res.Id === data[0].Id ? <IconName.BsInfoSquare className="icon" /> : null}{" "}
                        </Link>
                      </div>
                      </td>
                    </tr>
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
        </div>

        <div className="mt-5  d-flex justify-content-center">
          <div className="p-2 text-center">
            <h1>
              Have questions about Odyssey Timer? Please Contact Us at{" "}
              <a href="mailto:odyssey@zehntech.com" className="contactMail">
                odyssey@zehntech.com
              </a>
            </h1>
          </div>
        </div>

        <div id="myModal" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <button type="button" className="close" data-bs-dismiss="modal">&times;</button> */}
                <h4 className="modal-title">
                  <b>Steps to give permission on MacOS after download Odyssey
                  Application:</b> 
                </h4>
              </div>
              <div className="modal-body">
                <ol style={{ listStyle: "decimal", margin: "10px" }}>
                  <li>Open Finder.</li>
                  <li>Locate the odyssey app.</li>
                  <li>
                    <b>Control + Click</b> the app.
                  </li>
                  <li>It is shown like this because an Apple developer account has not yet been set up for Odyssey Desktop. You can ignore the prompt information for now.</li>
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={MacInfo} alt="image" width={200}/>
                  </div>
                  <ul style={{ listStyle: "disc", marginLeft: '20px'}}>
                    <li>Click Open.</li>
                    <li>The app should be saved as an exception in your security settings, allowing you to open it in the future.</li>
                    <li>Now, grant accessibility permission to <b>Odyssey Desktop</b> through <b>Security & Privacy</b> .</li>
                  </ul>
                </ol>

              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary shadow-none"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          </div>
      </div>
    </section>
  );
};

export default Download;
