import React, { useState, useEffect } from "react";
import "../css/style.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  addUser,
  adminProjectsDropDown,
  assignProject,getOrgShit,
  get_Roles,
  listTeams,checkJWTToken
} from "../authApi/AuthCRUD";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { Header } from "../layoutComponent/Header";
import eyeSlashIcon from "../Images/formImages/eye-slash-icon.svg";
import eyeIcon from "../Images/formImages/eye-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import backArrow from "../Images/livedashboard/back-arrow.svg";

const emptyStr = " ";
const adduserSchema = Yup.object().shape({
  username: Yup.string().required("User name is required").matches(/^\S.*[a-zA-Z\s]*$/g, "Don't allow space on first letter"),
  role: Yup.string().required("Role is required"),
  email: Yup.string()
    .email("Wrong email format.Enter valid email address")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Minimum 8 characters is required")
    .max(20, "Maximum 20 characters is required")
    .trim()
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(
      /[~`!.#%&*_+@?^${}()|[\]\\]/,
      "Password requires an one special case character"
    ),
    team:Yup.string().required("Team is required"),
    Shift_Time_Id:Yup.string().required("User Working shift is required"),
});
export default function Download() {
  const [arrayData, setArrayData] = useState([]);
  const [arrayData1, setArrayData1] = useState([]);
  const [projectDropdown, setProjectDropdown] = useState([]);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const UserToken = Authentication.Token;
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let err = "";
  const [selectedValue, setSelectedValue] = useState([]);
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [User_Role, setUser_Role] = useState();
  const [Status, setStatus] = useState(false);
  const [Team_Id, setTeam_Id] = useState();
  const [First_Name, setFirst_Name] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };



  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  useEffect(() => {
    // Auto logout user when token is expire
    if(!checkJWTToken(UserToken)){
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    }

    else{
      getShifts();
      listTeams(Tenant, UserRole)
      .then((response) => {
        setArrayData1(response.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
    adminProjectsDropDown(Tenant, UserRole)
      .then((res) => {
        setProjectDropdown(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });

    get_Roles(Tenant, UserRole)
      .then((res) => {
        setArrayData(res.data.Data);
      })
      .catch((err) => {});
    return () => {};
    }
  }, []);

 
  function myFunction(item, id) {
    let data = {
      tenantname: Tenant,
      userid: id,
      projectid: item,
    };
    assignProject(data)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      username: "",
      role: "",
      team:"",
      Shift_Time_Id:"",
    },
    validationSchema: adduserSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      let data = {
        Tenantname: Tenant,
        User_Role: UserRole,
        User_Name: values.username.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' '),
        Full_Name: First_Name,
        Email: values.email.toLowerCase(),
        Password: values.password,
        User_Role: values.role,
        Status: true,
        Team_Id: values.team,
        Shift_Time_Id: values.Shift_Time_Id,
        Projects: selectedValue,
      };
      setLoading(true);
      addUser(data)
      .then((res) => {
        setUrlResponseMsg(true);
      toast.success(res.data.Message = "Employee Created Successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setTimeout(() => {
        navigate("/admin");
      }, 2000);
      setLoading(false);
      })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
  });

  //getshift
  let [shiftData, setShiftData] = useState([]);

  const getShifts = () => {
    getOrgShit()
      .then((res) => {
        setShiftData(res.data.Data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Create User" />

        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i><img src={backArrow} alt="back-Arrow" /></i>
            <span className="font-poppins-regular backBtn">&nbsp;&nbsp;&nbsp;Back</span>
          </div>
        </div>

        <section className="addUsers">
          <div className="container">
            <span className="error"> {err}</span>
            <form onSubmit={formik.handleSubmit}  autoComplete="off">
              <div className="heading">Create New Users </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group mt-3">
                    <p className="mb-2 lableRepo">Email <span className="text-danger">*</span></p>
                    <input
                      label="Email"
                      autoComplete="off"
                      type="text"
                      style={{ width: "100%" }}
                      className="form-control bdr-radius team-select"
                      name="Email"
                      value={Email}
                      {...formik.getFieldProps("email")}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.email}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group mt-3">
                    <p className="mb-2 lableRepo">User Name <span className="text-danger">*</span></p>
                    <input
                      label="First Name"
                      type="text"  autoComplete="off"
                      style={{ width: "100%" }}
                      className="form-control bdr-radius team-select text-capitalize"
                      name="First_Name"
                      value={First_Name}
                      {...formik.getFieldProps("username")}
                      onChange={formik.handleChange}
                      // onChange={(e) => setFirst_Name(e.target.value)}
                    />
                  </div>
                  {formik.touched.username && formik.errors.username && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.username}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group mt-3">
                    <p className="mb-2 lableRepo">Password <span className="text-danger">*</span></p>
                    <input
                      label="Password"  autoComplete="off"
                      type={passwordShown ? "text" : "password"}
                      style={{ width: "100%" }}
                      className="form-control bdr-radius team-select"
                      name="password"
                      value={Password}
                      {...formik.getFieldProps("password")}
                      onChange={formik.handleChange}
                      // onChange={(e) => {
                      //   setPassword(e.target.value);
                      // }}
                    />
                    {passwordShown ? (
                      <i onClick={togglePasswordVisiblity} className="pe-auto">
                        <img className="eye-icon" src={eyeIcon} alt="eyeicon" 
                          style={{
                            float: "right",
                            top: "-32px",
                            position: " relative",
                            marginRight: "11px",
                          }}
                        />
                      </i>
                    ) : (
                      <i onClick={togglePasswordVisiblity} className="pe-auto">
                        <img
                          className="eye-icon"
                          src={eyeSlashIcon}
                          alt="eyeicon"
                          style={{
                            float: "right",
                            top: "-32px",
                            position: " relative",
                            marginRight: "11px",
                          }}
                        />
                      </i>
                    )}
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.password}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <p className="mb-2 lableRepo">Role <span className="text-danger">*</span></p>
                    <select
                      className="form-select team-select bdr-radius"
                      name="member"
                      {...formik.getFieldProps("role")}
                      onChange={formik.handleChange}
                      // onChange={(e) => setUser_Role(e.target.value)}
                    >
                      <option value=''>Select Role</option>
                      {arrayData.map((data, i) => {
                        return (
                          <option key={i} value={data.Role_Id}>
                            {data.Role_Name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {formik.touched.role && formik.errors.role && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.role}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group ">
                    <p className="mb-2 lableRepo">Team <span className="text-danger">*</span></p>
                    <select
                      className="form-select team-select bdr-radius"
                    placeholder="sjdcbbsdvbv"
                      name="member"
                      {...formik.getFieldProps("team")}
                      onChange={formik.handleChange}
                      // onChange={(e) => setTeam_Id(e.target.value)}
                    >
                      <option value=''>Select Team</option>
                      {arrayData1.map((data, i) => {
                        return (
                          <option key={i} value={data.Team_Id}>
                            {data.Team_Name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {formik.touched.team && formik.errors.team && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.team}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="form-group ">
                    <p className="mb-2 lableRepo">User Working Shift <span className="text-danger">*</span></p>
                    <select
                      className="form-select team-select bdr-radius"
                      name="member"
                      {...formik.getFieldProps("Shift_Time_Id")}
                      onChange={formik.handleChange}
                    >
                      <option value="">Select Shift</option>
                      {shiftData.map((data, i) => {
                        return (
                          <option key={i} value={data.Id}>
                            {data.Shift_Name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {formik.touched.Shift_Time_Id && formik.errors.Shift_Time_Id && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.Shift_Time_Id}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <p className="mb-2 lableRepo">Project</p>
                    <Select
                      className="dropdown team-select"
                      placeholder="Select Project"
                      value={projectDropdown.filter((obj) =>
                        selectedValue.includes(obj.value)
                      )} // set selected values
                      options={projectDropdown} // set list of the data
                      onChange={handleChange} // assign onChange function
                      // {...formik.getFieldProps("project")}
                      // onChange={formik.handleChange}
                      isMulti
                      isClearable
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  type="submit"
                  className="btn buttonDefault me-2"
                  disabled={loading}
                  // onClick={saveUser}
                >
                  {!loading && <span>Save</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Save
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <Link to="/admin" style={{ color: "#fff" }}>
                  <button
                    type="button"
                    className="btn buttonDefault me-2"
                    style={{
                      background: "#8A899C",
                    }}
                  >
                      {" "}
                      Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </section>
      </div>
      {urlResponseMsg ? (
      <>
        <ToastContainer />
      </>
    ) : null}
    </section>
  );
}
