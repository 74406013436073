import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { Header } from "../layoutComponent/Header";
import { updateRole, getRolesPermissions } from "../authApi/AuthCRUD";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../css/style.css";
import styled from "styled-components";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditRole() {
  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;

  const navigate = useNavigate();

  const [permissionsList, setPermissionsList] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [checked, setChecked] = useState([]);
  let arr = [];

  const { search } = useLocation();
  let roleId = new URLSearchParams(search).get("id");

  const [formerrors, setFormErrors] = useState({
    Role_Name: "",
  });
  const validate = (values) => {
    const errors = {};
    if (!values.RoleName) {
      errors.Role_Name = "Role name is required*";
    }
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Role_Name: "",
      });
      return true;
    }
  };
  useEffect(() => {
    roleData();
  }, []);

  const roleData = () => {
    getRolesPermissions(roleId)
      .then((res) => {
        setPermissionsList(res.data.Data.Permissions);
        setRoleName(res.data.Data.Role_Name);
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const handleCheckbox = () => {
    var checked = [];
    var unchecked = [];
    $("input[type='checkbox']:checked").each(function () {
      checked[checked.length] = this.value;
    });
    $("input[type='checkbox']:not(:checked)").each(function () {
      unchecked[unchecked.length] = this.value;
    });
    setChecked(checked);
  };
  
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  const postData = () => {
    let filterList = permissionsList.filter((data) => {
      if (data.Status) {
        return data.Permission_Id;
      }
    });
    var filteredPermissionList = filterList.map(function (el) {
      return el.Permission_Id;
    });

    let data = {
      RoleName: roleName,
      RoleId: parseInt(roleId),
      SubPermissions: checked.length == 0 ? filteredPermissionList : checked,
    };
    if (validate(data)) {
      setUrlResponseMsg(true);
      updateRole(data)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/role");
          }, 2000);          
        })
        .catch((err) => {
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
        });
    }
  };

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Edit Role" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          <section
            className="bg-white p-0 p-md-2 p-lg-3 p-xl-5"
            style={{ borderRadius: "10px" }}
          >
            <div className="container-fluid">
              <form>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>
                        Role Name <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="text"
                        defaultValue={roleName}
                        onKeyUp={(e) => {
                          setRoleName(
                            e.target.value === "" ? roleName : e.target.value
                          );
                          setFormErrors({ ...formerrors, Role_Name: "" });
                        }}
                        className="form-control bdr-radius team-select"
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Role_Name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12"></div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt-4">
                      <Label className="mb-2">Permissions</Label>
                    </div>
                    <div className="row  align-items-center">
                      {permissionsList &&
                        permissionsList.map((item) => {
                          return (
                            <React.Fragment key={item.Permission_Id}>
                              <div className="col-lg-4 col-md-4 col-sm-12">
                                <Label className="font-poppins-medium">
                                  {item.Permission_Name}
                                </Label>
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="row">
                                  {item.SubPermissions.map((sub) => {
                                    return (
                                      <React.Fragment
                                        key={item.Sub_Permission_Id}
                                      >
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                defaultChecked={
                                                  sub.Status &&
                                                  arr.push(
                                                    sub.Status === true
                                                      ? sub.Sub_Permission_Id
                                                      : null
                                                  )
                                                }
                                                value={sub.Sub_Permission_Id}
                                                onChange={handleCheckbox}
                                              />
                                            }
                                            label={sub.Display_Name}
                                          />
                                        </div>
                                      </React.Fragment>
                                    );
                                  })}
                                </div>
                              </div>
                              <hr className="me-4" />
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <div className="row">
                    <div className="col mt-1">
                      <button
                        type="button"
                        onClick={postData}
                        className='buttonDefault'
                      >
                        Save
                      </button>
                    </div>
                    <div className="col mt-1">
                      <button type="button" to="/role" className=" buttonDefault" style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}>
                        <Link
                        className="cancelbtnhover"
                          to="/role"
                        >
                          Cancel
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </React.Fragment>
  );
}
