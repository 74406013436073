import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";

import moment from "moment";

import "../css/style.css";
import styled from "styled-components";
import "react-calendar/dist/Calendar.css";
import FormGroup from "@mui/material/FormGroup";
import Calendar from "react-calendar";
import Select from "react-select";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { TableHead } from "@mui/material";

import { Header } from "../layoutComponent/Header";
import {
  userDropdown,checkJWTToken,
  listofLeaves,
  postLeaveRequest,
  teamCalendar,
  showLeave,
} from "../authApi/AuthCRUD";

const options = ["First Half", "Second Half"];
  let hue = 0;
  let colors = []
  for (let j = 0; j < 10; j++) {
      let color = "hsl(" + hue + ",80%,50%)"      
      colors.push(color)      
      hue += 500
  }

export default function ApplyLeave() {
  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;
  const Labels = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #616773;
  `;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [empList, setEmpList] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [todate, setToDate] = useState(null);
  const [fromdate, setFromDate] = useState(null);
  const [note, setNoteData] = useState("");
  const [to, setTo] = useState(0);
  const [from, setFrom] = useState(1);
  const [data, setData] = useState([]);
  const [teamLeave, setTeamLeave] = useState([]);

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserToken = Authentication.Token;

  useEffect(() => {
    // Auto logout user when token is expire
    if(!checkJWTToken(UserToken)){
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    }
    else{
      emplist();
    leaveList();
    leaveCalendar();
    }
    
  }, []);

  const leaveList = () => {
    listofLeaves()
      .then((res) => {
        setLeaves(res.data.Data);
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const emplist = () => {
    userDropdown(Tenant)
      .then((res) => {
        setEmpList(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setSelectedPeople(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const change = (e) => {
    setSelectedLeave(e.value);
  };

  const saveNote = (e) => {
    setNoteData(e.target.value);
  };

  const postData = () => {
    let data = {
      LeaveType_Id: selectedLeave,
      From_Date: fromdate,
      From_Date_Half: from,
      To_Date: todate,
      To_Date_Half: to,
      Leave_Note: note,
      Notify_User: selectedPeople,
    };
    postLeaveRequest(data)
      .then((res) => {
        navigate("/show-leave");
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const leaveCalendar = () => {
    let date = moment(new Date()).format("YYYY-MM-DD");
    teamCalendar(date)
      .then((res) => {
        setData(res.data.Data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Apply Leave Here" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          <FormGroup>
            <section className="addUsers" style={{ padding: "30px 20px" }}>
              <div className="container-fluid">
                <div className="d-flex justify-content-between">
                  <div className="row col-8 border border-dark pb-3 float-left">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group mt-3">
                        <Label>From </Label>
                        <input
                          width="50px"
                          type="date"
                          // min={today}
                          max={endDate}
                          className="borders form-control"
                          // defaultValue={date}
                          onChange={(e) => {
                            setFromDate(e.target.value);
                            // setDate(e.target.value);
                          }}
                        />
                        <div className="mt-2">
                          <BootstrapSwitchButton
                            className="switch"
                            checked={true}
                            onstyle="primary"
                            offstyle="info"
                            onlabel="First Half"
                            offlabel="Second Half"
                            onChange={() => {
                              setFrom(0);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group mt-3">
                        <Label>To Date</Label>
                        <input 
                          width='50px'
                          type='date'
                          className="borders form-control"
                        />
                        {/* <input
                          width="50px"
                          type="date"
                          // min={date}
                          className="borders form-control"
                          // defaultValue={endDate}
                          onChange={(e) => {
                            setToDate(e.target.value);
                            // setEndDate(e.target.value);
                          }}
                        /> */}
                        <div className="mt-2">
                          <BootstrapSwitchButton
                            className="switch"
                            checked={false}
                            onstyle="primary"
                            offstyle="info"
                            onlabel="First Half"
                            offlabel="Second Half"
                            onChange={() => {
                              setTo(1);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <Labels className="mt-3 mb-1">
                      Select available leave type
                    </Labels>
                    <Select
                      className="dropdown"
                      placeholder="Select Leave Type"
                      options={leaves}
                      onChange={change}
                      isSingle
                    />

                    <Labels className="mt-4">
                      Note <Label>(Message to your Reporting Manager*)</Label>
                    </Labels>
                    <textarea
                      className="text"
                      placeholder="Please enter reason for applying leave"
                      onChange={saveNote}
                      rows={5}
                    />
                    <Labels className="mt-3 mb-1">
                      Notify Peoples{" "}
                      <Label>(You can notify multiple peoples*)</Label>
                    </Labels>
                    <Select
                      className="dropdown"
                      placeholder="These employees will be notified"
                      value={empList.filter((obj) =>
                        selectedPeople.includes(obj.value)
                      )}
                      options={empList}
                      onChange={handleChange}
                      isMulti
                      isClearable
                    />
                  </div>
                  <div className="row col-4 border border-dark ">
                    <div className="cal">
                      <Calendar
                        value={new Date()}
                        minDate={new Date()}
                        showNeighboringMonth={false}
                        onClickDay={(value) =>
                          showLeave(moment(value).format("YYYY-MM-DD"))
                            .then((res) => {
                              setTeamLeave(res.data.Data);
                            })
                            .catch((err) => {
                              console.log(err, "error");
                            })
                        }
                        tileContent={({ date, view }) =>
                          (view === "month" && date.getDay() === 6) ||
                          (view === "month" && date.getDay() === 0) ? (
                            <p className="weekend">WEEKEND</p>
                          ) : (
                            data.map((v) => {
                              return v.From_Date ===
                                moment(date).format("YYYY-MM-DD") ? (
                                <p className="leave">LEAVE</p>
                              ) : null;
                            })
                          )
                        }
                      />
                      <hr />
                      {teamLeave.length === 0 ? (
                        <h3 className="text-center mt-2">
                          Click on leave day to know more...
                        </h3>
                      ) : (
                        <div className="table table-responsive mt-2">
                          <table className="table">
                            <TableHead>
                              <tr>
                                <th
                                  className="tableHead"
                                  style={{ paddingLeft: "2rem" }}
                                >
                                  Name
                                </th>
                                <th
                                  className="tableHead "
                                  style={{ paddingLeft: "2rem" }}
                                >
                                  Date
                                </th>
                                <th className="tableHead">Day</th>
                              </tr>
                            </TableHead>

                            <tbody className="tablespace">
                              {teamLeave.map((value) => {
                                return (
                                  <tr>
                                    <td className="tableCell front-radius tablebackground ">
                                      <div className="d-flex">
                                        <div
                                          className="cal2"
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                            borderRadius: "13px",
                                            textAlign: "center",
                                            color: "#fff",
                                            backgroundColor:
                                              colors[
                                                Math.floor(
                                                  Math.random() * colors.length
                                                )
                                              ],
                                          }}
                                        >
                                          {value.Full_Name.charAt(
                                            0
                                          ).toUpperCase()}
                                        </div>
                                        <div className="ml-2">
                                          {value.Full_Name.split(/\s(.+)/)[0]}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="tableCell tablebackground">
                                      <div className="badge teamMonth text-wrap">
                                        {moment(value.From_Date).format("DD")}
                                        {"-"}
                                        {moment(value.To_Date).format(
                                          "DD"
                                        )}{" "}
                                        {moment(value.From_Date).format("MMM")}
                                      </div>
                                    </td>
                                    <td className="tableCell back-radius tablebackground">
                                      {value.Total_Leave_Days}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5 d-flex justify-content-center align-items-center">
                  <div className="row">
                    <div className="col mt-1">
                    <div className="col mt-1">
                      <button
                        type="button"
                        onClick={postData}
                        style={styles.saveBtn}
                      >
                        <span>Save</span>
                      </button>
                    </div>
                      <button type="button" to="/upload-version-list" style={styles.cancelBtn}>
                        <Link
                          to="/upload-version-list"
                          style={{ textDecoration: "none;", color: "white" }}
                        >
                          {" "}
                          Cancel
                        </Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </FormGroup>
        </div>
      </section>
    </React.Fragment>
  );
}
