import React, { useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useNavigate } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import {
  employeeHourlyRateList,
  changeHourlyRateByProjectId,
  updateHourlyRate,
  checkUserPermissions,
  checkJWTToken,
} from "../authApi/AuthCRUD";
import edit from "../Images/edit.svg";
import tick from "../Images/profile.svg";
import crossIcon from "../Images/livedashboard/cross-icon.svg";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import sorticon from "../Images/sorticon.svg";
import ReactPaginate from "react-paginate";
import ClockLoader from "react-spinners/ClockLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EmployeeHourlyRate() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const UserToken = Authentication.Token;

  const [loading, setLoading] = useState(false);
  const [hourlyRateList, setHourlyRateList] = useState([]);
  const Tenant = Authentication.Tenant;
  const [noOfPage, setNoOfPage] = useState(1);
  const [sort, setSort] = useState(true);
  let [currentpage, setCurrentPage] = useState(1);
  let [flag, setflag] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  let [dataPerPage, setDataPerPage] = useState(10);

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getUserPermission();
      getData();
    }
  }, []);

  const getData = (
    pageNo = currentpage || 1,
    search = "",
    keys = "",
    check = true,
    pagesize = dataPerPage
  ) => {
    let data = {
      search: search,
      key: keys,
      ascdsc: check,
      pageNumber: pageNo,
      pageSize: pagesize,
    };
    setLoading(true);
    employeeHourlyRateList(data)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setHourlyRateList(response.data.Data);
        setEditCurrency(response.data.Data.Currency_Type);
        setNoOfPage(response.data.TotalPages);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const [isInputActive, setIsInputActive] = useState(false);
  const [saveCancelBtn, setSaveCancelBtn] = useState(false);
  const [editUserId, setEditUserId] = useState(0);
  const [editRate, setEditRate] = useState();
  let [userProjectId, setUserProjectId] = useState();
  const [editCurrency, setEditCurrency] = useState("");

  const handleEdit = (getUserId) => {
    setEditUserId(getUserId);
    setIsInputActive(true);
    setSaveCancelBtn(true);
    var pId = document.getElementById("selectedValue" + getUserId).value;
    changeHourlyRateByProjectId(getUserId, pId)
      .then((response) => {
        // toast.success(response.data.Message, {
        //   position: "top-right",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: false,
        //   progress: undefined,
        // });
        // setTimeout(() => {
        document.getElementById("rateValue1" + getUserId).value =
          response.data.Data.Hourly_Rate;
        document.getElementById("currencyValue1" + getUserId).value =
          response.data.Data.Currency_Type;
        // }, 2000);
      })
      .catch((err) => {});
  };

  const handleCancelChangeName = () => {
    setIsInputActive(false);
    setSaveCancelBtn(false);
  };

  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  const handleChangeName = (id) => {
    var pId = document.getElementById("selectedValue" + id).value;

    var currencyValue = document.getElementById("currencyValue1" + id).value;

    var Rate = parseFloat(editRate);
    var v = document.getElementById("rateValue11" + id);
    console.log(Rate, ">>>>>>>>>>", v.value, currencyValue);

    let data = {
      userId: id,
      projectId: pId,
      rate: Rate ? Rate : v.value,
      currency: currencyValue,
    };
    setUrlResponseMsg(true);
    updateHourlyRate(data)
      .then((res) => {
        toast.success(res.data.Message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setTimeout(() => {
          setIsInputActive(false);
          setSaveCancelBtn(false);
          getData();
        }, 2000);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      getData(currentpage, "", flag, dataPerPage);
    }
  };

  const onSort = (key, check) => {
    if (check) setflag(false);
    else setflag(true);
    getData(currentpage, key, flag);
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getData(pageNumber);
  };

  const searchHandler = (e) => {
    if (e.target.value == "") {
      getData();
    } else {
      // (pageNo, keys, check, pagesize)
      getData(1, e.target.value, "", flag, dataPerPage);
      // getData(e.target.value, currentpage,  flag, dataPerPage);
    }
  };

  const changeRateByProjectId = (p, u) => {
    changeHourlyRateByProjectId(u, p)
      .then((response) => {
        document.getElementById("rateValue1" + u).innerHTML =
          response.data.Data.Hourly_Rate;
        document.getElementById("currentValue" + u).innerHTML =
          response.data.Data.Currency_Type;
      })
      .catch((err) => {});
  };

  let [pagePermission, setPagePermission] = useState([]);

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "User_Hourly_Rate") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <>
      <div style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Employee Hourly Rate" />

          <div className="row mt-3">
            <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
              <div className="sb-example-3 w-100">
                <div className="search__container">
                  <input
                    className="search-input-field font-poppins-regular"
                    type="text"
                    placeholder="Search Version..."
                    autoComplete="on"
                    onKeyUp={searchHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              {!hourlyRateList || hourlyRateList === null ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No Result Available
                  </h5>
                </>
              ) : (
                <>
                  <div className="table-responsive">
                    <table
                      className="table leaves"
                      style={{
                        borderBottomWidth: "0px",
                        borderBottomColor: "none",
                      }}
                    >
                      {/* <TableHead> */}
                      <thead>
                        <tr>
                          <th className="tableHead leaveTable ps-3">
                            Employee's &nbsp;
                            <button
                              onClick={() => {
                                onSort("User_Name", flag);
                              }}
                              type="button"
                            >
                              <img src={sorticon} alt="icon" />
                            </button>
                          </th>
                          <th className="tableHead leaveTable ">
                            Project's
                          </th>
                          <th className="tableHead leaveTable ">
                            Hourly Rate &nbsp;
                            <button
                              onClick={() => {
                                onSort("Hourly_Rate", flag);
                              }}
                              type="button"
                            >
                              <img src={sorticon} alt="icon" />
                            </button>
                          </th>
                          <th
                            className="tableHead leaveTable "
                            style={{ minWidth: "100px" }}
                          >
                            Currency &nbsp;
                            <button
                              onClick={() => {
                                onSort("Currency_Type", flag);
                              }}
                              type="button"
                            >
                              <img src={sorticon} alt="icon" />
                            </button>
                          </th>
                          <th style={{ width: "100px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {hourlyRateList.map((list, i) => {
                          return (
                            <>
                              <React.Fragment>
                                <tr>
                                  <td
                                    className="tableCell front-radius"
                                  >
                                    <div className="mainDiv compName" style={{marginTop: '9px'}}>
                                      {list.Full_Name}
                                    </div>
                                  </td>
                                  <td className="tableCell">
                                    <div className="mainDiv d-flex  align-items-center ">
                                      <select
                                        className="form-select team-select borders"
                                        style={{ width: "200px" }}
                                        id={`selectedValue${list.User_Id}`}
                                        onChange={(e) => {
                                          changeRateByProjectId(
                                            e.target.value,
                                            list.User_Id
                                          );
                                          setUserProjectId(e.target.value);
                                        }}
                                      >
                                        {list.ProjectList.map((data, i) => {
                                          return (
                                            <React.Fragment
                                              key={data.Project_Id}
                                            >
                                              <option value={data.Project_Id}>
                                                {data.Project_Name}
                                              </option>
                                            </React.Fragment>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </td>

                                  <td className=" tableCell">
                                    <div className="mainDiv d-flex  align-items-center ">
                                      {isInputActive &&
                                      editUserId == list.User_Id ? (
                                        <>
                                          <form>
                                            <div className="d-flex align-items-center">
                                              <input
                                                id={`rateValue11${list.User_Id}`}
                                                name="policy"
                                                type="text"
                                                className="form-control w-20  team-select"
                                                autoComplete="off"
                                                style={{
                                                  borderRadius: "6px",
                                                  height: "40px",
                                                }}
                                                defaultValue={list.Rate}
                                                onChange={(e) =>
                                                  setEditRate(e.target.value)
                                                }
                                              />
                                            </div>
                                          </form>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            id={`rateValue${list.User_Id}`}
                                            className="d-flex "  style={{marginTop: '7px'}}
                                          >
                                            {list.Rate}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td className="tableCell">
                                    <div className="mainDiv d-flex  ">
                                      {isInputActive &&
                                      editUserId == list.User_Id ? (
                                        <>
                                          <select
                                            className="form-select form-input-field borders selectVal heightSelct"
                                            name="Currency"
                                            id={`currencyValue1${list.User_Id}`}
                                            style={{
                                              marginLeft: '10px',
                                              width: "150px",
                                              height: "40px !important",
                                            }}
                                            defaultValue={list.Currency_Type}
                                            onChange={(e) =>
                                              setEditCurrency(e.target.value)
                                            }
                                          >
                                            <option value="USD">
                                              US Dollar
                                            </option>
                                            <option value="EUR">Euro</option>
                                            <option value="INR">
                                              Indian Rupee
                                            </option>
                                            <option value="AUD">
                                              Australia Dollar
                                            </option>
                                            <option value="BHD">
                                              Bahraini Dinar
                                            </option>
                                            <option value="BDT">
                                              Bangladeshi Taka
                                            </option>
                                            <option value="BZD">
                                              Belize Dollar
                                            </option>
                                          </select>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            id={`currentValue${list.User_Id}`}  style={{marginTop: '7px'}}
                                            className="d-flex justify-content-center align-items-center "
                                          >
                                            {list.Currency_Type}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td className="tableCell back-radius">
                                    {pagePermission.map((res) => {
                                      if (
                                        res.Sub_Permission_Name ===
                                        "update_hourly_rate"
                                      ) {
                                        return (
                                          <>
                                            <div className="mainDiv ">
                                              {saveCancelBtn &&
                                              editUserId == list.User_Id ? (
                                                <>
                                                  <div className="btns d-flex justify-content-center align-items-center ">
                                                    <img
                                                      src={tick}
                                                      alt="tick-icon"
                                                      className="tick-icon-height"
                                                      onClick={() =>
                                                        handleChangeName(
                                                          list.User_Id
                                                        )
                                                      }
                                                    />
                                                    &nbsp;
                                                    <div
                                                      className="cross-policy-name"
                                                      role="button"
                                                    >
                                                      <img
                                                        src={crossIcon}
                                                        alt="tick-icon"
                                                        style={{
                                                          height: "18px",
                                                        }}
                                                        onClick={
                                                          handleCancelChangeName
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <button title="Update Hourly Rate"
                                                  className=""
                                                  style={{marginTop: '7px'}}
                                                  onClick={() => {
                                                    handleEdit(list.User_Id);
                                                  }}
                                                >
                                                  <img
                                                    src={edit}
                                                    alt="icon"
                                                    width={20}
                                                  />
                                                </button>
                                              )}
                                            </div>
                                          </>
                                        );
                                      }
                                    })}
                                  </td>
                                </tr>
                              </React.Fragment>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
          {noOfPage == 1 && hourlyRateList.length <= 10 ? (
            ""
          ) : (
            <div className="paginations d-flex justify-content-between align-items-center mt-2">
              <div className="d-flex justify-content-center align-items-center">
                <p className="lableRepo">Rows per page : </p>
                <input
                  id="input-control"
                  name="policy"
                  type="text"
                  className="form-control w-20 text-center team-select"
                  autoComplete="off"
                  style={{
                    borderRadius: "6px",
                    height: "30px",
                  }}
                  defaultValue={dataPerPage}
                  onChange={(e) => setDataPerPage(e.target.value)}
                  onKeyDown={testPage}
                />
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={noOfPage}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </div>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
}
