import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./ViewProjectDetails.css";
import { shallowEqual, useSelector } from "react-redux";
import { getProjectDetails, checkUserPermissions } from "../authApi/AuthCRUD";
import moment from "moment";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import ReactTooltip from "react-tooltip";

export default function ViewProjectDetails() {
  let navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("id");
  const [projectDetail, setProjectDetail] = useState([]);
  const [projectUserData, setProjectUserData] = useState([]);
  let fullName = [];
  let initials = "";

  useEffect(() => {
    getUserPermission();
    getProjectDetails(projectId)
      .then((response) => {
        setProjectDetail(response.data.Data);
        if (response.data.Data.Users.length !== 0) {
          setProjectUserData(response.data.Data.Users);
        } else {
          setProjectUserData([]);
        }
      })
      .catch((err) => {
        setProjectDetail([]);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  }, [projectId]);

  let [pagePermission, setPagePermission] = useState([]);
  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Tasks") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <>
      <div className="container-fluid">
        <Header title="Project Details" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <div className="projectDetailsCard">
          <div className="projectName">{projectDetail.Project_Name}</div>
          <hr />

          <div className="projectheader mt-3 mb-3">
            <div className="row d-flex align-items-center">
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12  d-flex">
                <div className="customerDetails d-flex  align-items-center">
                  <div
                    className="rounded-circle d-flex justify-content-center"
                    style={{
                      width: " 50px",
                      height: "50px",
                      color: "#ffffff",
                      background:
                        "linear-gradient(264.71deg, #8F009B 18.58%, #420085 128.77%)",
                    }}
                  >
                    <div
                      className="d-flex span-text text-uppercase"
                      style={{ fontSize: "16px" }}
                    >
                      NA
                    </div>
                  </div>
                  <div className="customerHeading ms-3">
                    <div className="customerName">Customer Name</div>
                    <div className="customerDetailName">Na</div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                <div className="viewTasks">
                  {pagePermission.map((list) => {
                    if (list.Sub_Permission_Name === "read_task") {
                      return (
                        <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center  mt-md-0 mt-lg-0">
                          <div className="btn-div-width">
                            <Link
                              type="button"
                              className="btn btn-lg w-100 font-poppins-medium align-center"
                              style={{
                                borderRadius: "5px",
                                borderColor: "#6300B0",
                                background: "#6300B0",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                color: "#FFF",
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "10px 20px",
                              }}
                              to={`/project-tasks?id=${projectId}`}
                            >
                              <span>View All Tasks</span>
                            </Link>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="projectContent mt-3 mb-3">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">Start Date</div>
                  <div className="customerDetailName">
                    {moment(projectDetail.Start_Date).format("MM.DD.YYYY")}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">End Date</div>
                  <div className="customerDetailName">
                    {moment(projectDetail.End_Date).format("MM.DD.YYYY")}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">Total Task</div>
                  <div className="customerDetailName">{projectDetail.Task}</div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-4">
                <div className="customerHeading">
                  <div className="customerName">Total Time</div>
                  <div className="customerDetailName">
                    {projectDetail.Total_Time}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="assign-team mt-4">
            <div className="d-flex align-items-center">
              <div className="customerName">Assign: </div>
              <div className="customerDetailName ms-2">
                {projectUserData.length ? projectUserData.length : 0}
              </div>
            </div>

            <div className="mt-2 d-flex flex-wrap">
              {projectUserData.length === 0 ? (
                <React.Fragment>
                  <span
                    className="text-secondary d-flex justify-content-center font-poppins-regular"
                    style={{ fontSize: "14px" }}
                  >
                    This project is not assign to anyone.
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {projectUserData.map((userData, index) => {
                    fullName = userData.Full_Name.split(" ");
                    if (fullName.length === 2) {
                      initials =
                        fullName.shift().charAt(0) + fullName.pop().charAt(0);
                    } else {
                      initials = fullName.shift().charAt(0);
                    }
                    return (
                      <React.Fragment key={index}>
                        <Link to={`/UserProfileView?id=${userData.User_Id}`}>
                          <div
                            className="rounded-circle mb-3 me-1 d-flex justify-content-center"
                            style={{
                              width: "35px",
                              height: "35px",
                              background:
                                "linear-gradient(264.71deg, rgb(205 68 217) 18.58%, #420085 128.77%)",
                            }}
                            data-tip={userData.Full_Name}
                            data-for="userFullName"
                            role="button"
                          >
                            <div className="d-flex align-items-center text-white text-uppercase" style={{fontSize: '14px'}}>
                              {initials}
                            </div>
                            <ReactTooltip
                              id="userFullName"
                              place="bottom"
                              type="dark"
                              effect="solid"
                              multiline={true}
                            />
                          </div>
                        </Link>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        {/* <div className="card mt-4 p-2 p-md-4 p-lg-4">
          <div>
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card-box project-detail">
                      <div className="media mt-0 m-b-30">
                        <img
                          className="d-flex mr-3 rounded-circle"
                          alt="64x64"
                          src="https://bootdey.com/img/Content/avatar/avatar2.png"
                          style={{ width: "48px", height: "48px" }}
                        />
                        <div className="media-body mt-2">
                          <h5 className="media-heading mb-0 mt-0 font-poppins-medium">
                            Customer : James L. Kelley
                          </h5>
                          <span className="badge badge-danger">Urgent</span>
                        </div>
                      </div>
                      <h4 className="m-b-20 font-poppins-medium">
                        Project name : {projectDetail.Project_Name}
                      </h4>
                      <p className="text-muted">{projectDetail.Description}</p>
                      <ul className="list-inline task-dates m-b-0 mt-5">
                        <li>
                          <h5 className="m-b-5 font-poppins-medium">
                            Start Date
                          </h5>
                          <p className="font-poppins-regular">
                            {moment(projectDetail.Start_Date).format(
                              "DD MMMM YYYY"
                            )}
                          </p>
                        </li>
                        <li>
                          <h5 className="m-b-5 font-poppins-medium">
                            End Date
                          </h5>
                          <p className="font-poppins-regular">
                            {moment(projectDetail.End_Date).format(
                              "DD MMMM YYYY"
                            )}
                          </p>
                        </li>
                        <li>
                          <h5 className="m-b-5 font-poppins-medium">
                            Total Time
                          </h5>
                          <p className="font-poppins-regular">
                            {projectDetail.Total_Time}
                          </p>
                        </li>
                      </ul>
                      <div className="clearfix"></div>
                      <div className="mt-4">
                        <h5 className="font-poppins-medium">
                          Total Task :{" "}
                          <span className="font-poppins-regular">
                            {projectDetail.Task}
                          </span>
                          &nbsp;
                          {pagePermission.map((list) => {
                            if (list.Sub_Permission_Name === "read_task") {
                              return (
                                <Link
                                  to={`/project-tasks?id=${projectId}`}
                                  className="text-decoration-none"
                                >
                                  <span
                                    class="badge font-poppins-regular fw-normal"
                                    style={{
                                      background: "#9615a1",
                                      padding: "8px",
                                    }}
                                  >
                                    View Tasks
                                  </span>
                                </Link>
                              );
                            }
                          })}
                        </h5>
                      </div>
                      <div className="assign-team mt-4">
                        <h5 className="m-b-5 font-poppins-medium">Assign to</h5>
                        <div className="mt-2 d-flex flex-wrap">
                          {projectUserData.length === 0 ? (
                            <React.Fragment>
                              <span
                                className="text-secondary d-flex justify-content-center font-poppins-regular"
                                style={{ fontSize: "14px" }}
                              >
                                This project is not assign to anyone.
                              </span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              {projectUserData.map((userData, index) => {
                                fullName = userData.Full_Name.split(" ");
                                if (fullName.length === 2) {
                                  initials =
                                    fullName.shift().charAt(0) +
                                    fullName.pop().charAt(0);
                                } else {
                                  initials = fullName.shift().charAt(0);
                                }
                                return (
                                  <React.Fragment key={index}>
                                    <Link
                                      to={`/UserProfileView?id=${userData.User_Id}`}
                                    >
                                      <div
                                        className="rounded-circle mb-3 me-1 d-flex justify-content-center"
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                          background:
                                            "linear-gradient(264.71deg, rgb(205 68 217) 18.58%, #420085 128.77%)",
                                        }}
                                        data-tip={userData.Full_Name}
                                        data-for="userFullName"
                                        role="button"
                                      >
                                        <div className="d-flex align-items-center text-white text-uppercase">
                                          {initials}
                                        </div>
                                        <ReactTooltip
                                          id="userFullName"
                                          place="bottom"
                                          type="dark"
                                          effect="solid"
                                          multiline={true}
                                        />
                                      </div>
                                    </Link>
                                  </React.Fragment>
                                );
                              })}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
