import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { shallowEqual, useSelector } from "react-redux";
import { addLeaveType } from "../authApi/AuthCRUD";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  getListLeaveType,
  updateLeave,
  deleteLeaveType,
  getLeaveTypeById,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import ClockLoader from "react-spinners/ClockLoader";
import ReactPaginate from "react-paginate";
import "../css/style.css";
import AddLeave from "./AddLeave";
import sorticon from "../Images/sorticon.svg";
import Dropdown from "react-bootstrap/Dropdown";

import "font-awesome/css/font-awesome.min.css";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="actionBtn"
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </button>
));
const leaveSchema = Yup.object().shape({
  Leave_Name: Yup.string().required("Leave type is required*"),
  Subject_Of_Approval: Yup.string().required("Subject is required*"),
});
export default function ListLeave() {
  const navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const UserRole = Authentication.User_Role;
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("ASC");
  const [leaveList, setleaveList] = useState([]);
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);
  const Tenant = Authentication.Tenant;
  const createdById = Authentication.User_Id;
  let [flag, setflag] = useState(false);
  const [noOfPage, setNoOfPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  let [currentpage, setCurrentPage] = useState(1);
  let [L_Id, setL_Id] = useState();

  const { search } = useLocation();
  const leaveId = new URLSearchParams(search).get("id");
  const [allow_Half_Leave, setAllow_Half_Leave] = useState(false);
  const [allow_Quarter_Leave, setAllow_Quarter_Leave] = useState(false);
  const [record_Without_Approval, setRecord_Without_Approval] = useState(false);
  const [formValues, setFormValues] = useState({
    Leave_Id: L_Id,
    Leave_Name: "",
    Allow_Half_Leave: "",
    Allow_Quarter_Leave: "",
    Record_Without_Approval: "",
    Subject_Of_Approval: "",
  });

  useEffect(() => {
    getUserPermission();
    getLeave();
  }, []);

  const opnModal = (e) => {
    e.preventDefault();
    getLeaveTypeById(Tenant, L_Id)
      .then((response) => {
        setFormValues({
          Leave_Id: L_Id,
          Leave_Name: response.data.Data.Leave_Name,
          Subject_Of_Approval: response.data.Data.Subject_Of_Approval,
          Allow_Half_Leave: response.data.Data.Allow_Half_Leave,
          Allow_Quarter_Leave: response.data.Data.Allow_Quarter_Leave,
          Record_Without_Approval: response.data.Record_Without_Approval,
        });
        // setAllow_Half_Leave(response.data.Data.Allow_Half_Leave),
        // setAllow_Quarter_Leave(response.data.Data.Allow_Quarter_Leave),
        // setRecord_Without_Approval(response.data.Data.Record_Without_Approval)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      Leave_Name: formValues.Leave_Name,
      Allow_Half_Leave: allow_Half_Leave,
      Allow_Quarter_Leave: allow_Quarter_Leave,
      Record_Without_Approval: record_Without_Approval,
      Subject_Of_Approval: formValues.Subject_Of_Approval,
    };
    setLoading(true);
    updateLeave(formData)
      .then((res) => {
        //navigate('/projects-list')
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getLeave = (
    colName = "",
    page = currentpage,
    search = searchKey,
    sorttype = flag
  ) => {
    let data = {
      PageNumber: page,
      sortType: sorttype,
      sortColumn: colName,
      searchname: search,
    };
    getListLeaveType(data)
      .then((res) => {
        setleaveList(res.data.Data);
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getLeave("", pageNumber, searchKey, flag);
  };

  const onSort = (colName, sortType) => {
    if (sortType) setflag(false);
    else setflag(true);
    getLeave(colName, currentpage, searchKey, sortType);
  };

  const inputChangeHandler = (e) => {
    if (e == "") {
      getLeave();
    } else {
      getLeave("", 1, e, flag);
    }
  };
  const formik = useFormik({
    initialValues: {
      Leave_Name: "",
      Allow_Half_Leave: false,
      Allow_Quarter_Leave: false,
      Record_Without_Approval: false,
      Subject_Of_Approval: "",
      Created_By: createdById,
      TenantName: Tenant,
    },
    validationSchema: leaveSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let data = {
        Leave_Name: values.Leave_Name,
        Allow_Half_Leave: values.Allow_Half_Leave,
        Allow_Quarter_Leave: values.Allow_Quarter_Leave,
        Record_Without_Approval: values.Record_Without_Approval,
        Subject_Of_Approval: values.Subject_Of_Approval,
        Created_By: createdById,
        TenantName: Tenant,
      };
      setLoading(true);
      setUrlResponseMsg(true);
      addLeaveType(data)
        .then((res) => {
          setLoading(false);
          document.getElementById("cancel-add-leave").click();
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          getLeave();
        })
        .catch((error) => {
          // console.log(error.res.data)
          setLoading(false);
        });
    },
  });

  //Update Leaves
  const deleteLeave = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Leave Type?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then((result) => {
      if (result.isDenied) {
        deleteLeaveType(Tenant, id)
          .then((res) => {
            Swal.fire("Sucessfully deleted", "", "success");
            getLeave();
          })
          .catch((err) => {});
      }
    });
  };

  let [pagePermission, setPagePermission] = useState([]);
  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Approve_Leaves") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };
  return (
    <>
      <section className="m-0 d-flex">
        <div className="container-fluid">
          <Header title="Leave" />

          <div className="contentSection mt-4 mb-4">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12  d-flex">
                <div className="sb-example-3 w-100">
                  <div className="search__container">
                    <input
                      className="search-input-field font-poppins-regular"
                      type="text"
                      placeholder="Search leaves..."
                      autoComplete="on"
                      onKeyUp={(e) => {
                        setSearchKey(e.target.value);
                        inputChangeHandler(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {pagePermission.map((list) => {
                if (list.Sub_Permission_Name === "read_leaves") {
                  return (
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 ">
                      <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                        <div className="btn-div-width">
                          <Link
                            type="button"
                            className="btn btn-lg w-100 font-poppins-medium align-center"
                            style={{
                              borderRadius: "5px",
                              borderColor: "#6300B0",
                              background: "#6300B0",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              color: "#FFF",
                              fontWeight: "500",
                              fontSize: "12px",
                              padding: "10px 20px",
                            }}
                            to={{
                              pathname: "/view-all-leaves",
                            }}
                          >
                            <span>View Leaves</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              {pagePermission.map((list) => {
                if (list.Sub_Permission_Name === "create_leave_type") {
                  return (
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 ">
                      <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                        <div className="btn-div-width">
                          <div className="btn-div-width">
                            <button
                              id="leave-button"
                              data-bs-toggle="modal"
                              data-bs-target="#AddNewLeave"
                              className="btn btn-lg w-100 font-poppins-medium align-center"
                              style={{
                                borderRadius: "5px",
                                borderColor: "#6300B0",
                                background: "#6300B0",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                color: "#FFF",
                                fontWeight: "500",
                                fontSize: "12px",
                                padding: "10px 20px",
                              }}
                            >
                              Add Leave Type
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <hr />

          {loading ? (
            <>
              <div className="d-flex justify-content-center text-center loaderDiv">
                <ClockLoader color="#6400B0" speedMultiplier={3} />
                <span className="loading">Loading....</span>
              </div>
            </>
          ) : (
            <>
              {leaveList.length === 0 ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No Result Available
                  </h5>
                </>
              ) : (
                <>
                  <div className="table-responsive">
                    <table
                      className="table"
                      style={{
                        borderBottomWidth: "0px",
                        borderBottomColor: "none",
                      }}
                    >
                      <thead className="adminTable">
                        <tr>
                          <th className="tableHead ps-0 firstHead text-capitalize">
                            <span style={{ marginLeft: "10px" }}>
                              Leave Type
                            </span>{" "}
                            &nbsp;
                            <button
                              onClick={() => {
                                onSort("Leave_Name", flag);
                              }}
                              type="button"
                            >
                              <img src={sorticon} alt="icon" />
                            </button>
                          </th>
                          <th className="tableHead">
                            Subject Of Approval &nbsp;
                            <button
                              onClick={() => {
                                onSort("Subject_Of_Approval", flag);
                              }}
                              type="button"
                            >
                              <img src={sorticon} alt="icon" />
                            </button>{" "}
                          </th>
                          <th className="tableHead leaveTable ">
                            Full-day leave
                          </th>
                          <th className="tableHead leaveTable ">
                            Half-day leave
                          </th>
                          <th className="tableHead leaveTable ">
                            Quarter-day leave
                          </th>
                          <th className="tableHead leaveTable "></th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaveList.map((res, i) => (
                          <tr
                            className=""
                            style={{ borderRadius: "30px" }}
                            key={i}
                          >
                            <td className="tableCell front-radius compName">
                              <div className="mainDiv">{res.Leave_Name}</div>
                            </td>
                            <td className="tableCell">
                              <div className="mainDiv">
                                {res.Subject_Of_Approval}
                              </div>
                            </td>
                            <td className="tableCell">
                              <div className="mainDiv ">
                                {res.Allow_Quarter_Leave == true ? (
                                  <div
                                    className="tick"
                                    style={{ color: "green" }}
                                  >
                                    <i className="fa fa-check"></i>
                                  </div>
                                ) : (
                                  <div
                                    className="tick"
                                    style={{ color: "red" }}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="tableCell">
                              <div className="mainDiv ">
                                {res.Allow_Half_Leave == true ? (
                                  <div
                                    className="tick"
                                    style={{ color: "green" }}
                                  >
                                    <i className="fa fa-check"></i>
                                  </div>
                                ) : (
                                  <div
                                    className="tick"
                                    style={{ color: "red" }}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="tableCell">
                              <div className="mainDiv ">
                                {res.Record_Without_Approval == true ? (
                                  <div
                                    className="tick"
                                    style={{ color: "green" }}
                                  >
                                    <i className="fa fa-check"></i>
                                  </div>
                                ) : (
                                  <div
                                    className="tick"
                                    style={{ color: "red" }}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className=" tableCell back-radius">
                              <div className="Maindiv d-flex justify-content-center align-items-center">
                                <div className="edit me-2" title="Update Leave Type">
                                  {pagePermission.map((list) => {
                                    if (
                                      list.Sub_Permission_Name ===
                                      "update_leave_type"
                                    ) {
                                      return (
                                        <>
                                          <Link
                                            to={`/UpdateLeaveTypes?id=${res.Leave_Id}`}
                                          >
                                            <img
                                              width={20}
                                              src={edit}
                                              alt="icon"
                                            />
                                          </Link>
                                        </>
                                      );
                                    }
                                  })}
                                </div>
                                <div className="view" title="Delete Leave Type">
                                  {pagePermission.map((list) => {
                                    if (
                                      list.Sub_Permission_Name ===
                                      "delete_leave_type"
                                    ) {
                                      return (
                                        <>
                                          <img
                                            onClick={() =>
                                              deleteLeave(res.Leave_Id)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              width: "16px",
                                            }}
                                            src={delet}
                                            alt="icon"
                                          />
                                        </>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </td>
                            {/* <td className="text-center tableCell back-radius">
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu size="sm" title="">
                                  {pagePermission.map((list) => {
                                    if (
                                      list.Sub_Permission_Name ===
                                      "update_leave_type"
                                    ) {
                                      return (
                                        <>
                                          <Dropdown.Item
                                            className="drpdwnitem"
                                            style={{
                                              color: "#616773",
                                              opacity: "0.9",
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              fontFamily: "Poppins",
                                              fontStyle: "normal",
                                            }}
                                          >
                                            <Link
                                              to={`/UpdateLeaveTypes?id=${res.Leave_Id}`}
                                              className="drpdwnitem"
                                              
                                            >
                                              <div className="d-flex ">
                                                <img src={edit} alt="icon" />
                                                &nbsp; Edit
                                              </div>
                                            </Link>
                                          </Dropdown.Item>
                                        </>
                                      );
                                    }
                                  })}
                                  {pagePermission.map((list) => {
                                    if (
                                      list.Sub_Permission_Name ===
                                      "delete_leave_type"
                                    ) {
                                      return (
                                        <>
                                          <Dropdown.Item className="drpdwnitem">
                                            <div
                                              className="d-flex"
                                              onClick={() =>
                                                deleteLeave(res.Leave_Id)
                                              }
                                            >
                                              <div className="d-flex">
                                                <img src={delet} alt="icon" />
                                                <div>&nbsp; Delete</div>
                                              </div>
                                            </div>
                                          </Dropdown.Item>
                                        </>
                                      );
                                    }
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
          {
            <div className="mb-3">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={noOfPage}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          }

          {/* //Add Leave tpe Model */}
          <div
            className="modal fade"
            id="AddNewLeave"
            data-bs-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="AddNewLeave"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl">
              <div
                id="modal-center"
                className="modal-content"
                style={{ padding: "22px 32px" }}
              >
                {/* row for heading and cross icon */}
                <div className="row">
                  <div className="col-12">
                    <div className="mx-3 d-flex justify-content-between">
                      <div className="d-flex">
                        <h4 className="font-poppins-bold profile-details-heading">
                          Leave Type
                        </h4>
                      </div>
                      <div
                        role="button"
                        id="graph-import-close"
                        data-bs-dismiss="modal"
                      >
                        {/* <img style={{ height: "25px", width: "29px" }} src={crossIcon} alt="cross" /> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* row for heading and cross icon end  */}
                <div className="modal-body padding-profile">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row margin-row">
                      <div className="form-group col-sm-12 col-md-6  col-lg-6 col-xl-6 mb-4">
                        <label className="w-100 label-field mb-2 font-poppins-medium">
                          Leave Type <span className="text-danger">*</span>
                          <input
                            value={formik.values.Leave_Name}
                            {...formik.getFieldProps("Leave_Name")}
                            onChange={formik.handleChange}
                            id="input-control"
                            type="text"
                            className="mt-2 form-control form-input-field"
                            autoComplete="off"
                            placeholder="Leave Type"
                          />
                        </label>
                        {formik.touched.Leave_Name &&
                          formik.errors.Leave_Name && (
                            <div>
                              <div className="text-alert font-poppins-medium">
                                <span role="alert" className="formErr">
                                  {formik.errors.Leave_Name}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="form-group col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                        <label className="w-100 label-field mb-2 font-poppins-medium">
                          Subject of Approval{" "}
                          <span className="text-danger">*</span>
                          <input
                            value={formik.values.Subject_Of_Approval}
                            {...formik.getFieldProps("Subject_Of_Approval")}
                            onChange={formik.handleChange}
                            id="input-control"
                            type="text"
                            className="mt-2 form-control form-input-field"
                            autoComplete="off"
                            placeholder="Subject"
                          />
                        </label>
                        {formik.values.Subject_Of_Approval == "" &&
                          formik.touched.Subject_Of_Approval &&
                          formik.errors.Subject_Of_Approval && (
                            <div>
                              <div className="text-alert font-poppins-medium">
                                <span role="alert" className="formErr">
                                  {formik.errors.Subject_Of_Approval}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div>
                      <div className="form-group col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              className="form-check-input input-checkbox"
                              type="checkbox"
                              // value="false"
                              id="flexCheckDefault"
                              checked={formik.values.Allow_Half_Leave}
                              {...formik.getFieldProps("Allow_Half_Leave")}
                            />
                            <span
                              className="font-poppins-regular"
                              id="remember_me_chkbox"
                            >
                              Full-day Leave
                            </span>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input input-checkbox"
                              type="checkbox"
                              // value="false"
                              id="flexCheckDefault"
                              checked={formik.values.Allow_Quarter_Leave}
                              {...formik.getFieldProps("Allow_Quarter_Leave")}
                            />
                            <span
                              className="font-poppins-regular"
                              id="remember_me_chkbox"
                            >
                              Half-day Leave
                            </span>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input input-checkbox"
                              type="checkbox"
                              // value="false"
                              id="flexCheckDefault"
                              checked={formik.values.Record_Without_Approval}
                              {...formik.getFieldProps(
                                "Record_Without_Approval"
                              )}
                            />
                            <span
                              className="font-poppins-regular"
                              id="remember_me_chkbox"
                            >
                              Quarter-day Leave
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-0 p-0">
                      <div className="d-flex justify-content-end f-manage">
                        <div className="d-flex flex-row row-manage">
                          <div className="d-flex">
                            <button className="btn buttonDefault font-poppins-medium">
                              {!loading && <span>Submit</span>}
                              {loading && (
                                <span
                                  className="indicator-progress font-poppins-regular"
                                  style={{ display: "block" }}
                                >
                                  Submit
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                          <div className="d-flex">
                            <button
                              type="button"
                              id="cancel-add-leave"
                              className="btn buttonDefault me-2 cancelbtnhover"
                              data-bs-dismiss="modal"
                              onClick={formik.resetForm}
                              style={{
                                background: "#8A899C",
                              }}
                            >
                              {" "}
                              Cancel
                            </button>
                            {/* <button
                              type="button"
                              id="cancel-add-leave"
                              className="btn text-white
                                                    cancel-leave-btn
                                                    font-poppins-medium"
                              data-bs-dismiss="modal"
                              onClick={formik.resetForm}
                            >
                              Cancel
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
