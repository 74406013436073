import React, { useEffect, useState } from "react";
import "../css/style.css";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import ReactPaginate from "react-paginate";
import ClockLoader from "react-spinners/ClockLoader";
import { CSVLink } from "react-csv";
import sorticon from "../Images/sorticon.svg";
import moment from "moment";
import view from "../Images/livedashboard/view.svg";
import { ToastContainer, toast } from "react-toastify";
import {
  disable_User,
  enable_User,
  getAdmins,
  checkUserPermissions,
  checkJWTToken,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

export default function Admin() {
  const navigate = useNavigate();
  let dispatch = useDispatch();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const UserToken = Authentication.Token;

  let [loading, setLoading] = useState(true);
  let hue = 0;
  let colors = [];
  for (let j = 0; j < 10; j++) {
    let color = "hsl(" + hue + ",80%,50%)";
    colors.push(color);
    hue += 500;
  }
  let [currentpage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(1);
  let [data, setData] = useState([]);
  let [status, setStatus] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  let [dataPerPage, setDataPerPage] = useState(10);
  let [statusValue, setStatusValue] = useState([]);

  let userObj = {};

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getUserPermission();
      getData();
    }
  }, []);

  const getData = (
    page = currentpage || 1,
    keys = "",
    check = true,
    search = searchKey,
    stat = status,
    pageSize = dataPerPage
  ) => {
    setLoading(true);
    let data = {
      tenantname: Tenant,
      key: keys,
      ascdsc: check,
      searchname: search,
      status: stat,
      pageNumber: page,
      pagesize: pageSize,
    };
    getAdmins(data)
      .then((res) => {
        setLoading(false);
        setData(res.data.Data);
        setNoOfPage(res.data.TotalPages);
        dataExport(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };
  const dataExport = (userData) => {
    let userArr = [];
    userData &&
      userData.forEach((res, i) => {
        userObj = {
          User_Name: res.Full_Name,
          Role: res.Role_Name,
          Team: res.Team_Name,
          Projects: res.ProjectCount,
          Last_Seen: res.Last_Seen,
        };
        userArr.push(userObj);
      });
    setCsvData(userArr);
  };

  // Pagination
  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getData(pageNumber, "", flag, "", status);
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      getData(currentpage, "", flag, "", status, dataPerPage);
    }
  };

  //Sorting
  let [flag, setflag] = useState(false);
  const onSortChange = (key, check) => {
    if (check) setflag(false);
    else setflag(true);
    getData(currentpage, key, flag, searchKey, status);
  };

  //Status

  const handleActive = (stat) => {
    var zeroCLass = document.getElementById("Zero_toggle");
    zeroCLass.classList.remove("active");
    getData(1, "", flag, searchKey, stat);
  };

  const handleAll = (stat) => {
    getData(1, "", flag, searchKey, "");
  };

  const handleInactive = (stat) => {
    var zeroCLass = document.getElementById("Zero_toggle");
    zeroCLass.classList.remove("active");
    getData(1, "", flag, searchKey, stat);
  };
  //Status

  //DisableUser
  const disableUser = (id) => {
    let data = {
      tenantname: Tenant,
      userid: id,
    };
    disable_User(data)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  //EnableUser
  const enableUser = (id) => {
    let data = {
      tenantname: Tenant,
      userid: id,
    };
    enable_User(data)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  //Start Search
  const inputChangeHandler = (e) => {
    if (e === "") {
      getData();
    } else {
      getData(1, "", flag, e);
    }
  };
  //End Search
  let [pagePermission, setPagePermission] = useState([]);

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Users") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };


  const handleToggle = (id,taskStatus) => {
    // setIsToggled(!isToggled);
    let data = {
      tenantname: Tenant,
      userid: id,
    };
    if(taskStatus){
      disable_User(data)
      .then((res) => {
          getData()
      })
      .catch((err) => {
        console.log(err, "error");
      });


    }
    else{
      enable_User(data)
      .then((res) => {
        getData();
      })
      .catch((err) => {
        console.log(err, "error");
      });


      
    }
  };
  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Employees" />
        <div className="contentSection mt-4 mb-4">
          <div className="row mb-3 d-flex justify-content-between align-items-center">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-2">
              <div className="sb-example-3 w-100">
                <div className="search__container">
                  <input
                    className="search-input-field"
                    type="text"
                    placeholder="Search Users..."
                    onKeyUp={(e) => {
                      setSearchKey(e.target.value);
                      inputChangeHandler(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-2 d-flex justify-content-center align-items-center">
                  <div className="btn-div-width w-100">
                    <Link to="/import-bulk-user" className="">
                      <button
                        type="button"
                        className={
                          data == null
                            ? "btn btn-lg exportBtn not-allowed team-select"
                            : "btn btn-lg exportBtn team-select"
                        }
                        style={{
                          borderRadius: "5px",
                          width: "100%",
                          borderColor: "#8a899c",
                          background: "#8a899c",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          color: "#FFF",
                          fontWeight: "500",
                          fontSize: "12px",
                          padding: "10px 20px",
                        }}
                      >
                        Import Employees
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-2 d-flex justify-content-center align-items-center">
                  <div className="btn-div-width w-100">
                    <button
                      type="button"
                      className={
                        data == null
                          ? "btn btn-lg exportBtn not-allowed team-select"
                          : "btn btn-lg exportBtn team-select"
                      }
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        borderColor: "#8a899c",
                        background: "#8a899c",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        color: "#FFF",
                        fontWeight: "500",
                        fontSize: "12px",
                        padding: "10px 20px",
                      }}
                      disabled={data == null}
                    >
                      <CSVLink
                        data={csvData}
                        className="csvEx"
                        filename={"user-data.csv"}
                      >
                        Export Employees
                      </CSVLink>
                    </button>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-2">
                  <div className="d-flex justify-content-center align-items-center">
                    {pagePermission.map((list) => {
                      if (list.Sub_Permission_Name === "create_user") {
                        return (
                          <>
                            <div className="btn-div-width w-100">
                              <Link
                                type="button"
                                className="btn btn-lg w-100 font-poppins-medium align-center"
                                style={{
                                  borderRadius: "5px",
                                  borderColor: "#6300B0",
                                  background: "#6300B0",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  color: "#FFF",
                                  fontWeight: "500",
                                  fontSize: "12px",
                                  padding: "10px 20px",
                                }}
                                to={{
                                  pathname: "/add-user",
                                }}
                              >
                                <span>Add New Employee</span>
                              </Link>
                            </div>
                          </>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-2">
              <div className="adminStatus d-flex align-items-center">
                <span className="st mr-2">Status</span>
                <div className="sb-example-3">
                  <div className="wrapper ">
                    <div className="toggle_radioStatus">
                      <input
                        type="radio"
                        onChange={() => {
                          setStatus(1);
                          handleAll(1);
                        }}
                        value="All"
                        className="toggle_option active"
                        id="Zero_toggle"
                        name="toggle_option"
                      ></input>

                      <input
                        type="radio"
                        onChange={() => {
                          setStatus(1);
                          handleActive(1);
                        }}
                        value="Active"
                        className="toggle_option"
                        id="first_toggle"
                        name="toggle_option"
                      ></input>
                      <input
                        type="radio"
                        onChange={() => {
                          setStatus(0);
                          handleInactive(0);
                        }}
                        value="Inactive"
                        className="toggle_option"
                        id="second_toggle"
                        name="toggle_option"
                      ></input>
                      <label htmlFor="Zero_toggle">
                        <p className="font-poppins-medium">All</p>
                      </label>
                      <label htmlFor="first_toggle">
                        <p className="font-poppins-medium">Active</p>
                      </label>
                      <label htmlFor="second_toggle">
                        <p className="font-poppins-medium">Inactive</p>
                      </label>
                      <div className="toggle_option_slider"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />
        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {data == null || data.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div className="table-responsive">
                  <table
                    className="table"
                    style={{
                      borderBottomWidth: "0px",
                      borderBottomColor: "none",
                    }}
                  >
                    <thead className="adminTable">
                      <tr>
                        <th className="tableHead ps-0 firstHead text-capitalize">
                          <span style={{ marginLeft: "20px" }}>
                            {" "}
                            User Name{" "}
                          </span>{" "}
                          &nbsp;
                          <button
                            onClick={() => {
                              onSortChange("Full_Name", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>
                        </th>
                        <th className="tableHead">
                          {" "}
                          Role{" "}
                          <button
                            onClick={() => {
                              onSortChange("Role_Name", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>{" "}
                        </th>
                        <th className="tableHead">
                          {" "}
                          Team{" "}
                          <button
                            onClick={() => {
                              onSortChange("Team_Name", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>{" "}
                        </th>
                        <th className="tableHead">
                          {" "}
                          Projects Count{" "}
                          <button
                            onClick={() => {
                              onSortChange("ProjectCount", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>{" "}
                        </th>
                        <th className="tableHead">
                          {" "}
                          Last Seen{" "}
                          <button
                            onClick={() => {
                              onSortChange("Last_Seen", flag);
                            }}
                            type="button"
                          >
                            <img src={sorticon} alt="icon" />
                          </button>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((res, i) => (
                        <tr
                          className=""
                          style={{ borderRadius: "30px" }}
                          key={i}
                        >
                          <td
                            className={
                              res.Status
                                ? "tableCell front-radius d-flex compName"
                                : "cellInactive front-radius d-flex compName"
                            }
                          >
                            <div className="mainDiv  d-flex">

                            <label class="switch">
                              <input type="checkbox"  checked={res.Status}    onChange={() => handleToggle(res.User_Id,res.Status)} />
                              <span class="slider round"></span>
                            </label>
                              <div
                                className="profileImg"
                                style={{
                                  backgroundColor:
                                    colors[
                                      Math.floor(Math.random() * colors.length)
                                    ],
                                }}
                              >
                                <p>{res.User_Name.toUpperCase().charAt(0)}</p>
                              </div>
                              <Link to={`/UserProfileView?id=${res.User_Id}`}>
                                {res.Full_Name}
                              </Link>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status
                                ? "tableCell"
                                : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>{res.Role_Name}</div>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status
                                ? "tableCell"
                                : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>{res.Team_Name}</div>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status
                                ? "tableCell"
                                : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              <div>{res.ProjectCount}</div>
                            </div>
                          </td>
                          <td
                            className={
                              res.Status
                                ? "tableCell"
                                : "cellInactive"
                            }
                          >
                            <div className="mainDiv">
                              {moment(res.Last_Seen).format("DD.MM.YYYY")}
                            </div>
                          </td>
                          <td
                            className={
                              res.Status
                                ? "tableCell back-radius"
                                : "cellInactive back-radius"
                            }
                          >
                            <div className="Maindiv d-flex justify-content-center align-items-center">
                              <div className="edit me-2" title="Update User">
                                {pagePermission.map((list) => {
                                  if (
                                    list.Sub_Permission_Name === "update_user"
                                  ) {
                                    return (
                                      <>
                                        <Link
                                          to={`/edit-user?id=${res.User_Id}`}
                                        >
                                          <img
                                            width={20}
                                            src={edit}
                                            alt="icon"
                                          />
                                        </Link>
                                      </>
                                    );
                                  }
                                })}
                              </div>
                              <div className="view" title="View User Profile">
                                <Link
                                  to={`/UserProfileView?id=${res.User_Id}`}
                                  className="drpdwnitem"
                                  style={{
                                    color: "#59A561 !important",
                                  }}
                                >
                                  <img
                                    width={22}
                                    
                                    src={view}
                                    alt="icon"
                                  />
                                </Link>
                              </div>
                            </div>
                          </td>
                          {/* <td
                            className={
                              res.Status
                                ? "tableCell back-radius"
                                : "cellInactive back-radius"
                            }
                          >
                            {pagePermission.map((list) => {
                              if (list.Sub_Permission_Name === "read_user") {
                                return (
                                  <div className="mainDiv text-center">
                                    <Dropdown>
                                      <Dropdown.Toggle as={CustomToggle} />
                                      <Dropdown.Menu size="sm" title="">
                                        {pagePermission.map((list) => {
                                          if (
                                            list.Sub_Permission_Name ===
                                            "update_user"
                                          ) {
                                            return (
                                              <>
                                                <Dropdown.Item
                                                  className="drpdwnitem"
                                                  style={
                                                    !res.Status
                                                      ? {
                                                          pointerEvents: "none",
                                                        }
                                                      : {}
                                                  }
                                                >
                                                  <Link
                                                    to={`/edit-user?id=${res.User_Id}`}
                                                  >
                                                    <div
                                                      className={
                                                        res.Status
                                                          ? "d-flex"
                                                          : "d-flex disabledIcn"
                                                      }
                                                    >
                                                      <img
                                                        src={edit}
                                                        alt="icon"
                                                      />
                                                      &nbsp; Edit
                                                    </div>
                                                  </Link>
                                                </Dropdown.Item>
                                              </>
                                            );
                                          }
                                        })}

                                        <Dropdown.Item
                                          className="drpdwnitem"
                                          style={{
                                            color: "#59A561",
                                            opacity: "0.9",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            fontFamily: "Poppins",
                                            fontStyle: "normal",
                                          }}
                                        >
                                          <div className="d-flex">
                                            <Link
                                              to={`/UserProfileView?id=${res.User_Id}`}
                                            >
                                              <div className="d-flex">
                                                <img src={profile} alt="icon" />
                                                <div>&nbsp; View</div>
                                              </div>
                                            </Link>
                                          </div>
                                        </Dropdown.Item>
                                        {pagePermission.map((list) => {
                                          if (
                                            list.Sub_Permission_Name ===
                                            "delete_user"
                                          ) {
                                            return (
                                              <>
                                                <Dropdown.Item className="drpdwnitem">
                                                  {!res.Status ? (
                                                    <div
                                                      className="d-flex"
                                                      id="1"
                                                      style={{ color: "blue" }}
                                                      onClick={() =>
                                                        enableUser(res.User_Id)
                                                      }
                                                    >
                                                      <img
                                                        src={delet}
                                                        alt="icon"
                                                      />
                                                      <div> &nbsp; Enable</div>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="d-flex"
                                                      style={{ color: "red" }}
                                                      id="2"
                                                      onClick={() =>
                                                        disableUser(res.User_Id)
                                                      }
                                                    >
                                                      <img
                                                        src={delet}
                                                        alt="icon"
                                                      />
                                                      <div> &nbsp; Disable</div>
                                                    </div>
                                                  )}
                                                </Dropdown.Item>
                                              </>
                                            );
                                          }
                                        })}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                );
                              }
                            })}
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}

        {(noOfPage === 1 && (!data || data.length <= 10)) || noOfPage === 0 ? (
          ""
        ) : (
          <div className="paginations d-flex justify-content-between align-items-center mt-2">
            <div className="d-flex justify-content-center align-items-center">
              <p className="lableRepo">Rows per page : </p>
              <input
                id="input-control"
                name="policy"
                type="text"
                className="form-control w-20 text-center team-select"
                autoComplete="off"
                style={{
                  borderRadius: "6px",
                  height: "30px",
                }}
                defaultValue={dataPerPage}
                onChange={(e) => setDataPerPage(e.target.value)}
                onKeyDown={testPage}
              />
            </div>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={noOfPage}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
    </section>
  );
}
