import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";
import "../css/style.css";
import ProfileIamge from "../Images/profileimage.png";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  clientList,
  createProjects,
  getAdminsByTeam,
  listTeams,
  getTeamsDropDown,
  getUsersDropDown,
} from "../authApi/AuthCRUD";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import Select from "react-select";
import { HuePicker } from "react-color";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const CreateProject = () => {
  let navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const [arrayData, setArrayData] = useState([]);
  const [arrayData1, setArrayData1] = useState([]);
  const [arrayData2, setArrayData2] = useState([]);
  const UserRole = localStorage.getItem("roleid");
  const [loading, setLoading] = useState(false);
  const Tenant = Authentication.Tenant;


  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
    colorPicker: {
      padding: "6px 50px",
    },
  };
  const Label = styled.label`
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  let newDate = setDate;
  const [Project_Name, setProjectname] = useState("");
  const [Start_Date, setStartdate] = useState("");
  const [End_Date, setEnddate] = useState("");
  const [Due_Date, setDuedate] = useState("");
  const [color, setColor] = useState("#37d67a");
  const [Priority, setPriority] = useState("");
  const [Description, setDescription] = useState("");
  const [member, setMember] = useState("");
  const [teams, setTeams] = useState("");
  const [clients, setClients] = useState("");
  const [Budget, setProjectbudget] = useState("");
  const [Currency, setCurrency] = useState("");
  const [projectkey, setProjectkey] = useState("");
  const [Billable, setCheckbox] = useState(false);
  // const baseURL = 'http://52.66.103.67:8088/admin/CreateProject';

  const [teamDropdown, setTeamDropdown] = useState([]);
  const [usersDropdown, setUsersDropdown] = useState([]);
  const [selectedTeamValue, setSelectedTeamValue] = useState([]);
  const [selectedUserValue, setSelectedUserValue] = useState([]);

  const handleTeamChange = (e) => {
    setSelectedTeamValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleUserChange = (e) => {
    setSelectedUserValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  
  useEffect(() => {
    let isMounted = true;
    clientList(Tenant, UserRole)
      .then((res) => {
        setArrayData(res.data.FirstList);
      })
      .catch((err) => {
        console.log(err);
      });

    listTeams(Tenant, UserRole)
      .then((res) => {
        setArrayData1(res.data.FirstList);
      })
      .catch((err) => {
        console.log(err);
      });

    getTeamsDropDown()
      .then((res) => {
        setTeamDropdown(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });

    getUsersDropDown()
      .then((res) => {
        setUsersDropdown(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  function TeamUsers(teams) {
    let data = {
      tenantname: Tenant,
      roleid: UserRole,
      teamid: teams,
    };
    getAdminsByTeam(data)
      .then((res) => {
        if (res.data.FirstList.User_Name != "") {
          setArrayData2(res.data.FirstList);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }

  const [value, setValue] = useState('');

  const Editorvalue = (value) => {
    setValue(value);
  }

  const [urlResponseMsg, setUrlResponseMsg] = useState(false);
  const createProjectSchema = Yup.object().shape({
    Project_Name: Yup.string().required("Project name is required"),
    Start_Date: Yup.string().required("Start Date is required"),
    End_Date: Yup.string().required("End Date is required"),
    Currency: Yup.string().required("Currency is required"),
  });
  //create project
  const formik = useFormik({
    initialValues: {
      Project_Name: "",
      Start_Date: "",
      End_Date: "",
      Status: true,
      Priority: "",
      Description: "",
      Budget: "",
      Currency: "",
      Billable: false,
      TenantName: Tenant,
      Color_Code: color,
      Client_Id: 1,
    },
    validationSchema: createProjectSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let data = {
        Project_Name: values.Project_Name,
        Start_Date: values.Start_Date,
        End_Date: values.End_Date,
        Status: values.Status,
        Priority: values.Priority,
        Description: value,
        Budget: values.Budget,
        Currency: values.Currency,
        Billable: values.Billable,
        TenantName: Tenant,
        Color_Code: color,
        Client_Id: 1,
        TeamIds: selectedTeamValue,
        Users: selectedUserValue
      };
      setLoading(true);
      setUrlResponseMsg(true);
      createProjects(data)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/projects-list");
          }, 2000);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
        });
    },
  });

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Create New Project" />

          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>

          <section className="addUsers p-2 p-md-4 p-lg-5">
            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="heading">Create New Project</div>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Project Name<span>*</span>
                      </Label>
                      <input
                        style={styles.select}
                        type="text"
                        {...formik.getFieldProps("Project_Name")}
                        className="form-control form-input-field"
                      />
                    </div>
                    {formik.touched.Project_Name &&
                      formik.errors.Project_Name && (
                        <div>
                          <div className="text-alert font-poppins-medium">
                            <span role="alert" className="formErr">
                              {formik.errors.Project_Name}
                            </span>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Start Date<span className="text-danger">*</span>
                      </Label>
                      <input
                        style={styles.select}
                        type="date"
                        max={endDate}
                        defaultValue={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                        {...formik.getFieldProps("Start_Date")}
                        className="form-control form-input-field"
                      />
                    </div>
                    {formik.touched.Start_Date && formik.errors.Start_Date && (
                      <div>
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formik.errors.Start_Date}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        End Date<span className="text-danger">*</span>
                      </Label>
                      <input
                        style={styles.select}
                        type="date"
                        min={date}
                        defaultValue={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                        {...formik.getFieldProps("End_Date")}
                        className="form-control form-input-field"
                      />
                    </div>
                    {formik.touched.End_Date && formik.errors.End_Date && (
                      <div>
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formik.errors.End_Date}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium color-img">
                        Color
                      </Label>
                      <div className="HueInput">
                        <HuePicker
                          color={color}
                          onChange={(color) => {
                            setColor(color.hex);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">Priority</Label>
                      <select
                        class="form-select form-input-field"
                        {...formik.getFieldProps("Priority")}
                      >
                        <option value="0">Please select priority</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Project Budget
                      </Label>
                      <input
                        style={styles.select}
                        placeholder='Project budget in numbers'
                        type="text"
                        {...formik.getFieldProps("Budget")}
                        className="form-control form-input-field"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Currency<span className="text-danger">*</span>
                      </Label>
                      <select
                        class="form-select form-input-field"
                        style={{ fontSize: "14px", color: "#fefdfc" }}
                        {...formik.getFieldProps("Currency")}
                      >
                        <option value="">Select Currency</option>
                        <option value="USD">
                                              US Dollar
                                            </option>
                                            <option value="EUR">Euro</option>
                                            <option value="INR">
                                              Indian Rupee
                                            </option>
                                            <option value="AUD">
                                            Australia Dollar
                                            </option>
                                            <option value="AUD">
                                              Australian Dollar
                                            </option>
                                            <option value="BHD">
                                              Bahraini Dinar
                                            </option>
                                            <option value="BDT">
                                              Bangladeshi Taka
                                            </option>
                                            <option value="BZD">
                                              Belize Dollar
                                            </option>
                      </select>
                    </div>
                    {formik.touched.Currency && formik.errors.Currency && (
                      <div>
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formik.errors.Currency}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Assign Project to Team
                      </Label>
                      <Select
                        className="dropdown team-select"
                        placeholder="Select team"
                        value={teamDropdown.filter((obj) =>
                          selectedTeamValue.includes(obj.value)
                        )} 
                        options={teamDropdown} // set list of the data
                        onChange={handleTeamChange} // assign onChange function
                        isMulti
                        isClearable
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Additional Assignee
                      </Label>
                      <Select
                        className="team-select "
                        placeholder="Select specific user"
                        value={usersDropdown.filter((obj) =>
                          selectedUserValue.includes(obj.value)
                        )} 
                        options={usersDropdown} // set list of the data
                        onChange={handleUserChange} // assign onChange function
                        isMulti
                        isClearable
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">Description</Label>
                      <input
                        style={styles.select}
                        type="text"
                        placeholder=""
                        {...formik.getFieldProps("Description")}
                        className="form-control form-input-field"
                      />
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Description</Label>
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={Editorvalue}
                      />
                    </div>
                  </div>
                </div>

                <div class="row m-0 p-0 mt-5">
                  <div class="d-flex justify-content-center align-items-center f-manage p-0">
                    <div class="d-flex flex-row row-manage">
                      <div class="d-flex">
                        <button class="btn  buttonDefault text-white font-poppins-medium">
                          {!loading && <span>Submit</span>}
                          {loading && (
                            <span
                              className="indicator-progress font-poppins-regular"
                              style={{ display: "block" }}
                            >
                              Submit
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div class="d-flex">
                        <Link
                          to="/projects-list"
                          className="text-decoration-none"
                        >
                          <button
                            type="button"
                            id="cancel-add-leave"
                            class="btn text-white  buttonDefault cancelbtnhover font-poppins-medium"
                            data-bs-dismiss="modal"
                            style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </React.Fragment>
  );
};

export default CreateProject;
