import React, { useState } from "react";
import "./SignUpNew.css";
import odyseyyLogo from "../Images/formImages/odyssey-logo.svg";
import odyseyySmLogo from "../Images/formImages/odyssey-sm-logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { updatePassword } from "../authApi/AuthCRUD";
import OTPInput from "otp-input-react";
import eyeSlashIcon from "../Images/formImages/eye-slash-icon.svg";
import eyeIcon from "../Images/formImages/eye-icon.svg";
import { ToastContainer, toast } from "react-toastify";

const resetPasswordSchema = Yup.object().shape({
  newpassword: Yup.string()
    .required("Password is required")
    .min(8, "Minimum 8 characters is required")
    .max(20, "Maximum 20 characters is required")
    .trim()
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(
      /[~`!.#%&*_+@?^${}()|[\]\\]/,
      "Password requires an one special case character"
    ),
  cpassword: Yup.string()
    .required("Password is required*")
    .min(8, "Minimum 8 characters is required")
    .max(20, "Maximum 20 characters is required*")
    .trim()
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(
      /[~`!.#%&*_+@?^${}()|[\]\\]/,
      "Password requires an one special case character"
    )
    .oneOf(
      [Yup.ref("newpassword"), null],
      "New password and confirm password must match"
    ),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const cId = new URLSearchParams(search).get("ACode");
  const tenantName = new URLSearchParams(search).get("Tenant");
  const [OTP, setOTP] = useState("");
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [tost, setTost] = useState(false);

  const formik = useFormik({
    initialValues: {
      newpassword: "",
      cpassword: "",
      //   otp: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let data = {
        codeid: cId,
        tenantname: tenantName.replaceAll(' ',''),
        newpassword: values.newpassword.replaceAll(' ',''),
        otp: OTP,
      };
      setLoading(true);
      updatePassword(data)
        .then((res) => {
          setLoading(false);
          setTost(true);
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/");
          }, 2000);
        })
        .catch((err) => {
          setLoading(false);
          // console.log(err);
        });
    },
  });
  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const [OTPs, setOTPs] = useState("");
  return (
    <>
      <div className="sign-up-wrapper d-none d-md-block d-lg-block d-xl-block">
        <div className="content">
          <div className="container">
            <div className="mb-7 d-flex justify-content-end text-white">
              <div className="d-flex align-items-center gap-22">
                <span className="font-poppins-regular account-head">
                  Remember your password?
                </span>
                <Link to="/" className="text-decoration-none">
                  <span className="text-white account-btn" role="button">
                    <span className="font-poppins-medium">Sign in</span>
                  </span>
                </Link>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-md-5 bg-left-section position-relative">
                <div className="position-absolute logo-center">
                  <img src={odyseyyLogo} alt="logo" />
                </div>
              </div>
              <div className="col-md-7 bg-right-form">
                <div className="row">
                  <div className="pt-pb-3">
                    <div className="text-center">
                      <h1 className="main-heading font-poppins-bold">
                        Reset Password
                      </h1>
                      <span className="sub-heading font-poppins-regular">
                        Please reset your password
                      </span>
                    </div>

                    <div>
                      <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <div className="row">
                          <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                            <label className="w-100  label-field   font-poppins-medium">
                              New Password*
                              <input
                                id="input-password-control"
                                type={newPasswordShown ? "text" : "password"}
                                className="mt-1 form-control input-field font-poppins-regular"
                                autoComplete="off"
                                placeholder="Password"
                                {...formik.getFieldProps("newpassword")}
                                onChange={formik.handleChange}
                              />
                              {newPasswordShown ? (
                                <i
                                  onClick={toggleNewPasswordVisiblity}
                                  className="pe-auto"
                                  style={{cursor: 'pointer'}}
                                >
                                  <img
                                    className="eye-icon"
                                    src={eyeIcon}
                                    alt="eyeicon"
                                    style={{cursor: 'pointer'}}
                                  />
                                </i>
                              ) : (
                                <i
                                  onClick={toggleNewPasswordVisiblity}
                                  className="pe-auto"
                                  style={{cursor: 'pointer'}}
                                >
                                  <img
                                    className="eye-icon"
                                    src={eyeSlashIcon}
                                    alt="eyeicon"
                                    style={{cursor: 'pointer'}}
                                  />
                                </i>
                              )}
                              {formik.touched.newpassword &&
                                formik.errors.newpassword && (
                                  <div>
                                    <div className="text-alert font-poppins-medium">
                                      <span role="alert" className="formErr">
                                        {formik.errors.newpassword}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                            <label className="w-100  label-field  font-poppins-medium">
                              Confirm Password*
                              <input
                                id="input-cpwd-control"
                                type={
                                  confirmPasswordShown ? "text" : "password"
                                }
                                className="mt-1 form-control input-field font-poppins-regular"
                                autoComplete="off"
                                placeholder="Enter Confirm Passwword"
                                {...formik.getFieldProps("cpassword")}
                                onChange={formik.handleChange}
                              />
                              {confirmPasswordShown ? (
                                <i
                                  onClick={toggleConfirmPasswordVisiblity}
                                  className="pe-auto"
                                  style={{cursor: 'pointer'}}
                                >
                                  <img
                                    className="eye-icon"
                                    src={eyeIcon}
                                    alt="eyeicon"
                                    style={{cursor: 'pointer'}}
                                  />
                                </i>
                              ) : (
                                <i
                                  onClick={toggleConfirmPasswordVisiblity}
                                  className="pe-auto"
                                  style={{cursor: 'pointer'}}
                                >
                                  <img
                                    className="eye-icon"
                                    src={eyeSlashIcon}
                                    alt="eyeicon"
                                    style={{cursor: 'pointer'}}
                                  />
                                </i>
                              )}
                              {formik.touched.cpassword &&
                                formik.errors.cpassword && (
                                  <div>
                                    <div className="text-alert font-poppins-medium">
                                      <span role="alert" className="formErr">
                                        {formik.errors.cpassword}
                                      </span>
                                    </div>
                                  </div>
                                )}
                            </label>
                          </div>
                        </div>

                        <div>
                          <label className="w-100 label-field font-poppins-medium">
                            Enter OTP*
                            <OTPInput
                              value={OTP}
                              name="otp"
                              onChange={setOTP}
                              autoFocus
                              OTPLength={4}
                              otpType="number"
                              disabled={false}
                              className="mt-1 otp-input"
                            />
                          </label>
                        </div>

                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 d-flex justify-content-center">
                            <button
                              type="submit"
                              className="btn mt-5 submit-btn font-poppins-regular"
                            >
                              {!loading && <span>Reset Password</span>}
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Reset Password
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-md-none d-lg-none d-xl-done">
        <div className="row m-0 p-0">
          <div className="col-12 sm-screen-bg">
            <div className="m-4 mt-5 d-flex justify-content-center align-items-center">
              <span className="font-poppins-regular account-sm-head">
                Remember your password?
              </span>
              &nbsp;&nbsp;
              <Link to="/" className="text-decoration-none">
                <span className="text-white account-sm-btn">
                  <span className="font-poppins-medium">Sign in</span>
                </span>
              </Link>
            </div>

            <div className="m-5 d-flex justify-content-center">
              <img src={odyseyySmLogo} alt="logo" />
            </div>
          </div>

          <div className="row p-0 m-0">
            <div className="col-12 bg-white">
              <div className="mt-5 mb-5 mx-1">
                <div className="text-center">
                  <h1 className="main-sm-heading font-poppins-bold">
                    Reset Password
                  </h1>
                  <span className="sub-heading font-poppins-regular">
                    Please reset your password
                  </span>
                </div>

                <div>
                  <form className="mt-5" onSubmit={formik.handleSubmit}>
                    <div className="row m-0">
                      <div className="form-group col-sm-12">
                        <label className="w-100 mb-3 label-sm-field font-poppins-medium">
                          Password*
                          <input
                            id="input-password-control"
                            type={newPasswordShown ? "text" : "password"}
                            className="mt-1 form-control input-sm-field font-poppins-regular"
                            autoComplete="off"
                            placeholder="Password"
                            {...formik.getFieldProps("newpassword")}
                            onChange={formik.handleChange}
                          />
                          {newPasswordShown ? (
                            <i
                              onClick={toggleNewPasswordVisiblity}
                              className="pe-auto"

                            >
                              <img
                                className="eye-sm-icon"
                                src={eyeIcon}
                                alt="eyeicon"
                              />
                            </i>
                          ) : (
                            <i
                              onClick={toggleNewPasswordVisiblity}
                              className="pe-auto"
                            >
                              <img
                                className="eye-sm-icon"
                                src={eyeSlashIcon}
                                alt="eyeicon"
                              />
                            </i>
                          )}
                          {formik.touched.newpassword &&
                            formik.errors.newpassword && (
                              <div>
                                <div className="text-alert font-poppins-medium">
                                  <span role="alert" className="formErr">
                                    {formik.errors.newpassword}
                                  </span>
                                </div>
                              </div>
                            )}
                        </label>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="form-group col-sm-12">
                        <label className="w-100 mb-3 label-sm-field font-poppins-medium">
                          Confirm Password*
                          <input
                            id="input-pwd-control"
                            type={confirmPasswordShown ? "text" : "password"}
                            className="mt-1 form-control input-sm-field font-poppins-regular"
                            autoComplete="off"
                            placeholder="Confirm Password"
                            {...formik.getFieldProps("cpassword")}
                            onChange={formik.handleChange}
                          />
                          {confirmPasswordShown ? (
                            <i
                              onClick={toggleConfirmPasswordVisiblity}
                              className="pe-auto"
                            >
                              <img
                                className="eye-sm-icon"
                                src={eyeIcon}
                                alt="eyeicon"
                              />
                            </i>
                          ) : (
                            <i
                              onClick={toggleConfirmPasswordVisiblity}
                              className="pe-auto"
                            >
                              <img
                                className="eye-sm-icon"
                                src={eyeSlashIcon}
                                alt="eyeicon"
                              />
                            </i>
                          )}
                          {formik.touched.cpassword &&
                            formik.errors.cpassword && (
                              <div>
                                <div className="text-alert font-poppins-medium">
                                  <span role="alert" className="formErr">
                                    {formik.errors.cpassword}
                                  </span>
                                </div>
                              </div>
                            )}
                        </label>
                      </div>
                    </div>

                    <div className="row m-0">
                      <label className="w-100 mb-3 label-sm-field font-poppins-medium">
                        Enter OTP*
                        <OTPInput
                          value={OTP}
                          name="otp"
                          onChange={setOTP}
                          autoFocus
                          OTPLength={4}
                          otpType="number"
                          disabled={false}
                          inputStyle="inputStyle"
                          separator={<span></span>}
                          className="mt-1 otp-input"
                        />
                      </label>
                    </div>


                    <div className="row">
                      <div className="col-sm-12 mt-2 d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn mt-5 submit-sm-btn font-poppins-regular"
                          disabled={loading}
                        >
                          {!loading && <span>Reset Password</span>}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Reset Password
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tost && <ToastContainer />}
    </>
  );
}
