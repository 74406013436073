import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import styled from "styled-components";
import "../css/style.css";
import ProfileIamge from "../Images/profileimage.png";
import { useNavigate, Link } from "react-router-dom";
import uploadLogo from "../Images/Upload.svg";
import moment from "moment";
import {
  getCompanySetting,
  updateOrgShift,
  postOrgShit,
  getOrgShitById,
  getOrgShit,
  setCompanyLogo,
  setCompanySetting,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import ClockLoader from "react-spinners/ClockLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../Images/edit.svg";
import tick from "../Images/profile.svg";
import crossIcon from "../Images/livedashboard/cross-icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Settings() {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const navigate = useNavigate();
  const Tenant = Authentication.Tenant;
  const companyEmail = Authentication.Email;
  let [attendance, setAttandance] = useState("");
  const [imageSizeErr, setImageSizeErr] = useState("");
  const [file, setFile] = useState({
    uploaded_image: "",
    preview_image: "",
  });
  let [idleTime, setIdleTime] = useState();
  const [loading, setLoading] = useState(false);
  const [pageloading, setPageLoading] = useState(false);
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);
  let [ssFreq, setSsFreq] = useState();
  const [formvalues, setFormvalues] = useState({
    company_name: "",
    company_subDomain: "",
  });
  let image = file.preview_image;
  const logoUpload = (selectedFile) => {
    if (selectedFile) {
      const data = selectedFile[0];
      if (!data.name.match(/\.(jpg|png|jpeg|gif)$/)) {
        setImageSizeErr("File must be one of jpg,png,jpeg,gif");
        setFile({
          uploaded_image: "",
          preview_image: "",
        });
      } else if (2 * 1024 * 1024 < data.size) {
        setImageSizeErr("File must be of size  2MB only ");
        setFile({
          uploaded_image: "",
          preview_image: "",
        });
      } else {
        setImageSizeErr("");
        const file = selectedFile[0];
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.readyState === 2) {
            setFile({
              uploaded_image: selectedFile[0],
              preview_image: reader.result,
            });
          }
        };
        reader.readAsDataURL(file);
      }
    }
    document.getElementById("bdrImg").classList.add("bdr");
  };

  const removeLogo = () => {
    setFile({ preview_image: "" });
    document.getElementById("bdrImg").classList.remove("bdr");
  };

  useEffect(() => {
    getUserPermission();
    getShifts();

    let data = {
      tenantname: Tenant,
      companyemail: companyEmail,
    };
    setPageLoading(true);
    getCompanySetting(data)
      .then((res) => {
        setPageLoading(false);
        setFormvalues({
          ...formvalues,
          company_name: res.data.Data.Company_Name,
          company_subDomain: res.data.Data.Tenant_Url,
        });
        setAttandance(res.data.Data.Attendance_threshold);
        setIdleTime(res.data.Data.Idle);
        setSsFreq(res.data.Data.ScreenShot_Frequency);
        setFile({ ...file, preview_image: res.data.Data.logo });
      })
      .catch((err) => {
        setPageLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  }, []);

  const saveSetting = (e) => {
    const data = new FormData();
    data.append("Tenant_Name", Tenant);
    data.append("logo", file.uploaded_image);
    const formData = {
      Tenant_Name: Tenant,
      Company_Email: companyEmail,
      Idle: idleTime,
      Company_Name: formvalues.company_name,
      Attendance_threshold: attendance,
      ScreenShot_Frequency: ssFreq,
    };
    setLoading(true);
    setUrlResponseMsg(true);
    setCompanySetting(formData)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.Message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });

    setCompanyLogo(data)
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalues({ ...formvalues, [name]: value });
  };

  let [pagePermission, setPagePermission] = useState([]);

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Company_Settings") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  let [check, setcheck] = useState();

  let [shiftData, setShiftData] = useState([]);
  let [shiftName, setShiftName] = useState("");
  let [shiftStartTime, setshiftStartTime] = useState("");
  let [shiftEndTime, setshiftEndTime] = useState("");
  let [shiftWorkingHours, setshiftWorkingHours] = useState("");
  const [isInputActive, setIsInputActive] = useState(false);
  const [saveCancelBtn, setSaveCancelBtn] = useState(false);
  const [shiftId, setshiftId] = useState(0);

  const getShifts = () => {
    getOrgShit()
      .then((res) => {
        setShiftData(res.data.Data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const [formerrors, setFormErrors] = useState({
    Shift_Name: "",
    Shift_WorkingHours: "",
    Shift_StartTime: "",
    Shift_EndTime: "",
  });

  const handleEdit = (id) => {
    setshiftEndTime("");
    setshiftStartTime("");
    setshiftId(id);
    setshiftWorkingHours("");
    getOrgShitById(id).then((res) => {
      setShiftName(res.data.Data.Shift_Name);
      setshiftEndTime(
        moment(res.data.Data.Shift_EndTime, ["h:mm A"]).format("HH:mm")
      );
      setshiftStartTime(
        moment(res.data.Data.Shift_StartTime, ["h:mm A"]).format("HH:mm")
      );
      setshiftWorkingHours(
        moment(res.data.Data.Shift_WorkingHours, ["h:mm A"]).format("HH:mm")
      );
    });
    setIsInputActive(true);
    setSaveCancelBtn(true);
  };

  const validate = (values) => {
    const errors = {};
    let regexTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    if (!regexTime.test(values.Shift_WorkingHours)) {
      errors.Shift_WorkingHours = "Time should be in hh:mm";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Shift_WorkingHours: "",
      });
      return true;
    }
  };

  const handleChangeName = (id) => {
    let data = {
      Id: id,
      Shift_Name: shiftName,
      Shift_WorkingHours: shiftWorkingHours,
      Shift_StartTime: moment(shiftStartTime, "hh:mm").format("LT"),
      Shift_EndTime: moment(shiftEndTime, "hh:mm").format("LT"),
    };
    setUrlResponseMsg(true);
    console.log(data);
    if (validate(data)) {
      updateOrgShift(data)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
            setIsInputActive(false);
            setSaveCancelBtn(false);
            getShifts();
          }, 2000);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCancelChangeName = () => {
    setIsInputActive(false);
    setSaveCancelBtn(false);
  };
  // -------------------------

  //Add New Shift
  const leaveSchema = Yup.object().shape({
    Shift_Name: Yup.string().required("Shift Name is required*"),
    Shift_StartTime: Yup.string().required("CLock-in time is required*"),
    Shift_EndTime: Yup.string().required("Clock-out time is required*"),
    Shift_WorkingHours: Yup.string().required("Working hours is required*").matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Time should be in hh:mm')
  });
  const formik = useFormik({
    initialValues: {
      Shift_Name: "",
      Shift_WorkingHours: "",
      Shift_StartTime: "",
      Shift_EndTime: "",
    },
    validationSchema: leaveSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let data = {
        Shift_Name: values.Shift_Name,
        Shift_WorkingHours: values.Shift_WorkingHours,
        Shift_StartTime: moment(values.Shift_StartTime, "hh:mm").format("LT"),
        Shift_EndTime: moment(values.Shift_EndTime, "hh:mm").format("LT"),
      };
      setLoading(true);
      setUrlResponseMsg(true);

      console.log(data);

      postOrgShit(data)
        .then((res) => {
          setLoading(false);
          document.getElementById("cancel-add-leave").click();
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          getShifts();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    },
  });


  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Settings" />
        {pageloading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            <div className="settingBox">
              <div className="setting  mt-3 mb-3 ">
                <div className="row">
                  <div className="col-lg-10 col-md-9 col-sm-4 col-xs-4 settingHeader">
                    <h4 className="heading">General Information</h4>
                  </div>
                  <div className="col-lg-2 col-md-3 col-sm-12  col-xs-12">
                    <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end me-auto mt-2 mt-md-0 mt-lg-0">
                      <button
                        type="button"
                        className="btn btn-lg w-100 font-poppins-medium align-center"
                          style={{
                            borderRadius: "5px",
                            borderColor: "#6300B0",
                            background: "#6300B0",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            color: "#FFF",
                            fontWeight: "500",
                            fontSize: "12px",
                            padding: "10px 20px",
                          }}


                        onClick={saveSetting}
                        disabled={loading}
                      >
                        {!loading && <span>Save</span>}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Save
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="inputFileds mt-3">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-3">
                        <label className="control-label label col-md-12 lbl">
                          Comapny Name*
                        </label>
                        <input
                          id="Comapny Name"
                          name="company_name"
                          onChange={handleChange}
                          placeholder="Comapny Name"
                          value={formvalues.company_name}
                          type="text"
                          readOnly
                          style={{ cursor: "no-drop" }}
                          className="form-control bdr-radius team-select"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="control-label label col-md-12 lbl">
                          Domain*
                        </label>
                        <input
                          id="subdomain"
                          name="company_subDomain"
                          onChange={handleChange}
                          placeholder="Your branded login page subdomain"
                          type="text"
                          value={formvalues.company_subDomain}
                          style={{ cursor: "no-drop" }}
                          className="form-control bdr-radius team-select"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="avatar-upload" id="bdrImg">
                        <div className="avatar-edit">
                          <input
                            type="file"
                            id="imageUpload"
                            onChange={(e) => logoUpload(e.target.files)}
                            accept=".png, .jpg, .jpeg"
                          />
                          <label htmlFor="imageUpload"></label>
                        </div>
                        <div className="avatar-preview">
                          {file.preview_image &&
                          file.preview_image !== "null" ? (
                            <img id="imagePreview" src={image} />
                          ) : (
                            <>
                              <div className="container d-flex justify-content-center text-align-center">
                                <div style={{ padding: "2rem 0" }}>
                                  <div className="text-align-center">
                                    <div className="d-flex justify-content-center">
                                      <img src={uploadLogo} alt="upload" />
                                    </div>
                                    <h4 className="upload-logo-import">
                                      Update Logo
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {imageSizeErr ? (
                        <>
                          <div>
                            <p style={{ color: "red" }} className="text-danger">
                              {imageSizeErr}
                            </p>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {file.preview_image && file.preview_image !== null ? (
                        <div className="del_avtar" onClick={removeLogo}>
                          x
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-2"></div>
                  </div>
                </div>
              </div>

              <hr />

              <section className="workingTimeShift mt-3 mb-3">
                <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-12">
                    <div className="heading"> Shift</div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                    <div className="d-flex justify-content-lg-end justify-content-md-end justify-content-center mt-2 mt-md-0 mt-lg-0">
                      <div className="btn-div-width">
                          <button
                            id="leave-button"
                            data-bs-toggle="modal"
                            data-bs-target="#AddNewShift"
                            style={{
                              width: '100%',
                            borderRadius: "5px",
                            borderColor: "#6300B0",
                            background: "#6300B0",
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            color: "#FFF",
                            fontWeight: "500",
                            fontSize: "12px",
                            padding: "10px 20px",
                          }}
                          >
                            Create New Shift
                          </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="linkItem">
                  {!shiftData || shiftData.length === 0 ? (
                    <>
                      <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                        No Result Available
                      </h5>
                    </>
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table
                          className="table projects"
                          style={{
                            borderBottomWidth: "0px",
                            borderBottomColor: "none",
                          }}
                        >
                          <thead>
                            <tr>
                            <th className="tableHead ps-2">Shift Id</th>
                              <th className="tableHead ">Shift Name</th>
                              <th className="tableHead">
                                Clock-in Time
                              </th>
                              <th className="tableHead">
                                Clock-out Time
                              </th>
                              <th className="tableHead">
                                Working Hours
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {shiftData.map((res, i) => {
                              return (
                                <tr>
                                <td
                                    className="tableCell front-radius"
                                  >
                                    <div className="mainDiv">
                                      {res.Id}
                                    </div>
                                  </td>
                                  <td className="tableCell">
                                    <div className="Maindiv d-flex compName">
                                      {isInputActive && shiftId == res.Id ? (
                                        <>
                                          <form>
                                            <div className="d-flex align-items-center">
                                              <input
                                                id={`shiftName${res.Id}`}
                                                name="policy"
                                                type="text"
                                                className="form-control team-select"
                                                autoComplete="off"
                                                style={{
                                                  borderRadius: "6px",
                                                  height: "33px",
                                                  width: "120px",
                                                }}
                                                defaultValue={res.Shift_Name}
                                                onChange={(e) =>
                                                  setShiftName(e.target.value)
                                                }
                                              />
                                            </div>
                                          </form>
                                        </>
                                      ) : (
                                        <>{res.Shift_Name}</>
                                      )}
                                    </div>
                                  </td>
                                  <td className="tableCell">
                                    {isInputActive && shiftId == res.Id ? (
                                      <>
                                        <form>
                                          <div className="d-flex align-items-center">
                                            <input
                                              id={`shiftName${res.Id}`}
                                              name="policy"
                                              type="time"
                                              className="form-control team-select "
                                              autoComplete="off"
                                              style={{
                                                borderRadius: "6px",
                                                height: "33px",
                                              }}
                                              defaultValue={shiftStartTime}
                                              // defaultValue='18:09'
                                              onChange={(e) =>
                                                setshiftStartTime(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </form>
                                      </>
                                    ) : (
                                      <>{res.Shift_StartTime}</>
                                    )}
                                  </td>
                                  <td className="tableCell">
                                    {isInputActive && shiftId == res.Id ? (
                                      <>
                                        <form>
                                          <div className="d-flex align-items-center">
                                            <input
                                              id={`shiftName${res.Id}`}
                                              name="policy"
                                              type="time"
                                              className="form-control team-select"
                                              autoComplete="off"
                                              style={{
                                                borderRadius: "6px",
                                                height: "33px",
                                              }}
                                              defaultValue={shiftEndTime}
                                              onChange={(e) =>
                                                setshiftEndTime(e.target.value)
                                              }
                                            />
                                          </div>
                                        </form>
                                      </>
                                    ) : (
                                      <>{res.Shift_EndTime}</>
                                    )}
                                  </td>
                                  <td className="tableCell">
                                    {isInputActive && shiftId == res.Id ? (
                                      <>
                                        <form>
                                          <div className="d-flex  align-items-center">
                                            <input
                                              id={`shiftWorkingHours${res.Id}`}
                                              name="policy"
                                              type="text"
                                              className="form-control team-select settingWorkingTime"
                                              autoComplete="off"
                                              style={{
                                                borderRadius: "6px",
                                                height: "33px",
                                                width: "120px",
                                              }}
                                              defaultValue={
                                                res.Shift_WorkingHours
                                              }
                                              onChange={(e) =>
                                                setshiftWorkingHours(
                                                  e.target.value
                                                )
                                              }
                                              // onChange={(e) =>
                                              //   setshiftWorkingHours(
                                              //     e.target.value
                                              //   )
                                              // }
                                            />
                                          </div>
                                          <div className="text-alert font-poppins-medium">
                                            <span
                                              role="alert"
                                              className="formErr"
                                            >
                                              {formerrors.Shift_WorkingHours}
                                            </span>
                                          </div>
                                        </form>
                                      </>
                                    ) : (
                                      <>{res.Shift_WorkingHours}</>
                                    )}
                                  </td>
                                  <td className="tableCell back-radius">
                                    <div className="mainDiv">
                                      {saveCancelBtn && shiftId == res.Id ? (
                                        <>
                                          <div className="btns d-flex align-items-center">
                                            <img
                                              src={tick}
                                              alt="tick-icon"
                                              className="tick-icon-height"
                                              onClick={() =>
                                                handleChangeName(res.Id)
                                              }
                                            />
                                            &nbsp;
                                            <div
                                              className="cross-policy-name"
                                              role="button"
                                            >
                                              <img
                                                src={crossIcon}
                                                alt="tick-icon"
                                                style={{
                                                  height: "18px",
                                                }}
                                                onClick={handleCancelChangeName}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <button
                                          className=""
                                          onClick={() => {
                                            handleEdit(res.Id);
                                          }}
                                        >
                                          <img
                                            src={edit}
                                            alt="icon"
                                            width={20}
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </section>

              <hr />

              {pagePermission.length == 2 ? (
                <>
                  <div className="setting  mt-3 mb-3 ">
                    <div className="heading">
                      Default account settings for new users
                    </div>
                    <p className="formlabel">Idle Monitoring</p>

                    <div className="linkItem">
                      <div className="slider-box">
                        <div>
                          <div className="label lbltxt">
                            Idle Timeout: {idleTime} minutes
                          </div>
                          <Box>
                            <Slider
                              key={`slider-${idleTime}`}
                              defaultValue={idleTime}
                              max={20}
                              onChange={(e) => setIdleTime(e.target.value)}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        </div>

                        <div>
                          <div className="label lbltxt">
                            Screenshot Frequency : {ssFreq} screenshots per hour
                          </div>
                          <Box>
                            <Slider
                              key={`slider-${ssFreq}`}
                              defaultValue={ssFreq}
                              max={60}
                              onChange={(e) => setSsFreq(e.target.value)}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="setting px-0 px-md-3 px-lg-4 mt-3 mb-3 ">
                    <div className="heading">
                      Default account settings for new users
                    </div>
                    <p className="formlabel">Idle Monitoring</p>

                    <div
                      className="linkItem bann"
                      title="You dont have permission to update the settings"
                    >
                      <div className="slider-box">
                        <div>
                          <div className="label lbltxt">
                            Idle Timeout: {idleTime} minutes
                          </div>
                          <Box>
                            <Slider
                              className="newslider"
                              key={`slider-${idleTime}`}
                              defaultValue={idleTime}
                              max={20}
                              onChange={(e) => setIdleTime(e.target.value)}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              disabled
                            />
                          </Box>
                        </div>

                        <div>
                          <div className="label lbltxt">
                            ScreenShot Frequency : {ssFreq} screenshots per hour
                          </div>
                          <Box>
                            <Slider
                              className="newslider"
                              key={`slider-${ssFreq}`}
                              defaultValue={ssFreq}
                              max={60}
                              onChange={(e) => setSsFreq(e.target.value)}
                              aria-label="Default"
                              valueLabelDisplay="auto"
                              disabled
                            />
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        {/* //Add New Shift Modal */}
        <div
          className="modal fade"
          id="AddNewShift"
          data-bs-backdrop="static"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddNewShift"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div
              id="modal-center"
              className="modal-content"
              style={{ padding: "22px 32px" }}
            >
              {/* row for heading and cross icon */}
              <div className="row">
                <div className="col-12">
                  <div className="mx-3  mt-3 mb-3  d-flex justify-content-between">
                    <div className="d-flex">
                      <h4 className="font-poppins-bold profile-details-heading">
                        Add New Shift
                      </h4>
                    </div>
                    <div
                      role="button"
                      id="graph-import-close"
                      data-bs-dismiss="modal"
                    >
                      {/* <img style={{ height: "25px", width: "29px" }} src={crossIcon} alt="cross" /> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* row for heading and cross icon end  */}
              <div className="modal-body padding-profile">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-1 ">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Shift Name <span className="text-danger">*</span>
                        <input
                          value={formik.values.Shift_Name}
                          {...formik.getFieldProps("Shift_Name")}
                          onChange={formik.handleChange}
                          id="input-control"
                          type="text"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                          placeholder="Shift Name"
                        />
                      </label>
                      {formik.values.Shift_Name == "" &&
                        formik.touched.Shift_Name &&
                        formik.errors.Shift_Name && (
                          <div>
                            <div className="text-alert font-poppins-medium">
                              <span role="alert" className="formErr">
                                {formik.errors.Shift_Name}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-1">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Total Working Hours <span className="text-danger">*</span>
                        <input
                          type="text"
                          {...formik.getFieldProps("Shift_WorkingHours")}
                          className="form-control form-input-field mt-2"
                          placeholder="Time in hh:mm"
                        />
                      </label>
                      <div>
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formik.errors.Shift_WorkingHours}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-1">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Clock-in Time(in hh:mm AM/PM){" "}
                        <span className="text-danger">*</span>
                        <input
                          value={formik.values.Shift_StartTime}
                          {...formik.getFieldProps("Shift_StartTime")}
                          onChange={formik.handleChange}
                          id="input-control"
                          type="time"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                        />
                      </label>
                      {formik.values.Shift_StartTime == "" &&
                        formik.touched.Shift_StartTime &&
                        formik.errors.Shift_StartTime && (
                          <div>
                            <div className="text-alert font-poppins-medium">
                              <span role="alert" className="formErr">
                                {formik.errors.Shift_StartTime}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-1">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Clock-out Time(in hh:mm AM/PM){" "}
                        <span className="text-danger">*</span>
                        <input
                          value={formik.values.Shift_EndTime}
                          {...formik.getFieldProps("Shift_EndTime")}
                          onChange={formik.handleChange}
                          id="input-control"
                          type="time"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                        />
                      </label>
                      {formik.values.Shift_EndTime == "" &&
                        formik.touched.Shift_EndTime &&
                        formik.errors.Shift_EndTime && (
                          <div>
                            <div className="text-alert font-poppins-medium">
                              <span role="alert" className="formErr">
                                {formik.errors.Shift_EndTime}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="row m-0 p-0">
                    <div className="d-flex justify-content-end f-manage mt-4">
                      <div className="d-flex flex-row row-manage">
                        <div className="d-flex">
                          <button className="btn save-btn text-white font-poppins-medium">
                            {!loading && <span>Submit</span>}
                            {loading && (
                              <span
                                className="indicator-progress font-poppins-regular"
                                style={{ display: "block" }}
                              >
                                Submit
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                        <div className="d-flex">
                          <button
                            type="button"
                            id="cancel-add-leave"
                            className="btn text-white
                                                    cancel-leave-btn
                                                    font-poppins-medium"
                            data-bs-dismiss="modal"
                            onClick={formik.resetForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </section>
  );
}
