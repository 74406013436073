import React, { useEffect, useState } from "react";
import "../css/style.css";
import "./reports.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getDailyAttendance,
  getAllUserForReport,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import ClockLoader from "react-spinners/ClockLoader";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DailyAttendance() {
  const navigate = useNavigate();
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  let newDate = moment(today).format("MM-DD-YYYY")
  const [endDate, setEndDate] = useState(today);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const loggedInUserId = Authentication.User_Id;
  const loggedInUserName = Authentication.Full_Name;
  let hue = 0;
  let colors = [];
  for (let j = 0; j < 10; j++) {
    let color = "hsl(" + hue + ",80%,50%)";
    colors.push(color);
    hue += 500;
  }
  let [userData, setUserData] = useState();
  let [loading, setLoading] = useState(true);
  const [user_Id, setUser_Id] = useState(loggedInUserId);
  const [empList, setEmpList] = useState([]);
  const [dateAr, setDateAr] = useState([]);
  const [permissionName, setPermissionName] = useState("");
  let [pagePermission, setPagePermission] = useState([]);
  const [tokenExpirationTime, setTokenExpirationTime] = useState(null);

  useEffect(() => {
    getUserPermission();
    emplist();
    getAttandance(UserRole == "2" ? loggedInUserId : user_Id, endDate);
  }, []);

  let [dataPerPage, setDataPerPage] = useState(10);
  const [noOfPage, setNoOfPage] = useState(1);
  let [currentpage, setCurrentPage] = useState(1);

  console.log('outer end date', endDate);

  
  const getAttandance = (
    userId,
    end_date,
    currentpage,
    pagesize = dataPerPage
  ) => {

    let checkArr = [];
    let startDate = new Date(end_date);
    startDate.setDate(startDate.getDate() - 6);
    let dt = String(startDate.getDate()).padStart(2, "0");
    let mn = String(startDate.getMonth() + 1).padStart(2, "0");
    let yr = startDate.getFullYear();
    startDate = yr + "-" + mn + "-" + dt;
    checkArr.push(moment(end_date).format("MM-DD-YYYY"));
    function dateByWeek(dateselcted = end_date) {
      let startDate2;
      let dt;
      let mn;
      let yr;
      for (let i = 0; i < 1; i++) {
        startDate2 = new Date(dateselcted);
        startDate2.setDate(startDate2.getDate() - 1);
        dt = String(startDate2.getDate()).padStart(2, "0");
        mn = String(startDate2.getMonth() + 1).padStart(2, "0");
        yr = startDate2.getFullYear();
        startDate2 = yr + "-" + mn + "-" + dt;
        let formated = mn + "-" + dt + "-" + yr;
        checkArr.push(formated);
        if (startDate2 !== startDate) {
          dateByWeek(startDate2);
        }
      }
    }
    dateByWeek(end_date);
    setDateAr(checkArr.reverse());

    let data = {
      tenantname: Tenant,
      userid: userId,
      Start_Date: startDate,
      End_Date: end_date,
      PageNumber: currentpage,
      PageSize: pagesize,
    };
    getDailyAttendance(data)
      .then((res) => {
        setLoading(false);
        setUserData(res.data.Data);
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        }
         else {
          navigate("/forbidden");
        }
      });
  };

  const testPage = (event) => {
    if (event.key === "Enter") {
      getAttandance(
        UserRole == "2" ? loggedInUserId : user_Id,
        endDate,
        currentpage,
        dataPerPage
      );
    }
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getAttandance(
      UserRole == "2" ? loggedInUserId : user_Id,
      endDate,
      pageNumber,
      dataPerPage
    );
  };

//date selected
const handleDateChange = (selectedDate) => {
  const formattedDate = selectedDate
    ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}`
    : null;
    setEndDate(formattedDate);
    getAttandance(
      UserRole == "2" ? loggedInUserId : user_Id,
      formattedDate
    );
};

  //UserDropdown
  const emplist = (permissionName = "user_daily_attendance") => {
    getAllUserForReport(Tenant, permissionName)
      .then((res) => {
        if (!Array.isArray(res.data.Data)) {
          let temp = { ...res.data.Data };
          res.data.Data = [temp];
          setEmpList(res.data.Data);
        } else {
          setEmpList(res.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Daily_attendance") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                emplist(list.Sub_Permission_Name);
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Daily Attendance" />

        <div className="mt-3 mb-3">
          <div className="row d-flex align-items-center ">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-between align-items-center mt-1">
              <p className="lableRepo" style={{ marginRight: "5px" }} value="0">
                Employees
              </p>
              <select
                className="form-select team-select borders"
                value={user_Id}
                onChange={(e) => {
                  setUser_Id(e.target.value);
                  getAttandance(e.target.value, endDate);
                }}
              >
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "admin_daily_attendance") {
                    return (
                      <>
                        <option defaultValue="0" value="0">
                          All Employees
                        </option>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                  if (list.Sub_Permission_Name === "user_daily_attendance") {
                    return (
                      <>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </select>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center mt-1">
              <p className="lableRepo">Date</p>


              <DatePicker
        selected={endDate ? new Date(endDate) : null}
        onChange={handleDateChange}
        dateFormat="dd.MM.yyyy"
        className="form-control borders team-select ml-2 selectDate"
        maxDate={new Date()} // Set maximum date to today's date
        // Other props, such as max date, can be added here
      />

              {/* <input
                type="date"
                name="end_Date"
                className="form-control borders team-select ml-2 selectDate"
                defaultValue={endDate}
                max={today}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  getAttandance(
                    UserRole == "2" ? loggedInUserId : user_Id,
                    e.target.value
                  );
                }}
              /> */}
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12 mt-1 ">
              {/* <button
                type="button"
                className="btn btn-default team-select cursor-pointer btn-lg exportBtn me-auto"
              >
                Export to CSV
              </button> */}
            </div>
          </div>
        </div>

        <hr />
        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {userData == null || userData.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div className="container-fliud mt-1">
                  <div className="table-responsive mt-1">
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            className="tableHead"
                            style={{ minWidth: "250px" }}
                          >
                          </th>
                          {dateAr.map((res, i) => {
                            return (
                              <th
                                key={i}
                                className="tableHead text-center"
                                style={{ minWidth: "150px" }}
                              >
                                {/* {moment(res).format("DD.MM.YYYY")} */}
                                {moment(res, "MM-DD-YYYY").format("DD.MM.YYYY")}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                    {userData &&
                      userData.map((res, i) => {
                        return (
                          <table
                            key={i}
                            className="table mt-2"
                            style={{
                              background: "white",
                              borderRadius: "10px",
                            }}
                          >
                            <thead>
                              <tr>
                                <td
                                  className="d-flex"
                                  style={{ minWidth: "250px" }}
                                >
                                  <div
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "13px",
                                      textAlign: "center",
                                      color: "#fff",
                                      backgroundColor:
                                        colors[
                                          Math.floor(
                                            Math.random() * colors.length
                                          )
                                        ],
                                    }}
                                  >
                                    {res.User_Name.charAt(0).toUpperCase()}
                                  </div>
                                  <span style={{ marginLeft: "10px" }}></span>
                                  {res.User_Name}
                                </td>
                              </tr>
                            </thead>
                            <tbody className="reportList">
                              <tr>
                                <td style={{ minWidth: "250px" }}>
                                  <li>Workday</li>
                                </td>
                                {dateAr.map((resp, index) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center"
                                      style={{ minWidth: "150px" }}
                                    >
                                      {res.listByDates.map(
                                        (result1, index2) => {
                                          if (result1.Start_Date == resp) {
                                            return (
                                              <React.Fragment key={index2}>
                                                <div className="workday">
                                                  {result1.Workday}
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td style={{ minWidth: "250px" }}>
                                  <li>Clock in</li>
                                </td>
                                {dateAr.map((resp, index) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center"
                                      style={{ minWidth: "150px" }}
                                    >
                                      {res.listByDates.map(
                                        (result1, index1) => {
                                          if (result1.Start_Date == resp) {
                                            return (
                                              <React.Fragment key={index1}>
                                                {result1.Start_Time ===
                                                "00:00:00"
                                                  ? "-"
                                                  : moment(
                                                      result1.Start_Time,
                                                      "HH:mm:ss"
                                                    ).format("hh:mm A")}
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td style={{ minWidth: "250px" }}>
                                  <li>Clock Out</li>
                                </td>
                                {dateAr.map((resp, index) => {
                                  return (
                                    <td
                                      key={index}
                                      className="text-center"
                                      style={{ minWidth: "150px" }}
                                    >
                                      {res.listByDates.map(
                                        (result1, index1) => {
                                          if (result1.Start_Date == resp) {
                                            return (
                                              <React.Fragment key={index1}>
                                              
                                                {result1.Start_Date == newDate ? '-' : (result1.End_Time === "00:00:00"
                                                  ? "-"
                                                  :  moment(
                                                      result1.End_Time,
                                                      "HH:mm:ss"
                                                    ).format("hh:mm A"))}
                                              </React.Fragment>
                                            );
                                          }
                                        }
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
            {(noOfPage === 1 && (!userData || userData.length <= 10)) ||
            noOfPage === 0 ? (
              ""
            ) : (
              <div className="paginations d-flex justify-content-between align-items-center mt-2">
                <div className="d-flex justify-content-center align-items-center">
                  <p className="lableRepo">Rows per page : </p>
                  <input
                    id="input-control"
                    name="policy"
                    type="text"
                    className="form-control w-20 text-center team-select"
                    autoComplete="off"
                    style={{
                      borderRadius: "6px",
                      height: "30px",
                    }}
                    defaultValue={dataPerPage}
                    onChange={(e) => setDataPerPage(e.target.value)}
                    onKeyDown={testPage}
                  />
                </div>
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={noOfPage}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}
