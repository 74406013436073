import React, { useState, useEffect } from "react";
import "../css/style.css";
import Select from "react-select";
import {
  adminProjectsDropDown,getOrgShit,
  assignProject,
  getUserById,
  get_Roles,
  listTeams,
  userUpdate,
  checkJWTToken,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function EditUser() {
  const [arrayData, setArrayData] = useState([]);
  const [arrayData1, setArrayData1] = useState([]);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const UserToken = Authentication.Token;

  //project assign
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  //project assign
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);


  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getSpecificUser();
      getRoles();
      getShifts();
      getTeamList();
      getAdminProjects();
    }
  }, []);

  const getRoles = () => {
    get_Roles(Tenant, UserRole)
      .then((res) => {
        setArrayData(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeamList = () => {
    listTeams(Tenant, UserRole)
      .then((res) => {
        setArrayData1(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAdminProjects = () => {
    adminProjectsDropDown(Tenant, UserRole)
      .then((res) => {
        setProjectDropdown(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { search } = useLocation();
  const User_Id = new URLSearchParams(search).get("id");
  const [User_Name, setUser_Name] = useState();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [User_Role, setUser_Role] = useState("");
  const [Status, setStatus] = useState(false);
  const [Team_Id, setTeam_Id] = useState("");
  const [shiftId, setshiftId] = useState("");
  const [selectRole, setSelectRole] = useState(false);
  let [data, setData] = useState([]);


  let [shiftData, setShiftData] = useState([]);

  const getShifts = () => {
    getOrgShit()
      .then((res) => {
        setShiftData(res.data.Data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const [formerrors, setFormErrors] = useState({
    User_Name: "",
    Email: "",
    Team_Id: "",
    Shift_Time_Id: "",
    User_Role: "",
  });

  const validate = (values) => {
    const errors = {};
    if (!values.User_Name.trim()) {
      errors.User_Name = "User name is required*";
    }
    if (!values.Team_Id) {
      errors.Team_Id = "Team is required*";
    }
    if (!values.Shift_Time_Id) {
      errors.Shift_Time_Id = "User Working shift is required*";
    }
    if (!values.User_Role.trim()) {
      errors.User_Role = "User role is required*";
    }
    if (values.Email === "") {
      errors.Email = "Email is required*";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        User_Name: "",
        Email: "",
        Team_Id: "",
        Shift_Time_Id: "",
        User_Role: "",
      });
      return true;
    }
  };

  function editUser() {
    let data = {
      Tenantname: Tenant,
      UserId: User_Id,
      Full_Name: User_Name,
      User_Name: User_Name,
      Email: Email,
      User_Role: User_Role,
      Status: true,
      Team_Id: Team_Id,
      Shift_Time_Id: shiftId,
      Projects: selectedValue,
    };
    if (validate(data)) {
      setLoading(true);
      userUpdate(data)
        .then((res) => {
          setUrlResponseMsg(true);
        toast.success(res.data.Message = "Employee Updated Successfully", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/admin");
        }, 2000);
        setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, "error");
        });
    }
  }

  function myFunction(item) {
    let data = {
      Tenantname: Tenant,
      UserId: User_Id,
      projectid: item,
    };
    assignProject(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err, "error");
      });
  }

  const getSpecificUser = () => {
    getUserById(Tenant, User_Id)
      .then((response) => {
        setData(response);
        setEmail(response.data.Data.Email);
        setPassword(response.data.Data.Password);
        setUser_Name(response.data.Data.Full_Name);
        setUser_Role(response.data.Data.User_Role);
        setStatus(response.data.Data.Status);
        setTeam_Id(response.data.Data.Team_Id);
        setshiftId(response.data.Data.Shift_Time_Id);
        setSelectedValue(response.data.Data.Projects);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  return (
    <section className=" d-flex">
      <div className="container-fluid">
        <Header title="Update User" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <section className="addUsers">
          <div className="container">
            <form>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="mb-2 lableRepo">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      // label="Email"
                      type="email"
                      style={{ width: "100%" }}
                      className="form-control bdr-radius team-select"
                      name="Email"
                      value={Email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setFormErrors({ ...formerrors, Email: "" });
                      }}
                    />
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Email}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="mb-2 lableRepo">
                      User Name <span className="text-danger">*</span>
                    </label>
                    <input
                      label="First Name"
                      type="text"
                      style={{ width: "100%" }}
                      className="form-control bdr-radius team-select"
                      name="User_Name"
                      value={User_Name}
                      onChange={(e) => {
                        setUser_Name(e.target.value);
                        setFormErrors({ ...formerrors, User_Name: "" });
                      }}
                    />
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.User_Name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="mb-2 lableRepo">
                      Role <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select team-select bdr-radius"
                      id="sltrole"
                      value={User_Role}
                      name="User_Role"
                      onChange={(e) => {
                        setUser_Role(e.target.value);
                        setFormErrors({ ...formerrors, User_Role: "" });
                      }}
                    >
                      <option>Roles</option>
                      {arrayData.map((data, i) => {
                        return (
                          <option key={i} value={data.Role_Id}>
                            {data.Role_Name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.User_Role}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="mb-2 lableRepo">
                      Teams <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select team-select bdr-radius"
                      id="sltteam"
                      value={Team_Id}
                      name="team"
                      onChange={(e) => {
                        setTeam_Id(e.target.value);
                        setFormErrors({ ...formerrors, Team_Id: "" });
                      }}
                    >
                      <option>Teams</option>
                      {arrayData1.map((data, i) => {
                        return (
                          <option key={i} value={data.Team_Id}>
                            {data.Team_Name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Team_Id}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 mt-4">
                  <p className="mb-2 lableRepo">
                    User Working Shift <span className="text-danger">*</span>
                  </p>
                  <select
                    className="form-select team-select bdr-radius"
                    name="member"
                    value={shiftId}
                    onChange={(e) => {
                      setshiftId(e.target.value);
                        setFormErrors({ ...formerrors, Shift_Time_Id: "" });
                      }}
                  >
                    <option>Select Shift</option>
                    {shiftData.map((data, i) => {
                      return (
                        <option key={i} value={data.Id}>
                          {data.Shift_Name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Shift_Time_Id}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group mt-3">
                    <label className="mb-2 lableRepo">Project</label>
                    <Select
                      className="dropdown team-select"
                      placeholder="Select Project"
                      value={projectDropdown.filter((obj) =>
                        selectedValue.includes(obj.value)
                      )} // set selected values
                      options={projectDropdown} // set list of the data
                      onChange={handleChange} // assign onChange function
                      isMulti
                      isClearable
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-6">
                  <div className="form-check checkbox mt-3">
                    <label className="mb-2 lableRepo">Status</label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="check1"
                      value={data.Status}
                      name="Status"
                      checked={Status}
                      onChange={(e) => {
                        setStatus(e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div> */}
              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn buttonDefault me-2"
                  disabled={loading}
                  onClick={editUser}
                >
                  {!loading && <span>Save</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Save
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <Link to="/admin" style={{ color: "#fff" }}>
                  <button
                    type="button"
                    className="btn buttonDefault me-2"
                    style={{
                      background: "#8A899C",
                    }}
                  >
                    {" "}
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </section>
      </div>
      {urlResponseMsg ? (
      <>
        <ToastContainer />
      </>
    ) : null}
    </section>
    
  );
}
