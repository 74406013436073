import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../css/style.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  getAdminProjectsByTeam,
  getProjectById,
  listTeams,
  updateProject,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HuePicker } from "react-color";
import ReactQuill from "react-quill";
//import './updateproject.css'



export default function UpdateProject() {
  let navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const [arrayData, setArrayData] = useState([]);
  const [arrayData1, setArrayData1] = useState([]);
  const [arrayData2, setArrayData2] = useState([]);
  const Tenant = Authentication.Tenant;
  const UserRole = localStorage.getItem("roleid");
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
    colorPicker: {
      padding: "6px 50px",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;
  const [users, setUsers] = useState([]);
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("id");
  // const [member, setMember] = useState("");
  const [teams, setTeams] = useState("");
  const [roleid, setRoleid] = useState(2);
  const [Status, setStatus] = useState(false);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBillable, setIsBillable] = useState(false);
  const [color, setColor] = useState("");
  const [value, setValue] = useState("");

  const Editorvalue = (value) => {
    setValue(value);
  };

  const [formerrors, setFormErrors] = useState({
    Project_Name: "",
    Start_Date: "",
    End_Date: "",
    Currency: "",
  });
  //const [Status,setStatus]=useState(false);
  const [formValues, setFormValues] = useState({
    Project_Id: projectId,
    Project_Name: "",
    Start_Date: "",
    End_Date: "",
    Description: "",
    Priority: "",
    Currency: "",
    Budget: "",
    Color_Code: "",
    Billable: "",
    Users: [],
  });
  let [data, setData] = useState([]);
  useEffect(() => {
    getProjectById(projectId)
      .then((response) => {
        setFormValues({
          Project_Id: projectId,
          Project_Name: response.data.Data.Project_Name,
          Start_Date: response.data.Data.StartDate,
          End_Date: response.data.Data.EndDate,
          Priority: response.data.Data.Priority,
          Description: response.data.Data.Description,
          Currency: response.data.Data.Currency,
          Budget: response.data.Data.Budget,
          Billable: response.data.Data.Billable,
          Client_Id: response.data.Data.Client_Id,
          Users: response.data.Data.Users,
        });
        setValue(response.data.Data.Description);
        setIsBillable(response.data.Data.Billable);
        setColor(response.data.Data.Color_Code);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  }, [projectId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      Project_Name: formValues.Project_Name,
      Start_Date: formValues.Start_Date,
      End_Date: formValues.End_Date,
      Priority: formValues.Priority,
      Description: value,
      Budget: formValues.Budget,
      Currency: formValues.Currency,
      Billable: true,
      Color_Code: color,
      Client_Id: 1,
      Project_Id: projectId,
      Users: formValues.Users,
    };
    if (validate(formData)) {
      setLoading(true);
      setUrlResponseMsg(true);
      updateProject(formData)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/projects-list");
          }, 2000);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.Project_Name.trim()) {
      errors.Project_Name = "Project name is required*";
    }
    if (!values.Start_Date) {
      errors.Start_Date = "Start date is required*";
    }
    if (!values.End_Date.trim()) {
      errors.End_Date = "End date is required*";
    }
    if (values.Currency === "") {
      errors.Currency = "Currency is required*";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Project_Name: "",
        Start_Date: "",
        End_Date: "",
        Currency: "",
      });
      return true;
    }
  };
  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Update Project" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>
          <section className="addUsers p-2 p-md-4 p-lg-5">
            <div>
              <form onSubmit={handleSubmit}>
                <div className="heading">Update Project</div>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Project Name <span className="text-danger">*</span>
                      </Label>
                      <input
                        value={formValues.Project_Name}
                        type="text"
                        name="Project_Name"
                        style={styles.select}
                        className="form-control form-input-field"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Project_Name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        Start Date <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="date"
                        style={styles.select}
                        name="Start_Date"
                        className="form-control form-input-field"
                        value={formValues.Start_Date}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Start_Date}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">
                        End Date <span className="text-danger">*</span>
                      </Label>
                      <input
                        type="date"
                        name="End_Date"
                        style={styles.select}
                        value={formValues.End_Date}
                        onChange={handleChange}
                        className="form-control form-input-field"
                      />
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.End_Date}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">Color</Label>
                      <div className="HueInput">
                        <HuePicker
                          color={color}
                          onChangeComplete={(color) => {
                            setColor(color.hex);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">Priority</Label>
                      <select
                        style={styles.select}
                        name="Priority"
                        value={formValues.Priority}
                        onChange={handleChange}
                        class="form-select form-input-field"
                      >
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group mt-3">
                      <Label>Project Budget</Label>
                      <input
                        style={styles.select}
                        type="text"
                        name="Budget"
                        value={formValues.Budget}
                        onChange={handleChange}
                        className="form-control form-input-field"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="form-group mt-3">
                      <Label className="font-poppins-medium">Currency</Label>
                      <select
                        class="form-select form-input-field"
                        name="Currency"
                        style={styles.select}
                        value={formValues.Currency}
                        onChange={handleChange}
                      >
                        <option value="USD">US Dollar</option>
                        <option value="EUR">Euro</option>
                        <option value="INR">Indian Rupee</option>
                        <option value="AUD">Australia Dollar</option>
                        <option value="AUD">Australian Dollar</option>
                        <option value="BHD">Bahraini Dinar</option>
                        <option value="BDT">Bangladeshi Taka</option>
                        <option value="BZD">Belize Dollar</option>
                      </select>
                    </div>
                    <div className="text-alert font-poppins-medium">
                      <span role="alert" className="formErr">
                        {formerrors.Currency}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <Label className="font-poppins-medium mt-3">
                      Description
                    </Label>
                    <input
                      style={styles.select}
                      type="text"
                      name="Description"
                      value={formValues.Description}
                      onChange={handleChange}
                      className="form-control form-input-field"
                    />
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Description</Label>
                      {/* <input style={styles.select}type="text" onChange={(e) => setDescription(e.target.value)} className="form-control" /> */}
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={Editorvalue}
                      />
                    </div>
                  </div>
                </div>


                {/* <div className="mt-3">
                  <div className="form-group mt-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Billable"
                        id="check1"
                        value={isBillable}
                        checked={isBillable}
                        onChange={(e) => {
                          setIsBillable(e.target.checked);
                        }}
                      />
                      <span
                        className="label-field font-poppins-regular align-middle"
                        id="remember_me_chkbox"
                      >
                        Billable
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="row m-0 p-0 mt-3">
                  <div className="d-flex justify-content-end f-manage p-0">
                    <div class="d-flex flex-row row-manage">
                      <div className="d-flex">
                        <button
                          type="submit"
                          className="btn  buttonDefault text-white font-poppins-medium"
                        >
                          {!loading && <span>Submit</span>}
                          {loading && (
                            <span
                              className="indicator-progress font-poppins-regular"
                              style={{ display: "block" }}
                            >
                              Submit
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                      <div class="d-flex">
                        <Link
                          to="/projects-list"
                          className="text-decoration-none"
                        >
                          <button
                            type="button"
                            id="cancel-add-leave"
                            className="btn text-white  buttonDefault cancelbtnhover font-poppins-medium"
                            style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </>
  );
}