import React, { useEffect, useState } from "react";
import "../css/style.css";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import ReactPaginate from "react-paginate";
import ClockLoader from "react-spinners/ClockLoader";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import { shallowEqual, useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import {
  disableAdmin,
  disable_User,
  enableAdmin,
  enable_User,
  getAdmins,
  getAllUserForReport,
  getAdminsByStatus,
  sortUsers,
  getTasksDropdown,
  getTime,
  adminProjectsDropDown,
  setTime,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import EditIcon from "../Images/edit.svg";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));

export default function UserTimeline() {
  const navigate = useNavigate();

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const loggedInUserId = Authentication.User_Id;
  const loggedInUserName = Authentication.Full_Name;
  const [user_Id, setUser_Id] = useState(1);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [empList, setEmpList] = useState([]);
  let selectedUser = localStorage.getItem("selectedUser");
  let selectedDate = localStorage.getItem("selectedDate");
  let hue = 0;
  let colors = [];
  for (let j = 0; j < 10; j++) {
    let color = "hsl(" + hue + ",80%,50%)";
    colors.push(color);
    hue += 500;
  }
  let [currentpage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [noOfPage, setNoOfPage] = useState(1);
  const [expanded, setExpanded] = useState(false);
  let [data, setData] = useState([]);
  let [status, setStatus] = useState("");
  let [start_Time, setStart_Time] = useState("");
  let [end_Time, setEnd_Time] = useState("");
  const [project, setProject] = useState("");
  const [task, setTask] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  let userObj = {};
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [msg, setMsg] = useState(false);
  let options = [];
  let [pagePermission, setPagePermission] = useState([]);

  useEffect(() => {
    getUserPermission();
    timeLine(selectedUser || loggedInUserId, today);
    emplist();
    adminProjectsDropDown(Tenant)
      .then((res) => {
        setProjectDropdown(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      localStorage.removeItem("selectedUser");
      localStorage.removeItem("selectedDate");
    };
  }, []);

  let [editStart, seteditStart] = useState("");
  let [editEnd, seteditEnd] = useState("");

  const [formerrors, setFormErrors] = useState({
    Start_Time: "",
    End_Time: "",
    Project_Id: "",
    Task_Id: "",
  });
  const validate = (values) => {
    const errors = {};


    if (!values.Start_Time) {
      errors.Start_Time = "Start_Time is required";
    }
    if (!values.End_Time) {
      errors.End_Time = "End_Time is required";
    }
    if (editStart > values.Start_Time) {
      errors.Start_Time = "Time should be greater than " + editStart;
    }
    if (values.Start_Time >= editEnd) {
      errors.Start_Time = "Time should not be greater than " + editEnd;
    }
    if (editStart > values.End_Time) {
      errors.End_Time = "Time should be greater than " + editStart;
    }
    if (values.End_Time > editEnd) {
      errors.End_Time = "Time should not be greater than " + editEnd;
    }
    if (values.Start_Time > values.End_Time) {
      errors.End_Time = "End Time should not be greater than Start Time";
    }
    if (!values.Project_Id) {
      errors.Project_Id = "Project is required";
    }
    if (!values.Task_Id) {
      errors.Task_Id = "Task is required";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Start_Time: "",
        End_Time: "",
        Project_Id: "",
        Task_Id: "",
      });
      return true;
    }
  };

  const editTime = (timeId, id) => {
    let data = {
      User_Id: user_Id,
      Time_Id: timeId,
      Start_Date: date,
      Start_Time: start_Time,
      End_Time: end_Time,
      Task_Id: task,
      Project_Id: project,
    };

    if (validate(data)) {
      setMsg(true);
      setLoadingSave(true);
      setTime(data)
        .then((res) => {
          setLoadingSave(false);
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          timeLine(selectedUser || loggedInUserId, selectedDate || today);
          handleEpandRow(id);
        })
        .catch((err) => {
          setLoadingSave(false);
          if (err.response.data.Status == "Expire") {
            navigate("/plan-expire");
          } else if (err.response.data.Status == "Unauthorize") {
            navigate("/unauthorize");
          } else {
            navigate("/forbidden");
          }
        });
    }
  };
  const getTask = (pId) => {
    getTasksDropdown(pId)
      .then((res) => {
        setTaskList(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getData = (
    page = currentpage || 1,
    keys = "",
    check = true,
    search = searchKey,
    stat = status
  ) => {
    setLoading(true);
    let data = {
      tenantname: Tenant,
      key: keys,
      ascdsc: check,
      searchname: search,
      status: stat,
      pageNumber: page,
    };
    getAdmins(data)
      .then((res) => {
        setLoading(false);
        setData(res.data.Data);
        setNoOfPage(res.data.TotalPages);
        // dataExport(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };
  const timeLine = (selectedUser, selectedDate) => {
    setLoading(true);
    getTime(selectedUser, selectedDate)
      .then((res) => {
        setLoading(false);
        setData(res.data.Data.reverse());
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        setLoadingSave(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };
  //UserDropdown
  const emplist = (permissionName = "user_timeline") => {
    getAllUserForReport(Tenant, permissionName)
      .then((res) => {
        if (!Array.isArray(res.data.Data)) {
          let temp = { ...res.data.Data };
          res.data.Data = [temp];
          setEmpList(res.data.Data);
        } else {
          setEmpList(res.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleEpandRow = (timeId) => {
    setTask("");
    setProject("");
    setStart_Time("");
    setEnd_Time("");
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(timeId);
    let obj = {};
    isRowExpanded ? (obj[timeId] = false) : (obj[timeId] = true);
    setExpandState(obj);
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== timeId)
      : currentExpandedRows.concat(timeId);
    setExpandedRows(newExpandedRows);
  };
  const dataExport = (userData) => {
    let userArr = [];
    userData &&
      userData.forEach((res, i) => {
        userObj = {
          User_Name: res.Full_Name,
          Role: res.Role_Name,
          Team: res.Team_Name,
          Projects: res.ProjectCount,
          Last_Seen: res.Last_Seen,
        };
        userArr.push(userObj);
      });
    setCsvData(userArr);
  };
  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "User_timeline") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                emplist(list.Sub_Permission_Name);
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

    //date selected
const handleStartDateChange = (selectedDate) => {
  const formattedDate = selectedDate
    ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}`
    : null;
    setDate(formattedDate);
                  localStorage.setItem("selectedDate", formattedDate);
                  timeLine(user_Id, formattedDate);
};

  return (
    <section className="d-flex" style={{ padding: "10px 0px" }}>
      <div className="container-fluid">
        <Header title="User Timeline" />
        <div className="contentSection mb-3">
          <div className="row d-flex align-items-center mt-3 ">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-between align-items-center mt-1">
              <p className="lableRepo"> Employees </p>
              <select
                className="form-select team-select borders ml-2"
                value={selectedUser || loggedInUserId}
                onChange={(e) => {
                  setUser_Id(e.target.value);
                  localStorage.setItem("selectedUser", e.target.value);
                  timeLine(e.target.value, date);
                }}
              >
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "user_timeline") {
                    return (
                      <>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </select>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center  align-items-center mt-1">
              <p className="lableRepo">Date</p>

              <DatePicker
        selected={date ? new Date(date) : null}
        onChange={handleStartDateChange}
        dateFormat="dd.MM.yyyy"
        className="form-control borders team-select selectDate"
        maxDate={new Date()} 
        todayButton="TODAY"
      />
              {/* <input
                type="date"
                defaultValue={date}
                className="borders form-control ml-2 team-select selectDate"
                max={today}
                onChange={(e) => {
                  setDate(e.target.value);
                  localStorage.setItem("selectedDate", e.target.value);
                  timeLine(user_Id, e.target.value);
                }}
              /> */}
            </div>
          </div>
        </div>

        <hr />
        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {!data || data.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div className="table-responsive">
                  <table
                    className="table"
                    style={{
                      borderBottomWidth: "0px",
                      borderBottomColor: "none",
                    }}
                  >
                    <thead className="UsertimelineTable">
                      <tr>
                        <th className="tableHead" style={{ minWidth: "350px" }}>
                          Project
                        </th>
                        <th className="tableHead" style={{ minWidth: "350px" }}>
                          Task
                        </th>
                        <th
                          className="tableHead ps-0"
                          style={{ minWidth: "100px" }}
                        >
                          Start Time
                        </th>
                        <th
                          className="tableHead "
                          style={{ minWidth: "100px" }}
                        >
                          End Time
                        </th>
                        <th
                          className="tableHead "
                          style={{ minWidth: "100px" }}
                        >
                          Active Time
                        </th>
                        <th
                          className="tableHead "
                          style={{ minWidth: "100px" }}
                        >
                          Idle Time
                        </th>
                        <th
                          className="tableHead "
                          style={{ minWidth: "100px" }}
                        >
                          Total Time
                        </th>
                        <th
                          className="tableHead "
                          style={{ minWidth: "100px" }}
                        >
                          
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((res, i) => (
                        <React.Fragment key={i}>
                          <tr
                            className=""
                            style={{ borderRadius: "30px" }}
                            key={i}
                          >
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell front-radius"
                                  : "cellInactive front-radius"
                              }
                            >
                              <div className="mainDiv">
                                <div>{res.Project_Name}</div>
                              </div>
                            </td>
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell "
                                  : "cellInactive "
                              }
                            >
                              <div className="mainDiv">
                                <div>{res.Task_Name}</div>
                              </div>
                            </td>
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell "
                                  : "cellInactive "
                              }
                            >
                              <div className="mainDiv">{res.Start_Time}</div>
                            </td>
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell "
                                  : "cellInactive "
                              }
                            >
                              <div className="mainDiv">
                                <div>{res.End_Time}</div>
                              </div>
                            </td>
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell "
                                  : "cellInactive "
                              }
                            >
                              <div className="mainDiv">{res.Active_Time}</div>
                            </td>
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell "
                                  : "cellInactive "
                              }
                            >
                              <div className="mainDiv ">
                                {res.Idle_Time}
                              </div>
                            </td>
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell "
                                  : "cellInactive "
                              }
                            >
                              <div className="mainDiv ">
                                {res.TotalTime}
                              </div>
                            </td>
                            <td
                              className={
                                res.Time_Id !== 0
                                  ? "tableCell back-radius"
                                  : "cellInactive back-radius"
                              }
                            >
                              <div className="mainDiv " title="Insert Manual Time">
                                {res.Time_Id == 0 && (
                                  <button
                                    type="button"
                                    className="btn"
                                    style={{ padding: "0" }}
                                    onClick={() => {
                                      handleEpandRow(res.ID);
                                      seteditStart(res.Start_Time);
                                      seteditEnd(res.End_Time);
                                    }}
                                  >
                                    <img src={EditIcon} alt="edit" />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                          {expandedRows.includes(res.ID) && (
                            <tr>
                              <td
                                className="tableCell"
                                colSpan="8"
                                style={{ borderRadius: "10px" }}
                              >
                                <div
                                  className="row d-flex align-items-end"
                                  style={{
                                    backgroundColor: "#FFF",
                                    color: "#FFF",
                                    padding: "8px",
                                  }}
                                >
                                  <div className="col-md-10 col-sm-12">
                                    <div className="row">
                                      <div className="col-md-3 timLine">
                                        <div className="form-group mt-3">
                                          <p className="mb-2 lableRepo">
                                            Project
                                          </p>
                                          <select
                                            style={{
                                              width: "100%",
                                              padding: "3px",
                                            }}
                                            // disabled={end_Time == ""}
                                            className="form-control bdr-radius"
                                            onChange={(e) => {
                                              setProject(e.target.value);
                                              getTask(e.target.value);
                                              setFormErrors({
                                                ...formerrors,
                                                Project_Id: "",
                                              });
                                            }}
                                          >
                                            <option value="">
                                              Select Project
                                            </option>
                                            {projectDropdown &&
                                              projectDropdown.map((data, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={data.value}
                                                  >
                                                    {data.label}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                        </div>
                                        <div className="text-alert font-poppins-medium">
                                          <span
                                            role="alert"
                                            className="formErr"
                                          >
                                            {formerrors.Project_Id}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-3 timLine">
                                        <div className="form-group mt-3">
                                          <p className="mb-2 lableRepo">Task</p>
                                          <select
                                            style={{
                                              width: "100%",
                                              padding: "3px",
                                            }}
                                            // disabled={project == ""}
                                            className="form-control bdr-radius"
                                            onChange={(e) => {
                                              setTask(e.target.value);
                                              setFormErrors({
                                                ...formerrors,
                                                Task_Id: "",
                                              });
                                            }}
                                          >
                                            <option value="">
                                              Select Task
                                            </option>
                                            {taskList &&
                                              taskList.map((data, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={data.Task_Id}
                                                  >
                                                    {data.Task_Name}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                        </div>
                                        <div className="text-alert font-poppins-medium">
                                          <span
                                            role="alert"
                                            className="formErr"
                                          >
                                            {formerrors.Task_Id}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-3 timLine">
                                        <div className="form-group mt-3">
                                          <p className="mb-2 lableRepo">
                                            Start Time
                                          </p>
                                          <input
                                            type="time"
                                            style={{
                                              width: "100%",
                                              padding: "3px",
                                            }}
                                            required
                                            min={res.Start_Time}
                                            max={res.End_Time}
                                            className="form-control bdr-radius"
                                            value={start_Time}
                                            onChange={(e) => {
                                              setStart_Time(e.target.value);
                                              setFormErrors({
                                                ...formerrors,
                                                Start_Time: "",
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className="text-alert font-poppins-medium">
                                          <span
                                            role="alert"
                                            className="formErr"
                                          >
                                            {formerrors.Start_Time}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-3 timLine">
                                        <div className="form-group mt-3">
                                          <p className="mb-2 lableRepo">
                                            End Time
                                          </p>
                                          <input
                                            type="time"
                                            style={{
                                              width: "100%",
                                              padding: "3px",
                                            }}
                                            required
                                            min={res.Start_Time}
                                            max={res.End_Time}
                                            className="form-control bdr-radius"
                                            // disabled={start_Time == ""}
                                            value={end_Time}
                                            onChange={(e) => {
                                              setEnd_Time(e.target.value);
                                              setFormErrors({
                                                ...formerrors,
                                                End_Time: "",
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className="text-alert font-poppins-medium">
                                          <span
                                            role="alert"
                                            className="formErr"
                                          >
                                            {formerrors.End_Time}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-12 d-flex justify-content-md-end justify-content-sm-end align-items-center timlinebtn">
                                    <div className="timesave me-3">
                                      <button
                                        type="button"
                                        className="btn"
                                        style={{
                                          background: "#7D0096",
                                          color: "#fff",
                                        }}
                                        onClick={() => {
                                          editTime(res.Time_Id, res.ID);
                                        }}
                                        // disabled={loadingSave || task == ""}
                                      >
                                        {!loadingSave && <span>Save</span>}
                                        {loadingSave && (
                                          <span
                                            className="indicator-progress"
                                            style={{ display: "block" }}
                                          >
                                            Save
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                          </span>
                                        )}
                                      </button>
                                    </div>
                                    <div className="timecncl">
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={() => {
                                          handleEpandRow(res.ID);
                                        }}
                                        style={{
                                          background: "#8A899C",
                                          color: "#fff",
                                        }}
                                      >
                                        {" "}
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
        {/* {(noOfPage == 1 && data.length <= 10) || noOfPage == 0 ? (
          ""
        ) : (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={noOfPage}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        )} */}
      </div>
      {msg && <ToastContainer />}
    </section>
  );
}
