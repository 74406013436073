import React, { useEffect, useState } from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useLocation } from "react-router-dom";
import { createTask, getAllUserForTask } from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import { shallowEqual, useSelector } from "react-redux";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function CreateTask() {
  let navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const projectId = new URLSearchParams(search).get("id");
  const [empList, setEmpList] = useState([]);
  const [value, setValue] = useState('');
  const [user_Id, setUser_Id] = useState(0);

  const styles = {
    select: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "#B6B6B6",
    },
    cancelBtn: {
      border: "none",
      marginRight: "5px",
      borderRadius: "60px",
      background: "#616773",
      color: "#fff",
      padding: "10px 100px",
    },
    saveBtn: {
      border: "none",
      borderRadius: "30px",
      padding: "10px 100px",
      background: "#6414b5",
      color: "#fff",
    },
  };
  const Label = styled.label`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #616773;
  `;


  const taskSchema = Yup.object().shape({
    Task_Name: Yup.string().required("Task name is required"),
    Start_Date: Yup.string().required("Start Date is required"),
    Estimated_Time: Yup.string()
    .matches(/\d:\d/, 'Invaild format')
  });

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [urlResponseMsg, setUrlResponseMsg] = useState(false);

  const formik = useFormik({
    initialValues: {
      Task_Name: "",
      Start_Date: "",
      End_Date: "",
      Estimated_Time: "",
      Status: true,
      Priority: "",
      Description: "",
      Assignee: "",
      TenantName: Tenant,
      Description: value,
      Assignee_Name: "",
      Project_Id: projectId,
      // teamid: teams,
    },
    validationSchema: taskSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let data = {
        Task_Name: values.Task_Name,
        Start_Date: values.Start_Date,
        End_Date: values.End_Date,
        Estimated_Time: values.Estimated_Time,
        Status: values.Status,
        Priority: values.Priority,
        Description: value,
        Assignee_Name: values.Assignee_Name,
        TenantName: Tenant,
        Assignee: user_Id,
        Project_Id: projectId,
      }
      setLoading(true);
      setUrlResponseMsg(true);
      createTask(data)
        .then((res) => {
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setTimeout(() => {
            navigate(-1);
          }, 2000);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
        });
    },
  });


  // List All User
  useEffect(() => {
    emplist(projectId);
    return () => {
    };
  }, []);

  const emplist = (id) => {
    getAllUserForTask(Tenant, id)
      .then((res) => {
        setEmpList(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Editorvalue = (value) => {
    setValue(value);
  }

  let active = false;

  const toggle = () => {
    let toggle = document.querySelector('.toggle')
    let text = document.querySelector('.text')
    active = !active
    if (active) {
        toggle.classList.add('active')
        text.innerHTML = 'N'
    } else {
        toggle.classList.remove('active')
        text.innerHTML = 'FF'
    }
}

  return (
    <React.Fragment>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Create New Task" />

          <div className="text-start mt-3 mb-3">
            <div className="d-flex align-items-center" role="button" onClick={() => navigate(-1)}>
              <i><img src={backArrow} alt="back-Arrow" /></i>
              <span className="font-poppins-regular backBtn">&nbsp;&nbsp;&nbsp;Back</span>
            </div>
          </div>

          <section className="addUsers p-2 p-md-4 p-lg-5">
            <div>
              <form onSubmit={formik.handleSubmit}>
              <div className="heading">Create New Task</div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Task Name</Label>
                      <input
                        style={styles.select}
                        type="text"
                        {...formik.getFieldProps('Task_Name')}
                        className="form-control form-input-field"
                      />
                    </div>
                    {formik.touched.Task_Name && formik.errors.Task_Name && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.Task_Name}
                        </span>
                      </div>
                    </div>
                  )}
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Start Date</Label>
                      <input
                        style={styles.select}
                        type="date"
                        max={endDate}
                          defaultValue={date}
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                        {...formik.getFieldProps('Start_Date')}
                        className="form-control form-input-field"
                        placeholder="Start_Date"
                      />
                    </div>
                    {formik.touched.Start_Date && formik.errors.Start_Date && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.Start_Date}
                        </span>
                      </div>
                    </div>
                  )}
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>End Date</Label>
                      <input
                        style={styles.select}
                        type="date"
                        min={date}
                          defaultValue={endDate}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                        {...formik.getFieldProps('End_Date')}
                        className="form-control form-input-field"
                        placeholder="End_Date"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mt-3 col-lg-6 col-md-6 col-sm-12">
                    <Label>Assignee</Label>
                    <select
                      className="form-control form-input-field"
                      name="member"
                      onChange={(e) => setUser_Id(e.target.value)}
                    >
                      <option>Assignee</option>
                      {empList.map((data, i) => {
                        return (
                          <React.Fragment key={i}>
                            <option value={data.User_Id} >
                              {data.Full_Name}
                            </option>
                          </React.Fragment>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Priority</Label>
                      <select
                        class="form-select form-input-field"
                        {...formik.getFieldProps('Priority')}
                      >
                        <option value="">Select Priority</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                        <option value="Low">Low</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Estimated Time(in hh:mm)</Label>
                      <input
                        style={styles.select}
                        type="text"
                        {...formik.getFieldProps('Estimated_Time')}
                        className="form-control form-input-field"
                        placeholder="Estimated Time"
                      />
                    </div>
                    {formik.touched.Estimated_Time && formik.errors.Estimated_Time && (
                    <div>
                      <div className="text-alert font-poppins-medium">
                        <span role="alert" className="formErr">
                          {formik.errors.Estimated_Time}
                        </span>
                      </div>
                    </div>
                  )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group mt-3">
                      <Label>Description</Label>
                      <ReactQuill
                        theme="snow"
                        value={value}
                        onChange={Editorvalue}
                      />
                    </div>
                  </div>
                </div>

                <div class="row m-0 p-0 mt-3"><div class="d-flex justify-content-end f-manage p-0">
                  <div class="d-flex flex-row row-manage">
                    <div class="d-flex">
                      <button class="btn  buttonDefault text-white font-poppins-medium">
                        {!loading && <span>Submit</span>}
                        {loading && (
                          <span
                            className="indicator-progress font-poppins-regular"
                            style={{ display: "block" }}
                          > Submit
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                    <div class="d-flex">
                        <button type="button" id="cancel-add-leave"
                          class="btn text-white  buttonDefault cancelbtnhover font-poppins-medium"
                          onClick={() => navigate(-1)}
                          style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                        >
                          Cancel
                        </button>
                    </div>
                  </div>
                </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </section>
      {urlResponseMsg ? (
        <>
          <ToastContainer />
        </>
      ) : null}
    </React.Fragment>
  );
}
