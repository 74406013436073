import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import {
  getAllUserForReport,
  getUserScreenShots,
  getUserInfoScreenShots,
  screenshotDelete,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Header } from "../layoutComponent/Header";
import ModalImage from "react-modal-image";
import ClockLoader from "react-spinners/ClockLoader";
import Swal from "sweetalert2";
import delet from "../Images/delet.svg";
import { log } from "@craco/craco/lib/logger";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function UserScreenshot() {
  const navigate = useNavigate();

  const styles = {
    empGenDetail: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "21px",
      color: "#292a2d",
      textAlign: "center",
    },
    timeBox: {
      textAlign: "center",
      padding: "12px 8px",
      opacity: "0.5",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
    },
    emptime: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "20px",
      color: "#292a2d",
    },
    emptimelabel: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "21px",
      color: "#616773",
    },

    screenshotImg: {
      border: "0px solid #B6B6B6",
      width: "100%",
      padding: "50px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#616773",
      opacity: "0.9",
      textAlign: "center",
      background: "#E6E6E6",
    },
    screenshotTime: {
      margin: "10px 0",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#616773",
      opacity: "0.9",
    },
    screenShotGrid: {
      marginTop: "10px",
      padding: "1rem",
      border: "2px dashed #6414B5",
      borderRadius: "15px",
    },
  };
  const [permissionName, setPermissionName] = useState("");

  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const loggedInUserId = Authentication.User_Id;
  const loggedInUserName = Authentication.Full_Name;
  const [empList, setEmpList] = useState([]);


  
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [csvData, setCsvData] = useState([]);
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState([]);
  const [User_Name, setUserName] = useState("");
  const [delta, setDates] = useState("");
  const [TotalTime, setTotalTime] = useState("");
  const [ActiveTime, setActiveTime] = useState("");
  const [IdleTime, setIdleTime] = useState("");
  const [SevenDayActive, setSevenDayActive] = useState("");
  const [StartTime, setStartTime] = useState("");
  const [EndTime, setEndTime] = useState("");
  const [screenshotData, setScreenshotData] = useState([]);
  const [user_Id, setUser_Id] = useState(loggedInUserId);
  let [pagePermission, setPagePermission] = useState([]);
  let selectedUser = localStorage.getItem("selectedUser");
  let selectedDate = localStorage.getItem("selectedDate");


  useEffect(() => {
    getUserPermission();
    let inter;
    emplist();
    getScreenShots(selectedUser || user_Id);
    inter = function () {
      selectedDate = localStorage.getItem("selectedDate") || today;
      if (selectedDate == today) {
        selectedUser =
          localStorage.getItem("selectedUser") === null
            ? loggedInUserId
            : localStorage.getItem("selectedUser");
        selectedDate = localStorage.getItem("selectedDate") || today;
        setDate(selectedDate);
        setUser_Id(selectedUser);
        getScreenShots(selectedUser, selectedDate);
      }
    };
    return () => {
      clearInterval(inter);
      localStorage.removeItem("selectedUser");
      localStorage.removeItem("selectedDate");
    };
  }, []);
  const emplist = (permissionName = "user_screenshot") => {
    getAllUserForReport(Tenant, permissionName)
      .then((res) => {
        if (!Array.isArray(res.data.Data)) {
          let temp = { ...res.data.Data };
          res.data.Data = [temp];
          setEmpList(res.data.Data);
        } else {
          setEmpList(res.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDateChange = (selectedDate) => {
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, "0")}-${selectedDate
        .getDate()
        .toString()
        .padStart(2, "0")}`
      : null;
    setDate(formattedDate);
    localStorage.setItem("selectedDate", formattedDate);
    getScreenShots(
      selectedUser === null ? loggedInUserId : selectedUser,
      formattedDate
    );
  };
  const getScreenShots = (userID, selected_date = date) => {
    setLoading(true);
    getUserScreenShots(selected_date, Tenant, userID)
      .then((res) => {
        setLoading(false);
        // setScreenshotData(res.data.Data.reverse());
        if (res.data.Status == "Success") {
          setScreenshotData(res.data.Data.reverse());
        } else if (res.data.Status == "Expire") {
          navigate("/plan-expire");
        } else {
          navigate("/unauthorize");
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
    getUserInfoScreenShots(selected_date, Tenant, userID)
      .then((res) => {
        if (res.data.Status == "Success") {
          setData(res.data.Data);
          setData(res);
          setUserName(res.data.Data.Full_Name);
          setDates(res.data.Data.Date);
          setTotalTime(res.data.Data.TotalTime);
          setActiveTime(res.data.Data.ActiveTime);
          setIdleTime(res.data.Data.IdleTime);
          setSevenDayActive(res.data.Data.SevenDayActive);
          setStartTime(res.data.Data.StartTime);
          setEndTime(res.data.Data.EndTime);
        } else if (res.data.Status == "Expire") {
          navigate("/plan-expire");
        } else {
          navigate("/unauthorize");
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };
  const deleteScreenShot = (ssId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this screenshot?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Delete`,
    }).then((result) => {
      console.log(ssId, '>>>>>>>>>>>>>>>>>>');
      if (result.isDenied) {
        screenshotDelete(ssId)
          .then((res) => {
            Swal.fire("Sucessfully deleted", "", "success");
            getScreenShots(selectedUser, selectedDate || date);
          })
          .catch((err) => {});
      }
    });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Screenshots") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                const x = list.Sub_Permission_Name;
                emplist(x);
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };
  return (
    
    <section
      className="sidebar d-flex"
      style={{ padding: "10px 0", height: "auto" }}
    >
      <div className="container-fluid">
        <Header title="Screenshots" />

        <section className="empDetail">
          <div className="row">
            <div className="col-lg-5 col-md-4 col-sm-6 col-xs-12 mt-3 d-flex justify-content-between align-items-center">
              <p
                className="lableRepo font-poppins-regular"
                style={{ marginRight: "15px" }}
                value="0"
              >
                Employees
              </p>
              <select
                width="250px"
                id="employeeSelect"
                className="form-select team-select borders"
                value={selectedUser || user_Id}
                // defaultValue={user_Id}
                onChange={(e) => {
                  setUser_Id(e.target.value);
                  localStorage.setItem("selectedUser", e.target.value);
                  getScreenShots(e.target.value, date);
                }}
              >
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "admin_screenshot") {
                    return (
                      <>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                  if (list.Sub_Permission_Name === "user_screenshot") {
                    return (
                      <>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </select>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-3 d-flex justify-content-center align-items-center">
              <p
                className="lableRepo font-poppins-regular"
                style={{ marginRight: "8px" }}
              >
                Date
              </p>


              <DatePicker
        selected={date ? new Date(date) : null}
        onChange={handleDateChange}
        dateFormat="dd.MM.yyyy"
        className="borders form-control team-select selectDate"
        // Other props, such as max date, can be added here
      />

              {/* <input
                width="250px"
                type="date"
                defaultValue={date}
                max={today}
                className="borders form-control team-select selectDate date form-control"
                onChange={(e) => {
                  setDate(e.target.value);
                  localStorage.setItem("selectedDate", e.target.value);
                  getScreenShots(
                    selectedUser === null ? loggedInUserId : selectedUser,
                    e.target.value
                  );
                }}
                dateFormat='dd-mm-yy'
              /> */}
            </div>
          </div>
        </section>

        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            <section className="employeeDetail mt-4">
              <div
                className="detailbox"
                style={{
                  padding: "30px 10px",
                  borderRadius: "15px",
                  background: "#fff",
                }}
              >
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12 ScreenshotCol">
                    <div className="row">
                      <div className="col-lg-12 col-md-4 col-sm-4 col-xs-12 xx">
                        <div className="d-flex  align-items-center mt-1 ">
                          <p className="lableRepo font-poppins-regular">
                            Employee
                          </p>
                          <div>: &nbsp;</div>
                          <div style={styles.empGenDetail} value={User_Name}>
                            {User_Name}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-4 col-xs-12 ">
                        <div className="d-flex  align-items-center mt-1 ">
                          <p className="lableRepo respLableRepo font-poppins-regular">
                            Date
                          </p>
                          <div>: &nbsp;</div>
                          <div style={styles.empGenDetail}>
                            {moment(delta).format("DD.MM.YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-4 col-sm-4 col-xs-12">
                        <div className="d-flex  align-items-center mt-1 ">
                          <p className="lableRepo respLableRepo font-poppins-regular">
                            Time
                          </p>
                          <div>: &nbsp;</div>
                          <div style={styles.empGenDetail}>
                            {StartTime} to {EndTime}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screenshotNav col-lg-9 col-md-12 col-sm-12 ">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12  mt-2">
                        <div style={styles.timeBox}>
                        <p
                            className="lableRepo mb-1 font-poppins-regular"
                            style={styles.emptimelabel}
                          >
                            Total Time
                          </p>
                          <div style={styles.emptime}>{TotalTime}</div>
                          
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12  mt-2">
                        <div style={styles.timeBox}>
                        <p
                            className="lableRepo mb-1 font-poppins-regular"
                            style={styles.emptimelabel}
                          >
                            Active Time
                          </p>
                          <div style={styles.emptime}>{ActiveTime}</div>
                          
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12  mt-2">
                        <div style={styles.timeBox}>
                        <p
                            className="lableRepo mb-1 font-poppins-regular"
                            style={styles.emptimelabel}
                          >
                            Idle Time
                          </p>
                          <div style={styles.emptime}>{IdleTime}</div>
                          
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12  mt-2">
                        <div style={styles.timeBox}>
                        <p
                            className="lableRepo mb-1 font-poppins-regular"
                            style={styles.emptimelabel}
                          >
                            Weekly Total Time
                          </p>
                          <div style={styles.emptime}>{SevenDayActive}</div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="row m-0">
              {screenshotData.length === 0 ? (
                <>
                  <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                    No Screenshots Available
                  </h5>
                </>
              ) : (
                <>
                  {screenshotData &&
                    screenshotData.map((data, i) => {
                      return (
                        <React.Fragment key={i}>
                          <div
                            className="col-12 bg-white mt-3 pb-3"
                            style={{
                              border: "transparent",
                              borderRadius: "15px",
                            }}
                          >
                            <div style={styles.screenShotGrid}>
                              <div className="row align-items-center">
                                <div className="col-lg-10 col-md-10 col-sm-12 mt-1">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="row">
                                    <div className="col-lg-12">
                                      <div className="ssItems">
                                        <div className="keyss">
                                          Employee : &nbsp;
                                        </div>
                                        <div className="valuess">{User_Name}</div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="ssItems">
                                        <div className="keyss">
                                          Date : &nbsp;
                                        </div>
                                        <div className="valuess">
                                          {moment(delta).format("DD.MM.YYYY")}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="row">
                                    <div className="col-lg-12">
                                      <div className="ssItems">
                                        <div className="keyss">
                                          Interval From :&nbsp;
                                        </div>
                                        <div className="valuess">
                                          {data.Display_StartTime}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="ssItems">
                                        <div className="keyss">
                                          Interval To :&nbsp;
                                        </div>
                                        <div className="valuess">
                                          {data.Display_EndTime}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 d-flex justify-content-center align-items-center mt-1">
                                  <div className="activityPerHour">
                                    {data.ActivityPerByHour > 100
                                      ? 100
                                      : data.ActivityPerByHour < 0
                                      ? 0
                                      : Math.round(data.ActivityPerByHour)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              {data.Screenshots.map((snapData, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div className="col-md-2">
                                      <div
                                        className="screenshotTime"
                                        style={styles.screenshotTime}
                                      >
                                        {snapData.ScreenShot_Time}
                                      </div>
                                      <div>
                                        <ModalImage
                                          small={snapData.ScreenShot}
                                          large={snapData.ScreenShot}
                                        />
                                      </div>

                                      {pagePermission.map((list) => {
                                        if (
                                          list.Sub_Permission_Name ===
                                          "admin_screenshot"
                                        ) {
                                          return (
                                            <>
                                              <button
                                                type="button"
                                                style={{
                                                  color: "red",
                                                  paddingTop: "5px",
                                                }}
                                                onClick={() => {
                                                  deleteScreenShot(
                                                    snapData.User_ScreenShot_Id
                                                  );
                                                }}
                                              >
                                                <img
                                                  src={delet}
                                                  alt="delete-icon"
                                                />
                                              </button>
                                            </>
                                          );
                                        }
                                        if (
                                          list.Sub_Permission_Name ===
                                          "user_screenshot"
                                        ) {
                                          return <></>;
                                        }
                                      })}
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
}
