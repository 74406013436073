export const authUserRegister = (data) => {
  return {
    type: "REGISTER",
    payload: { data },
  };
};

export const authUserLogin = (token, data) => {
  return {
    type: "LOGIN",
    payload: { token, data },
  };
};

export const profilePic=(userProfile)=>{
  return {
      type:"PROFILE",
      payload:{userProfile}
  }
}

export const updateProfile=(data)=>{
  return {
      type:"UPDATEPROFILE",
      payload:{data}
  }
}

export const authUserLogout = () => {
  return {
    type: "LOGOUT",
  };
};
