import React, { useEffect, useState } from "react";
import "../css/style.css";
import "./reports.css";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import { getUserProductivity } from "../authApi/AuthCRUD";
import ClockLoader from "react-spinners/ClockLoader";

export default function UserProductivty(props) {
  const navigate = useNavigate();
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [endDate, setEndDate] = useState(today);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const loggedInUserId = Authentication.User_Id;
  const loggedInUserName = Authentication.Full_Name;
  let colors = [
    "#ADCC95",
    "#E49CAD",
    "#CCAF95",
    "#95CCB1",
    "#A395CC",
    "#CB95CC",
    "#BA95CC",
    "#B6AC56",
    "#A395CC",
    "#85A6D9",
  ];
  let [userData, setUserData] = useState();
  let [loading, setLoading] = useState(true);
  const [user_Id, setUser_Id] = useState(0);
  const [empList, setEmpList] = useState([]);
  const [dateAr, setDateAr] = useState([]);

  useEffect(() => {
    userProductivity(user_Id, props.startDate, props.end_date);
  }, []);

  const userProductivity = (user_Id, Start_Date, endDate) => {
    function dateRange(startDate, endDate, steps = 1) {
      let currentDate = new Date(startDate);
      const dateArray = [];
      let dt;
      let mn;
      let yr;
      while (currentDate <= new Date(endDate)) {
        dt = String(currentDate.getDate()).padStart(2, "0");
        mn = String(currentDate.getMonth() + 1).padStart(2, "0");
        yr = currentDate.getFullYear();
        let x = yr + "-" + mn + "-" + dt;
        let formated = mn + "-" + dt + "-" + yr;
        dateArray.push(formated);
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + steps);
        //startDate2.setDate(startDate2.getDate() - 1);
        //scurrentDate.setDate(currentDate.getDate() + steps)
      }
      setDateAr(dateArray);
    }
    dateRange(Start_Date, endDate);
    let data = {
      userid: props.usrId,
      Start_Date: props.startDate,
      End_Date: props.end_date,
    };

    getUserProductivity(data)
      .then((res) => {
        setUserData(res.data.Data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  return (
    <>
      {loading ? (
        <>
          <div
            className="d-flex justify-content-center text-center loaderDiv"
            style={{ margin: "8% 38%" }}
          >
            <ClockLoader color="#6400B0" speedMultiplier={3} />
            <span className="loading">Loading....</span>
          </div>
        </>
      ) : (
        <>
          <div className="mt-1">
            <div className="table-responsive mt-1">
              <table className="table">
                <thead>
                  <tr>
                    <th className="tableHead" style={{ minWidth: "250px" }}>
                    </th>
                    {dateAr.map((res, i) => {
                      return (
                        <th
                          key={i}
                          className="tableHead text-center"
                          style={{ minWidth: "120px" }}
                        >
                          {moment(res, "MM-DD-YYYY").format("DD.MM.YYYY")}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
              {userData &&
                userData.map((res, i) => {
                  return (
                    <table
                      key={i}
                      className="table mt-2"
                      style={{ background: "white", borderRadius: "10px" }}
                    >
                      <thead>
                        <tr>
                          <td className="d-flex" style={{ minWidth: "250px" }}>
                            <div
                              className="profileImg"
                              style={{
                                backgroundColor:
                                  colors[
                                    Math.floor(Math.random() * colors.length)
                                  ],
                              }}
                            >
                              {" "}
                              <p>{res.User_Name.toUpperCase().charAt(0)}</p>
                            </div>
                            {res.User_Name}
                          </td>
                        </tr>
                      </thead>
                      <tbody className="reportList">
                      <tr>
                          <td style={{ minWidth: "250px", padding:'0 !important' }}>
                            <li>Workday</li>
                          </td>
                          {dateAr.map((resp, index) => {
                            return (
                              <td
                                key={index}
                                className="text-center p-0"
                                style={{ minWidth: "120px" }}
                              >
                                {res.usersDates.map((result1, index2) => {
                                  if (result1.Start_Date == resp) {
                                    return (
                                      <React.Fragment key={index2}>
                                        <div className="workday">
                                          {result1.Workday}
                                        </div>
                                      </React.Fragment>
                                    );
                                  }
                                })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td style={{ minWidth: "250px", padding:'0 !important' }}>
                            <li>Activity Level</li>
                          </td>
                          {dateAr.map((resp, index) => {
                            return (
                              <td
                                key={index}
                                className="text-center p-0"
                                style={{ minWidth: "120px" }}
                              >
                                {res.usersDates.map((result1, index1) => {
                                  if (result1.Start_Date == resp) {
                                    return (
                                      <React.Fragment key={index1}>
                                        {result1.Activity_Level === "0"
                                          ? "-"
                                          : result1.Activity_Level + "%"}
                                      </React.Fragment>
                                    );
                                  }
                                })}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td style={{ minWidth: "250px" ,padding:'0 !important'}}>
                            <li>Active Time</li>
                          </td>
                          {dateAr.map((resp, index) => {
                            return (
                              <td
                                key={index}
                                className="text-center p-0"
                                style={{ minWidth: "120px" }}
                              >
                                {res.usersDates.map((result1, index1) => {
                                  if (result1.Start_Date == resp) {
                                    return (
                                      <React.Fragment key={index1}>
                                        {result1.Active_Time === "00h:00m"
                                          ? "-"
                                          : result1.Active_Time}
                                      </React.Fragment>
                                    );
                                  }
                                })}
                              </td>
                            );
                          })}
                        </tr>
                       
                      </tbody>
                    </table>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
