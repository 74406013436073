import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as CgIcons from "react-icons/cg";
import "../css/style.css";
import "../livedashboard/dashboard.css";
import ProfileIamge from "../Images/profileimage.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import { useLocation } from "react-router-dom";
import {
  checkJWTToken,
  GetUserView,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import { Header } from "../layoutComponent/Header";
import { useNavigate } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";

const CustomDown = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();

      onClick(e);
    }}
  >
    {children}

    <span className="ProfileContent">
      <img
        src={ProfileIamge}
        alt="ProfileIamge"
        style={{ width: "35px", height: "35px" }}
      />
    </span>
  </a>
));
const UserProfileView = () => {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;

  const UserToken = Authentication.Token;

  const { search } = useLocation();
  const UserId = new URLSearchParams(search).get("id");

  const [User_Name, setUser_Name] = useState("");
  const [Email, setEmail] = useState("");
  const [Team_Name, setTeam_Name] = useState("");
  const [TaskCount, setTaskCount] = useState(0);
  const [UserProjectList, setUserProjectList] = useState([]);
  const [Role_Name, setRole_Name] = useState("");
  const [storedProfileImage, setStoredProfileImage] = useState("");
  const [shift, setshift] = useState("");

  let projectCount = UserProjectList.length;
  let fullName = [];
  let initials = "";
  fullName = User_Name.split(" ");
  if (fullName.length === 2) {
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  } else {
    initials = fullName.shift().charAt(0);
  }

  let navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getUserPermission();
      getSpecificUser();
    }
  }, []);

  const getSpecificUser = () => {
    GetUserView(UserId)
      .then((response) => {
        setUser_Name(response.data.Data.UserName);
        setEmail(response.data.Data.Email);
        setTeam_Name(response.data.Data.TeamName);
        setRole_Name(response.data.Data.RoleName);
        setTaskCount(response.data.Data.TaskCount);
        setshift(response.data.Data.Shift_Name);
        setUserProjectList(response.data.Data.UserProjectList);
        setStoredProfileImage(response.data.Data.ProfileImage);
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  let [userReportPermission, setUserReportPermission] = useState([]);
  let [pagePermission, setPagePermission] = useState([]);

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      response.data.Data.Permissions.filter((result) => {
        if (result.Permission_Name === "User_report") {
          setUserReportPermission(
            result.SubPermissions.filter((innerRes) => {
              return innerRes.Sub_Permission_Name;
            })
          );
        }
        if (result.Permission_Name === "Projects") {
          setPagePermission(
            result.SubPermissions.filter((list) => {
              return list.Sub_Permission_Name;
            })
          );
        }
      });
    });
  };

  console.log(pagePermission);

  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="User Profile" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <div
          id="card-part-user"
          className="card display-profile-card mt-4 mb-4"
        >
          {/* begin::profile header code for md and lg screen */}
          <div className="card-header bg-white d-none d-md-block d-lg-block p-4">
            <div className="card-title m-0 d-flex justify-content-between">
              <h3 className="fw-bolder font-poppins-bold m-0 profile-details-heading">
                Profile Details
              </h3>
            </div>
          </div>

          {/* begin::profile card body*/}
          <div className="card-body">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-4 col-md-4 col-sm-12 mt-2">
                <div className="ProfileContent d-flex justify-content-center align-items-center">
                  {storedProfileImage && storedProfileImage != "null" ? (
                    <>
                      <img
                        src={storedProfileImage}
                        alt="ProfileIamge"
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="rounded-circle mb-3 d-flex justify-content-center"
                        style={{
                          width: " 150px",
                          height: "150px",
                          color: "#ffffff",
                          background:
                            "linear-gradient(264.71deg, #8F009B 18.58%, #420085 128.77%)",
                        }}
                      >
                        <div className="d-flex span-text text-uppercase">
                          {initials}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 mt-2">
                <div className="card-block">
                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <p className="m-b-10 f-w-600 font-poppins-regular">
                        User Name
                      </p>
                      {User_Name !== null ? (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          {User_Name}
                        </span>
                      ) : (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          -
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <p className="m-b-10 f-w-600 font-poppins-regular">
                        Role
                      </p>
                      {Role_Name !== null ? (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          {Role_Name}
                        </span>
                      ) : (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          -
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <p className="m-b-10 f-w-600 font-poppins-regular">
                        Email
                      </p>
                      {Email !== null ? (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          {Email}
                        </span>
                      ) : (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          -
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <p className="m-b-10 f-w-600 font-poppins-regular">
                        Team
                      </p>
                      {Team_Name !== null ? (
                        <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                          {Team_Name}
                        </span>
                      ) : (
                        <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                          -
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <p className="m-b-10 f-w-600 font-poppins-regular">
                        Project Count
                      </p>
                      {projectCount !== null ? (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          {projectCount}
                        </span>
                      ) : (
                        <span className="fw-bolder font-poppins-regular fs-6 text-dark">
                          -
                        </span>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <p className="m-b-10 f-w-600 font-poppins-regular">
                        Task Count
                      </p>
                      {TaskCount !== null ? (
                        <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                          {TaskCount}
                        </span>
                      ) : (
                        <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                          -
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <p className="m-b-10 f-w-600 font-poppins-regular">
                        Working Shift
                      </p>
                      {shift !== null ? (
                        <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                          {shift}
                        </span>
                      ) : (
                        <span className="fw-bolder fs-6 font-poppins-regular text-dark">
                          -
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::profile card body*/}
        </div>

        <div
          id="card-part-user"
          className="card display-profile-card mt-4 mb-4"
        >
          {/* begin::profile header code for md and lg screen */}
          <div className="card-header bg-white d-none d-md-block d-lg-block p-4">
            <div className="card-title m-0 d-flex justify-content-between">
              <h3 className="fw-bolder font-poppins-bold m-0 profile-details-heading">
                Projects Details
              </h3>
            </div>
          </div>

          {!UserProjectList || UserProjectList.length === 0 ? (
            <>
              <h5 className="text-secondary d-flex justify-content-center mt-5 mb-3 font-poppins-medium">
                No Result Available
              </h5>
            </>
          ) : (
            <>
              <div className="proojectDetails">
                <div className="table-responsive userProject w-100">
                  <table style={{ padding: "10px 25px", width: "100%" }}>
                    <thead className="userProjectDataHead">
                      <tr>
                        <th className="projectName">
                          {UserProjectList.length === 1
                            ? "Project (Time Spent)"
                            : "Projects (Time Spent)"}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="userProjectDetails">
                      {UserProjectList &&
                        UserProjectList.map((res, i) => {
                          return (
                            <React.Fragment key={i}>
                              <tr className="border-bottom">
                                <td>
                                  <div className="projectName">
                                    {pagePermission.map((list) => {
                                      if (
                                        list.Sub_Permission_Name ===
                                          "read_own_project" ||
                                        list.Sub_Permission_Name ===
                                          "read_all_project"
                                      ) {
                                        return (
                                          <>
                                            <Link
                                              to={`/project-details?id=${res.ProjectId}`}
                                            >
                                              <div className="Maindiv d-flex align-items-center">
                                                <div>{res.ProjectName}</div>
                                                <div className="projectTime ms-1">
                                                  ({res.WorkedHours})
                                                </div>
                                              </div>
                                            </Link>
                                          </>
                                        );
                                      } else {
                                        <>{res.ProjectName}</>;
                                      }
                                    })}
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default UserProfileView;
