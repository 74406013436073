import React, { useState, useEffect } from "react";
import { Header } from "../layoutComponent/Header";
import { useNavigate, Link, useLocation } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import {
  updateIntegration,
  integrationById,
  checkJWTToken,
} from "../authApi/AuthCRUD";

export default function IntegrationEdit() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const { search } = useLocation();
  const ID = new URLSearchParams(search).get("id");
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserToken = Authentication.Token;
  const [orginization, setOrginization] = useState("");
  const [project, setProject] = useState("");
  const [api, setAPI] = useState("");

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      getData(ID);
    }
  }, []);

  const getData = (ID) => {
    integrationById(ID)
      .then((response) => {
        setOrginization(response.data.Data.Organization);
        setProject(response.data.Data.Project_Name);
        setAPI(response.data.Data.Access_Token);
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const [formerrors, setFormErrors] = useState({
    ProjectName: "",
    PersonalAccessToken: "",
    Organization: "",
  });

  const validate = (values) => {
    const errors = {};
    if (!values.ProjectName) {
      errors.ProjectName = "Project name is required*";
    }
    if (!values.Organization) {
      errors.Organization = "Organization is required*";
    }
    if (!values.PersonalAccessToken) {
      errors.PersonalAccessToken = "Access Token is required*";
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        ProjectName: "",
        PersonalAccessToken: "",
        Organization: "",
      });
      return true;
    }
  };

  const postData = () => {
    let data = {
      Id: ID,
      ProjectName: project,
      PersonalAccessToken: api,
      Organization: orginization,
    };
    if (validate(data)) {
      updateIntegration(data)
        .then((res) => {
          navigate("/integrations");
        })
        .catch((err) => {
          console.log(err, "error");
        });
    }
  };

  return (
    <>
      <section className="m-0 d-flex">
        <div className="container-fluid">
          <Header title={"Edit Integration"} />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>

          <div className="addUsers mt-3 mb-3">
            <div className="card-body p-2 p-md-4 p-lg-5">
              <div className="integration-page">
                <h2>Settings</h2>

                <form className="mt-4">
                  <div>
                    <div className="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Organization Name <span className="text-danger">*</span>
                        <input
                          id="input-control"
                          name="application"
                          type="text"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                          defaultValue={orginization}
                          onChange={(e) => {
                            setOrginization(
                              e.target.value === ""
                                ? orginization
                                : e.target.value
                            );
                            setFormErrors({ ...formerrors, Organization: "" });
                          }}
                        />
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formerrors.Organization}
                          </span>
                        </div>
                      </label>
                      <label className="w-100 label-field mb-2 font-poppins-medium">
                        Project Name <span className="text-danger">*</span>
                        <input
                          id="input-control"
                          name="application"
                          type="text"
                          className="mt-2 form-control form-input-field"
                          autoComplete="off"
                          defaultValue={project}
                          disabled
                          style={{cursor: 'no-drop'}}
                          onChange={(e) => {
                            setProject(
                              e.target.value === "" ? project : e.target.value
                            );
                            setFormErrors({ ...formerrors, ProjectName: "" });
                          }}
                        />
                        <div className="text-alert font-poppins-medium">
                          <span role="alert" className="formErr">
                            {formerrors.ProjectName}
                          </span>
                        </div>
                      </label>

                      <div className="mt-4 mb-2">
                        <h4 className="fs-5">API Key</h4>
                      </div>

                      <div class="steps-box">
                        <div class="card step">
                          <div class="step-count"></div>
                          <div class="step-content">
                            <span className="step-heading">
                              Create a personal access token
                            </span>{" "}
                            <br />
                            <span className="font-poppins-regular">
                              From the User Settings → Access Tokens page
                            </span>
                          </div>
                        </div>
                        <div class="card step">
                          <div class="step-count"></div>
                          <div class="step-content">
                            <span className="step-heading font-poppins-medium">
                              Copy the generated access token
                            </span>
                            <br />
                            <span className="font-poppins-regular">
                              The access token appears in Your New Personal
                              Access Token field.
                            </span>
                          </div>
                        </div>
                        <div class="card">
                          <div class="step">
                            <div class="step-count"></div>
                            <div class="step-content">
                              <span className="step-heading font-poppins-medium">
                                Paste the API access key in the field below and
                                click Submit.
                              </span>
                            </div>
                          </div>
                          <div class="mt-3">
                            <label class="label-field w-100 mb-2 font-poppins-medium">
                              API access key{" "}
                              <span className="text-danger">*</span>
                              <input
                                class="form-control input-field"
                                placeholder="Enter access key"
                                defaultValue={api}
                                onChange={(e) => {
                                  setAPI(
                                    e.target.value === "" ? api : e.target.value
                                  );
                                  setFormErrors({
                                    ...formerrors,
                                    PersonalAccessToken: "",
                                  });
                                }}
                              />
                              <div className="text-alert font-poppins-medium">
                                <span role="alert" className="formErr">
                                  {formerrors.PersonalAccessToken}
                                </span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="d-flex flex-row row-manage mt-5 justify-content-center justify-content-md-end justify-content-lg-center">
                  <div className="d-flex">
                    <button className="btn  buttonDefault text-white font-poppins-medium">
                      <span
                        className="indicator-progress font-poppins-regular"
                        style={{ display: "block" }}
                        onClick={postData}
                      >
                        Submit
                        {/* <span className="spinner-border spinner-border-sm align-middle ms-2"></span> */}
                      </span>
                    </button>
                  </div>
                  <div className="d-flex">
                    <button
                      type="button"
                      id="cancel-add-leave"
                      className="btn text-white  buttonDefault cancelbtnhover font-poppins-medium"
                      data-bs-dismiss="modal"
                      style={{
                            textDecoration: "none;",
                            color: "white", background: '#8A899C'
                          }}
                    >
                      <Link
                        to="/integrations"
                        style={{
                          textDecoration: "none;",
                          color: "white",
                        }}
                      >
                        {" "}
                        Cancel
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
