import React, { useState } from "react";
import "./SignUpNew.css";
import odyseyyLogo from "../Images/formImages/odyssey-logo.svg";
import odyseyySmLogo from "../Images/formImages/odyssey-sm-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../authApi/AuthCRUD";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { TextureRounded } from "@material-ui/icons";

const forgotPasswordSchema = Yup.object().shape({
    tenantname: Yup.string()
        .required("Company name is required"),
    email: Yup.string()
        .email("Wrong email format. Enter valid email address")
        .max(50, "Maximum 50 symbols")
        .required("Email is required").trim(),
});

export default function ForgotPasswordNew() {
    const navigate = useNavigate();
    const [err,setErr] = useState(false)
    const [textErr, setTextErr] = useState('')
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            tenantname: "",
            email: "",
        },
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            let data = {
                tenantname : values.tenantname.replaceAll(" ", ""),
                email : values.email
            }
            setLoading(true);
            forgotPassword(data)
                .then((response) => {
                    setLoading(false);
                    navigate("/resend/email");
                })
                .catch((error) => {
                    setTextErr(error.response.data.Message);
                    setErr(true)
                    setLoading(false);
                });
        },
    });
    return (
        <>
            <div className="sign-up-wrapper d-none d-md-block d-lg-block d-xl-block">
                <div className="content">
                    <div className="container">
                        <div className="mb-7 d-flex justify-content-end text-white">
                            <div className="d-flex align-items-center gap-22">
                                <span className="font-poppins-regular account-head">Back to</span>
                                <Link to="/" className="text-decoration-none">
                                    <span className="text-white account-btn" role="button">
                                        <span className="font-poppins-medium">Sign in</span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-md-5 bg-left-section position-relative">
                                <div className="position-absolute logo-center">
                                    <img src={odyseyyLogo} alt="logo" />
                                </div>
                            </div>
                            <div className="col-md-7 bg-right-form">
                                <div className="row">
                                    <div className=""  style={{padding: '2.5rem 5rem'}}>
                                        <div className="text-center">
                                            <h1 className="main-heading font-poppins-bold">Forgot Password</h1>
                                            <span className="sub-heading font-poppins-regular">Please enter the registered email address</span>
                                        </div>

                                        <div>
                                        {err&&<div
                            style={{
                              border: "2px solid red",
                              padding: "5px 10px",
                              margin: "20px 0 10px 0",
                            }}
                          >
                            <span className="d-flex justify-content-center text-danger font-poppins-medium">
                            {textErr}                            </span>
                          </div>}
                                        
                                        {/* <p className="text-center mt-4" style={{fontSize: '13px', color: '#e62c29'}}></p>} */}
                                            <form className={err?"mt-1":"mt-5"} onSubmit={formik.handleSubmit}>
                                                <div className="row">
                                                    <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <label className="w-100 label-field font-poppins-medium">
                                                            Company Name*
                                                            <input
                                                                id="input-name-control"
                                                                type="text"
                                                                className={clsx(
                                                                    "mt-1 form-control input-field font-poppins-regular text-capitalize",
                                                                    {
                                                                        "is-invalid": formik.touched.tenantname && formik.errors.tenantname,
                                                                    },
                                                                    {
                                                                        "is-valid": formik.touched.tenantname && !formik.errors.tenantname,
                                                                    }
                                                                )}
                                                                autoComplete="off"
                                                                placeholder="Company Name"
                                                                {...formik.getFieldProps("tenantname")}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </label>
                                                        {formik.touched.tenantname && formik.errors.tenantname && (
                                                            <div>
                                                                <div className='text-alert font-poppins-medium'>
                                                                    <span role='alert' className="formErr">{formik.errors.tenantname}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                        <label className="w-100 label-field font-poppins-medium">
                                                            Email*
                                                            <input
                                                                id="input-email-control"
                                                                type="text"
                                                                className={clsx(
                                                                    "mt-1 form-control input-field font-poppins-regular",
                                                                    {
                                                                        "is-invalid": formik.touched.email && formik.errors.email,
                                                                    },
                                                                    {
                                                                        "is-valid": formik.touched.email && !formik.errors.email,
                                                                    }
                                                                )}
                                                                autoComplete="off"
                                                                placeholder="Enter Email"
                                                                {...formik.getFieldProps("email")}
                                                                onChange={formik.handleChange}
                                                            />
                                                        </label>
                                                        {formik.touched.email && formik.errors.email && (
                                                            <div>
                                                                <div className='text-alert font-poppins-medium'>
                                                                    <span role='alert' className="formErr">{formik.errors.email}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 d-flex justify-content-center">
                                                        <button
                                                            type="submit"
                                                            className="btn mt-5 submit-btn font-poppins-regular"
                                                        >
                                                            {!loading && <span>Reset Password</span>}
                                                            {loading && (
                                                                <span
                                                                    className="indicator-progress"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    Reset Password
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-block d-md-none d-lg-none d-xl-done">
                <div className="row m-0 p-0">
                    <div className="col-12 sm-screen-bg">
                        <div className="m-4 mt-5 d-flex justify-content-center align-items-center">
                            <span className="font-poppins-regular account-sm-head">Back to</span>&nbsp;&nbsp;
                            <Link to="/" className="text-decoration-none">
                                <span className="text-white account-sm-btn">
                                    <span>Sign in</span>
                                </span>
                            </Link>
                        </div>

                        <div className="m-5 d-flex justify-content-center">
                            <img src={odyseyySmLogo} alt="logo" />
                        </div>
                    </div>

                    <div className="row p-0 m-0">
                        <div className="col-12 bg-white">
                            <div className="mt-5 mb-5 mx-1">
                                <div className="text-center">
                                    <h1 className="main-sm-heading">Forgot Password</h1>
                                    <span className="sub-heading">Please enter the registered email address.</span>
                                </div>

                                <div>
                                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                                        <div className="row m-0">
                                            <div className="form-group col-sm-12">
                                                <label className="w-100 mb-3 label-field font-poppins-medium">
                                                    Company Name*
                                                    <input
                                                        id="input-name-control"
                                                        type="text"
                                                        className={clsx(
                                                            "mt-1 form-control input-sm-field font-poppins-regular text-capitalize",
                                                            {
                                                                "is-invalid": formik.touched.tenantname && formik.errors.tenantname,
                                                            },
                                                            {
                                                                "is-valid": formik.touched.tenantname && !formik.errors.tenantname,
                                                            }
                                                        )}
                                                        autoComplete="off"
                                                        placeholder="Company Name"
                                                        {...formik.getFieldProps("tenantname")}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.tenantname && formik.errors.tenantname && (
                                                        <div>
                                                            <div className='text-alert font-poppins-medium'>
                                                                <span role='alert' className="formErr">{formik.errors.tenantname}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row m-0">
                                            <div className="form-group col-sm-12">
                                                <label className="w-100 mb-3 label-field font-poppins-medium">
                                                    Email*
                                                    <input
                                                        id="input-email-control"
                                                        type="text"
                                                        className="mt-1 form-control input-sm-field font-poppins-regular"
                                                        autoComplete="off"
                                                        placeholder="Enter Email"
                                                        {...formik.getFieldProps("email")}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.email && formik.errors.email && (
                                                        <div>
                                                            <div className='text-alert font-poppins-medium'>
                                                                <span role='alert' className="formErr">{formik.errors.email}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12 mt-2 d-flex justify-content-center">
                                                <button
                                                    type="submit"
                                                    className="btn mt-5 submit-sm-btn font-poppins-regular"
                                                    disabled={loading}
                                                >
                                                    {!loading && <span>Reset Password</span>}
                                                    {loading && (
                                                        <span
                                                            className="indicator-progress"
                                                            style={{ display: "block" }}
                                                        >
                                                            Reset Password
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}