import React, { forwardRef, useEffect, useState } from "react";
import "../css/style.css";
import "./reports.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  getuserReport,
  getAllUserForReport,
  getTotalTimeofProjects,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import UserTaskReport from "./UserTaskReport";
import UserProductivty from "./UserProductivity";
import UserDailyActivitySummary from "./UserDailyActivitySummary";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import ClockLoader from "react-spinners/ClockLoader";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
export default function UserReport() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const loggedInUserId = Authentication.User_Id;
  const loggedInUserName = Authentication.Full_Name;
  let [userRepo, setUserRepo] = useState();
  let [projectTab, setProjectTab] = useState(true);
  let [taskTab, setTaskTab] = useState(false);
  let [productivityTab, setProductivityTab] = useState(false);
  let [dailyActivityTab, setDailyActivityTab] = useState(false);
  const [user_Id, setUser_Id] = useState(0);
  const [empList, setEmpList] = useState([]);
  const [totalTime, setTotalTime] = useState({});
  let [loading, setLoading] = useState(true);
  let [pagePermission, setPagePermission] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getUserPermission();
    emplist();
    getUsrReport(UserRole == "2" ? loggedInUserId : user_Id);
  }, []);

  const emplist = (permissionName = "user_user_report") => {
    getAllUserForReport(Tenant, permissionName)
      .then((res) => {
        if (!Array.isArray(res.data.Data)) {
          let temp = { ...res.data.Data };
          res.data.Data = [temp];
          setEmpList(res.data.Data);
        } else {
          setEmpList(res.data.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "User_report") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                emplist(list.Sub_Permission_Name);
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  const getUsrReport = (
    usrId = user_Id,
    startDate = date,
    end_date = endDate
  ) => {
    setLoading(true);
    let data = {
      tenantname: Tenant,
      userid: usrId,
      Start_Date: startDate,
      End_Date: end_date,
    };
    getuserReport(data)
      .then((res) => {
        setLoading(false);
        setUserRepo(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
    getTotalTimeofProjects(data)
      .then((result) => {
        setLoading(false);
        setTotalTime(result.data.Data);
      })
      .catch((er) => {
        setLoading(false);
      });
  };
  const showProjectTab = () => {
    setProjectTab(true);
    setTaskTab(false);
    setProductivityTab(false);
    setDailyActivityTab(false);
  };
  const showTaskTab = () => {
    setProjectTab(false);
    setTaskTab(true);
    setProductivityTab(false);
    setDailyActivityTab(false);
  };
  const showProductivityTab = () => {
    setProjectTab(false);
    setTaskTab(false);
    setProductivityTab(true);
    setDailyActivityTab(false);
  };
  const showDailyActivityTab = () => {
    setProjectTab(false);
    setTaskTab(false);
    setProductivityTab(false);
    setDailyActivityTab(true);
  };

  //date selected
  const handleStartDateChange = (selectedDate) => {
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${selectedDate
          .getDate()
          .toString()
          .padStart(2, "0")}`
      : null;
    setDate(formattedDate);
    getUsrReport(
      UserRole == "2" ? loggedInUserId : user_Id,
      formattedDate,
      endDate
    );
  };

  //date selected
  const handleEndtDateChange = (selectedDate) => {
    const formattedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${selectedDate
          .getDate()
          .toString()
          .padStart(2, "0")}`
      : null;
    setEndDate(formattedDate);
    getUsrReport(
      UserRole == "2" ? loggedInUserId : user_Id,
      date,
      formattedDate
    );
  };

  // const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="example-custom-input form-control   selectDate"
  //     onClick={onClick}
  //     onChange={onChange}
  //     ref={ref}
  //   ></input>
  // ));
  return (
    <section className="d-flex" style={{ padding: "10px 0" }}>
      <div className="container-fluid">
        <Header title="Employee Report" />
        <div className="mt-3 mb-3">
          <div className="row d-flex  align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-between align-items-center mt-1">
              <p className="lableRepo" style={{ marginRight: "5px" }} value="0">
                Employee
              </p>
              <select
                className="form-select team-select borders"
                value={user_Id}
                onChange={(e) => {
                  setUser_Id(e.target.value);
                  getUsrReport(e.target.value, date, endDate);
                }}
              >
                {pagePermission.map((list) => {
                  if (list.Sub_Permission_Name === "admin_user_report") {
                    return (
                      <>
                        <option value="0">Select Employee</option>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                  if (list.Sub_Permission_Name === "user_user_report") {
                    return (
                      <>
                        {empList.map((data, i) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                username={data.Full_Name}
                                value={data.User_Id}
                              >
                                {data.Full_Name}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </select>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center mt-1">
              <p className="lableRepo">Start&nbsp;Date</p>

              <DatePicker
                selected={date ? new Date(date) : null}
                onChange={handleStartDateChange}
                dateFormat="dd.MM.yyyy"
                className="form-control borders team-select selectDate"
                maxDate={new Date()}
                todayButton="TODAY"
              />

              {/* <input
                    type="date"
                    name="Start_Date"
                    className="form-control borders team-select selectDate"
                    defaultValue={date}
                    max={today}
                    onChange={(e) => {
                      setDate(e.target.value);
                      getUsrReport(
                        UserRole == "2" ? loggedInUserId : user_Id,
                        e.target.value,
                        endDate
                      );
                    }}
                  /> */}
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center mt-1">
              <p className="lableRepo">End&nbsp;Date</p>

              <DatePicker
                selected={endDate ? new Date(endDate) : null}
                onChange={handleEndtDateChange}
                dateFormat="dd.MM.yyyy"
                className="form-control borders team-select selectDate"
                maxDate={new Date()} // Set maximum date to today's date
                // Other props, such as max date, can be added here
              />
              {/* <input
                    type="date"
                    name="end_Date"
                    className="form-control borders team-select selectDate"
                    defaultValue={endDate}
                    min={date}
                    max={today}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      getUsrReport(
                        UserRole == "2" ? loggedInUserId : user_Id,
                        date,
                        e.target.value
                      );
                    }}
                  /> */}
            </div>
          </div>
        </div>
        {loading ? (
          <>
            <div className="d-flex justify-content-center text-center loaderDiv">
              <ClockLoader color="#6400B0" speedMultiplier={3} />
              <span className="loading">Loading....</span>
            </div>
          </>
        ) : (
          <>
            {!userRepo || userRepo.length === 0 ? (
              <>
                <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                  No Result Available
                </h5>
              </>
            ) : (
              <>
                <div
                  className="card"
                  style={{ border: "none", borderRadius: ".5rem" }}
                >
                  <div className="row p-4">
                    {totalTime ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1">
                          <div className="userRepoSumm">
                            <p className="lableRepo mt-1">Active Time</p>
                            <h1 className="fontSize">
                              {totalTime.Active_Time}
                            </h1>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1">
                          <div className="userRepoSumm">
                            <p className="lableRepo mt-1">Manual Time</p>
                            <h1 className="fontSize">{totalTime.Manual}</h1>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1">
                          <div className="userRepoSumm">
                            <p className="lableRepo mt-1">Idle Time</p>
                            <h1 className="fontSize">{totalTime.Idle_Time}</h1>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1">
                          <div className="userRepoSumm">
                            <p className="lableRepo mt-1">Total Time</p>
                            <h1 className="fontSize">
                              {totalTime.Total_Inc_Time}
                            </h1>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <h5 className="text-secondary d-flex justify-content-center my-3 font-poppins-medium">
                          No Data
                        </h5>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="card mt-4"
                  style={{
                    border: "none",
                    borderRadius: ".5rem",
                    padding: "10px 10px 13px 10px",
                  }}
                >
                  <div className="row d-flex justify-content-between">
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1 me-0 lableRepo text-center cursor-pointer repoTab"
                      onClick={showProjectTab}
                    >
                      <span
                        className={
                          projectTab ? "userReortsBtn" : "checkuserReortsBtn"
                        }
                      >
                        Projects
                      </span>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1 me-0 lableRepo text-center cursor-pointer repoTab"
                      style={{ color: taskTab ? "#6300B0" : "" }}
                      onClick={showTaskTab}
                    >
                      <span
                        className={
                          taskTab ? "userReortsBtn" : "checkuserReortsBtn"
                        }
                      >
                        Tasks
                      </span>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1 me-0 lableRepo text-center cursor-pointer repoTab"
                      style={{ color: productivityTab ? "#6300B0" : "" }}
                      onClick={showProductivityTab}
                    >
                      <span
                        className={
                          productivityTab
                            ? "userReortsBtn"
                            : "checkuserReortsBtn"
                        }
                      >
                        Productivity
                      </span>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mt-1 me-0 lableRepo text-center cursor-pointer repoTab"
                      style={{ color: dailyActivityTab ? "#6300B0" : "" }}
                      onClick={showDailyActivityTab}
                    >
                      <span
                        className={
                          dailyActivityTab
                            ? "userReortsBtn"
                            : "checkuserReortsBtn"
                        }
                      >
                        Daily Activity
                      </span>
                    </div>
                  </div>
                </div>

                <div className="table-responsive ">
                  {projectTab ? (
                    <table className="table reports">
                      <thead>
                        <tr>
                          <th
                            className="tableHead"
                            style={{ minWidth: "200px" }}
                          >
                            {" "}
                            Project{" "}
                          </th>
                          <th className="tableHead">Activity(%)</th>
                          <th className="tableHead"> Idle(%)</th>
                          <th className="tableHead "> Active Time </th>
                          <th className="tableHead "> Manual Time </th>
                          <th className="tableHead "> Idle Time </th>
                          <th className="tableHead "> Total Time </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userRepo &&
                          userRepo.map((res, i) => {
                            return (
                              <React.Fragment key={i}>
                                <tr>
                                  <td className="tableCell front-radius tableCellCenter compName">
                                    {res.Project_Name}
                                  </td>
                                  <td className=" tableCell">
                                    <div className="progress-circle">
                                      <CircularProgressbar
                                        value={res.Activity_Level}
                                        text={`${res.Activity_Level}%`}
                                        styles={buildStyles({
                                          textColor: "black",
                                          pathColor: "green",
                                        })}
                                      />
                                    </div>
                                  </td>
                                  <td className=" tableCell ">
                                    <div className="progress-circle">
                                      <CircularProgressbar
                                        value={res.Idle_Percentage}
                                        counterClockwise={true}
                                        text={`${res.Idle_Percentage}%`}
                                        styles={buildStyles({
                                          textColor: "black",
                                          pathColor: "red",
                                        })}
                                      />
                                    </div>
                                  </td>
                                  <td
                                    className=" tableCellCenter"
                                    style={{ color: "#59A561" }}
                                  >
                                    {res.Active_Time}
                                  </td>
                                  <td
                                    className=" tableCellCenter"
                                    style={{ color: "#CCAF95" }}
                                  >
                                    {res.Manual}
                                  </td>
                                  <td
                                    className=" tableCellCenter"
                                    style={{ color: "#FF7B69" }}
                                  >
                                    {res.Idle_Time}
                                  </td>
                                  <td
                                    className=" tableCellCenter back-radius"
                                    style={{ color: "#BA95CC" }}
                                  >
                                    {res.Total_Time}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}
                  {taskTab ? (
                    <UserTaskReport
                      usrId={UserRole == "2" ? loggedInUserId : user_Id}
                      startDate={date}
                      end_date={endDate}
                    />
                  ) : (
                    ""
                  )}
                  {productivityTab ? (
                    <UserProductivty
                      usrId={UserRole == "2" ? loggedInUserId : user_Id}
                      startDate={date}
                      end_date={endDate}
                    />
                  ) : (
                    ""
                  )}
                  {dailyActivityTab ? (
                    <UserDailyActivitySummary
                      usrId={UserRole == "2" ? loggedInUserId : user_Id}
                      startDate={date}
                      end_date={endDate}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </section>
  );
}
