import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useNavigate, Link } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import Dropdown from "react-bootstrap/Dropdown";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import view from "../Images/livedashboard/view.svg";
import crossIcon from "../Images/livedashboard/cross-icon.svg";
import tick from "../Images/profile.svg";
import moment from "moment";
import {
  viewAllLeaveRequest,
  viewPendingRequest,
  leaveRequest,
  checkUserPermissions,
} from "../authApi/AuthCRUD";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import ClockLoader from "react-spinners/ClockLoader";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </a>
));
export default function ApproveLeaveRequests() {
  const navigate = useNavigate();
  let [currentpage, setCurrentPage] = useState(1);
  let [pendingLeavePage, setPendingLeavePage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(1);
  let [data, setData] = useState([]);
  const [noOfPendingLeavePage, setNoOfPendingLeavePage] = useState(1);
  let [pendingLeaveData, setPendingLeaveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseMsg, setResponseMsg] = useState(false);

  useEffect(() => {
    getUserPermission();
    getLeaveData();
    getPendingLeaves();
  }, []);

  const getLeaveData = (search = "", key = "", currentPage = currentpage) => {
    let data = {
      searchValue: search,
      key: "",
      pageNumber: currentPage,
    };
    setLoading(true);
    viewAllLeaveRequest(data)
      .then((res) => {
        setLoading(false);
        setData(res.data.Data);
        setNoOfPage(res.data.TotalPages);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getPendingLeaves = (
    search = "",
    key = "",
    currentPage = pendingLeavePage
  ) => {
    let data = {
      searchValue: search,
      key: "",
      pageNumber: currentPage,
    };
    setLoading(true);
    viewPendingRequest(data)
      .then((res) => {
        setLoading(false);
        setPendingLeaveData(res.data.Data);
        setNoOfPendingLeavePage(res.data.TotalPages);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const leaveRequestHandler = (id, value) => {
    let leaveValue = 0;
    if (value === "approved") {
      leaveValue = 1;
      let data = {
        LeaveRequest_Id: id,
        IsApprove: leaveValue,
      };
      setLoading(true);
      leaveRequest(data)
        .then((res) => {
          setResponseMsg(true);
          toast.success(res.data.Message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setLoading(false);
          getLeaveData();
          getPendingLeaves();
        })
        .catch((err) => {});
    } else if (value === "cancelled") {
      leaveValue = 2;
      setLoading(true);
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to cancel this Leave Request?",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "Yes",
        cancelButtonText: "No",
        denyButtonColor: "#06970e",
        class: "swal-alert-shadow-none",
      }).then((result) => {
        if (result.isDenied) {
          let data = {
            LeaveRequest_Id: id,
            IsApprove: leaveValue,
          };
          setLoading(true);
          leaveRequest(data)
            .then((res) => {
              setResponseMsg(true);
              toast.success(res.data.Message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
              });
              setLoading(false);
              getLeaveData();
              getPendingLeaves();
            })
            .catch((err) => {});
        }
      });
    }
  };

  const searchHandler = (e) => {
    if (e.target.value == "") {
      getLeaveData();
      getPendingLeaves();
    } else {
      getLeaveData(e.target.value, "", 1);
      getPendingLeaves(e.target.value, "", 1);
    }
  };

  const viewLeavesPageHandler = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getLeaveData("", "", pageNumber);
  };

  const pendingLeavesPageHandler = (data) => {
    let pageNumber = data.selected + 1;
    setPendingLeavePage(pageNumber);
    getPendingLeaves("", "", pageNumber);
  };

  let [pagePermission, setPagePermission] = useState([]);
  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      const filteredPermissions = response.data.Data.Permissions.filter(
        (data) => {
          if (data.Permission_Name === "Approve_Leaves") {
            setPagePermission(
              data.SubPermissions.filter((list) => {
                return list.Sub_Permission_Name;
              })
            );
          }
        }
      );
    });
  };

  return (
    <>
      <section className="m-0 d-flex">
        <div className="container-fluid">
          <Header title="Leave" />
          <div className="text-start mt-3 mb-3">
            <div
              className="d-flex align-items-center"
              role="button"
              onClick={() => navigate(-1)}
            >
              <i>
                <img src={backArrow} alt="back-Arrow" />
              </i>
              <span className="font-poppins-regular backBtn">
                &nbsp;&nbsp;&nbsp;Back
              </span>
            </div>
          </div>

          <div className="mt-4 mb-4">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex">
                <div className="d-flex">
                  <ul
                    class="nav leave-tabs nav-tabs mb-1"
                    id="myTab0"
                    role="tablist"
                    style={{ gap: "40px" }}
                  >
                    <li class="leave-tabs-heading nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="home-tab0"
                        data-bs-toggle="tab"
                        data-bs-target="#view-leaves"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        View All Leaves
                      </button>
                    </li>
                    <li class="nav-item leave-tabs-heading" role="presentation">
                      <button
                        class="nav-link"
                        id="profile-tab0"
                        data-bs-toggle="tab"
                        data-bs-target="#approve-leaves"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Pending Requests
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="row">
            <div className="col-12">
              <div class="tab-content" id="myTabContent0">
                <div
                  class="tab-pane fade show active"
                  id="view-leaves"
                  role="tabpanel"
                  aria-labelledby="home-tab0"
                >
                  {loading ? (
                    <>
                      <div className="d-flex justify-content-center text-center loaderDiv">
                        <ClockLoader color="#6400B0" speedMultiplier={3} />
                        <span className="loading">Loading....</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {data == null || data.length === 0 ? (
                        <>
                          <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                            No Result Available
                          </h5>
                        </>
                      ) : (
                        <>
                          <div className="table-responsive">
                            <table
                              className="table"
                              style={{
                                borderBottomWidth: "0px",
                                borderBottomColor: "none",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th className="tableHead ps-4">
                                    Requested By
                                  </th>
                                  <th className="tableHead text-center">
                                    Leave Dates
                                  </th>
                                  <th className="tableHead text-center">
                                    Leave Type
                                  </th>
                                  <th className="tableHead">Leave Notes</th>
                                  <th className="tableHead text-center">
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((list, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <tr
                                        className="taskProjectTableData "
                                        style={{
                                          borderRadius: "30px",
                                          height: "65px",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <td className="compName front-radius">
                                          <div
                                            className="mainDiv d-flex"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <span
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {list.Full_Name}
                                            </span>{" "}
                                          </div>
                                        </td>
                                        <td className=" tableCell  text-center">
                                          <span>
                                            {moment(list.From_Date).format(
                                              "D.MM.YYYY"
                                            )}
                                          </span>
                                        </td>
                                        <td className=" tableCell  text-center">
                                          <span>{list.LeaveType}</span>
                                        </td>
                                        
                                        <td className="tableCell ">
                                          <div className="mainDiv">
                                            {list.Leave_Note}
                                          </div>
                                        </td>
                                        <td className=" tableCell back-radius text-center">
                                          {list.LeaveStatus == "Approved" ? (
                                            <>
                                              <div
                                                className="mainDiv text-center"
                                                style={{ color: "green" }}
                                              >
                                                {list.LeaveStatus}
                                              </div>
                                            </>
                                          ) : list.LeaveStatus ==
                                            "cancelled" ? (
                                            <>
                                              <div
                                                className="mainDiv text-center"
                                                style={{ color: "red" }}
                                              >
                                                Cancelled
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div
                                                className="mainDiv text-center"
                                                style={{ color: "blue" }}
                                              >
                                                {list.LeaveStatus}
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div className="mb-3">
                    {(noOfPage == 1 && (!data || data.length <= 10)) ||
                    noOfPage == 0 ? (
                      ""
                    ) : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={noOfPage}
                        onPageChange={viewLeavesPageHandler}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="approve-leaves"
                  role="tabpanel"
                  aria-labelledby="profile-tab0"
                >
                  {loading ? (
                    <>
                      <div className="d-flex justify-content-center text-center loaderDiv">
                        <ClockLoader color="#6400B0" speedMultiplier={3} />
                        <span className="loading">Loading....</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {pendingLeaveData == null ||
                      pendingLeaveData.length === 0 ? (
                        <>
                          <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                            No pending leaves
                          </h5>
                        </>
                      ) : (
                        <>
                          <div className="table-responsive">
                            <table
                              className="table"
                              style={{
                                borderBottomWidth: "0px",
                                borderBottomColor: "none",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th className="tableHead ps-4">
                                    Requested By
                                  </th>
                                  <th className="tableHead text-center ">
                                    Leave On
                                  </th>
                                  <th className="tableHead text-center">
                                    Leave Type
                                  </th>
                                  {/* <th className="tableHead text-center">
                                    Status
                                  </th> */}
                                  <th className="tableHead text-center">
                                    Leave Notes
                                  </th>
                                  <th className="tableHead text-center">
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {pendingLeaveData.map((leaveData, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr
                                        className="taskProjectTableData "
                                        style={{
                                          borderRadius: "30px",
                                          height: "65px",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        <td className="compName front-radius">
                                          <div
                                            className="mainDiv d-flex"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <span
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {leaveData.Full_Name}
                                            </span>{" "}
                                          </div>
                                        </td>
                                        <td className=" tableCell  text-center">
                                          <div className="mainDiv text-center">
                                            <span>
                                              {moment(
                                                leaveData.From_Date
                                              ).format("D.MM.YYYY")}
                                            </span>
                                          </div>
                                        </td>
                                        <td className=" tableCell  text-center">
                                          <span>{leaveData.LeaveType}</span>
                                        </td>
                                        {/* <td className=" tableCell  text-center">
                                          <div className="mainDiv text-center">
                                            {leaveData.LeaveStatus}
                                          </div>
                                        </td> */}
                                        <td className=" tableCell  text-center">
                                          <div className="mainDiv text-center">
                                            {leaveData.Leave_Note}
                                          </div>
                                        </td>
                                        <td className="tableCell back-radius">
                                          {pagePermission.map((list) => {
                                            if (
                                              list.Sub_Permission_Name ===
                                              "approve_leaves"
                                            ) {
                                              return (
                                                <>
                                                  <div className="mainDiv text-center d-flex justify-content-center align-items-center">
                                                    <img
                                                      src={tick}
                                                      alt="tick-icon"
                                                      className="tick-icon-height"
                                                      role="button"
                                                      onClick={() =>
                                                        leaveRequestHandler(
                                                          leaveData.LeaveRequest_Id,
                                                          "approved"
                                                        )
                                                      }
                                                    />
                                                    &nbsp;
                                                    <div className="cross-policy-name">
                                                      <img
                                                        src={crossIcon}
                                                        alt="cross-icon"
                                                        role="button"
                                                        style={{
                                                          height: "18px",
                                                          color: "red",
                                                        }}
                                                        onClick={() =>
                                                          leaveRequestHandler(
                                                            leaveData.LeaveRequest_Id,
                                                            "cancelled"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          })}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div className="mb-3">
                    {(noOfPendingLeavePage == 1 &&
                      (!pendingLeaveData || pendingLeaveData.length <= 10)) ||
                    noOfPendingLeavePage == 0 ? (
                      ""
                    ) : (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={noOfPendingLeavePage}
                        onPageChange={pendingLeavesPageHandler}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </section>
      {responseMsg && <ToastContainer />}
    </>
  );
}
