import React, { useEffect, useState } from "react";
import "../css/style.css";
import "./dashboard.css";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import ReactPaginate from "react-paginate";
import sorticon from "../Images/sorticon.svg";
import ModalImage from "react-modal-image";
import {
  dashboard,
  checkJWTToken,
  listTeams,
  checkUserPermissions,
  getDailyReport,
  getuserReport,
  userDashboardScreenshot,
  userDashboardTime,
} from "../authApi/AuthCRUD";
import { shallowEqual, useSelector } from "react-redux";
import { Header } from "../layoutComponent/Header";
import ClockLoader from "react-spinners/ClockLoader";
import lastWorkedOn from "../Images/livedashboard/last-worked-ss.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import moment from "moment";

export default function Dashboard(props) {
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const Tenant = Authentication.Tenant;
  const UserRole = Authentication.User_Role;
  const User_Id = Authentication.User_Id;
  const Tokens = Authentication.Token;
  // const Tokens ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJjZmE2OWJjNy1hNzUwLTQ5OWMtOWRjMy01OWZjMWMzM2MwOGIiLCJ2YWxpZCI6IjEiLCJ1c2VyaWQiOiIxMDAyIiwidGVuYW50bmFtZSI6Im5ld3NhbXRlY2giLCJVc2VyUm9sZUlkIjoiMiIsImV4cCI6MTY3MzkzMzM2MCwiaXNzIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzNjkvIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NDQzNjkvIn0.llxCpsfNd26rymrfLdP4Ws8VunGsBxiHotCet1fQ6ds";

  const [loading, setLoading] = useState(false);
  const [arrayData1, setArrayData1] = useState([]);
  let [data, setData] = useState([]);
  const [noOfPage, setNoOfPage] = useState(1);
  let [currentpage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  let [flag, setflag] = useState(false);
  const [teamId, setTeamId] = useState(0);
  let hue = 0;
  let colors = [];
  for (let j = 0; j < 10; j++) {
    let color = "hsl(" + hue + ",80%,50%)";
    colors.push(color);
    hue += 500;
  }

  let [dataPerPage, setDataPerPage] = useState(10);
  let navigate = useNavigate();

  useEffect(() => {
    getUserPermission();
  }, []);

  //API : Get Dashboard Data
  const getData = (
    page = currentpage || 1,
    keys = "",
    check = true,
    search = searchKey,
    team = teamId,
    pageNo = dataPerPage
  ) => {
    let data = {
      key: keys,
      ascdsc: check,
      searchname: search,
      pageNumber: page,
      teamId: team,
      PageSize: pageNo,
    };
    setLoading(true);
    dashboard(data)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setData(res.data.Data);
          setNoOfPage(res.data.TotalPages);
        } else {
          navigate("/unauthorize");
        }
      })
      .catch((err) => {
        navigate("/forbidden");
      });
  };

  const onSort = (key, check) => {
    if (check) setflag(false);
    else setflag(true);
    getData(currentpage, key, flag, searchKey, teamId, dataPerPage);
  };

  const handlePageClick = (data) => {
    let pageNumber = data.selected + 1;
    setCurrentPage(pageNumber);
    getData(pageNumber);
  };

  const searchHandler = (e) => {
    if (e == "") {
      getData(1, "", flag, "", teamId, dataPerPage);
    } else {
      setSearchKey(e);
      getData(1, "", flag, e, teamId, dataPerPage);
    }
  };
  // Team Wise Sorting
  function teamShorting(selected_value) {
    localStorage.setItem("selected_value", selected_value);
    getData(1, "", flag, searchKey, selected_value, dataPerPage);
  }

  const testPage = (event) => {
    if (event.key === "Enter") {
      getData(currentpage, "", flag, searchKey, teamId, dataPerPage);
    }
  };

  const teamList = () => {
    listTeams(Tenant, UserRole)
      .then((res) => {
        // console.log(res.data);
        setArrayData1(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let [pagePermission, setPagePermission] = useState([]);
  let [filteredPermissions, setfilteredPermissions] = useState([]);
  let [userReportPermission, setUserReportPermission] = useState([]);
  let [userScreenshotPermission, setUserScreenshotPermission] = useState([]);

  const getUserPermission = () => {
    checkUserPermissions().then((response) => {
      response.data.Data.Permissions.filter((result) => {
        if (result.Permission_Name === "User_report") {
          setUserReportPermission(
            result.SubPermissions.filter((innerRes) => {
              return innerRes.Sub_Permission_Name;
            })
          );
        }
        if (result.Permission_Name === "Screenshots") {
          setUserScreenshotPermission(
            result.SubPermissions.filter((innerRes) => {
              return innerRes.Sub_Permission_Name;
            })
          );
        }
      });
      const test = response.data.Data.Permissions.filter((data) => {
        if (data.Permission_Name === "Dashboard") {
          data.SubPermissions.filter((list) => {
            if (list.Sub_Permission_Name === "admin_dashboard") {
              getData();
              teamList();
            } else {
              //user
              setweekDate(dateRange);
              TimeInfo();
              UserChart();
              getReport();
              multipleUsersScreenshot();
            }
          });
          setPagePermission(
            data.SubPermissions.filter((list) => {
              return list.Sub_Permission_Name;
            })
          );
        }
      });
    });
  };

  //User Dashboard Js

  const [weekDate, setweekDate] = useState("");
  const [chartData, setChartData] = useState([]);
  const [chart, setChart] = useState(null);

  const today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  const NewDate = mm + "-" + dd + "-" + yyyy;

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  //Filtered Date two show week date on header
  let dates = [];
  for (let i = 0; i < 7; i++) {
    let date = new Date(today.getTime());
    date.setDate(date.getDate() - i);
    const dayOfWeek = daysOfWeek[date.getUTCDay()];
    const month = months[date.getUTCMonth()];
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    dates.push(`${dayOfWeek}, ${month} ${day}, ${year}`);
  }
  dates.reverse();
  const dateRange = `${dates[0]} - ${dates[dates.length - 1]}`;

  let start = `${dates[0]}`;
  var StartDate = moment(start).format("YYYY-MM-DD");
  let end = `${dates[dates.length - 1]}`;
  var EndDate = moment(end).format("YYYY-MM-DD");

  //Chart Integration and display chart
  const UserChart = () => {
    let data = {
      userid: User_Id,
      Start_Date: StartDate,
      End_Date: EndDate,
    };
    setLoading(true);
    getDailyReport(data)
      .then((res) => {
        setLoading(false);
        setChartData(res.data.Data[0].usersDates);
        getchartDetails(res.data.Data[0].usersDates);
      })
      .catch((err) => {});
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  let state = {};
  const getchartDetails = (data) => {
    let timer = [];
    let manual = [];
    let timeout = [];
    const labels = [];
    // console.log(data);
    data.map((res) => {
      labels.push(moment(res.Start_Date).format("DD.MM.YYYY"));
      timer.push(res.Active_Time);
      manual.push(res.Manual_Time);
      timeout.push(res.Idle_Time);
    });

    state = {
      labels: labels,
      datasets: [
        {
          label: "Timer",
          data: timer,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgb(75, 192, 192)",
          borderWidth: 1,
        },
        {
          label: "Manual",
          data: manual,
          backgroundColor: "rgba(201, 203, 207, 0.3)",
          borderColor: "rgb(201, 203, 207)",
          borderWidth: 1,
        },
        {
          label: "Timeout",
          data: timeout,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 1,
        },
      ],
    };
    setChart(state);
  };

  const multipleUsersScreenshot = () => {
    userDashboardScreenshot(EndDate)
      .then((res) => {
        setLoading(false);
        setScreenshotData(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "error");
      });
  };

  //Project with time integration
  let [report, setReport] = useState();
  const getReport = () => {
    let data = {
      tenantname: Tenant,
      userid: User_Id,
      Start_Date: StartDate,
      End_Date: EndDate,
    };
    getuserReport(data)
      .then((res) => {
        setLoading(false);
        setReport(res.data.Data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  //screenshot integration
  const [screenshotData, setScreenshotData] = useState([]);

  const [userTimeData, setUserTimeData] = useState([]);

  const [TodayIdleTimePer, setTodayIdleTimePer] = useState("");
  const [TodayTotalTimePer, setTodayTotalTimePer] = useState("");
  const [TotalTime, setTotalTime] = useState("");
  const [ActiveTime, setActiveTime] = useState("");
  const [TodayActiveTimePer, setTodayActiveTimePer] = useState("");
  const [IdleTime, setIdleTime] = useState("");
  const [WeekTime, setWeekTime] = useState("");
  const [SevenDayTimePer, setSevenDayTimePer] = useState("");

  const TimeInfo = () => {
    userDashboardTime()
      .then((res) => {
        setWeekTime(res.data.Data.WeekTime);
        setSevenDayTimePer(res.data.Data.SevenDayTimePer);
        setTotalTime(res.data.Data.TotalTime);
        setActiveTime(res.data.Data.ActiveTime);
        setIdleTime(res.data.Data.IdleTime);
        setTodayActiveTimePer(res.data.Data.TodayActiveTimePer);
        setTodayTotalTimePer(res.data.Data.TodayTotalTimePer);
        setTodayIdleTimePer(res.data.Data.TodayIdleTimePer);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section className="d-flex" style={{ padding: "10px 0" }}>
        <div className="container-fluid">
          <Header title="Dashboard" />

          {pagePermission.map((list) => {
            //Admin Dashboard
            if (list.Sub_Permission_Name === "admin_dashboard") {
              return (
                <>
                  <div className="contentSection mt-4 mb-4">
                    <div className="row d-flex justify-content-between align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-8 col-sm-8 col-xs-12 mt-2">
                        <div className="sb-example-3">
                          <div className="search__container">
                            <input
                              className="search-input-field"
                              type="text"
                              autoComplete="on"
                              placeholder="Search Users..."
                              onKeyUp={(e) => {
                                searchHandler(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="dashboardTeamDrop col-xl-2 col-lg-4 col-md-4 col-sm-4 col-xs-12 d-flex justify-content-center align-items-center mt-2">
                        <label
                          className="lableRepo"
                          style={{ marginRight: "10px" }}
                        >
                          Team
                        </label>
                        <select
                          className="form-select team-select"
                          name="member"
                          id="mySelect"
                          // onClick={selectOptionsColor()}
                          onChange={(e) => {
                            teamShorting(e.target.value);
                            setTeamId(e.target.value);
                          }}
                        >
                          <option value="0" id="option" onClick={getData}>
                            All Teams
                          </option>
                          {arrayData1.map((data, i) => {
                            return (
                              <option id="option" key={i} value={data.Team_Id}>
                                {data.Team_Name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <hr />

                  {loading ? (
                    <>
                      <div className="d-flex justify-content-center text-center loaderDiv">
                        <ClockLoader color="#6400B0" speedMultiplier={3} />
                        <span className="loading">Loading....</span>
                      </div>
                    </>
                  ) : (
                    <>
                      {!data || data.length === 0 ? (
                        <>
                          <h5 className="text-secondary d-flex justify-content-center mt-5 font-poppins-medium">
                            No Result Available
                          </h5>
                        </>
                      ) : (
                        <>
                          <div className="table-responsive">
                            <table
                              className="table"
                              style={{
                                borderBottomWidth: "0px",
                                borderBottomColor: "none",
                              }}
                            >
                              {/* <TableHead> */}
                              <thead>
                                <tr>
                                {
                                  data.length === 1 ? (
                                    <>
                                    <th className="tableHead ps-4 firstHead">
                                    Employee &nbsp;
                                    <button
                                      onClick={() => {
                                        onSort("Full_Name", flag);
                                      }}
                                      type="button"
                                    >
                                      <img src={sorticon} alt="icon" />
                                    </button>
                                  </th>
                                    </>
                                  ) : (
                                    <>
                                    <th className="tableHead ps-4 firstHead">
                                    Emnployees &nbsp;
                                    <button
                                      onClick={() => {
                                        onSort("Full_Name", flag);
                                      }}
                                      type="button"
                                    >
                                      <img src={sorticon} alt="icon" />
                                    </button>
                                  </th>
                                    </>
                                  )
                                }
                                  
                                  <th className="tableHead ">
                                  Latest Screenshot &nbsp;
                                  </th>
                                  <th className="tableHead ">
                                    Today &nbsp;
                                    <button
                                      onClick={() => {
                                        onSort("ActivityTodayPer", flag);
                                      }}
                                      type="button"
                                    >
                                      <img src={sorticon} alt="icon" />
                                    </button>
                                  </th>
                                  <th className="tableHead ">
                                    Yesterday &nbsp;
                                    <button
                                      onClick={() => {
                                        onSort("ActivityYesterday", flag);
                                      }}
                                      type="button"
                                    >
                                      <img src={sorticon} alt="icon" />
                                    </button>
                                  </th>
                                  <th className="tableHead ">
                                    Last 7 Days &nbsp;
                                    <button
                                      onClick={() => {
                                        onSort("Activity7dayPer", flag);
                                      }}
                                      type="button"
                                    >
                                      <img src={sorticon} alt="icon" />
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data &&
                                  data.map((res, i) => (
                                    <>
                                      <tr>
                                        <td className="tableCell front-radius compName">
                                          <div
                                            className="mainDiv d-flex"
                                            style={{
                                              marginLeft: "10px",
                                              paddingTop: "12px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                                borderRadius: "13px",
                                                textAlign: "center",
                                                color: "#fff",
                                                paddingTop: "2px",
                                                backgroundColor:
                                                  colors[
                                                    Math.floor(
                                                      Math.random() *
                                                        colors.length
                                                    )
                                                  ],
                                              }}
                                            >
                                              {res.Full_Name.charAt(
                                                0
                                              ).toUpperCase()}
                                            </div>
                                            <span
                                              style={{ marginLeft: "10px" }}
                                            ></span>{" "}
                                            {res.Full_Name}
                                          </div>

                                          <div
                                            className="timePercentage"
                                            style={{ marginLeft: "3.25rem" }}
                                          >
                                            {res.ActiveStatus == "Online" && (
                                              <span
                                                style={{ color: "#46995D" }}
                                              >
                                                ●{" "}
                                              </span>
                                            )}
                                            {res.ActiveStatus}
                                          </div>
                                        </td>
                                        <td className=" tableCell otherItem">
                                          <Link
                                            to={`/screenshots?id=${res.User_Id}`}
                                          >
                                            <div
                                              className="d-flex position-relative"
                                              onClick={() => {
                                                localStorage.setItem(
                                                  "selectedUser",
                                                  res.User_Id
                                                );
                                              }}
                                            >
                                              <img
                                                src={lastWorkedOn}
                                                alt="ss"
                                                className="ss-blur-img"
                                              />
                                              <span className="click-to-view">
                                                Click to view
                                              </span>
                                            </div>
                                          </Link>
                                        </td>
                                        <td className=" tableCell otherItem">
                                          <div
                                            className="mainDiv "
                                            style={{ paddingTop: "10px" }}
                                          >
                                            <div>{res.ActivityToday}</div>
                                            <div className="timePercentage">
                                              {res.ActivityTodayPer}
                                            </div>
                                          </div>
                                        </td>
                                        <td className=" tableCell otherItem">
                                          <div
                                            className="mainDiv "
                                            style={{ paddingTop: "10px" }}
                                          >
                                            <div>{res.ActivityYesterday}</div>
                                            <div className="timePercentage">
                                              {res.ActivityYesterdayPer}
                                            </div>
                                          </div>
                                        </td>
                                        <td className=" tableCell back-radius otherItem">
                                          <div
                                            className="mainDiv "
                                            style={{ paddingTop: "10px" }}
                                          >
                                            <div>{res.Activity7day}</div>
                                            <div className="timePercentage">
                                              {res.Activity7dayPer}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {(noOfPage == 1 && (!data || data.length <= 10)) ||
                  noOfPage == 0 ? (
                    ""
                  ) : (
                    <div className="paginations d-flex justify-content-between align-items-center mt-2">
                      <div className="d-flex justify-content-center align-items-center">
                        <p className="lableRepo">Rows per page : </p>
                        <input
                          id="input-control"
                          name="policy"
                          type="text"
                          className="form-control w-20 text-center team-select"
                          autoComplete="off"
                          style={{
                            borderRadius: "6px",
                            height: "30px",
                          }}
                          defaultValue={dataPerPage}
                          onChange={(e) => setDataPerPage(e.target.value)}
                          onKeyDown={testPage}
                        />
                      </div>
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={noOfPage}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  )}
                </>
              );
            }
            //User Dashboard
            else {
              return (
                <>
                  <section className="UserDashboard">
                    <div className="date">{weekDate}</div>

                    <div className="row userTimeData text-center">
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="userTimeCard">
                          <div className="header">Total Time today</div>
                          <div className="userTime">{TotalTime}</div>
                          <div className="timeIncDec">
                            <span>{TodayTotalTimePer}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="userTimeCard">
                          <div className="header">Total Active today</div>
                          <div className="userTime">{ActiveTime}</div>
                          <div className="timeIncDec ">
                            <span>{TodayActiveTimePer}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="userTimeCard">
                          <div className="header">Idle Time</div>
                          <div className="userTime">{IdleTime}</div>
                          <div className="timeIncDec idleTime">
                            <span>{TodayIdleTimePer}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div className="userTimeCard">
                          <div className="header">Worked This Week</div>
                          <div className="userTime">{WeekTime}</div>
                          <div className="timeIncDec">
                            <span>{SevenDayTimePer}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row userSummary">
                      <div className="col-lg-8 col-md-12 col-sm-12 chart">
                        <div className="header d-flex justify-content-between align-items-center">
                          <div>Daily Activity Summary</div>
                          {/* <div className="downloadbtn">Download</div> */}
                        </div>
                        <div className="userChart">
                          {chart && (
                            <Bar
                              data={chart}
                              options={{
                                scales: {
                                  y: {
                                    ticks: {
                                      callback: function (value) {
                                        return (
                                          ((value / this.max) * 100).toFixed(
                                            0
                                          ) + "%"
                                        ); // convert it to percentage
                                      },
                                    },
                                  },
                                },
                                title: {
                                  display: true,
                                  text: "Average Rainfall per month",
                                  fontSize: 20,
                                  maintainAspectRatio: false,
                                },
                                legend: {
                                  display: true,
                                  position: "right",
                                },
                              }}
                              style={{ width: "100%", height: "auto" }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 project">
                        <div className="header">Project Summary</div>

                        <div className="table-responsive userProject">
                          <table>
                            <thead className="userProjectDataHead">
                              <tr>
                                <th className="projectName">Project & Task</th>
                                <th className="spentTime">Time Spent</th>
                              </tr>
                            </thead>
                            <tbody className="userProjectDetails">
                              {report &&
                                report.slice(0, 3).map((res, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <tr className="border-bottom">
                                        <td>
                                          <div className="projectName">
                                            {res.Project_Name}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="projectTime d-flex justify-content-center align-items-center">
                                            <div>{res.Total_Time}</div>
                                            <div
                                              className="progress-circle"
                                              style={{
                                                width: "35px",
                                                height: "35px",
                                                marginLeft: "5px",
                                              }}
                                            >
                                              <CircularProgressbar
                                                value={res.Activity_Level}
                                                counterClockwise={true}
                                                text={`${res.Activity_Level}%`}
                                                styles={buildStyles({
                                                  textColor: "black",
                                                  pathColor: "green",
                                                })}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                            </tbody>
                          </table>
                          {report && report.length === 4 ? (
                            <div className="viewAllProjectReport">
                              {userReportPermission.map((list) => {
                                if (
                                  list.Sub_Permission_Name ===
                                    "admin_user_report" ||
                                  list.Sub_Permission_Name ===
                                    "user_user_report"
                                ) {
                                  return (
                                    <>
                                      <Link to={"/user-report"} className="">
                                        View All {">"}
                                      </Link>
                                    </>
                                  );
                                }
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row recentActivity">
                      <div className="header d-flex justify-content-between align-items-center">
                        <div className="header">
                          Recent Activity <span>({NewDate})</span>{" "}
                        </div>
                        <div className="downloadbtn">
                          {userScreenshotPermission.map((list) => {
                            if (
                              list.Sub_Permission_Name === "admin_screenshot" ||
                              list.Sub_Permission_Name === "user_screenshot"
                            ) {
                              return (
                                <>
                                  <Link to={"/screenshots"} className="">
                                    View All {">"}
                                  </Link>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                      {!screenshotData || screenshotData.length === 0 ? (
                        <>
                          <div
                            className="centerDiv mt-3"
                            style={{ textAlign: "center" }}
                          >
                            <h5 className="text-secondary ">
                              No screenshots available
                            </h5>
                          </div>
                        </>
                      ) : (
                        <div className="table-responsive">
                          <table>
                            <thead></thead>
                            <tbody className="d-flex justify-content-between align-items-center">
                              {screenshotData &&
                                screenshotData.map((res, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <div className="screenshotRow">
                                        <div className="screenshotTime">
                                          {res.ScreenShot_Time}
                                        </div>
                                        <div>
                                          <ModalImage
                                            small={res.ScreenShot}
                                            large={res.ScreenShot}
                                          />
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </section>
                </>
              );
            }
          })}
        </div>
      </section>
    </>
  );
}
