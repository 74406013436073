const initialAuthState = {
  data: undefined,
  token: undefined,
  userProfile: undefined,
};

const auth = (state = initialAuthState, action) => {
  switch (action.type) {
    case "REGISTER": {
      const activationCode = action.payload.data;
      return { ...state, activationCode };
    }
    case "LOGIN": {
      const token = action.payload.token;
      const data = action.payload.data;

      return { ...state, token, data };
    }
    case "PROFILE": {
      const userProfile = action.payload.userProfile;
      return { ...state, userProfile }
    }
    case "UPDATEPROFILE": {
      const data = action.payload.data
      return { ...state, data }
    }
    case "LOGOUT": {
      return { initialAuthState };
    }

    default:
      return state;
  }
};

export default auth;
