import axios from "axios";
import store from "../store";
import jwtDecode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL || "api";


axios.interceptors.request.use(
  (config) => {
    let token = store.getState().auth.token;
    if (token) {
      if (checkJWTToken(token)) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }
    } else {
      return config;
    }
  },
  (error) => {
    Promise.reject(error);
  }
);

export function checkJWTToken(Token) {
  const token = Token;
  if (!token) {
    return false;
  } else {
    const decodedToken = jwtDecode(token);
    const expiryTime = decodedToken.exp;
    if (expiryTime * 1000 < Date.now()) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}



export function login(data) {
  return axios.post(`${API_URL}/api/Auth/loginUser`, data);
}

export function register(data) {
  return axios.post(`${API_URL}/api/Auth/SignUp`, data);
}

export function resend(data) {
  return axios.post(`${API_URL}/api/Auth/ResendSignUpMail`, data);
}

export function userVerification(data) {
  return axios.post(`${API_URL}/api/Auth/UserVerification`, data);
}

export function forgotPassword(data) {
  return axios.post(`${API_URL}/api/Auth/forgotPassword`, data);
}

export function updatePassword(data) {
  return axios.post(`${API_URL}/api/Auth/UpdateForgotPassword`, data);
}

export function verifyEmailForgetPass(data) {
  return axios.post(`${API_URL}/admin/`, data);
}

export function dashboard(data) {
  return axios.get(
    `${API_URL}/api/Dashboard/AdminNewDashboard?sortOrder=${data.ascdsc}&searchValue=${data.searchname}&sortCoulumn=${data.key}&teamId=${data.teamId}&PageNumber=${data.pageNumber}&PageSize=${data.PageSize}`
  );
}

export function listTeams(tenantname, roleid) {
  return axios.get(
    `${API_URL}/api/AdminUser/ListTeams?tenantname=${tenantname}`
  );
}

export function teamSorting(data) {
  return axios.get(
    `${API_URL}/api/Dashboard/AdminDashboardByTeamId?tenantname=${data.tenantname}&teamid=${data.teamid}`
  );
}

export function getAdmins(data) {
  return axios.get(
    `${API_URL}/api/AdminUser/AllAdminUser?tenantname=${data.tenantname}&orderby=${data.ascdsc}&status=${data.status}&key=${data.key}&searchname=${data.searchname}&PageNumber=${data.pageNumber}&PageSize=${data.pagesize}`
  );
}

export function sortUsers(data, flag) {
  if (flag) {
    return axios.get(
      `${API_URL}/api/AdminUser/AllAdminUserASC?tenantname=${data.tenantname}&PageSize=10&PageNumber=${data.PageNumber}&status=${data.status}`
    );
  }
  return axios.get(
    `${API_URL}/api/AdminUser/AllAdminUserDSC?tenantname=${data.tenantname}&PageSize=10&PageNumber=${data.PageNumber}&status=${data.status}`
  );
}

export function getAdminsByStatus(data) {
  return axios.get(
    `${API_URL}/api/AdminUser/AllAdminUser?tenantname=${data.tenantname}&PageSize=10&status=${data.status}`
  );
}

export function getAdminsByTeam(data) {
  return axios.get(`${API_URL}/admin/AllAdminUserByTeam`, data);
}
export function disable_User(data) {
  return axios.post(`${API_URL}/api/AdminUser/DisableUser`, data);
}

export function enable_User(data) {
  return axios.post(`${API_URL}/api/AdminUser/EnableUser`, data);
}

export function get_Roles(Tenant) {
  return axios.get(`${API_URL}/api/AdminUser/GetRoles?tenantname=${Tenant}`);
}

export function adminProjectsDropDown(Tenant) {
  return axios.get(
    `${API_URL}/api/AdminUser/AdminProjectsDropDown?tenantname=${Tenant}`
  );
}

export function userUpdate(data) {
  return axios.post(`${API_URL}/api/AdminUser/UpdateUser`, data);
}

export function assignProject(data) {
  return axios.post(`${API_URL}/api/AdminUser/AssignProjectToUser`, data);
}

export function getUserById(Tenant, User_Id) {
  return axios.get(
    `${API_URL}/api/AdminUser/GetUserById?tenantname=${Tenant}&userid=${User_Id}`
  );
}

export function getProfile(Tenant, UserRole, User_Id) {
  return axios.get(
    `${API_URL}/admin/GetViewUserById?tenantname=${Tenant}&roleid=${UserRole}&userid=${User_Id}`
  );
}

export function clientList(data) {
  return axios.get(
    `${API_URL}/api/Client/GetClient?tenantname=${data.Tenant}&sortOrder=true&PageNumber=${data.page}&PageSize=10`
  );
}

export function getClient(tenant, cId) {
  return axios.get(
    `${API_URL}/api/Client/GetClientById?tenantname=${tenant}&clientid=${cId}`
  );
}

export function updateClient(data) {
  return axios.post(`${API_URL}/api/Client/UpdateClient`, data);
}

export function clientDelete(tenant, cId) {
  return axios.post(
    `${API_URL}/api/Client/DisableClient?tenantname=${tenant}&clientid=${cId}`
  );
}

export function enable_Project(Tenant, id) {
  return axios.get(`${API_URL}/api/Project/EnableProject?projectid=${id}`);
}

export function disable_Project(Tenant, id) {
  return axios.get(`${API_URL}/api/Project/DisableProject?projectid=${id}`);
}

export function createClient(data) {
  return axios.post(`${API_URL}/api/Client/CreateClient`, data);
}

// export function createTeam(teamname) {
//   return axios.post(
//     `${API_URL}/admin/CreateTeam?tenantname=zehntech&userroleid=2&teamname=${teamname}`
//   );
// }

export function createRole(Tenant, UserRole, Role_Name) {
  return axios.post(
    `${API_URL}/admin/CreateRole?tenantname=${Tenant}&userroleid=${UserRole}&rolename=${Role_Name}`
  );
}

export function viewTasks(tenantname, role, TaskId) {
  return axios.get(
    `${API_URL}/admin/GetViewTaskById?tenantname=${tenantname}&roleid=${role}&taskid=${TaskId}`
  );
}

export function getUserInfoScreenShots(date, Tenant, userId) {
  return axios.get(
    `${API_URL}/api/ScreenShot/GetUserTimeInfo?date=${date}&tenantname=${Tenant}&userid=${userId}`
  );
}

export function getUserScreenShots(date, Tenant, userId) {
  return axios.get(
    `${API_URL}/api/ScreenShot/GetUserScreenShot?date=${date}&tenantname=${Tenant}&userid=${userId}`
  );
}

export function createProjects(data) {
  return axios.post(`${API_URL}/api/Project/PostProject`, data);
}

export function createTask(data) {
  return axios.post(`${API_URL}/api/Task/CreateTask`, data);
}

export function getTasksById(taskId) {
  return axios.get(`${API_URL}/api/Task/GetTaskById?taskId=${taskId}`);
}

export function updateTasks(data) {
  return axios.post(`${API_URL}/api/Task/UpdateTaskById`, data);
}

export function getTasksDropdown(pId) {
  return axios.get(`${API_URL}/api/Task/GetProjectTasks?projectid=${pId}`);
}

export function getTasksByProject(data) {
  return axios.get(
    `${API_URL}/api/Task/GetProjectTasks?projectid=${data.projectId}&status=${data.status}&searchname=${data.searchname}&isSort=${data.isSort}&sortColumn=${data.sortColumn}&sortType=${data.sortType}&PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`
  );
}

export function getTaskByProjectStatus(data) {
  return axios.get(`${API_URL}/admin/GetTaskByProjectByStatus`, data);
}

export function getAdminProjects(data) {
  return axios.get(
    `${API_URL}/api/Project/GetPojectSorted?tenantname=${data.tenant}&OrderBy=${data.ascdsc}&searchvalue=${data.searchname}&key=${data.key}&pageno=${data.pageNumber}&pagesize=${data.PageSize}`
  );
}

export function getAdminProjectsByTeam(Tenant, UserRole, selected_value) {
  return axios.get(
    `${API_URL}/admin/AdminProjectsByTeam?tenantname=${Tenant}&roleid=${UserRole}&teamid=${selected_value}`
  );
}

export function getProjectById(projectId) {
  return axios.get(
    `${API_URL}/api/Project/GetProjectById?projectId=${projectId}`
  );
}

export function updateProject(data) {
  return axios.post(`${API_URL}/api/Project/UpdateProject`, data);
}

export function addUser(data) {
  return axios.post(`${API_URL}/api/AdminUser/AddNewUser`, data);
}

export function getCompanySetting(data) {
  return axios.get(
    `${API_URL}/api/CompanySetting/GetCompanySettings?companyemail=${data.companyemail}&tenantname=${data.tenantname}`
  );
}

export function setCompanyLogo(data) {
  return axios.post(
    `${API_URL}/api/CompanySetting/SetCompanySettingsLogo`,
    data
  );
}

export function setCompanySetting(data) {
  return axios.post(`${API_URL}/api/CompanySetting/SetCompanySettings`, data);
}
export function getEmailTemplateList() {
  return axios.get(`${API_URL}/api/EmailTemplateType/GetAllEmailTemplates`);
}
export function EditEmailTemplateList(
  list_id,
  cc_email,
  bcc_email,
  subject,
  body
) {
  return axios.put(
    `${API_URL}/api/EmailTemplateType/PutEmailTemplate/${list_id}`,
    {
      cc_email,
      bcc_email,
      subject,
      body,
    }
  );
}

export function GetEmailTemplateDetail(list_id) {
  return axios.get(
    `${API_URL}/api/EmailTemplateType/GetEmailTemplate/${list_id}`
  );
}
export function deleteEmailTemplate(id) {
  return axios.delete(
    `${API_URL}/api/EmailTemplateType/DeleteEmailTemplate/${id}`
  );
}

export function setDataFortest(data) {
  // return axios.post(`http://192.168.0.140:8077/api/Project`,data);
}

export function getDataFortest() {
  // return axios.get(`http://192.168.0.140:8077/api/Project?tenantname=zehntech`);
}
export function getProjectDrop(data) {
  return axios.get(
    `${API_URL}/api/Reports/getAllProjectList?tenantname=${data}`
  );
}
export function getProjectReport(data) {
  return axios.get(
    `${API_URL}/api/Reports/getProjectReport?tenantname=${data.tenantname}&projectid=${data.projectId}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}&PageNumber=${data.pageNumber}&PageSize=10`
  );
}
export function getDailyAttendance(data) {
  return axios.get(
    `${API_URL}/api/Reports/GetDailyAttendance?tenantname=${data.tenantname}&userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}&PageNumber=${data.pageNumber}&PageSize=10`
  );
}
export function getSummaryReport(data) {
  return axios.get(
    `${API_URL}/api/Reports/GetSummaryReport?tenantname=${data.tenantname}&userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}`
  );
}
export function getuserReport(data) {
  return axios.get(
    `${API_URL}/api/reports/getUserProjectReport?tenantname=${data.tenantname}&userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}`
  );
}
export function getUserTaskReport(data) {
  return axios.get(
    `${API_URL}/api/reports/getUserTaskReport?tenantname=${data.tenantname}&userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}`
  );
}
export function searchMember(tenant, teamId, value) {
  return axios.get(
    `${API_URL}/api/Dashboard/AdminSearchDashboard?tenantname=${tenant}&teamid=${teamId}&searchname=${value}`
  );
}

export function getAllUserForReport(Tenant, permissionName) {
  return axios.get(
    `${API_URL}/api/Reports/GetAllUserForReport?tenantname=${Tenant}&permissionName=${permissionName}`
  );
}
export function getAllUserForTask(data, id) {
  return axios.get(
    `${API_URL}/api/Task/GetAllUserForReport?tenantname=${data}&projectid=${id}`
  );
}

export function getTotalTimeofProjects(data) {
  return axios.get(
    `${API_URL}/api/reports/getAllProjectData?tenantname=${data.tenantname}&userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}`
  );
}

export function addLeaveType(data) {
  return axios.post(`${API_URL}/api/LeaveTypes/AddLeaveTypes`, data);
}

export function employeeHourlyRateList(data) {
  return axios.get(
    `${API_URL}/api/HourlyRate/GetUserHourlyRate?searchname=${data.search}&Key=${data.key}&orderby=${data.ascdsc}&PageNumber=${data.pageNumber}&PageSize=${data.pageSize}`
  );
}

export function searchProjectName(tenantName, searchName) {
  return axios.get(
    `${API_URL}/api/Project/GetSeachProject?tenantname=${tenantName}&searchname=${searchName}`
  );
}

export function getListLeaveType(data) {
  return axios.get(
    `${API_URL}/api/LeaveTypes/GetNewLeaveTypes?sortOrder=${data.sortType}&searchValue=${data.searchname}&sortCoulumn=${data.sortColumn}&PageNumber=${data.PageNumber}&PageSize=10`
  );
}

export function getNewRoles(data) {
  return axios.get(
    `${API_URL}/api/Permission/GetAllRoles?sortOrder=${data.sortType}&searchValue=${data.searchname}&sortCoulumn=${data.sortColumn}&PageNumber=${data.PageNumber}&PageSize=${data.pageSize}`
  );
}

export function addRolePermission(data) {
  return axios.post(`${API_URL}/api/Permission/AddNewRole`, data);
}

export function getPermissions() {
  return axios.get(`${API_URL}/api/Permission/GetAllPermission`);
}

export function roleById(roleId) {
  return axios.get(`${API_URL}/api/Permission/GetRoleById?roleId=${roleId}`);
}
export function updateRole(data) {
  return axios.post(`${API_URL}/api/Permission/UpdateRole`, data);
}

export function enableRole(role) {
  return axios.put(`${API_URL}/api/Permission/EnableRole?role_Id=${role}`);
}

export function disableRole(role) {
  return axios.put(`${API_URL}/api/Permission/DisableRole?role_Id=${role}`);
}

export function deleteLeaveType(tenant, id) {
  return axios.post(
    `${API_URL}/api/LeaveTypes/DeleteLeaveTypesById?tenantname=${tenant}&leavetypeid=${id}`
  );
}

export function changePassword(formData) {
  return axios.post(`${API_URL}/api/UserProfile/UpdateUserPassword`, formData);
}

export function getUserProfile(tenantname, email) {
  return axios.get(
    `${API_URL}/api/UserProfile/GetUserProfile?tenantname=${tenantname}&email=${email}`
  );
}

export function updateUserProfile(profileData) {
  return axios.post(
    `${API_URL}/api/UserProfile/UpdateUserProfile`,
    profileData
  );
}

export function uploadProfileLogo(formData) {
  return axios.post(
    `${API_URL}/api/UserProfile/UpdateUserProfileLogo`,
    formData
  );
}

export function getProjectDetails(projectId) {
  return axios.get(
    `${API_URL}/api/Project/GetProjectViewById?projectId=${projectId}`
  );
}
export function searchLeave(tenant, teamId, value) {
  return axios.get(
    `${API_URL}/api/project/GetSearchLeave?tenantname=${tenant}&searchname=${value}`
  );
}
export function getTime(userid, date) {
  return axios.get(
    `${API_URL}/api/ManualTimeEdit/GetNewUserTimeLine?userid=${userid}&date=${date}`
  );
}
export function setTime(data) {
  return axios.post(`${API_URL}/api/ManualTimeEdit/AddManualTimeEntry`, data);
}
export function screenshotDelete(id) {
  return axios.post(
    `${API_URL}/api/ScreenShot/DisableScreenShot?screenshotid=${id}`
  );
}

export function addNewPolicy(name) {
  return axios.post(`${API_URL}/api/Policy/PostPolicy?policyname=${name}`);
}
export function getPolicies() {
  return axios.get(`${API_URL}/api/Policy/GetAllPolicy`);
}
export function deleteOnePolicy(id) {
  return axios.delete(`${API_URL}/api/Policy/DeletePolicy/${id}`);
}
export function getApplicationsByPolicy(data) {
  return axios.get(
    `${API_URL}/api/Application/GetApplicationByPolicy?tenantname=${data.tenantname}&policyid=${data.policyid}`
  );
}
export function addApplications(data) {
  return axios.post(`${API_URL}/api/Application/CreateApplication`, data);
}
export function deleteApplications(id) {
  return axios.post(
    `${API_URL}//api/Application/DisableApplication?application=${id}`
  );
}
export function updateApplications(data) {
  return axios.post(`${API_URL}/api/Application/UpdateApplication`, data);
}
export function updateLeave(data) {
  return axios.post(`${API_URL}/api/LeaveTypes/UpdateLeaveTypes`, data);
}
export function getLeaveTypeById(tenant, leaveid) {
  return axios.get(
    `${API_URL}/api/LeaveTypes/GetLeaveTypesById?tenantname=${tenant}&leavetypeid=${leaveid}`
  );
}

export function getTeams(data) {
  return axios.get(
    `${API_URL}/api/Team/GetTeams?tenantname=${data.tenantname}&OrderBy=${data.sortOrdr}&searchValue=${data.searchVal}&key=${data.colName}&pageno=${data.pageNumber}&pagesize=${data.pagesize}`
  );
}

export function createTeam(data) {
  return axios.post(`${API_URL}/api/Team/CreateTeam`, data);
}
export function updateTeam(data) {
  return axios.post(`${API_URL}/api/Team/UpdateTeam`, data);
}
export function getUserbyTeam(data) {
  return axios.get(
    `${API_URL}/api/Team/GetUsersByTeam?tenantname=${data.tenantname}&teamid=${data.id}`
  );
}
export function addUserinTeam(data) {
  return axios.post(
    `${API_URL}//api/Team/AddUsersfromTeam?tenantname=${data.tenantname}&teamid=${data.tid}&userid=${data.uid}`
  );
}
export function removeUserfromteam(data) {
  return axios.post(
    `${API_URL}/api/Team/RemoveUsersfromTeam?tenantname=${data.tenantname}&userid=${data.id}`
  );
}
export function getUserProductivity(data) {
  return axios.get(
    `${API_URL}/api/Reports/getProductivityReport?userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}`
  );
}
export function getEmpLogs(data) {
  return axios.get(
    `${API_URL}/api/Reports/getemployeelogreport?tenantname=${data.tenantname}&userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}&PageNumber=${data.page}&PageSize=${data.pagesize}`
  );
}
export function getDailyReport(data) {
  return axios.get(
    `${API_URL}/api/Reports/getDailyActivitySummary?userid=${data.userid}&Start_Date=${data.Start_Date}&End_Date=${data.End_Date}`
  );
}
export function getDesktopApplication(data) {
  return axios.get(
    `${API_URL}/api/DesktopDownload/GetDesktopApplicationVersions?sortOrder=${data.sortOrdr}&searchValue=${data.searchname}&sortCoulumn=${data.colName}&PageNumber=${data.pageNumber}&PageSize=10`
  );
}

export function uploadDesktopApplication(data) {
  return axios.post(
    `${API_URL}/api/DesktopDownload/UploadDesktopApplication`,
    data
  );
}

export function enableApplication(version) {
  return axios.put(
    `${API_URL}/api/DesktopDownload/EnableDesktopApplicationVersion?version_Id=${version}`
  );
}

export function disableApplication(version) {
  return axios.put(
    `${API_URL}/api/DesktopDownload/DisableDesktopApplicationVersion?version_Id=${version}`
  );
}

export function updateDesktopApplication(data) {
  return axios.put(
    `${API_URL}/api/DesktopDownload/UpdateDesktopApplication`,
    data
  );
}

export function applicationByVersionId(version) {
  return axios.get(
    `${API_URL}/api/DesktopDownload/GetDesktopApplicationByVersionId?version_Id=${version}`
  );
}

export function downloadApplication() {
  return axios.get(
    `${API_URL}/api/DesktopDownload/GetDesktopApplicationVersionsList`
  );
}

export function azureProjectIntegration(data) {
  return axios.post(`${API_URL}/api/AzureProject/PostAzureProject`, data);
}

export function getIntegrations() {
  return axios.get(`${API_URL}/api/AzureProject/GetAllIntegration`);
}

export function updateIntegration(data) {
  return axios.put(`${API_URL}/api/AzureProject/UpdateIntegration`, data);
}

export function integrationById(id) {
  return axios.get(`${API_URL}/api/AzureProject/GetIntegrationById?id=${id}`);
}

export function deleteIntegration(id) {
  return axios.delete(
    `${API_URL}/api/AzureProject/DeleteIntegrationById?id=${id}`
  );
}

export function userLeaves(data) {
  return axios.get(
    `${API_URL}/api/LeaveRequest/GetUserLeaves?userid=${data.userId}&PageNumber=${data.pageNumber}&PageSize=10`
  );
}

export function deleteLeaveRequest(Id) {
  return axios.delete(
    `${API_URL}/api/LeaveRequest/DeleteLeaveRequest?leaveRequestId=${Id}`
  );
}

export function listofLeaves() {
  return axios.get(`${API_URL}/api/LeaveRequest/GetListOfLeaves`);
}

export function postLeaveRequest(data) {
  return axios.post(`${API_URL}/api/LeaveRequest/CreateLeaveRequest`, data);
}

export function getLeaveById(id) {
  return axios.get(
    `${API_URL}/api/LeaveRequest/GetLeaveRequestById?leaveRequestId=${id}`
  );
}

export function updateLeaveRequest(data) {
  return axios.put(`${API_URL}/api/LeaveRequest/UpdateLeaveRequest`, data);
}

export function userDropdown(tenantName) {
  return axios.get(
    `${API_URL}/api/LeaveRequest/NotifyUserDropDown?tenantname=${tenantName}`
  );
}

export function teamCalendar(date) {
  return axios.get(
    `${API_URL}/api/LeaveRequest/GetLeaveCalendarByTeam?fromDate=${date}`
  );
}

export function showLeave(date) {
  return axios.get(
    `${API_URL}/api/LeaveRequest/GetTeamLeaveInfoByDate?date=${date}`
  );
}

export function viewAllLeaveRequest(data) {
  return axios.get(
    `${API_URL}/api/LeaveTypes/GetAllLeaves?OrderBy=true&searchValue=${data.searchValue}&key=${data.key}&pageno=${data.pageNumber}&pagesize=10`
  );
}

export function viewPendingRequest(data) {
  return axios.get(
    `${API_URL}/api/LeaveTypes/GetAllPendingLeaves?OrderBy=true&searchValue=${data.searchValue}&key=${data.key}&pageno=${data.pageNumber}&pagesize=10`
  );
}

export function leaveRequest(data) {
  return axios.put(`${API_URL}/api/LeaveTypes/ApproveCancleLeave`, data);
}

export function permissionData() {
  return axios.get(`${API_URL}/api/Permission/GetPermissions`);
}

export function getRolesPermissions(roleId) {
  return axios.get(
    `${API_URL}/api/Permission/GetRoleByRoleId?roleId=${roleId}`
  );
}

export function importEmployeeExcelSheet(excelData) {
  return axios.post(`${API_URL}/api/AdminUser/AddUserFromCSV`, excelData);
}

export function checkUserPermissions() {
  return axios.get(
    `${API_URL}/api/Permission/GetAllPermissionwithSubPermission`
  );
}

export function getAdminProjectReport(data) {
  return axios.get(
    `${API_URL}/api/Reports/getProjectReportHourlyRate?projectid=${data.projectId}&startDate=${data.startDate}&endDate=${data.endDate}&pageNumber=${data.pageNumber}&pageSize=10`
  );
}

export function getUserToAssignTeam(teamid) {
  return axios.get(`${API_URL}/api/Team/GetUsersForTeam?teamId=${teamid}`);
}

export function subscriptonPlans() {
  return axios.get(`${API_URL}/api/Payment/GetSubscriptionPlans`);
}

export function selectedPlan(selectedPlanData) {
  return axios.post(
    `${API_URL}/api/Payment/CreateCheckoutSession`,
    selectedPlanData
  );
}

export function userDashboardScreenshot(date) {
  return axios.get(
    `${API_URL}/api/Dashboard/GetUserLatestScreenShot?date=${date}`
  );
}

export function userDashboardTime() {
  return axios.get(`${API_URL}/api/Dashboard/GetUserDashboardTimeInfo`);
}

export function changeHourlyRateByProjectId(userId, projectId) {
  return axios.get(
    `${API_URL}/api/HourlyRate/GetUserHourlyRateByProject?userId=${userId}&projectId=${projectId}`
  );
}

export function updateHourlyRate(data) {
  return axios.put(
    `${API_URL}/api/HourlyRate/UpdatedUserHourlyRateByProject?userId=${data.userId}&projectId=${data.projectId}&rate=${data.rate}&currency=${data.currency}`
  );
}

export function getTeamsDropDown() {
  return axios.get(`${API_URL}/api/Project/GetTeamsDropDown`);
}

export function getUsersDropDown() {
  return axios.get(`${API_URL}/api/Project/GetUsersDropDown`);
}

export function getSubscriptionPlans() {
  return axios.get(
    `${API_URL}/api/Payment/GetSubscriptionPlans`
  );
}

export function getSubscriptionDetails() {
  return axios.post(
    `${API_URL}/api/Payment/GetSubscriptionDetails`
  );
}

export function getUpdatedSubscription(data) {
  return axios.post(
    `${API_URL}/api/Payment/UpdatePaymentStatus`,
    data
  );
}

export function TeamsList(tenantname) {
  return axios.get(
    `${API_URL}/api/Team/TeamsDropdown?tenantname=${tenantname}`
  );
}

export function GetUserView(userid) {
  return axios.get(
    `${API_URL}/api/AdminUser/GetUserView?userId=${userid}`
  );
}

export function getBackgroundApplications(data) {
  return axios.get(
    `${API_URL}/api/ProductivityPolicies/GetUserUsedApplicaton?userId=${data.userid}&date=${data.date}`
  );
}

export function getPaymentInvoice() {
  return axios.get(
    `${API_URL}/api/Payment/GetUserPaymentHistory`
  );
}

export function disableIntegration(id) {
  return axios.delete(
    `${API_URL}/api/AzureProject/DeleteIntegrationById/${id}`
  );
}

export function enableIntegration(id) {
  return axios.delete(
    `${API_URL}/api/AzureProject/EnableIntegrationByIdAsync/${id}`
  );
}

export function updatePolicy() {
  return axios.post(
    `${API_URL}/api/Policy/UpdatePolicy`
  );
}

export function getOrgShit() {
  return axios.get(
    `${API_URL}/api/CompanySetting/GetShift`
  );
}

export function getOrgShitById(id) {
  return axios.get(
    `${API_URL}/api/CompanySetting/GetShiftById?shiftId=${id}`
  );
}

export function postOrgShit(data) {
  return axios.post(`${API_URL}/api/CompanySetting/PostShift`, data);
}

export function updateOrgShift(data) {
  return axios.put(
    `${API_URL}/api/CompanySetting/PutShift`, data);
}

export function disableTask(data) {
  return axios.delete(
    `${API_URL}/api/Task/DisableTask?taskId=${data}`
  );
}

export function enableTask(data) {
  return axios.post(
    `${API_URL}/api/Task/EnableTask?taskId=${data}`
  );
}

