import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { authUserLogout } from "../reduxAuthentication/actions";
import { checkJWTToken } from "../authApi/AuthCRUD";

import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as IconName from "react-icons/bs";
import "../css/style.css";
import Logo from "../Images/odysseylogo.png";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";

const SidebarSpace = styled.div`
  background: #fff;
  padding-bottom: 10px;
`;
const ProfileContent = styled.div`
  text-align: center;
  margin: 20px 5px;
`;
const LogoContent = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoName = styled.div`
  font-weight: 600;
  font-size: 25px;
  margin-left: 5px;
`;
const ProfileName = styled.div`
  font-weight: 600;
  font-size: 20px;
`;
const ProfileEmail = styled.div`
  font-size: 13px;
  color: gray;
`;

const Sidebar = (props) => {
  let localItem = useSelector((store) => store.auth.data, shallowEqual);
  const UserToken = localItem.Token;

  let navigate = useNavigate();
  let dispatch = useDispatch();
  const storedImg = useSelector(
    (store) => store.auth.userProfile,
    shallowEqual
  );

  let fullName = [];
  let initials = "";

  fullName = localItem.Full_Name.split(" ");

  if (fullName.length === 2) {
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  } else {
    initials = fullName.shift().charAt(0);
  }

  var UserName = localStorage.getItem("Full_Name");
  var UserEmail = localStorage.getItem("Email");

  const location = useLocation();
  let getlocation = location.pathname;

  const [open, setOpen] = React.useState(false);
  const [adminSelectOpen, setAdminSelectOpen] = React.useState(false);
  const [storedProfileImage, setStoredProfileImage] = useState("");

  useEffect(() => {
    // Auto logout user when token is expire
    if (!checkJWTToken(UserToken)) {
      localStorage.clear();
      dispatch(authUserLogout());
      navigate("/");
    } else {
      setStoredProfileImage(storedImg);
    }
  }, [storedImg]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAdminSelectClick = () => {
    setAdminSelectOpen(!adminSelectOpen);
  };

  return (
    <React.Fragment>
      <SidebarSpace
        className="h-100 overflow-auto bg-light flex-column sticky-top pl-0"
        id="custom-scroll"
      >
        <LogoContent>
          <div className="logoImage">
            <img data-cke-saved-src={Logo} src={Logo} alt="Logo" width={60} />
          </div>
          <LogoName>Odyssey</LogoName>
        </LogoContent>
        <hr style={{ margin: "0", height: "3px", color: "#CCCCCC" }} />
        <ProfileContent>
          <div className="ProfileContent">
            {storedProfileImage && storedProfileImage != "null" ? (
              <>
                <img
                  src={storedProfileImage}
                  alt="ProfileIamge"
                  className="ProfileIamge"
                />
              </>
            ) : (
              <>
                <div
                  className="ProfileIamge rounded-circle mb-3 d-flex justify-content-center"
                  style={{
                    width: " 70px",
                    height: "70px",
                    background:
                      "linear-gradient(264.71deg, #8F009B 18.58%, #420085 128.77%)",
                  }}
                >
                  <div className="d-flex align-items-center text-white fs-3 text-uppercase">
                    {initials}
                  </div>
                </div>
              </>
            )}

            <div className="sidebar-user-box mt-2">
              <h5 className="font-poppins-medium">{localItem.Full_Name}</h5>
              <span className="font-poppins-regular">{localItem.Email}</span>
            </div>
          </div>
          <ProfileName>{UserName}</ProfileName>
          <ProfileEmail>{UserEmail}</ProfileEmail>
        </ProfileContent>
        <hr style={{ margin: "0", height: "3px", color: "#CCCCCC" }} />
        <div className="sideNav ">
          <div className="menuContent mt-3">
            <ul>
              {props.userData.map((show) => {
                return (
                  <React.Fragment key={show.Id}>
                    {show.Name === "DashBoard" ? (
                      <li
                        className={`${
                          getlocation === "/dashboard" ? "active" : ""
                        }`}
                      >
                        <Link to="/dashboard" className="nav-items-link">
                          <div>
                            <AiIcons.AiFillHome className="icon" />
                          </div>
                          <div className="item">Dashboard</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {show.Name === "Screenshots" ? (
                      <li
                        className={`${
                          getlocation === "/screenshots" ? "active" : ""
                        }`}
                      >
                        <div>
                          <Link to="/screenshots" className="nav-items-link">
                            <div>
                              <RiIcons.RiScreenshot2Line className="icon" />
                            </div>
                            <div className="item">Screenshots</div>
                          </Link>
                        </div>
                      </li>
                    ) : (
                      ""
                    )}

                    {show.Name === "ApplyLeave" ? (
                      <li
                        className={`${
                          getlocation === "/show-leave"
                            ? "active"
                            : getlocation === "/apply-leave"
                            ? "active"
                            : getlocation === "/update-leave"
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link to="/show-leave" className="nav-items-link">
                          <div>
                            <AiIcons.AiOutlineUserAdd className="icon" />
                          </div>
                          <div className="item">Apply Leave</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {show.Name === "Reports" ? (
                      <>
                        <li
                          className={`${
                            getlocation === "/daily-attendance"
                              ? "active"
                              : getlocation === "/summary-report"
                              ? "active"
                              : getlocation === "/user-report"
                              ? "active"
                              : getlocation === "/user-timeline"
                              ? "active"
                              : getlocation === "/employee-logs"
                              ? "active"
                              : getlocation === "/project-report"
                              ? "active"
                              : ""
                          }`}
                        >
                          <div
                            className="nav-items-link"
                            onClick={handleClick}
                            role="button"
                          >
                            <div>
                              <IoIcons.IoIosPaper className="icon" />
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="item">Reports</div>&nbsp;&nbsp;
                              <div className="mt-2">
                                {open ? (
                                  <IoIcons.IoIosArrowUp />
                                ) : (
                                  <IoIcons.IoIosArrowDown />
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {show.SubView.map((view) => {
                              return (
                                <React.Fragment key={view.Id}>
                                  {view.SubViewName === "Daily Attendance" ? (
                                    <Link
                                      to="/daily-attendance"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/daily-attendance"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Daily Attendance
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {/* {view.SubViewName === "Summary Reports" ? (
                                    <Link
                                      to="/summary-report"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/summary-report"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Summary Reports
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )} */}

                                  {view.SubViewName === "User Reports" ? (
                                    <Link
                                      to="/user-report"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/user-report"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Employee Reports
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {view.SubViewName === "User Timeline" ? (
                                    <Link
                                      to="/user-timeline"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/user-timeline"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Employee Timeline
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {view.SubViewName === "Employees Logs" ? (
                                    <Link
                                      to="/employee-logs"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/employee-logs"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Logs
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {view.SubViewName === "Project Reports" ? (
                                    <Link
                                      to="/admin-project-report"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation ===
                                            "/admin-project-report"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Project Report
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    ) : (
                      ""
                    )}

                    {show.Name === "Task & Projects" ? (
                      <li
                        className={`${
                          getlocation === "/projects-list"
                            ? "active"
                            : getlocation === "/create-project"
                            ? "active"
                            : getlocation === "/project-details"
                            ? "active"
                            : getlocation === "/update-project"
                            ? "active"
                            : getlocation === "/project-tasks"
                            ? "active"
                            : getlocation === "/create-task"
                            ? "active"
                            : getlocation === "/edit-task"
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link to="/projects-list" className="nav-items-link">
                          <div>
                            <AiIcons.AiFillProject className="icon" />
                          </div>
                          <div className="item">Projects</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {show.Name === "Admin" ? (
                      <>
                        <li
                          className={`${
                            getlocation === "/admin"
                              ? "active"
                              : getlocation === "/add-user"
                              ? "active"
                              : getlocation === "/edit-user"
                              ? "active"
                              : getlocation === "/UserProfileView"
                              ? "active"
                              : getlocation === "/role"
                              ? "active"
                              : getlocation === "/create-role"
                              ? "active"
                              : getlocation === "/edit-role"
                              ? "active"
                              : getlocation === "/view-roles"
                              ? "active"
                              : getlocation === "/hourly-rate-list"
                              ? "active"
                              // : getlocation === "/productivity-policy"
                              // ? "active"
                              : getlocation === "/team"
                              ? "active"
                              : getlocation === "/view-team"
                              ? "active"
                              : getlocation === "/customer"
                              ? "active"
                              : getlocation === "/create-customer"
                              ? "active"
                              : getlocation === "/edit-customer"
                              ? "active"
                              : ""
                          }`}
                        >
                          <div
                            className="nav-items-link"
                            onClick={handleAdminSelectClick}
                            role="button"
                          >
                            <div>
                              <RiIcons.RiAdminLine className="icon" />
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="item">Admin</div>&nbsp;&nbsp;
                              <div className="mt-2">
                                {adminSelectOpen ? (
                                  <IoIcons.IoIosArrowUp />
                                ) : (
                                  <IoIcons.IoIosArrowDown />
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                        <Collapse
                          in={adminSelectOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {show.SubView.map((view) => {
                              return (
                                <React.Fragment key={view.Id}>
                                  {view.SubViewName === "Users" ? (
                                    <Link
                                      to="/admin"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/admin"
                                              ? "activeDropClass"
                                              : getlocation === "/add-user"
                                              ? "activeDropClass"
                                              : getlocation === "/edit-user"
                                              ? "activeDropClass"
                                              : getlocation ===
                                                "/UserProfileView"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Employees
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {view.SubViewName === "Role" ? (
                                    <Link to="/role" className="nav-items-link">
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/role"
                                              ? "activeDropClass"
                                              : getlocation === "/create-role"
                                              ? "activeDropClass"
                                              : getlocation === "/edit-role"
                                              ? "activeDropClass"
                                              : getlocation === "/view-roles"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Role & Permission
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {view.SubViewName === "User Hourly Rate" ? (
                                    <Link
                                      to="/hourly-rate-list"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/hourly-rate-list"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Employee Hourly Rate
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {/* {view.SubViewName ===
                                  "Productivity Policy" ? (
                                    <Link
                                      to="/productivity-policy"
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation ===
                                            "/productivity-policy"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Productivity Policy
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )} */}

                                  {view.SubViewName === "Team" ? (
                                    <Link to="/team" className="nav-items-link">
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation === "/team"
                                              ? "activeDropClass"
                                              : getlocation === "/view-team"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Team
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}

                                  {view.SubViewName === "Users" ? (
                                    <Link
                                      to="/user-used-applications
                                      "
                                      className="nav-items-link"
                                    >
                                      <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon></ListItemIcon>
                                        <IconName.BsDot className="icon" />
                                        <div
                                          className={`${
                                            getlocation ===
                                            "/user-used-applications"
                                              ? "activeDropClass"
                                              : "item dropMenu"
                                          }`}
                                        >
                                          Application Tracking
                                        </div>
                                      </ListItemButton>
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </List>
                        </Collapse>
                      </>
                    ) : (
                      ""
                    )}

                    {show.Name === "Leave" ? (
                      <li
                        className={`${
                          getlocation === "/get-leave-types"
                            ? "active"
                            : getlocation === "/view-all-leaves"
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link to="/get-leave-types" className="nav-items-link">
                          <div>
                            <AiIcons.AiOutlineUserDelete className="icon" />
                          </div>
                          <div className="item">Leave</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* {show.Name === "Integration" ? (
                      <li
                        className={`${
                          getlocation === "/integrations"
                            ? "active"
                            : getlocation === "/integrations-new"
                            ? "activeDropClass"
                            : ""
                        }`}
                      >
                        <Link to="/integrations" className="nav-items-link">
                          <div>
                            <AiIcons.AiOutlineAppstoreAdd className="icon" />
                          </div>
                          <div className="item">Integrations</div>

                          
                        </Link>
                      </li>
                    ) : (
                      ""
                    )} */}

                    {/* {show.Name === "Subscription" ? (
                      <li
                        className={`${
                          getlocation === "/subscription" ? "active" : ""
                        }`}
                      >
                        <Link to="/subscription" className="nav-items-link">
                          <div>
                            <IoIcons.IoIosAddCircle className="icon" />
                          </div>
                          <div className="item">Subscription Plans</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )} */}

                    {show.Name === "Company Setting" ? (
                      <li
                        className={`${
                          getlocation === "/company-settings" ? "active" : ""
                        }`}
                      >
                        <Link to="/company-settings" className="nav-items-link">
                          <div>
                            <IoIcons.IoIosSettings className="icon" />
                          </div>
                          <div className="item">Company Settings</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {show.Name === "Download" ? (
                      <li
                        className={`${
                          getlocation === "/download" ? "active" : ""
                        }`}
                      >
                        <Link to="/download" className="nav-items-link">
                          <div>
                            <AiIcons.AiOutlineDownload className="icon" />
                          </div>
                          <div className="item">Desktop Application</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* {show.Name === "Upload" ? (
                      <>
                        <li
                          className={`${
                            getlocation === "/upload-version-list"
                              ? "active"
                              : getlocation === "/upload-app"
                              ? "activeDropClass"
                              : getlocation === "/update-app"
                              ? "activeDropClass"
                              : getlocation === "/upload-app-details"
                              ? "activeDropClass"
                              : ""
                          }`}
                        >
                          <Link
                            to="/upload-version-list"
                            className="nav-items-link"
                          >
                            <div>
                              <AiIcons.AiOutlineUpload className="icon" />
                            </div>
                            <div className="item">Upload</div>
                          </Link>
                        </li>
                      </>
                    ) : (
                      ""
                    )}

                    {show.Name === "Email Template" ? (
                      <li
                        className={`${
                          getlocation === "/email-template"
                            ? "active"
                            : getlocation === "/edit-email-template"
                            ? "activeDropClass"
                            : ""
                        }`}
                      >
                        <Link to="/email-template" className="nav-items-link">
                          <div>
                            <FaIcons.FaEnvelope className="icon" />
                          </div>
                          <div className="item">Email Template</div>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </SidebarSpace>
    </React.Fragment>
  );
};
export default Sidebar;
