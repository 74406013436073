import React, { useState } from "react";
import { Header } from "../layoutComponent/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { getLeaveTypeById, updateLeave } from "../authApi/AuthCRUD";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddLeave.css";
import edit from "../Images/edit.svg";
import delet from "../Images/delet.svg";
import profile from "../Images/profile.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { getListLeaveType, searchProjectName } from "../authApi/AuthCRUD";
import { useEffect } from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="threedots" />
  </button>
));
const leaveSchema = Yup.object().shape({
  Leave_Name: Yup.string().required("Leave name is required"),
  Subject_Of_Approval: Yup.string().required("Subject is required"),
});
export default function EditLeaveType() {
  const navigate = useNavigate();
  let Authentication = useSelector((store) => store.auth.data, shallowEqual);
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();
  const L_Id = new URLSearchParams(search).get("id");
  const [Leave_Name, setLeave_Name] = useState("");
  const [Subject_Of_Approval, setSubject_Of_Approval] = useState("");
  const [Allow_Half_Leave, setAllow_Half_Leave] = useState(false);
  const [Allow_Quarter_Leave, setAllow_Quarter_Leave] = useState(false);
  const [Record_Without_Approval, setRecord_Without_Approval] = useState(false);

  const Tenant = Authentication.Tenant;
  const createdById = Authentication.User_Id;

  useEffect(() => {
    getSpecificLeaves();
  }, []);

  const getSpecificLeaves = () => {
    getLeaveTypeById(Tenant, L_Id)
      .then((response) => {
        console.log(response.data.Data);
        setLeave_Name(response.data.Data[0].Leave_Name);
        setSubject_Of_Approval(response.data.Data[0].Subject_Of_Approval);
        setAllow_Half_Leave(response.data.Data[0].Allow_Half_Leave);
        setAllow_Quarter_Leave(response.data.Data[0].Allow_Quarter_Leave);
        setRecord_Without_Approval(
          response.data.Data[0].Record_Without_Approval
        );
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  const [formerrors, setFormErrors] = useState({
    Leave_Name: "",
    Subject_Of_Approval: "",
  });

  const validate = (values) => {
    const errors = {};
    if (!values.Leave_Name.trim()) {
      errors.Leave_Name = "Leave name is required*";
    }
    if (!values.Subject_Of_Approval) {
      errors.Subject_Of_Approval = "Subject of approval is required*";
    }
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return false;
    } else {
      setFormErrors({
        Leave_Name: "",
        Subject_Of_Approval: "",
      });
      return true;
    }
  };

  function editLeaveType() {
    let data = {
      Tenantname: Tenant,
      Leave_Id: L_Id,
      Leave_Name: Leave_Name,
      Allow_Half_Leave: Allow_Half_Leave,
      Allow_Quarter_Leave: Allow_Quarter_Leave,
      Record_Without_Approval: Record_Without_Approval,
      Subject_Of_Approval: Subject_Of_Approval,
    };
    if (validate(data)) {
      setLoading(true);
      updateLeave(data)
        .then((res) => {
          setLoading(false);
          navigate("/get-leave-types");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err, "error");
        });
    }
  }

  return (
    <section className=" d-flex">
      <div className="container-fluid">
        <Header title="Edit Leave Type" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <section className="addUsers">
          <div className="container">
            <form>
              <div className="row mt-2">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 label-field mb-2 font-poppins-medium">
                      Leave Type<span className="text-danger">*</span>
                      <input
                        id="input-control"
                        type="text"
                        className="mt-2 form-control form-input-field"
                        autoComplete="off"
                        placeholder="Leave Type"
                        value={Leave_Name}
                        onChange={(e) => {
                          setLeave_Name(e.target.value);
                          setFormErrors({ ...formerrors, Leave_Name: "" });
                        }}
                      />
                    </label>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Leave_Name}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="form-group mt-3">
                    <label className="w-100 label-field mb-2 font-poppins-medium">
                      Subject of Approval<span className="text-danger">*</span>
                      <input
                        id="input-control"
                        type="text"
                        className="mt-2 form-control form-input-field"
                        autoComplete="off"
                        placeholder="Subject"
                        value={Subject_Of_Approval}
                        onChange={(e) => {
                          setSubject_Of_Approval(e.target.value);
                          setFormErrors({
                            ...formerrors,
                            Subject_Of_Approval: "",
                          });
                        }}
                      />
                    </label>
                  </div>
                  <div className="text-alert font-poppins-medium">
                    <span role="alert" className="formErr">
                      {formerrors.Subject_Of_Approval}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="form-group mt-2 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-4">
                  <div className="col-12">
                  <div className="form-check">
                      <input
                        className="form-check-input input-checkbox"
                        type="checkbox"
                        checked={Allow_Quarter_Leave}
                        onChange={(e) => {
                          setAllow_Quarter_Leave(e.target.checked);
                        }}
                      />
                      <span
                        className="font-poppins-regular"
                        id="remember_me_chkbox"
                      >
                        Full-day leave
                      </span>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input input-checkbox"
                        type="checkbox"
                        checked={Allow_Half_Leave}
                        onChange={(e) => {
                          setAllow_Half_Leave(e.target.checked);
                        }}
                      />
                      <span
                        className="font-poppins-regular"
                        id="remember_me_chkbox"
                      >
                        Half-day leave
                      </span>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input input-checkbox"
                        type="checkbox"
                        checked={Record_Without_Approval}
                        onChange={(e) => {
                          setRecord_Without_Approval(e.target.checked);
                        }}
                      />
                      <span
                        className="font-poppins-regular"
                        id="remember_me_chkbox"
                      >
                        Quarter-day leave
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-center align-items-center">
                <button
                  type="button"
                  className="btn buttonDefault me-2"
                  disabled={loading}
                  onClick={editLeaveType}
                >
                  {!loading && <span>Save</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Save
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <Link to="/get-leave-types" className="cancelbtnhover" style={{ color: "#fff" }}>
                  <button
                    type="button"
                    className="btn buttonDefault me-2 cancelbtnhover"
                    style={{
                      background: "#8A899C",
                    }}
                  >
                    {" "}
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </section>
      </div>
    </section>
  );
}
