import React, { useEffect, useState } from "react";
import { Header } from "../layoutComponent/Header";
import { useNavigate, useLocation } from "react-router-dom";
import backArrow from "../Images/livedashboard/back-arrow.svg";
import styled from "styled-components";
import { roleById } from "../authApi/AuthCRUD";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../css/style.css";


const Label = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  font-weight: bold;
  color: #616773;
`;
const Labels = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
`;



export const ViewRole = () => {
  const [permissionsList, setPermissionsList] = useState([]);
  const [roleName, setRoleName] = useState("");

  const navigate = useNavigate();

  const { search } = useLocation();
  let roleId = new URLSearchParams(search).get("roleid");

  const roleData = () => {
    roleById(roleId)
      .then((res) => {
        setPermissionsList(res.data.Data.Permissions);
        setRoleName(res.data.Data.Role_Name);
      })
      .catch((err) => {
        if (err.response.data.Status == "Expire") {
          navigate("/plan-expire");
        } else if (err.response.data.Status == "Unauthorize") {
          navigate("/unauthorize");
        } else {
          navigate("/forbidden");
        }
      });
  };

  useEffect(() => {
    roleData();
  }, []);

  return (
    <section className="m-0 d-flex">
      <div className="container-fluid">
        <Header title="View Roles" />
        <div className="text-start mt-3 mb-3">
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => navigate(-1)}
          >
            <i>
              <img src={backArrow} alt="back-Arrow" />
            </i>
            <span className="font-poppins-regular backBtn">
              &nbsp;&nbsp;&nbsp;Back
            </span>
          </div>
        </div>

        <section className="addUsers" style={{ padding: "30px 50px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="form-group mt-3">
                  <Label className="">Role Name</Label> :{" "}
                  <Labels>{roleName}</Labels>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 d-flex">
              <Label className="Permissions mt-3">Permissions : </Label> 
              <Labels className="font-poppins-medium" style={{marginTop: '18px', marginLeft: '6px'}}>
              {permissionsList &&
                permissionsList.map((item) => {
                  return (
                    <React.Fragment key={item.Permission_Id}>
                      {/* <Labels className="mt-1 font-poppins-medium" style={{marginLeft: '40px'}}> */}
                        {item.Permission_Name}
                      
                      {item.SubPermissions.map((sub) => {
                        return (
                          <React.Fragment key={item.Sub_Permission_Id}>
                            {sub.Status ? (
                              <li className="viewPernissions">
                                {sub.Display_Name}
                              </li>
                            ) : (
                              " "
                            )}
                          </React.Fragment>
                        );
                      })}
                      {/* </Labels> */}
                    </React.Fragment>
                  );
                })}
              </Labels>
              </div>
            </div>
            {/* <FormGroup>
            <Label className="Permissions mt-3">Permissions : </Label> 
              {permissionsList &&
                permissionsList.map((item) => {
                  return (
                    <React.Fragment key={item.Permission_Id}>
                      <Labels className="mt-1 font-poppins-medium" style={{marginLeft: '40px'}}>
                        {item.Permission_Name}
                      
                      {item.SubPermissions.map((sub) => {
                        return (
                          <React.Fragment key={item.Sub_Permission_Id}>
                            {sub.Status ? (
                              <li className="viewPernissions">
                                {sub.Display_Name}
                              </li>
                            ) : (
                              " "
                            )}
                          </React.Fragment>
                        );
                      })}
                      </Labels>
                    </React.Fragment>
                  );
                })}
            </FormGroup> */}
          </div>
        </section>
      </div>
    </section>
  );
};

export default ViewRole;
