import React from "react";
import NavPage from "./NavPage";
import Sidebar from "./Sidebar";
import "./Layout.css";

export default function Layout(props) {
  return (
    <React.Fragment>
      <div className="row" style={{ margin: "0", padding: "0" }}>
        <div className="d-none d-md-none d-lg-block d-xl-block col-lg-3 sidebar-column user-scrollbar">
          <Sidebar userData={props.userData} />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-9 dashboard-column">
          <NavPage userData={props.userData} />
        </div>
      </div>
    </React.Fragment>
  );
}
